export const settingsOptions = [
  {
    label: "All Worksites",
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "worksites",
  },
  {
    label: "Selected Worksites",
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "name",
    radioGroup: "worksites",
    dropdown: {
      placeholder: "Select a Worksite",
    },
  },
  {
    label: "All Asset Types",
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "assetTypes",
  },
  {
    label: "Selected Asset Types",
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "AssetTypeName",
    radioGroup: "assetTypes",
    dropdown: {
      placeholder: "Select an Asset Type",
    },
  },
  {
    label: "All Assets",
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "assets",
  },
  {
    label: "Selected Assets",
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "assetName",
    radioGroup: "assets",
    dropdown: {
      placeholder: "Select an Asset",
    },
  },
];
