import styled from "styled-components";
export const ManagementPanelWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  .card {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  header {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .bg-white {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .assetTableszOver {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .buttontabpanels span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .title-style,
  .asset-type-size,
  .asset-type-size span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .userTabBarStyle p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  span.tooltipMUI h2 {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .div_user_pin {
    .btn-success {
      color: #fff;
      background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"} !important;
      border-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"} !important;
    }
  }
`;

export const Error = styled.div``;
export const ParagraphDiv = styled.div`
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  margin: 10px auto;
  font-size: 18px !important;
  b {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;
