import styled from "styled-components";
import CalendarIcon from "../../../src/assets/Calendar.svg";

export const StyledLabel = styled.label`
  position: relative;
  height: 100%;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  font-size: 12px;
  color: rgb(145, 145, 145);
  left: 3px;
`;

export const CustomHeaderWrapper = styled.div`
  margin: 10;
  display: flex;
  justify-content: center;
`;

export const ReactDatePicker = styled.div`
  font-size: 13px !important;
  font-family: "Open Sans", sans-serif;
  position: relative;
  input {
    border-radius: 4px;
    font-family: "Open Sans", sans-serif;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #323232 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    margin-bottom: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    background: url(${CalendarIcon});
    background-repeat: no-repeat;
    background-size: 190% 50%;
    background-position: left;
    cursor: pointer;

    &:focus {
      border-color: #0d5fbe !important;
      border: 2px solid;
    }
  }
  .react-datepicker-wrapper {
    display: block;
  }
`;
