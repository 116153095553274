/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  Enterpirse Landing Page Routes
 */

import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "../../view/Landing/Home.component";
import ForgotPassword from "../../view/Landing/ForgotPassword/ForgotPasswordForm.component";
import ResetPasswordForm from "../../view/Landing/ForgotPassword/ResetPasswordForm.component";
import RegistrationPage from "../../view/Landing/RegistrationLanding.component";
import BackToLogin from "../../view/Landing/BackToLogin/BackToLogin";

// Success Pages
import RequestAccessSuccessPage from "../../view/Landing/RequestAccessSuccess/RequestAccessSuccess.component";
import PasswordRecoverySuccess from "../../view/Landing/PasswordRecoverSuccess/PasswordRecoverSuccess.component";

// Layout
import LandingLayout from "../../layouts/LandingLayout";

const LandingRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);
const EnterpriseLandingRoutes = () => (
  <>
    <Switch>
      {/* Forgot Password Page route for Enterprise*/}
      <LandingRoute
        layout={LandingLayout}
        exact
        path={["/forgotpassword", "/:companyUrl/forgotpassword"]}
        component={ForgotPassword}
      />
      <LandingRoute
        layout={LandingLayout}
        exact
        path={["/reset-password", "/reset-password/", "/reset-password/:token"]}
        component={ResetPasswordForm}
      />
      {/* Success Pages */}

      {/* Request Access Success route for Enterprise */}
      <LandingRoute
        layout={LandingLayout}
        exact
        path={("/:companyUrl/requestaccesssuccess", "/requestaccesssuccess")}
        component={RequestAccessSuccessPage}
      />
      {/* Passwordy Recovery Success Access route for Enterprise */}
      <LandingRoute
        layout={LandingLayout}
        exact
        path={["/:companyUrl/enterprisepasswordrecoverysuccess", "/enterprisepasswordrecoverysuccess"]}
        component={PasswordRecoverySuccess}
      />

      {/* Backtologin page on successfull password change */}
      <LandingRoute layout={LandingLayout} exact path={["/backtologin"]} component={BackToLogin} />

      {/* Landing Page route for Enterprise */}
      <LandingRoute layout={LandingLayout} exact path={["/:companyUrl", ""]} component={Home} />

      {/* Registration Page route for All */}
      <LandingRoute layout={LandingLayout} exact path="/user/resgistration" component={RegistrationPage} />
    </Switch>
  </>
);

export default EnterpriseLandingRoutes;
