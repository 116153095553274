import React from "react";
import { HashRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ThemeProvider } from "styled-components";
// Landing Routes
import LandingRoutes from "./routes/Landing/landingRoutes";
import DashboardLandingRoutes from "./routes/Dashboard/LandingRoutes";

import { REACT_APP_STRIPE_PUB_KEY_LIVE } from "./config";
import useController from "./AppController";
import AppContext from "./AppContext";
import Alert from "./components/Alert";
import ErrorRoutes from "./routes/ErrorRoutes";
import OfflineModal from "./OfflineModal";
import { baseTheme, darkTheme } from "./themes/index";
import "./App.css";
import "./assets/styles/styles.css";
import "./intro-custom.css";

if (process.env.REACT_APP_DISABLE_CONSOLE_LOGS === "true") {
  console.log = () => {};
}
if (process.env.REACT_APP_DISABLE_CONSOLE_ERROR === "true") {
  console.error = () => {};
}

if (process.env.REACT_APP_DISABLE_CONSOLE_WARN === "true") {
  console.warn = () => {};
}
if (process.env.REACT_APP_DISABLE_CONSOLE_INFO === "true") {
  console.info = () => {};
}
if (process.env.REACT_APP_DISABLE_CONSOLE_DEBUG === "true") {
  console.debug = () => {};
}

const promise = loadStripe(REACT_APP_STRIPE_PUB_KEY_LIVE);
const { Provider } = AppContext;

export default function App() {
  const { state, dispatch } = useController();

  return (
    <Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <Elements stripe={promise}>
        <div className="App" style={{ height: `calc(100% - ${1}px)` }}>
          {state.alert && (
            <Alert
              isSuccess={state.alert.type === "success"}
              isFailed={state.alert.type === "error"}
              label={state.alert.message}
              duration={state.alert.duration}
            />
          )}
          <HashRouter>
            <ThemeProvider theme={baseTheme}>
              <DashboardLandingRoutes />
              <LandingRoutes />
              <ErrorRoutes />
            </ThemeProvider>
          </HashRouter>
          <OfflineModal />
        </div>
      </Elements>
    </Provider>
  );
}
