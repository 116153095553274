import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

export default function S3ConfirmationModal(props) {
  const { modalShow, setModalShow, setEditData, editData, setModalShowClose, isCheckboxChecked } = props;

  const { t } = useTranslation();

  useEffect(() => {}, [modalShow]);

  return (
    <Modal
      size="lg"
      className="modalS3Top text-style custom-modal mainModelPop "
      show={modalShow}
      onHide={props.handleModalClose}
    >
      {/* modal body starts */}
      <Modal.Body className="text-center modalPopup">
        <Row className="mb-4 mt-4 ">
          <Col>
            <span className="h3 s3ModalText mb-2">
              <Trans>
                Are you sure you want to {isCheckboxChecked ? "enable" : "disable"} s3 from this asset
              </Trans>{" "}
              ?
            </span>
          </Col>
        </Row>
        <Row className="justify-content-center s3modalbuttonyes">
          <button
            onClick={() => {
              setEditData({ ...editData, s3enabled: isCheckboxChecked });
              setModalShow(false);
            }}
            className="btn btn-primary btn-lg mb-2"
          >
            <Trans>YES</Trans>
          </button>
        </Row>
        <Row className="justify-content-center s3modalbuttonno">
          <button onClick={() => setModalShowClose()} className="btn btn-lg mb-3">
            <Trans>CANCEL</Trans>
          </button>
        </Row>
      </Modal.Body>
      {/* Modal body ends */}
    </Modal>
  );
}
