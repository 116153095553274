import {
  CompanyIcon,
  Profile,
  ProfileTextContent,
  ProfileName,
  ProfileCompanyName,
  ProfileAccountName,
} from "./SidebarProfile.styles";
import CompanyDrawerIcon from "../../assets/enterprise_logo/default-logo.png";
import SieraLogo from "../../assets/siera_logo_navy.png";
import ProfileIcon from "../../assets/Profile_Photo.svg";

const s3BucketLogo = require("../../config").s3BucketLogo; //Logo s3 path
const s3BucketProfileImg = require("../../config").s3BucketProfileImg; //User profile image s3 path

const SidebarProfile = (props) => {
  const { isSuperAdmin, userDetails } = props;

  return (
    <div className="NavBarTopM">
      <CompanyIcon className="company-image">
        <img
          className="company-imageIcon"
          alt="Company Icon"
          src={
            isSuperAdmin ? SieraLogo : userDetails.logo ? s3BucketLogo + userDetails.logo : CompanyDrawerIcon
          }
        />
      </CompanyIcon>

      <Profile>
        <img
          className="rounded-circle user-imageIcon"
          alt="Profile"
          src={
            userDetails && userDetails.userData.imgData
              ? s3BucketProfileImg + userDetails.userData.imgData
              : ProfileIcon
          }
        />

        <ProfileTextContent>
          <ProfileName>
            <span className="user-details-name" title={userDetails && userDetails.userData.fullName}>
              {" "}
              {userDetails && userDetails.userData.fullName}
            </span>
          </ProfileName>

          <ProfileCompanyName>
            <span
              className="user-details-companyname"
              title={isSuperAdmin ? "SIERA.AI" : userDetails.companyName}
            >
              {isSuperAdmin ? "SIERA.AI" : userDetails.companyName}
            </span>
          </ProfileCompanyName>
          <ProfileAccountName>
            <span className="user-details-accountname" title={userDetails?.userData?.userType?.name}>
              {userDetails?.userData?.userType?.name}
            </span>
          </ProfileAccountName>
        </ProfileTextContent>
      </Profile>
    </div>
  );
};
export default SidebarProfile;
