import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setAddChecklistModal } from "../../../../redux/modals/action";
import {
  getResponseType,
  setUpdateEditTemplate,
  createChecklist,
  updateChecklist,
  resetChecklistSuccessFlag,
} from "../../../../redux/checklists/action";
import { Trans, useTranslation } from "react-i18next";

// Components
import SnackBar from "../../SnackBar";
import ChecklistOverview from "../Add/ChecklistOverview.component";
import ChecklistDetail from "../Add/ChecklistDetail.component";

import alertConstant from "../../../../constant/messages/toaster.json";
import validationConstant from "../../../../constant/messages/validation.json";

import {
  toValidateChecklistSectionName,
  toValidateChecklistQuestionName,
} from "../../../../utils/validations/checkValidation";

import CustomStepper from "../../../../components/StepperComponent/Stepper.Component";
import SuccessMessageModal from "../../../../components/successMessage/SuccessMessageModal.component";

import ModalPopup from "../../../../components/ModalPopupComponent/ModalPopup.component";

const AddQuestion = ({
  createChecklist,
  createChecklistLoading,
  createChecklistSuccess,
  addChecklistModal,
  setAddChecklistModal,
  responseTypeList,
  getResponseType,
  editTemplate,
  setUpdateEditTemplate,
  loginDetails,
  isSuperAdmin,
  dropdownAccountList,
  editChecklist,
  checklistOverviewDetail,
  updateChecklist,
  updateChecklistLoading,
  createChecklistSuccessFlag,
  resetChecklistSuccessFlag,
  createChecklistError,
}) => {
  const [formSection, setFormSection] = useState(0); // State for forms 0 for checklist oveview and 1 for checklist details
  const [checklistData, setChecklistData] = useState({});
  const [selectedSectionId, setSelectedSectionId] = useState(0); // to store selected question id
  const [addMessage, setAddMessage] = useState("");
  const [filterAccountId, setFilterAccountId] = useState("");
  const [singleMeterReading, setSingleMeterReading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [deleteSectionIds, setDeleteSectionIds] = useState([]);
  const [deleteQuestionIds, setDeleteQuestionIds] = useState([]);
  const [editSuccess, setEditSuccess] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    getResponseType();
    if (editTemplate) {
      setChecklistData(editTemplate);
      setUpdateEditTemplate(false);
    }
  }, []);

  useEffect(() => {
    if (!addChecklistModal) {
      setChecklistData({});
      setFormSection(0);
    }
  }, [addChecklistModal]);

  useEffect(() => {
    if (createChecklistSuccessFlag) {
      setAddChecklistModal(false);
    }
  }, [createChecklistSuccessFlag]);

  useEffect(() => {
    if (editChecklist) {
      if (!checklistOverviewDetail) {
        //console.log("not overview-----------");
        return;
      }
      let newChecklist = {
        _id: checklistOverviewDetail._id,
        checklistName: checklistOverviewDetail.checklistName,
        checklistDescription: checklistOverviewDetail.checklistDescription,
        accountId: checklistOverviewDetail.accountId,
        sections: {},
      };
      let newData = checklistOverviewDetail?.sections.map((section, i) => {
        newChecklist.sections[i] = { ...section, questions: {} };
        let sectionData = section?.questions.map((question, j) => {
          newChecklist.sections[i].questions[j] = {
            ...question,
            failedResponse: question?.failedResponse?.trim(),
            responseTypeId: question?.responseTypeId?._id,
            controlType: question?.responseTypeId?.controlType,
            failedResponseText: question?.responseTypeId?.responseText,
            responseText: question?.responseTypeId?.responseText,
          };
        });
      });
      setChecklistData(newChecklist);
      setAddChecklistModal(true);
    }
  }, [editChecklist]);

  const handleClose = () => {
    setSingleMeterReading(false);
    setAddMessage(false);
    resetChecklistSuccessFlag();
    setEditSuccess(false);
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    if (!filterAccountId && isSuperAdmin && !editChecklist) {
      setAddMessage(validationConstant.account_required);
      return;
    }
    let form = event.target;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setFormSection(1);
  };

  const handleAddChecklist = (e) => {
    if (!checklistData.sections) {
      setAddMessage(alertConstant.section_required);
      return;
    }
    let sections = Object.keys(checklistData.sections);
    if (sections.length === 0) {
      setAddMessage(alertConstant.section_required);
      return;
    }

    let isError = false;

    sections.map((key) => {
      let section = checklistData.sections[key];
      if (toValidateChecklistSectionName(section.sectionName)) {
        setAddMessage(t(toValidateChecklistSectionName(section.sectionName)) + " in section");
        isError = true;
      }
      let questions = Object.keys(section.questions);
      if (questions.length === 0) {
        setAddMessage(`${alertConstant.question_required} ${section.sectionName}`);
        isError = true;
      }
      questions.map((key) => {
        let question = section.questions[key];
        if (toValidateChecklistQuestionName(question.questionText)) {
          setAddMessage(t(toValidateChecklistQuestionName(question.questionText)) + " in question");
          isError = true;
        }
      });
    });

    if (isError) {
      //setIsLoading(false);
      return;
    }

    if (editChecklist) {
      checklistData.accountId = checklistData.accountId; //wtf
      let sections = checklistData.sections;
      let newChecklist = {
        checklistId: checklistData._id,
        checklistName: checklistData.checklistName,
        checklistDescription: checklistData.checklistDescription,
        accountId: checklistData.accountId,
        sections: [],
      };
      Object.keys(sections).map((key, i) => {
        newChecklist.sections.push({ ...sections[key], questions: [] });
        let questions = sections[key].questions;
        Object.keys(questions).map((key, j) => {
          newChecklist.sections[i].questions.push({
            ...questions[key],
            responseTypeId: questions[key]?.responseTypeId,
            controlType: questions[key]?.controlType,
            failedResponseText: questions[key]?.failedResponseText,
          });
        });
      });
      //updateChecklist(newChecklist, setAddChecklistModal);
      updateChecklist(newChecklist, () => {
        setEditSuccess(true); // Set edit success state
        setAddChecklistModal(false); // Close the modal
      });
    } else {
      checklistData.accountId = isSuperAdmin ? filterAccountId : loginDetails.accountId;
      createChecklist(checklistData, setAddChecklistModal);
      setSelectedSectionId(0);
    }
  };

  const handleCloseModal = () => {
    resetChecklistSuccessFlag();
    setEditSuccess(false);
  };

  const onClose = () => {
    setAddChecklistModal(false);
    setSelectedSectionId(0);
    resetChecklistSuccessFlag();
  };

  const handleAddAnotherChecklist = () => {
    handleCloseModal();
    setAddChecklistModal(true);
  };

  const allSteps = [
    { iconClass: "checklist-overview", heading: "Checklist Overview" },
    { iconClass: "checklist-details", heading: "Checklist Details" },
  ];

  return (
    <>
      {/* Success Message Modal  */}
      <SuccessMessageModal
        show={createChecklistSuccessFlag}
        propertyName={"Checklist"}
        handleCloseModal={handleCloseModal}
        handleAddAnother={handleAddAnotherChecklist}
      />

      <ModalPopup
        size="xl"
        centered
        show={addChecklistModal}
        backdrop="static"
        title={editChecklist ? <Trans>Edit Checklist</Trans> : <Trans>Add Checklist</Trans>}
        handleClose={onClose}
      >
        <>
          <CustomStepper allSteps={allSteps} activeStep={formSection} />
          {formSection == 0 ? (
            <ChecklistOverview
              editChecklist={editChecklist}
              setFormSection={setFormSection}
              checklistData={checklistData}
              setChecklist={setChecklistData}
              setFilterAccountId={setFilterAccountId}
              filterAccountId={filterAccountId}
              isSuperAdmin={isSuperAdmin}
              dropdownAccountList={dropdownAccountList}
              handleNextClick={handleNextClick}
              isValidated={validated}
            />
          ) : (
            <ChecklistDetail
              singleMeterReading
              addMessage
              setAddMessage={setAddMessage}
              setSingleMeterReading={setSingleMeterReading}
              createChecklistSuccess
              createChecklistLoading={createChecklistLoading}
              updateChecklistLoading={updateChecklistLoading}
              setFormSection={setFormSection}
              responseTypeList={responseTypeList}
              checklistData={checklistData}
              setChecklist={setChecklistData}
              setSelectedSectionId={setSelectedSectionId}
              selectedSectionId={selectedSectionId}
              handleAddChecklist={handleAddChecklist}
              setDeleteSectionIds={setDeleteSectionIds}
              setDeleteQuestionIds={setDeleteQuestionIds}
              deleteSectionIds={deleteSectionIds}
              deleteQuestionIds={deleteQuestionIds}
              editChecklist={editChecklist}
            />
          )}
        </>
      </ModalPopup>

      <SnackBar
        isFailed={createChecklistError}
        isSuccess={createChecklistSuccess || editSuccess}
        label={
          createChecklistSuccess
            ? "Checklist created successfully."
            : editSuccess
            ? "Checklist updated successfully."
            : "Error occurred while processing the request. Please try again later."
        }
        handleClose={handleClose}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
  getResponseType: () => dispatch(getResponseType()),
  setUpdateEditTemplate: (data) => dispatch(setUpdateEditTemplate(data)),
  createChecklist: (data, setAddChecklistModal) => dispatch(createChecklist(data, setAddChecklistModal)),
  updateChecklist: (data, setAddChecklistModal) => dispatch(updateChecklist(data, setAddChecklistModal)),
  resetChecklistSuccessFlag: () => dispatch(resetChecklistSuccessFlag()),
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistOverviewDetail: state.checklist.checklistOverview,
  createChecklistLoading: state.checklist.createChecklistLoading,
  createChecklistSuccess: state.checklist.createChecklistSuccess,
  createChecklistError: state.checklist.createChecklistError,
  updateChecklistLoading: state.checklist.updateChecklistLoading,
  addChecklistModal: state.modals.addChecklistModal,
  responseTypeList: state.checklist.responseTypeList,
  editTemplate: state.checklist.editTemplate,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  createChecklistSuccessFlag: state.checklist.createChecklistSuccessFlag,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddQuestion));
