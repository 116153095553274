module.exports = [
  {
    id: "accounts",
    label: "Accounts",
    section: "DASHBOARD",
    permissionName: "ACCOUNT_MANAGEMENT",
    actionName: "view",
    relativePath: "/app/management",
  },
  {
    id: "worksites",
    label: "Worksites",
    section: "DASHBOARD",
    permissionName: "WORKSITE_MANAGEMENT",
    actionName: "view",
    relativePath: "/app/management",
  },
  {
    id: "assets",
    label: "Assets",
    section: "DASHBOARD",
    permissionName: "ASSET_MANAGEMENT",
    actionName: "view",
    relativePath: "/app/management",
  },
  {
    id: "assetType",
    label: "Asset Types",
    section: "DASHBOARD",
    permissionName: "ASSET_TYPE_MANAGEMENT",
    actionName: "view",
    relativePath: "/app/management/assettype",
  },
  {
    id: "users",
    label: "Users",
    section: "DASHBOARD",
    permissionName: "USER_MANAGEMENT",
    actionName: "view",
    relativePath: "/app/management",
  },
  {
    id: "userType",
    label: "User Types",
    section: "DASHBOARD",
    permissionName: "USER_TYPE_MANAGEMENT",
    actionName: "view",
    relativePath: "/app/management/userType",
  },
];
