import React, { useState, useEffect } from "react";
import { Col, Nav, Tab } from "react-bootstrap";
import { Trans } from "react-i18next";

import "../CameraSetting.css";
import "../CameraSliderView/CameraHeightView.module.css";
import { camPositionToCamNumber } from "../constants";
import SimpleDropdownFilter from "../../../../../components/SimpleDropdownFilter.component";
import SCZCameraHeightView from "./camSliderView/SCZCameraHeightView";
import SCZCameraWidthView from "./camSliderView/SCZCameraWidthView";
import SCZCameraDepthView from "./camSliderView/SCZCameraDepthView";
import { defaultSpeedControlValue, styles } from "./constants";

const SpeedControlSetting = (props) => {
  const {
    cameraPosition,
    s3CameraForm,
    setS3CameraForm,
    getCameraRawObject,
    inputValue,
    setInputValue,
    steps,
    unitType,
    isFeet,
    switchStatus,
    s3SettingsUntouchedObject,
    selectedCamera,
  } = props;

  const [tabKey, setTabKey] = useState(1);
  const [forkliftClass, setForkliftClass] = useState("rear");
  const [obstacleTypeList, setObstacleTypeList] = useState([]);
  const [isHeight, setIsHeight] = useState(false);
  const [isDepth, setIsDepth] = useState(false);
  const [obstacle, setObstacle] = useState("sczPedestrian");
  const [value, setValue] = useState(defaultSpeedControlValue);

  const getSwitchStatus = () => {
    return switchStatus[selectedCamera];
  };

  useEffect(() => {
    const currentSwitchStatus = getSwitchStatus();
    let currentObstacleType = [];

    if (currentSwitchStatus?.pedestrian?.inMotion || currentSwitchStatus?.pedestrian?.inStationary) {
      currentObstacleType.push({ value: "sczPedestrian", label: "Pedestrian" });
    }
    if (currentSwitchStatus?.object?.inMotion || currentSwitchStatus?.object?.inStationary) {
      currentObstacleType.push({ value: "sczObject", label: "Object" });
    }
    setObstacleTypeList([...currentObstacleType]);
    setObstacle(
      currentSwitchStatus?.pedestrian?.inMotion || currentSwitchStatus?.pedestrian?.inStationary
        ? "sczPedestrian"
        : "sczObject"
    );
  }, [switchStatus]);

  useEffect(() => {
    setForkliftClass(cameraPosition?.[1]);
  }, [steps, cameraPosition]);

  useEffect(() => {
    setForkliftClass(selectedCamera);
  }, [selectedCamera]);

  useEffect(() => {
    if (tabKey === 1) {
      setIsHeight(false);
      setIsDepth(false);
    } else if (tabKey === 2) {
      setIsHeight(false);
      setIsDepth(true);
    } else {
      setIsHeight(true);
      setIsDepth(false);
    }
  }, [tabKey]);

  useEffect(() => {
    const camIndex = camPositionToCamNumber[selectedCamera] - 1;
    if (!s3CameraForm[camIndex]) {
      return;
    }
    let settingSczPedestrian = s3CameraForm[camIndex]["sczPedestrian"];
    let settingSczObject = s3CameraForm[camIndex]["sczObject"];
    let settingPedestrian = s3CameraForm[camIndex]["pedestrian"];
    let settingObject = s3CameraForm[camIndex]["object"];

    let newInputValue = {};

    newInputValue.pedestrian = {
      pos1: settingPedestrian.depth,
      pos2: settingPedestrian.left,
      pos3: settingPedestrian.right,
      pos4: settingPedestrian.top,
      pos5: settingPedestrian.bottom,
      pos6: settingPedestrian.middle,
      zones: settingPedestrian?.zones,
    };

    newInputValue.object = {
      pos1: settingObject.depth,
      pos2: settingObject.left,
      pos3: settingObject.right,
      pos4: settingObject.top,
      pos5: settingObject.bottom,
      pos6: settingObject.middle,
      zones: settingObject?.zones,
    };

    newInputValue.sczPedestrian = {
      pos2: settingSczPedestrian.left,
      pos3: settingSczPedestrian.right,
      pos4: settingSczPedestrian.top,
      pos5: settingSczPedestrian.bottom,
      pos6: settingSczPedestrian.middle,
      zones: settingSczPedestrian?.zones,
    };

    newInputValue.sczObject = {
      pos2: settingSczObject.left,
      pos3: settingSczObject.right,
      pos4: settingSczObject.top,
      pos5: settingSczObject.bottom,
      pos6: settingSczObject.middle,
      zones: settingSczObject?.zones,
    };

    setInputValue({ ...inputValue, ...newInputValue });
  }, [cameraPosition, selectedCamera, s3SettingsUntouchedObject]);

  const updateSpeedControl = (updatedObject) => {
    const { pos2, pos3, pos4, pos5, pos6, zones } = updatedObject[obstacle];

    s3CameraForm[camPositionToCamNumber[selectedCamera] - 1][obstacle] = {
      left: pos2,
      right: pos3,
      top: pos4,
      bottom: pos5,
      middle: pos6,
      zones: {
        one: zones?.one,
        two: zones?.two,
        three: zones?.three,
        four: zones?.four,
      },
    };
    setS3CameraForm({ ...s3CameraForm });
    setInputValue(updatedObject);
  };

  return s3CameraForm && Object.keys(s3CameraForm)?.length > 0 ? (
    obstacleTypeList.length !== 0 ? (
      <div className="camera-setting-container">
        <div className="d-flex justify-content-center">
          {isHeight ? (
            <SCZCameraHeightView
              setTabKey={setTabKey}
              getCameraRawObject={getCameraRawObject}
              obstacle={obstacle}
              value={value}
              setValue={setValue}
              inputValue={inputValue}
              setInputValue={updateSpeedControl}
              s3CameraForm={s3CameraForm}
              setS3CameraForm={setS3CameraForm}
              forkliftClass={forkliftClass}
              setForkliftClass={setForkliftClass}
              selectedCamera={selectedCamera}
              unitType={unitType}
              isFeet={isFeet}
              s3SettingsUntouchedObject={s3SettingsUntouchedObject}
              camIndex={camPositionToCamNumber[selectedCamera] - 1}
            />
          ) : isDepth ? (
            <SCZCameraDepthView
              setTabKey={setTabKey}
              getCameraRawObject={getCameraRawObject}
              obstacle={obstacle}
              value={value}
              setValue={setValue}
              inputValue={inputValue}
              setInputValue={updateSpeedControl}
              s3CameraForm={s3CameraForm}
              setS3CameraForm={setS3CameraForm}
              isDepth={isDepth}
              isHeight={isHeight}
              forkliftClass={forkliftClass}
              setForkliftClass={setForkliftClass}
              selectedCamera={selectedCamera}
              unitType={unitType}
              isFeet={isFeet}
              s3SettingsUntouchedObject={s3SettingsUntouchedObject}
              camIndex={camPositionToCamNumber[selectedCamera] - 1}
            />
          ) : (
            <SCZCameraWidthView
              setTabKey={setTabKey}
              getCameraRawObject={getCameraRawObject}
              obstacle={obstacle}
              value={value}
              setValue={setValue}
              inputValue={inputValue}
              setInputValue={updateSpeedControl}
              s3CameraForm={s3CameraForm[camPositionToCamNumber[selectedCamera] - 1]}
              setS3CameraForm={setS3CameraForm}
              isDepth={isDepth}
              isHeight={isHeight}
              forkliftClass={forkliftClass}
              setForkliftClass={setForkliftClass}
              selectedCamera={selectedCamera}
              unitType={unitType}
              isFeet={isFeet}
              s3SettingsUntouchedObject={s3SettingsUntouchedObject}
              camIndex={camPositionToCamNumber[selectedCamera] - 1}
            />
          )}
        </div>
        <div className="">
          <div className="">
            <div className="camera-setting-dropdown mt-1">
              <Col lg={4} className="pl-0">
                <div className="obstacle-select mb-1">
                  <SimpleDropdownFilter
                    value={obstacle}
                    filter={{
                      type: "cam-position",
                    }}
                    options={obstacleTypeList}
                    label="Obstacle"
                    handleFilter={(obj) => setObstacle(obj.value)}
                  />
                </div>
                <Tab.Container id="left-tabs-example" activeKey={tabKey} defaultActiveKey="1">
                  <Nav
                    variant="pills"
                    className="flex-row m-0 tab-setting"
                    style={{ backgroundColor: "#F2F2F2", width: "100%" }}
                  >
                    <Nav.Item
                      style={{ flex: "1" }}
                      onClick={() => {
                        setTabKey(1);
                        setIsHeight(false);
                        setIsDepth(false);
                      }}
                    >
                      <Nav.Link eventKey="1" className="p-2 pr-3 pl-3">
                        WIDTH
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      style={{ flex: "1" }}
                      onClick={() => {
                        setTabKey(2);
                        setIsHeight(false);
                        setIsDepth(true);
                      }}
                    >
                      <Nav.Link eventKey="2" className="p-2 pr-3 pl-3">
                        DEPTH
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      style={{ flex: "1" }}
                      onClick={() => {
                        setTabKey(3);
                        setIsHeight(true);
                      }}
                    >
                      <Nav.Link eventKey="3" className="p-2 pr-3 pl-3">
                        HEIGHT
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div style={styles.textStyle}>
                    {(tabKey === 1 || tabKey === 2 || tabKey === 3) && (
                      <span>
                        <Trans>Define the dimensions for the detection area</Trans>
                      </span>
                    )}
                  </div>
                </Tab.Container>
              </Col>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <p className="default-text text-open-sans">
        <Trans>
          Please turn on detection by obstacle type, in camera preferences, to define near-miss detection
          values.
        </Trans>
      </p>
    )
  ) : (
    <p>Loading.......</p>
  );
};

export default SpeedControlSetting;
