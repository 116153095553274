// Libraries
import React from "react";
import { Trans, useTranslation } from "react-i18next";

// Custom Styles
import Styles from "./TabsStyles.module.css";

function UserTabBar(props) {
  const { activeTab, setActiveTab, isOperator } = props;
  const { t } = useTranslation();
  const handleSelect = (e) => {
    console.log(e.target.value);
    setActiveTab(e.target.value);
  };

  return (
    <>
      <div className="userTabBarStyle">
        <div className={`${Styles.tabbar}`}>
          <p
            className={`${Styles.tabbaritem} ${activeTab === "userOverview" ? Styles.tabbaritemactive : ""}`}
            onClick={() => setActiveTab("userOverview")}
          >
            <Trans>User Overview</Trans>
          </p>

          <p
            className={`${Styles.tabbaritem} ${
              activeTab === "operatorCertificates" ? Styles.tabbaritemactive : ""
            }`}
            onClick={() => setActiveTab("operatorCertificates")}
          >
            <Trans>Operator Certification</Trans>
          </p>

          {isOperator && (
            <p
              className={`${Styles.tabbaritem} ${
                activeTab === "assetPermissions" ? Styles.tabbaritemactive : ""
              }`}
              onClick={() => setActiveTab("assetPermissions")}
            >
              <Trans>Asset Permissions</Trans>
            </p>
          )}
          {/*   
                    <p className={`${Styles.tabbaritem} ${activeTab === "inspectionReports" ? Styles.tabbaritemactive : ""}`} onClick={() => setActiveTab("inspectionReports")}><Trans>Inspection Reports</Trans></p>
                    */}
        </div>
        <div className={`${Styles.mobiletabbar}`}>
          <div>
            <select value={activeTab} onChange={(val) => handleSelect(val)}>
              <option value="userOverview">{t("User Overview")}</option>
              <option value="operatorCertificates">{t("Operator Certification")}</option>
              <option value="assetPermissions">{t("Asset Permissions")}</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserTabBar;
