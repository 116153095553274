import React, { useState } from "react";
import DataTableInspection from "../../../../../components/DataTableComponent/DataTable.component";
import { tableHeader, paginationOptions } from "../constants";
import { toDownloadFiles } from "../../../../../redux/config/action";
import awsConstant from "../../../../../constant/awsFolder.json";
import PopoverCustom from "../../../../../components/PopoverCustom.component";
import ReportDownload from "../../ReportShare/ReportShareModal.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Checkbox from "../../../../../components/Checkbox/Checkbox.component";
import moment from "moment-timezone";
import { getTimeWithTimeZone } from "../../../../../utils/timezoneConvert";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PassFail, StyledSpan } from "./TableWrapper.component.styles";

const InspectionReportsDataTable = ({
  data,
  loginDetails,
  isSuperAdmin,
  isSiteAdmin,
  inspectionReportList,
  toDownloadFiles,
  setIsSuccess,
  setIsError,
  fileDownloadLoading,
  handleCheckboxClick,
  handleSelectAll,
  downloadReportSet,
  isAllSelected,
  totalInspectionReport,
  handlePageLength,
  totalPageReport,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  selectedAccount,
  rowHeight,
}) => {
  const [loadingReportId, setLoadingReportId] = useState("");

  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.inspectionReport, `${id}.pdf`);
  };

  const viewReportClickHandler = (e, row) => {
    localStorage.setItem("unitType", loginDetails?.unitType);
    localStorage.setItem("inspectionReportObject", JSON.stringify(row));
    window.open("/#/app/inspection-report", "_blank");
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i title="View" onClick={(e) => viewReportClickHandler(e, row)} className="icon icon-view"></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
            ></i>
            {/* <i className="icon icon-share"></i> */}
            <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 1,
                        reportId: row._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>
          </div>
        )}
      </>
    );
  };

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          value={row._id}
          checked={downloadReportSet.has(row._id)}
        />
      ),
    },
  ];

  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: data.name,
          selector: (row) => row?.accountId?.companyName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Worksite") {
        return {
          name: data.name,
          selector: (row) => row.siteId?.name || "NA",
          sortable: true,
        };
      }
      if (data.name === "Asset Type") {
        return {
          name: data.name,
          selector: (row) => row?.assetId?.assetTypeId?.AssetTypeName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Asset Name") {
        return {
          name: data.name,
          selector: (row) => row?.assetId?.assetName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Inspected By") {
        return {
          name: data.name,
          selector: (row) => row?.inspectorId?.fullName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Actions") {
        return {
          name: data.name,
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }
      if (data.name === "Date, Time") {
        return {
          name: data.name,
          selector: (row) => {
            const inspectionDate = row?.inspectionAt;
            const timeZone = row?.siteId?.timeZone || "NA";

            const formattedDate = !inspectionDate
              ? "NA"
              : `${moment(inspectionDate)
                  .utcOffset(timeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm A")} ${getTimeWithTimeZone(timeZone)}`;

            return <span title={formattedDate}>{formattedDate}&nbsp;</span>;
          },
          sortable: true,
        };
      }

      if (data.name === "Report Status") {
        return {
          name: data.name,
          selector: (row) => {
            const status = row?.status || "NA";
            const checklistName = row?.checklistId?.checklistName || "NA";

            const statusMap = {
              passed: { className: "PassBorderDiv", message: "Pass" },
              failed: { className: "FailBorderDiv", message: "Fail" },
              timedout: { className: "TimedOutBorderDiv", message: "Timed Out" },
            };

            if (status in statusMap) {
              const { className, message } = statusMap[status];
              const tooltipContent = (
                <Tooltip id={`tooltip-${status}`}>
                  <span>{checklistName}</span>
                </Tooltip>
              );

              return (
                <PassFail>
                  <div id="button-container" className={className}>
                    <OverlayTrigger placement="top" overlay={tooltipContent}>
                      <div>
                        <span id="nameUserStyle">{checklistName}</span>
                        <span id="statusStyle">{message}</span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </PassFail>
              );
            }

            return status; // If status is not within expected values
          },
          sortable: true,
        };
      }

      return {
        name: data.name,
        selector: () => data.feildName,
        sortable: true,
      };
    });

  columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Account", className: "accountStyle" },
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Report Status", className: "reportStatusStyle" },
  ];

  const mobileCheckboxAccordian = ["Asset Type", "Asset Name", "Inspected By"];

  if (!Array.isArray(data)) {
    return <StyledSpan height={rowHeight}>No inspection reports available</StyledSpan>;
  }

  return (
    <>
      {!selectedAccount && isSuperAdmin ? (
        <StyledSpan height={rowHeight}>Please select an account to view the reports</StyledSpan>
      ) : (
        <DataTableInspection
          title=""
          columns={columns}
          data={data}
          pagination
          responsive
          dense
          rowsPerPageOptions={[10, 25, 50, 100]}
          loading
          rowHeight={"370"}
          scrollable={true}
          fixedHeader
          mobileCheckboxOnly
          mobileDateTimeOnly
          mobileActionItemOnly
          mobileCheckboxDateTime={mobileCheckboxDateTime}
          mobileCheckboxAccordion={mobileCheckboxAccordian}
          noDataMessage="No inspection reports available"
          pageSize={pageSize}
          pageNo={pageNo}
          totalPageReport={totalInspectionReport}
          handlePageLength={handlePageLength}
          paginationOptions={paginationOptions}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  inspectionReportList: state.reports.inspectionReportList,
  getInspectionReportListLoading: state.reports.getInspectionReportListLoading,
  totalInspectionReport: state.reports.inspectionReportTotalCount,
  assetOverview: state.assets.AssetOverview,
  getZipFileReportLoading: state.reports.getZipFileReportLoading,
  assetsList: state.assets.Assets,
  assetTypeList: state.assets.AssetTypes,
  inspectionReportFilter: state.filters.inspectionReportFilter,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
  exportAllInspectionCSVLoading: state.reports.exportAllInspectionCSVLoading,
  exportAllInspectionCSVSuccess: state.reports.exportAllInspectionCSVSuccess,
  exportAllInspectionCSVError: state.reports.exportAllInspectionCSVError,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionReportsDataTable));
