import React, { useState, useEffect, useRef } from "react";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import alertConstant from "../../../../constant/messages/toaster.json";
import SwitchComponent from "../../../../components/Switchbox/SwitchComponent";
import InputField from "../../../../components/InputFieldComponent/InputField.component";
import Button from "../../../../components/Button/Button.component";

import DropdownComponent from "../../../../components/DropdownComponent/Dropdown.component";

import {
  ChecklistDeatilStyled,
  DeleteIconStyled,
  ChecklistHeader,
  ChecklistContainer,
  ChecklistRowContainer,
  ChecklistMenuSideBarLeft,
  ChecklistDetailDescRight,
  ChecklistSideBarMenuUL,
  ChecklistSideBarMenuLI,
  CheckSectionName,
  AccordionDivCont,
  AccordionCardHeaderDiv,
  AccorDivTop,
  AccordDivHead,
  AccordHeadName,
  AccordianDetailDiv,
  AccordianDetailBody,
  AccordianDetailBodyDiv,
  SpanDiv,
  BorderLineDiv,
  BorderLineDivSE,
  AccordDataQuestText,
  AccordResponseType,
  OkNotOkDropdown,
  DeleteIconDiv,
  AccordianDetailBodyDivSe,
  AccordDataQuestTextSe,
  SwitchButtonDiv,
  DropdownOkNotOk,
  PleaseAddQuestion,
  ChecklistCreationDiv,
  ChecklistBottomButtons,
  ChecklistButtonDivLeft,
  ChecklistButtonDivRight,
  StyledInformationIcon,
  InformationDiv,
  InformationDivIcon,
  EmptySectionWrapper,
  ToggleWrapper,
  OnOffText,
  AccordianLeftData,
  AccordianRightData,
  OverlayTriggerDivPS,
  DeleteIconDivRespMobile,
  AddSectionMobileButton,
} from "./ChecklistDetail.component.Styles";

function ChecklistDetail({
  checklistData,
  setChecklist,
  setSelectedSectionId,
  selectedSectionId,
  responseTypeList,
  setFormSection,
  handleAddChecklist,
  createChecklistLoading,
  updateChecklistLoading,
  createChecklistSuccess,
  singleMeterReading,
  setSingleMeterReading,
  addMessage,
  setAddMessage,
  setDeleteSectionIds,
  setDeleteQuestionIds,
  deleteSectionIds,
  deleteQuestionIds,
  editChecklist,
}) {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [addQuestionDisabled, setAddQuestionDisabled] = useState(true);
  const [sectionData, setSectionData] = useState({});
  const [counter, setCounter] = useState(1);
  const [questionCounter, setQuestionCounter] = useState(1);
  const [section, setSection] = React.useState({
    randomiseQuestion: false,
    sectionStartNotification: false,
    enableIgnition: false,
    sectionQuestionResponseTimeStatus: false,
    sectionQuestionResponseTime: 60,
  });
  const [isMeterReadingData, setIsMeterReadingData] = useState(false);
  const [question, setQuestion] = useState({
    controlType: responseTypeList?.[0]?.controlType, //wtf + 1 for previous code
    failedResponseText: responseTypeList?.[0]?.failedResponse,
    failedResponse: responseTypeList?.[0]?.failedResponse,
    responseTypeText: responseTypeList?.[0]?.responseText,
    isQuestionMarkAsChecklistFail: false,
    responseTypeId: responseTypeList?.[0]?._id, //wtf + 1 for previous code
  });
  const [responseTypeListLocal, setResponseTypeLocal] = useState(responseTypeList);
  const [failResponseTypeDropdown, setFailResponseTypeDropdown] = useState([
    { value: "PASS", label: "PASS" },
    { value: "FAIL", label: "FAIL" },
  ]);

  const sectionRef = useRef(null);
  const questionRef = useRef(null);

  const { t } = useTranslation();

  const isMeterReadingQuestionPresent = (checklistData) => {
    let isPresent = false;
    for (const [sectionKey, sectionValue] of Object.entries(checklistData?.sections)) {
      for (const [questionKey, questionValue] of Object.entries(sectionValue["questions"])) {
        for (const [responseTextKey, responseTextValue] of Object.entries(questionValue)) {
          if (responseTextKey === "responseText") {
            console.log(`${responseTextKey}: ${responseTextValue}`);
            if (
              responseTextValue === "Meter Reading (Optional)" ||
              responseTextValue === "Meter Reading (Required)"
            ) {
              isPresent = true;
            }
            if (responseTextValue == "OK, NOT OK" || responseTextValue == "OK, NOT OK, NA") {
              setFailResponseTypeDropdown([
                { value: "OK", label: "OK" },
                { value: "NOT OK", label: "NOT OK" },
              ]);
            }
            if (responseTextValue == "PASS, FAIL" || responseTextValue == "PASS, FAIL, NA") {
              setFailResponseTypeDropdown([
                { value: "PASS", label: "PASS" },
                { value: "FAIL", label: "FAIL" },
              ]);
            }
            if (responseTextValue == "YES, NO" || responseTextValue == "YES, NO, NA") {
              setFailResponseTypeDropdown([
                { value: "YES", label: "YES" },
                { value: "NO", label: "NO" },
              ]);
            }
          }
        }
      }
    }
    return isPresent;
  };

  useEffect(() => {
    if (!editChecklist && !checklistData.sections) {
      addSection();
    }

    const sortedResponseTypeList = responseTypeListLocal?.toSorted((a, b) =>
      a.responseText?.localeCompare?.(b.responseText)
    );

    setResponseTypeLocal(sortedResponseTypeList);
  }, []);

  useEffect(() => {
    if (checklistData.sections) {
      setSectionData(checklistData.sections[selectedSectionId]);
    }
    setAddQuestionDisabled(
      checklistData.sections ? !(Object.keys(checklistData.sections)?.length > 0) : true
    );
    sectionRef?.current?.scrollIntoView();

    console.log(checklistData);

    if (isMeterReadingQuestionPresent(checklistData)) {
      console.log("YES");
      const updatedResponseTypeList = responseTypeListLocal?.filter((responseType) => {
        if (
          responseType.responseText === "Meter Reading (Optional)" ||
          responseType.responseText === "Meter Reading (Required)"
        ) {
          return false;
        }
        return true;
      });
      console.log("FILTERED LIST", updatedResponseTypeList);
      setResponseTypeLocal(updatedResponseTypeList);
    } else {
      console.log("NO");
      const sortedResponseTypeList = responseTypeList?.toSorted((a, b) =>
        a.responseText?.localeCompare?.(b.responseText)
      );

      setResponseTypeLocal(sortedResponseTypeList);
    }
  }, [checklistData]);

  useEffect(() => {
    console.log(responseTypeListLocal);
  }, [responseTypeListLocal]);

  const addSection = (e) => {
    if (!checklistData.sections) {
      checklistData.sections = {};
    }
    let id = Date.now();
    checklistData.sections[id] = {
      notificationMessage: `Notification message ${counter}`,
      questions: {},
      randomiseQuestion: true,
      sectionDescription: `New description ${counter}`,
      sectionName: `Section name ${counter}`,
      sectionStartNotification: true,
      enableIgnition: false,
      sectionQuestionResponseTimeStatus: false,
      sectionQuestionResponseTime: 60,
    };
    setCounter(counter + 1);
    setChecklist({ ...checklistData });
    setSelectedSectionId(id);
    delete section["questions"];
    setSection({});
  };

  const addQuestion = (e) => {
    if (question.failedResponseText === "Meter Reading") {
      setIsMeterReadingData(true);
    }
    if (question.failedResponseText === "Meter Reading" && isMeterReadingData) {
      setSingleMeterReading(alertConstant.one_meter_reading_allowed);
      return;
    }
    if (!checklistData.sections[selectedSectionId].questions) {
      checklistData.sections[selectedSectionId].questions = {};
    }
    let id = Date.now();
    checklistData.sections[selectedSectionId].questions[id] = {
      controlType: responseTypeList?.[0]?.controlType, //wtf + 1 for previous code
      failedResponseText: responseTypeList?.[0]?.failedResponse,
      failedResponse: responseTypeList?.[0]?.failedResponse,
      responseTypeText: responseTypeList?.[0]?.responseText,
      responseText: responseTypeList?.[0]?.responseText,
      isQuestionMarkAsChecklistFail: false,
      responseTypeId: responseTypeList?.[0]?._id,
      questionText: `Question No ${questionCounter}`,
      questionResponseTime: checklistData?.sections?.[selectedSectionId]?.sectionQuestionResponseTime,
    };
    setQuestionCounter(questionCounter + 1);
    setChecklist({ ...checklistData });
    setQuestion({
      failedResponse: responseTypeList?.[0]?.failedResponse,
      response: responseTypeList?.[0]?.responseText,
    });
    setTimeout(() => {
      questionRef?.current?.scrollIntoView();
    }, 200);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <Trans>
        Enabling this option will Fail the entire checklist if the user enters the ‘fail response’ to this
        question
      </Trans>
    </Tooltip>
  );

  const renderTooltipMessage = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <Trans>{props.message}</Trans>
    </Tooltip>
  );

  const CustomOverlayTrigger = (props) => {
    return (
      <OverlayTriggerDivPS>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltipMessage({ message: props.message })}
        >
          <InformationDivIcon>
            <StyledInformationIcon className="icon icon-information-tooltip" />
          </InformationDivIcon>
        </OverlayTrigger>
      </OverlayTriggerDivPS>
    );
  };

  const setSectionDataById = (e) => {
    e.preventDefault();
    setSelectedSectionId(e.currentTarget.id);
    setSectionData(checklistData?.sections[e.currentTarget.id]);
  };

  const setSectionFormData = (e) => {
    setSectionData({ ...sectionData, [e.target.name]: e.target.value });
    if (checklistData.sections) {
      checklistData.sections[selectedSectionId][e.target.name] = e.target.value;
      setChecklist({ ...checklistData });
    }
  };

  const validateSectionResponseTime = (name, value) => {
    //restrict the value between 60 and 300
    const calculatedValue = value > 300 ? 300 : value < 15 ? 15 : value;
    checklistData.sections[selectedSectionId][name] = calculatedValue;
    //set the same value to question response time too
    const updatedSectionData = Object.keys(sectionData.questions).map(
      (key, i) => (sectionData.questions[key].questionResponseTime = calculatedValue)
    );
    setSectionData(updatedSectionData);
    setChecklist({ ...checklistData });
  };

  const setQuestionFormData = (e) => {
    const { id: questionId, name, value } = e.target;
    checklistData.sections[selectedSectionId].questions[questionId][name] = value;
    setSectionData({ ...checklistData.sections[selectedSectionId] });
    setChecklist({ ...checklistData });
  };

  const setQuestionFormDataForDropdown = (selectedData, id, name) => {
    checklistData.sections[selectedSectionId].questions[id][name] = selectedData.value;
    setSectionData({ ...checklistData.sections[selectedSectionId] });
    setChecklist({ ...checklistData });
  };

  const validateQuestionResponseTime = (name, id, value) => {
    //restrict the value between 60 and 300
    const calculatedValue = value > 300 ? 300 : value < 15 ? 15 : value;
    checklistData.sections[selectedSectionId].questions[id][name] = calculatedValue;
    setSectionData({ ...checklistData.sections[selectedSectionId] });
    setChecklist({ ...checklistData });
  };

  const setResponseType = (selectedData, key, data) => {
    const { controlType, responseText, value, failedResponse } = selectedData;
    const previousReponseText = checklistData?.sections?.[selectedSectionId]?.questions?.[key]?.responseText;
    // console.log("Selected Data : ",selectedData);

    if (responseText === "Meter Reading") {
      setIsMeterReadingData(true);
    }
    if (previousReponseText === "Meter Reading") {
      setIsMeterReadingData(false);
      setSingleMeterReading(false);
    }
    if (responseText === "Meter Reading" && isMeterReadingData) {
      setSingleMeterReading(alertConstant.one_meter_reading_allowed);
      return;
    }

    checklistData.sections[selectedSectionId].questions[key].responseTypeId = value;
    checklistData.sections[selectedSectionId].questions[key].controlType = controlType;
    checklistData.sections[selectedSectionId].questions[key].responseText = responseText;
    checklistData.sections[selectedSectionId].questions[key].failedResponse = failedResponse;
    checklistData.sections[selectedSectionId].questions[key].isQuestionMarkAsChecklistFail = false;
    setSectionData({ ...checklistData.sections[selectedSectionId] });
    setChecklist({ ...checklistData });
  };

  const setQuestionCheckbox = (e) => {
    let questionId = e.target.id;
    checklistData.sections[selectedSectionId].questions[questionId][e.target.name] = e.target.checked;
    setSectionData({ ...checklistData.sections[selectedSectionId] });
    setChecklist({ ...checklistData });
  };

  const setSectionCheckbox = (e) => {
    setSectionData({ ...sectionData, [e.target.name]: e.target.checked });
    if (checklistData.sections) {
      checklistData.sections[selectedSectionId][e.target.name] =
        !checklistData.sections[selectedSectionId][e.target.name];
      setChecklist({ ...checklistData });
    }
  };

  const removeSection = (e) => {
    e.stopPropagation();
    let ids = e.currentTarget.id?.split(",");
    let id = ids[0];
    let _id = ids[1];
    if (_id !== "undefined") {
      let idList = [...deleteSectionIds];
      idList.push(_id);
      setDeleteSectionIds(idList);
    }

    delete checklistData.sections[id];
    setChecklist({ ...checklistData });
    let firstSectionId = Object.keys(checklistData.sections)?.[0];
    if (parseInt(selectedSectionId) === parseInt(id) && firstSectionId) {
      setSelectedSectionId(firstSectionId);
      setSectionData(checklistData.sections[firstSectionId]);
    } else {
      setSelectedSectionId(selectedSectionId);
    }
  };

  const removeQuestion = (e) => {
    let ids = e.currentTarget.id?.split(",");
    let id = ids[0];
    let _id = ids[1];
    if (_id !== "undefined") {
      let idList = [...deleteQuestionIds];
      idList.push(_id);
      setDeleteQuestionIds(idList);
    }

    if (checklistData?.sections?.[selectedSectionId]?.questions?.[id]?.responseText === "Meter Reading") {
      setIsMeterReadingData(false);
      setSingleMeterReading(false);
    }
    delete checklistData.sections[selectedSectionId].questions[id];
    setChecklist({ ...checklistData });
    setSectionData(checklistData?.sections?.[selectedSectionId]);
  };

  const isSubmitDisabled = () => {
    let disabled = false;
    checklistData?.sections && Object.keys(checklistData.sections).length > 0
      ? Object.keys(checklistData.sections).map((key, i) => {
          const section = checklistData?.sections[key];
          if (section?.sectionName?.trim() === "" || Object.keys(section.questions).length === 0) {
            disabled = true;
          }
          if (section?.questions && Object.keys(section?.questions)?.length > 0) {
            sectionData?.questions &&
              Object.keys(sectionData?.questions)?.map((key, i) => {
                const question = sectionData?.questions?.[key];
                if (question?.questionText?.trim() === "") {
                  disabled = true;
                }
              });
          }
        })
      : (disabled = true);
    return disabled;
  };

  return (
    <ChecklistDeatilStyled>
      <ChecklistHeader>
        <Trans>Checklist Details</Trans>
      </ChecklistHeader>
      <ChecklistContainer>
        <>
          <>
            {checklistData?.sections && Object.keys(checklistData.sections)?.[0] ? (
              <ChecklistRowContainer>
                <ChecklistMenuSideBarLeft>
                  <ChecklistSideBarMenuUL>
                    {checklistData.sections
                      ? Object.keys(checklistData.sections)?.map((key, i) => {
                          let section = checklistData?.sections[key];
                          return (
                            <ChecklistSideBarMenuLI
                              onClick={setSectionDataById}
                              selected={selectedSectionId == key}
                              id={key}
                              key={i}
                              className={`${
                                selectedSectionId == key ? "SidebarSelected" : "SidebarUnSelected"
                              }`}
                            >
                              <CheckSectionName title={section.sectionName}>
                                <Trans>{section.sectionName}</Trans>
                              </CheckSectionName>

                              <DeleteIconStyled
                                onClick={removeSection}
                                id={key + "," + section._id}
                                className="icon icon-delete"
                              ></DeleteIconStyled>
                            </ChecklistSideBarMenuLI>
                          );
                        })
                      : null}
                    <span ref={sectionRef}></span>
                  </ChecklistSideBarMenuUL>

                  <AddSectionMobileButton>
                    <Button
                      className="addSectioButtonMobile"
                      iconClass="add"
                      label={t("Add Section")}
                      onClick={() => addSection()}
                      showIcon
                      buttonType="secondary"
                    />
                  </AddSectionMobileButton>
                </ChecklistMenuSideBarLeft>

                {/* Section Overview Starts */}

                {sectionData && Object.keys(sectionData)?.length > 0 ? (
                  <ChecklistDetailDescRight>
                    <Accordion className="border-0">
                      <AccordionDivCont
                        className={`${accordionOpen == 1 ? "borderWhiteCont" : "borderBlueCont"}`}
                      >
                        <AccordionCardHeaderDiv>
                          <Accordion.Toggle as={AccordionCardHeaderDiv} eventKey="0">
                            <AccorDivTop
                              className={`${accordionOpen == 1 ? "theadGrayStyle" : "theadBlueStyle"}`}
                              onClick={() => {
                                setAccordionOpen(!accordionOpen);
                              }}
                            >
                              <AccordDivHead className={`${accordionOpen ? "textColoBl" : "textColoWh"}`}>
                                <AccordHeadName>
                                  <Trans>Section Details</Trans>
                                </AccordHeadName>
                              </AccordDivHead>
                            </AccorDivTop>
                          </Accordion.Toggle>

                          <AccordDivHead
                            onClick={() => {
                              setAccordionOpen(false);
                            }}
                          >
                            <AccordHeadName>
                              <InputField
                                value={sectionData ? sectionData.sectionName : ""}
                                onChange={setSectionFormData}
                                name="sectionName"
                                size="small"
                                label={t("Section Name *")}
                              />
                            </AccordHeadName>
                            <AccordHeadName>
                              <InputField
                                value={sectionData ? sectionData.sectionDescription : ""}
                                onChange={setSectionFormData}
                                name="sectionDescription"
                                size="small"
                                label={t("Section Description")}
                              />
                            </AccordHeadName>
                          </AccordDivHead>
                          <BorderLineDiv />
                        </AccordionCardHeaderDiv>
                        {!accordionOpen && (
                          <AccordianDetailDiv eventKey="0">
                            <AccordianDetailBody>
                              <AccordianDetailBodyDiv>
                                <AccordianLeftData>
                                  <SpanDiv>
                                    <Trans>Randomize Question Order</Trans>

                                    <CustomOverlayTrigger message="Enable this option if you wish to randomize the questions in this section, appearing to the operator"></CustomOverlayTrigger>
                                  </SpanDiv>
                                  <ToggleWrapper>
                                    <OnOffText>{sectionData?.randomiseQuestion ? "On" : "Off"}</OnOffText>
                                    <SwitchComponent
                                      checked={sectionData ? sectionData.randomiseQuestion : false}
                                      onClick={setSectionCheckbox}
                                      name="randomiseQuestion"
                                    />
                                  </ToggleWrapper>
                                </AccordianLeftData>
                              </AccordianDetailBodyDiv>

                              <BorderLineDiv />

                              <AccordianDetailBodyDiv minHeight={"67px"}>
                                <AccordianLeftData>
                                  <SpanDiv>
                                    <Trans>Section Start Notification</Trans>

                                    <CustomOverlayTrigger message="Enable this option if you want to leave a custom message for the operator before they start answering the questions of this section"></CustomOverlayTrigger>
                                  </SpanDiv>
                                  <ToggleWrapper>
                                    <OnOffText>
                                      {sectionData?.sectionStartNotification ? "On" : "Off"}
                                    </OnOffText>
                                    <SwitchComponent
                                      checked={sectionData ? sectionData.sectionStartNotification : false}
                                      onClick={setSectionCheckbox}
                                      name="sectionStartNotification"
                                    />
                                  </ToggleWrapper>
                                </AccordianLeftData>

                                <AccordianRightData>
                                  {sectionData?.sectionStartNotification && (
                                    <InputField
                                      //  className="accorSpan"
                                      value={sectionData ? sectionData.notificationMessage : ""}
                                      onChange={setSectionFormData}
                                      name="notificationMessage"
                                      size="small"
                                      label={t("Section Start Notification Message *")}
                                      //   variant="outlined"
                                    />
                                  )}
                                </AccordianRightData>
                              </AccordianDetailBodyDiv>

                              <BorderLineDiv />

                              <AccordianDetailBodyDiv>
                                <AccordianLeftData>
                                  <SpanDiv>
                                    <Trans>Enable Ignition</Trans>

                                    <CustomOverlayTrigger message="Enabling this option will Turn On the ignition of the vehicle when answering this question."></CustomOverlayTrigger>
                                  </SpanDiv>
                                  <ToggleWrapper>
                                    <OnOffText>{sectionData?.enableIgnition ? "On" : "Off"}</OnOffText>
                                    <SwitchComponent
                                      checked={sectionData ? sectionData.enableIgnition : false}
                                      onClick={setSectionCheckbox}
                                      name="enableIgnition"
                                    />
                                  </ToggleWrapper>
                                </AccordianLeftData>
                              </AccordianDetailBodyDiv>

                              <BorderLineDiv />

                              <AccordianDetailBodyDiv minHeight={"67px"}>
                                <AccordianLeftData>
                                  <SpanDiv>
                                    <Trans>Question Response Time</Trans>

                                    <CustomOverlayTrigger message="Define the maximum required response time for a question. Changing the time here will apply it to ALL questions within the section. You can also change the individual required time for each question within the section"></CustomOverlayTrigger>
                                  </SpanDiv>
                                  <ToggleWrapper>
                                    <OnOffText>
                                      {sectionData?.sectionQuestionResponseTimeStatus ? "On" : "Off"}
                                    </OnOffText>
                                    <SwitchComponent
                                      checked={
                                        sectionData ? sectionData.sectionQuestionResponseTimeStatus : false
                                      }
                                      onClick={setSectionCheckbox}
                                      name="sectionQuestionResponseTimeStatus"
                                    />
                                  </ToggleWrapper>
                                </AccordianLeftData>
                                <AccordianRightData>
                                  {sectionData?.sectionQuestionResponseTimeStatus && (
                                    <InputField
                                      onChange={setSectionFormData}
                                      onBlur={() =>
                                        validateSectionResponseTime(
                                          "sectionQuestionResponseTime",
                                          sectionData?.sectionQuestionResponseTime
                                        )
                                      }
                                      value={sectionData ? sectionData.sectionQuestionResponseTime : 150}
                                      name="sectionQuestionResponseTime"
                                      size="small"
                                      label={t("Response Timeout(in seconds)")}
                                      type="number"
                                    />
                                  )}
                                </AccordianRightData>
                              </AccordianDetailBodyDiv>
                            </AccordianDetailBody>
                          </AccordianDetailDiv>
                        )}
                      </AccordionDivCont>
                    </Accordion>
                    <br />

                    <AccordionDivCont
                      className={`${accordionOpen == 1 ? "borderBlueCont" : " borderWhiteCont"}`}
                    >
                      <AccorDivTop
                        className={`${accordionOpen == 1 ? " theadBlueStyle" : "theadGrayStyle"}`}
                        onClick={() => {
                          setAccordionOpen(!accordionOpen);
                        }}
                      >
                        <AccordDivHead className={`${accordionOpen ? "textColoBl" : "textColoWh"}`}>
                          <AccordHeadName>
                            <Trans>Question Details</Trans>
                          </AccordHeadName>
                        </AccordDivHead>
                      </AccorDivTop>

                      <AccordianDetailDiv>
                        {sectionData?.questions && Object.keys(sectionData.questions)[0] ? (
                          Object.keys(sectionData.questions)?.map((key, i) => {
                            let data = sectionData.questions?.[key];

                            const selectedOption = responseTypeList?.find((type) => {
                              return data?.responseTypeId === type?._id;
                            });
                            const selectedValue = {
                              label: selectedOption?.responseText,
                              value: selectedOption?._id,
                              responseText: selectedOption?.responseText,
                              controlType: selectedOption?.controlType,
                            };

                            return (
                              <AccordianDetailBody key={i}>
                                <AccordianDetailBodyDivSe>
                                  <AccordDataQuestText
                                    onClick={() => {
                                      setAccordionOpen(true);
                                    }}
                                  >
                                    <InputField
                                      name="questionText"
                                      onChange={setQuestionFormData}
                                      id={key}
                                      value={data.questionText}
                                      //  rows={accordionOpen ? 3 : 1}
                                      size="small"
                                      label={t("Question Title")}
                                    />
                                  </AccordDataQuestText>

                                  <AccordResponseType>
                                    <DropdownComponent
                                      label={"Response Type"}
                                      options={responseTypeListLocal?.map((data) => ({
                                        label: data.responseText,
                                        value: data._id,
                                        responseText: data.responseText,
                                        controlType: data.controlType,
                                        failedResponse: data.failedResponse,
                                      }))}
                                      value={selectedValue}
                                      id={key}
                                      name="failedResponse"
                                      handleFilter={(selectedData) =>
                                        setResponseType(selectedData, key, data)
                                      }
                                    />
                                  </AccordResponseType>

                                  <DeleteIconDiv>
                                    <DeleteIconStyled
                                      onClick={removeQuestion}
                                      id={key + "," + data._id}
                                      className="icon icon-delete"
                                    ></DeleteIconStyled>
                                  </DeleteIconDiv>
                                </AccordianDetailBodyDivSe>
                                <AccordianDetailBodyDivSe minHeight={"67px"}>
                                  <AccordDataQuestTextSe>
                                    {sectionData?.sectionQuestionResponseTimeStatus && (
                                      <InputField
                                        name="questionResponseTime"
                                        onChange={setQuestionFormData}
                                        onBlur={() =>
                                          validateQuestionResponseTime(
                                            "questionResponseTime",
                                            key,
                                            data.questionResponseTime
                                          )
                                        }
                                        id={key}
                                        type="number"
                                        value={data.questionResponseTime}
                                        //  rows={accordionOpen ? 3 : 1}
                                        size="small"
                                        label={t("Response Timeout (in seconds)")}
                                      />
                                    )}
                                  </AccordDataQuestTextSe>

                                  <OkNotOkDropdown>
                                    {data?.controlType?.toLocaleLowerCase() === "dropdown" && (
                                      <>
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltip}
                                        >
                                          <InformationDiv>
                                            <Trans>Critical Question</Trans>
                                            <StyledInformationIcon className="icon icon-information-tooltip" />
                                          </InformationDiv>
                                        </OverlayTrigger>

                                        <SwitchButtonDiv>
                                          <SwitchComponent
                                            id={key}
                                            name="isQuestionMarkAsChecklistFail"
                                            checked={data.isQuestionMarkAsChecklistFail}
                                            onClick={setQuestionCheckbox}
                                          />
                                        </SwitchButtonDiv>
                                        {data.isQuestionMarkAsChecklistFail &&
                                          (data.responseText == "OK, NOT OK, NA" ||
                                            data.responseText == "OK, NOT OK") && (
                                            <DropdownOkNotOk>
                                              <DropdownComponent
                                                label={"Fail on Response"}
                                                options={[
                                                  {
                                                    value: "OK",
                                                    label: "OK",
                                                  },
                                                  {
                                                    value: "NOT OK",
                                                    label: "NOT OK",
                                                  },
                                                ]}
                                                value={{
                                                  value: data.failedResponse,
                                                  label: data.failedResponse,
                                                }}
                                                id={key}
                                                name="failedResponse"
                                                handleFilter={(selectedData) => {
                                                  setQuestionFormDataForDropdown(
                                                    selectedData,
                                                    key,
                                                    "failedResponse"
                                                  );
                                                  // console.log("DE DATA", data)
                                                }}
                                              />
                                            </DropdownOkNotOk>
                                          )}

                                        {data.isQuestionMarkAsChecklistFail &&
                                          (data.responseText == "PASS, FAIL, NA" ||
                                            data.responseText == "PASS, FAIL") && (
                                            <DropdownOkNotOk>
                                              <DropdownComponent
                                                label={"Fail on Response"}
                                                options={[
                                                  {
                                                    value: "PASS",
                                                    label: "PASS",
                                                  },
                                                  {
                                                    value: "FAIL",
                                                    label: "FAIL",
                                                  },
                                                ]}
                                                value={{
                                                  value: data.failedResponse,
                                                  label: data.failedResponse,
                                                }}
                                                id={key}
                                                name="failedResponse"
                                                handleFilter={(selectedData) => {
                                                  setQuestionFormDataForDropdown(
                                                    selectedData,
                                                    key,
                                                    "failedResponse"
                                                  );
                                                  console.log("DE DATA", data);
                                                }}
                                              />
                                            </DropdownOkNotOk>
                                          )}

                                        {data.isQuestionMarkAsChecklistFail &&
                                          (data.responseText == "YES, NO, NA" ||
                                            data.responseText == "YES, NO") && (
                                            <DropdownOkNotOk>
                                              <DropdownComponent
                                                label={"Fail on Response"}
                                                options={[
                                                  {
                                                    value: "YES",
                                                    label: "YES",
                                                  },
                                                  {
                                                    value: "NO",
                                                    label: "NO",
                                                  },
                                                ]}
                                                value={{
                                                  value: data.failedResponse,
                                                  label: data.failedResponse,
                                                }}
                                                id={key}
                                                name="failedResponse"
                                                handleFilter={(selectedData) => {
                                                  setQuestionFormDataForDropdown(
                                                    selectedData,
                                                    key,
                                                    "failedResponse"
                                                  );
                                                  console.log("DE DATA", data);
                                                }}
                                              />
                                            </DropdownOkNotOk>
                                          )}
                                      </>
                                    )}
                                  </OkNotOkDropdown>
                                  <DeleteIconDiv></DeleteIconDiv>

                                  <DeleteIconDivRespMobile>
                                    <DeleteIconStyled
                                      onClick={removeQuestion}
                                      id={key + "," + data._id}
                                      className="icon icon-delete"
                                    >
                                      Delete
                                    </DeleteIconStyled>
                                  </DeleteIconDivRespMobile>
                                </AccordianDetailBodyDivSe>
                                <BorderLineDivSE />
                              </AccordianDetailBody>
                            );
                          })
                        ) : (
                          <PleaseAddQuestion>
                            <Trans>Please Add Questions</Trans>
                          </PleaseAddQuestion>
                        )}
                        <span ref={questionRef}></span>
                      </AccordianDetailDiv>
                    </AccordionDivCont>
                  </ChecklistDetailDescRight>
                ) : (
                  <EmptySectionWrapper>Please select a section</EmptySectionWrapper>
                )}

                {/* Section Overview Ends */}
              </ChecklistRowContainer>
            ) : (
              <ChecklistCreationDiv>
                <Trans>Please click on the 'Add Section' button to begin your checklist</Trans>.
              </ChecklistCreationDiv>
            )}
          </>
          <ChecklistBottomButtons>
            <ChecklistButtonDivLeft>
              <Button
                className="addSectioButtonDesktop"
                iconClass="add"
                label={t("Add Section")}
                onClick={() => addSection()}
                showIcon
                buttonType="secondary"
              />

              <Button
                className="addQuestButton"
                iconClass="add"
                label={t("Add Question")}
                onClick={() => addQuestion()}
                disabled={addQuestionDisabled}
                showIcon
                buttonType="secondary"
              />
            </ChecklistButtonDivLeft>
            <ChecklistButtonDivRight>
              <Button
                label={t("Back")}
                onClick={() => setFormSection(0)}
                showIcon
                iconClass="arrow-left"
                buttonType="ghost"
              />
              <Button
                iconClass="save"
                label={
                  createChecklistLoading || updateChecklistLoading ? (
                    <div title="Submit" className="spinner-border spinner-border-sm" role="status"></div>
                  ) : (
                    t("Submit")
                  )
                }
                form="addSectionForm"
                onClick={() => !createChecklistLoading && !updateChecklistLoading && handleAddChecklist()}
                disabled={isSubmitDisabled()}
                showIcon
                buttonType="save"
                style={{ width: "120px", height: "36px" }}
              />
            </ChecklistButtonDivRight>
          </ChecklistBottomButtons>
        </>
      </ChecklistContainer>
    </ChecklistDeatilStyled>
  );
}

export default ChecklistDetail;
