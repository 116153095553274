import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  toValidatePin,
  toValidatePassword,
  toValidateConfirmPassword,
} from "../../../../utils/validations/checkValidation";
import Input from "../../../../components/Input";

import {
  ChangePinChangePass,
  HeadingContainer,
  ContainerRow,
  ComponentDivSty,
  InfoDivStyle,
} from "./ChangePassword.styles.component";
import Button from "../../../../components/Button/Button.component";
import ModalComponent from "../../../../components/ModalPopupComponent/ModalPopup.component";
import SlideButton from "../../../../components/InfoDivComponent/InfoDiv.component";
import InputField from "../../../../components/InputFieldComponent/InputPasswordPin.component";
export default function PasswordModal({
  id,
  showModal,
  setModal,
  changePassword,
  isPin,
  isLoadingPasswordChange,
  setLoadingPasswordChange,
}) {
  const [passwordForm, setPasswordForm] = useState({ userId: id });
  const [show, setShow] = useState({});
  const [validated, setValidated] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setValidated(false);
    setLoadingPasswordChange(false);
    setPasswordForm({ userId: id });
    setIsError(false);
  }, [showModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingPasswordChange(true);
    setValidated(true);

    let isInvalidPassword = isPin
      ? toValidatePin(passwordForm.pin)
      : toValidatePassword(passwordForm.newPassword);
    setIsError(true);
    let isInvalidVerifyPassword =
      !isPin && toValidateConfirmPassword(passwordForm.newPassword, passwordForm.verifyPassword);

    let isInvalidPinLength = isPin && passwordForm.pin.trim().length < 4;

    if (isInvalidPassword || isInvalidVerifyPassword || isInvalidPinLength) {
      e.preventDefault();
      e.stopPropagation();
      setIsError(true);
      setLoadingPasswordChange(false);
      return;
    } else {
      setIsError(false);
    }

    changePassword(e, passwordForm);
  };

  const [isError, setIsError] = useState(false);

  const ComponentDiv = () => {
    return (
      <ComponentDivSty isError={isError}>{isPin ? t("PIN_RULES") : t("Password Rules")}</ComponentDivSty>
    );
  };

  return (
    <>
      <ModalComponent
        style={{ textAlign: "center" }}
        show={showModal}
        title={isPin ? <Trans>Edit User Details</Trans> : <Trans>Edit User Details</Trans>}
        size="lg"
        handleClose={() => setModal(false)}
        withHeader={true}
      >
        <ChangePinChangePass>
          <HeadingContainer>
            {isPin ? <Trans>Change pin</Trans> : <Trans>Change password</Trans>}
          </HeadingContainer>
          <Form noValidate id="changePassowrdForm" onSubmit={handleSubmit}>
            {/* modal body starts */}
            <ContainerRow>
              <Row className="p4">
                <Col md={6} className="mb-2">
                  <InputField
                    required
                    label={isPin ? t("New PIN") : t("New Password")}
                    name={isPin ? "pin" : "password"}
                    minLength={isPin ? "4" : "8"}
                    maxLength={isPin ? "32" : "64"}
                    type={isPin ? "text" : show.password ? "text" : "password"}
                    placeholder={isPin ? t("Enter New Pin") : t("Enter New Password")}
                    onChange={(e) =>
                      setPasswordForm({
                        ...passwordForm,
                        [isPin ? "pin" : "newPassword"]: e.target.value,
                      })
                    }
                    isError={
                      validated &&
                      (isPin
                        ? validated && toValidatePin(passwordForm.pin)
                        : validated && toValidatePassword(passwordForm.newPassword))
                    }
                    errorMessage={validated && (isPin ? validated && toValidatePin(passwordForm.pin) : "")}
                  />
                </Col>

                <Col md={6} className="mb-2">
                  {!isPin && (
                    <InputField
                      name="passwordVerification"
                      label="Confirm Password"
                      required
                      minLength={8}
                      type="password"
                      placeholder={t("Confirm new password")}
                      autocomplete="new-password"
                      onChange={(e) =>
                        setPasswordForm({
                          ...passwordForm,
                          verifyPassword: e.target.value,
                        })
                      }
                      isError={
                        validated &&
                        toValidateConfirmPassword(passwordForm.newPassword, passwordForm.verifyPassword)
                      }
                      errorMessage={
                        validated &&
                        toValidateConfirmPassword(passwordForm.newPassword, passwordForm.verifyPassword)
                      }
                    />
                  )}
                </Col>

                <Col md={12} className="mt-4">
                  <InfoDivStyle>
                    <SlideButton
                      buttonName={isPin ? "Pin Rules" : "Password Rules"}
                      divContent={<ComponentDiv />}
                      iconName="information-tooltip"
                      isOpen="false"
                    />
                  </InfoDivStyle>
                </Col>
              </Row>
            </ContainerRow>
            <div className="text-right">
              <Button
                iconClass="save"
                form="changePassowrdForm"
                // disabled={isPin ? !passwordForm?.pin?.trim() : !passwordForm?.newPassword?.trim()}
                label={
                  isLoadingPasswordChange ? (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    t("Save")
                  )
                }
                showIcon
                buttonType="save"
                disabled={
                  isPin
                    ? !passwordForm?.pin?.trim()
                    : !passwordForm?.newPassword?.trim() || !passwordForm?.verifyPassword?.trim()
                }
              />
            </div>
          </Form>
        </ChangePinChangePass>
      </ModalComponent>
    </>
  );
}
