import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import UserTypeList from "../userTypes/userTypeList/UserTypeList.component";
import ViewUserTypes from "./view/ViewUserTypes.component";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { setAddUserTypeModal, setModalWaitingToOpen } from "../../../../redux/modals/action";
import AddUserTypeModal from "./add/AddUserTypeModal.component";
import CommonSuccessMessage from "../../common/CommonSuccessMessage.component";
import Button from "../../../../components/Button/Button.component";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";
import {
  getUserTypeList,
  getUserTypeBySearchText,
  getSuperAdminUserTypes,
} from "../../../../redux/userType/action";
import { setDropdownAccountId } from "../../../../redux/dropdownFilters/action";
import {
  UserTypeManagementDiv,
  ButtonsContainer,
  UserTypeMainContainer,
  UserTypeListing,
  UserTypeDataList,
  SearchContainer,
} from "./UserTypesManagement.styles";
import Input from "../../../../components/Input";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";

const ViewUserTypesManagement = ({
  history,
  isSuperAdmin,
  updateActiveNavItem,
  setAddUserTypeModal,
  addUserTypeModal,
  dropdownAccountList,
  setModalWaitingToOpen,
  getUserTypeList,
  setDropdownAccountId,
  getUserTypeBySearchText,
  dropdownAccountId,
  getSuperAdminUserTypes,
}) => {
  const [filterAccountId, setFilterAccountId] = useState(dropdownAccountId);
  const [searchText, setSearchText] = useState("");
  const [selectedAccessLevel, setSelectedAccessLevel] = useState("SINGLE_ACCOUNT");
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("userType");
  }, []);

  const openAddUserTypeModal = () => {
    setAddUserTypeModal(true);
  };

  const handleAccountChange = (accountId) => {
    setFilterAccountId(accountId);
    setDropdownAccountId(accountId);
  };

  useEffect(() => {
    getUserTypeList();
  }, [dropdownAccountId]);

  const handleAccessLevelChange = (accessLevel) => {
    setSelectedAccessLevel(accessLevel);
    if (accessLevel === "ALL_ACCOUNTS") {
      getSuperAdminUserTypes(accessLevel);
      setDropdownAccountId("all");
    }
  };
  return (
    <UserTypeManagementDiv>
      {/* For breadcrumb */}
      <BreadCrumb />

      {/* For action buttons */}
      <ButtonsContainer>
        <PermissionCheck section={"DASHBOARD"} permissionName={"USER_MANAGEMENT"} actionName={"view"}>
          <Button
            label={t("Manage Users")}
            onClick={() => {
              updateActiveNavItem("users");
              history.push("/app/management");
            }}
            showIcon={false}
            buttonType="secondary"
          />
        </PermissionCheck>
        <PermissionCheck section={"DASHBOARD"} permissionName={"USER_TYPE_MANAGEMENT"} actionName={"add"}>
          <Button
            iconClass="add"
            label={t("Add User Type")}
            onClick={openAddUserTypeModal}
            showIcon={true}
            buttonType="secondary"
          />
        </PermissionCheck>
      </ButtonsContainer>

      <UserTypeMainContainer>
        {/* For user type listing */}
        <UserTypeListing>
          <SearchContainer>
            <SearchBox
              label={"Your User Types"}
              placeholder={"Search a user type"}
              searchText={searchText}
              setSearchText={setSearchText}
              onChange={(e) => getUserTypeBySearchText(e.target.value)}
              //  setSearchText={(data) => (editForm && editFormSecond ? setSearchText(data) : setEditAlertModal(true))}
            />
          </SearchContainer>
          <UserTypeList
            filterAccountId={filterAccountId}
            accessLevel={selectedAccessLevel}
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
          />
        </UserTypeListing>

        {/* For user type overview */}
        <UserTypeDataList>
          {isSuperAdmin && (
            <Row style={{ marginBottom: "12px" }}>
              <Col md={4} style={{ textAlign: "left" }}>
                <label
                  htmlFor="Account"
                  style={{
                    fontSize: "12px",
                    color: "rgb(154, 154, 154)",
                    fontFamily: "'Open Sans', sans-serif",
                    marginBottom: "1px",
                  }}
                >
                  User Type Access Level
                </label>
                <Input
                  options={[
                    {
                      _id: "ALL_ACCOUNTS",
                      label: "All Accounts",
                    },
                    {
                      _id: "SINGLE_ACCOUNT",
                      label: "Single Account",
                    },
                  ]}
                  isValidationError={false}
                  type="select"
                  as="select"
                  label="Account"
                  value={selectedAccessLevel}
                  onChange={(value) => handleAccessLevelChange(value)}
                />
              </Col>

              <Col md={4} style={{ textAlign: "left" }}>
                <label
                  htmlFor="Account"
                  style={{
                    fontSize: "12px",
                    color: "rgb(154, 154, 154)",
                    fontFamily: "'Open Sans', sans-serif",
                    marginBottom: "1px",
                  }}
                >
                  Account Name
                </label>
                <Input
                  options={dropdownAccountList?.map((data) => ({
                    _id: data._id,
                    label: data.companyName,
                  }))}
                  isValidationError={false}
                  type="select"
                  as="select"
                  label="Account"
                  value={dropdownAccountId}
                  onChange={(accId) => handleAccountChange(accId)}
                  disabled={isSuperAdmin && selectedAccessLevel !== "SINGLE_ACCOUNT"}
                />{" "}
              </Col>
            </Row>
          )}

          <ViewUserTypes setFilterAccountId={setFilterAccountId} selectedAccessLevel={selectedAccessLevel} />
        </UserTypeDataList>
      </UserTypeMainContainer>

      <AddUserTypeModal
        show={addUserTypeModal}
        toggleModalClose={() => {
          setAddUserTypeModal(false);
          setModalWaitingToOpen(false);
        }}
      />
      <CommonSuccessMessage />
    </UserTypeManagementDiv>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  addUserTypeModal: state.modals.addUserTypeModal,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setAddUserTypeModal: (data) => dispatch(setAddUserTypeModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  getUserTypeList: () => dispatch(getUserTypeList()),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  getUserTypeBySearchText: (searchText) => dispatch(getUserTypeBySearchText(searchText)),
  getSuperAdminUserTypes: (superAdminUserTypeId) => dispatch(getSuperAdminUserTypes(superAdminUserTypeId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewUserTypesManagement));
