import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Row, Col, Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs, AppBar, Typography, Box } from "@material-ui/core";

import timeZones from "../../../constant/timeZone";
import SnackBar from "../SnackBar/SnackBar";
import UpdateButtons from "../../../components/UpdateButtons.component";
import { worksiteDetailsUpdate, resetWorksitesState } from "../../../redux/worksites/action";
import { setEditForm } from "../../../redux/forms/action";

import Input from "../../../components/Input";
import ShiftDurationPanel from "./ShiftDurationPanel.component";
import { ManagementPanelWrapper } from "./WorksiteOverviewTab.component.styles";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const labelStyle = {
  position: "relative",
  height: "0px%",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
    boxShadow: "unset",
    borderBottom: "1px solid #ccc",
    fontSize: "30px",
  },
  labelWrapped: {
    fontSize: "0.875rem",
  },
}));

function WorksiteOverviewTab({
  worksiteOverview,
  OverviewDetails,
  statusButton,
  worksiteDetailsUpdate,
  updateLoading,
  updateSuccess,
  updateMessage,
  resetWorksitesState,
  WorksiteLoading,
  editForm,
  setEditForm,
}) {
  const { manager, address, timeZone, accountId, _id } = OverviewDetails
    ? OverviewDetails
    : { manager: "", address: "", timeZone: timeZones[0].value };
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [worksiteDetails, setWorksiteDetails] = useState({ manager, address, timeZone, _id });
  const [updateError, setUpdatetError] = useState("");
  const { t } = useTranslation();
  const [shiftSettings, setShiftSettings] = useState(null);

  useEffect(() => {
    setWorksiteDetails({
      ...worksiteDetails,
      manager: manager ? manager : null,
      address,
      timeZone,
      accountId,
      _id,
    });
    setUpdatetError("");
    setShiftSettings(OverviewDetails.shiftSettings);
  }, [OverviewDetails]);

  useEffect(() => {
    handleCancel();
  }, [editForm]);

  const handleChange = (event, newValue) => {
    setUpdatetError("");
    setValue(newValue);
  };

  const handleCancel = () => {
    setWorksiteDetails({ manager, address, timeZone, accountId, _id });
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    worksiteDetailsUpdate(worksiteDetails._id, { ...worksiteDetails, shiftSettings }, setEditForm);
  };

  const handleCloseSnackBar = () => {
    resetWorksitesState();
  };
  const handleMessage = (updateSuccess, updateError) => {
    if (updateSuccess) {
      return t(updateMessage);
    } else if (updateError) {
      return updateMessage;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <ManagementPanelWrapper>
        {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
        <div classes="tabPanels">
          <AppBar position="static" color="default" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="ant example"
              className="tabpanelSize"
            >
              <Tab
                className="buttontabpanels buttonTabSize"
                label={t("Worksite Overview")}
                {...a11yProps(0)}
              />
              <Tab className="buttontabpanels buttonTabSize" label={t("Worksite Shifts")} {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0} className="worksite-overview-panel max-w-full">
            {WorksiteLoading && (
              <div>
                <Skeleton className="m-2" count={4} width={"90%"} />
              </div>
            )}
            {!WorksiteLoading && worksiteOverview && (
              <>
                <Row className="worksite-overview-height">
                  <Col md={4}>
                    <div>
                      <Form.Group
                        disabled={editForm}
                        value={worksiteDetails.address}
                        // className="inputBoxDiv editForm hide-detail"
                        className="text-open-sans"
                        onChange={(e) =>
                          setWorksiteDetails({
                            ...worksiteDetails,
                            address: e.target.value,
                          })
                        }
                      >
                        <Form.Label style={labelStyle}>
                          <Trans>Address</Trans>
                        </Form.Label>
                        <Form.Control
                          disabled={editForm}
                          value={worksiteDetails.address}
                          //  className="inputBoxDiv editForm"
                          onChange={(e) =>
                            setWorksiteDetails({
                              ...worksiteDetails,
                              address: e.target.value,
                            })
                          }
                          type="text"
                          minLength={1}
                          maxLength={128}
                          //    className="inputBox"
                          placeholder={t("Enter address here")}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={4} className="work-label">
                    {/*   <Form.Group className="selectBoxDiv editForm hide-detail">
                    <Form.Label><Trans>Time Zone</Trans></Form.Label>
                    <Form.Control style={{appearance:"auto"}}
                      disabled={editForm}
                      value={worksiteDetails.timeZone}
                      as="select"
                      className="selectBox"
                      onChange={(e) =>
                        setWorksiteDetails({
                          ...worksiteDetails,
                          timeZone: e.target.value,
                        })
                      }
                    >
                      {timeZones.map(
                        (option, i) =>
                          <option key={i} value={option._id}>
                            {option.label}
                          </option>
                      )}
                    </Form.Control>
                  </Form.Group>*/}

                    <Input
                      disabled={editForm}
                      showLabel={true}
                      label={t("Time Zone")}
                      type="select"
                      options={timeZones?.map((option, i) => ({
                        _id: option._id,
                        label: option.label,
                      }))}
                      as="select"
                      // required={true}
                      // isValidationError={isValidationError}
                      value={worksiteDetails.timeZone}
                      onChange={(value) =>
                        setWorksiteDetails({
                          ...worksiteDetails,
                          timeZone: value,
                        })
                      }
                      // feedback={t("Please select a Product")}
                    />
                  </Col>
                </Row>

                <PermissionCheck section="DASHBOARD" permissionName="WORKSITE_MANAGEMENT" actionName="edit">
                  <Row className="editWorks editworksiteT worksiteRight">
                    <Col md={4}></Col>
                    <Col md={4}></Col>
                    <UpdateButtons
                      editOverview={editForm}
                      setEditOverview={setEditForm}
                      statusButton={statusButton}
                      handleUpdate={handleUpdate}
                      IsLoading={updateLoading}
                      overviewDetail={worksiteOverview}
                      handleCancel={handleCancel}
                    />
                  </Row>
                </PermissionCheck>
              </>
            )}
          </TabPanel>

          <TabPanel value={value} index={1} className="worksite-overview-panel buttontabpanels max-w-full">
            {!WorksiteLoading && worksiteOverview && (
              <ShiftDurationPanel
                savedShiftSettings={OverviewDetails.shiftSettings}
                updateLoading={updateLoading}
                handleUpdate={handleUpdate}
                worksiteId={worksiteOverview ? worksiteOverview._id : ""}
                shiftSettings={shiftSettings}
                setShiftSettings={setShiftSettings}
                statusButton={statusButton}
              />
            )}
          </TabPanel>
          <SnackBar
            isSuccess={updateSuccess}
            isFailed={updateError}
            label={handleMessage(updateSuccess, updateError)}
            handleClose={handleCloseSnackBar}
          />
        </div>
      </ManagementPanelWrapper>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  worksiteDetailsUpdate: (id, details, setEditForm) =>
    dispatch(worksiteDetailsUpdate(id, details, setEditForm)),
  resetWorksitesState: () => dispatch(resetWorksitesState()),
  setEditForm: (data) => dispatch(setEditForm(data)),
});

const mapStateToProps = (state) => ({
  worksiteOverview: state.worksites.WorksiteOverview,
  updateLoading: state.worksites.UpdateLoading,
  updateSuccess: state.worksites.UpdateSuccess,
  updateMessage: state.worksites.UpdateMessage,
  updateError: state.worksites.UpdateError,
  WorksiteLoading: state.worksites.WorksiteLoading,
  editForm: state.forms.editForm,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksiteOverviewTab));
