import React, { useState, useEffect } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import DateRangePickerFromTo from "../components/DatePickerComponent/DatePicker.component";

const DateRangePicker = ({
  filterState,
  setDate,
  startDate: initialStartDate,
  endDate: initialEndDate,
  size,
  minDate,
}) => {
  const [resetDate, setResetDate] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  useEffect(() => {
    console.debug("initialStartDate", initialStartDate);
    setResetDate({
      startDate: initialStartDate,
      endDate: initialEndDate,
    });
  }, [initialStartDate, initialEndDate]);

  const onDateChange = (onStartDate, onEndDate) => {
    onStartDate = onStartDate ? onStartDate : resetDate.startDate;
    onEndDate = onEndDate ? onEndDate : resetDate.endDate;

    let object = { ...filterState };
    if (onStartDate) {
      let start = moment(onStartDate).startOf("day");
      object.startDate = start;
    } else {
      object.startDate = moment(initialStartDate).format("YYYY-MM-DD");
    }

    if (onEndDate) {
      let end = moment(onEndDate).endOf("day");
      object.endDate = end;
    } else {
      if (onStartDate && object.startDate !== "all") {
        let end = moment(onStartDate).endOf("day");
        object.endDate = end;
      } else {
        object.endDate = moment(initialEndDate).format("YYYY-MM-DD");
      }
    }

    setDate(object);
  };

  return (
    <DateRangePickerFromTo
      dateRangePickerFromTo={true}
      size={size}
      onChangeStartDate={(date) => onDateChange(date, null)}
      onChangeEndDate={(date) => onDateChange(null, date)}
      selectedStartDate={moment(initialStartDate).toDate()}
      selectedEndDate={moment(initialEndDate).toDate()}
      minDate={minDate || new Date(2020, 0, 1)}
      maxDate={new Date()}
    />
  );
};

DateRangePicker.defaultProps = {
  size: "medium",
};

export default DateRangePicker;
