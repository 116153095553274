import React from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import InputField from "../../../../../components/InputFieldComponent/InputField.component";
import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import {
  UserTypeOverviewWrapper,
  HeaderTitle,
  BodyDetails,
  BodyDetilsDiv,
  AccessLevelsText,
  AccessLevelHeading,
  AccessLevelDescription,
  DivSlideButton,
} from "./styles/Modal.styles";
import {
  allUserTypeAccessLevels,
  worksiteUserTypeAccessLevels,
} from "../../../../../constant/userTypeAccessLevel";
import DropdownFilter from "../../../../../components/DropdownFilter.component";
import SlideButton from "../../../../../components/InfoDivComponent/InfoDiv.component";

function OverviewStep(props) {
  const { isSuperAdmin, addUserTypeModalData, setAddUserTypeModalData, dropdownAccountList } = props;
  const { t } = useTranslation();

  const returnValidValidStr = (str) => {
    return str.replace(/[^a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()\[\]{}_+=|\\-]/gi, "");
  };

  const ComponentData = () => {
    return (
      <AccessLevelsText>
        <AccessLevelHeading>
          <Trans>ADD_USER_TYPE_HEADING</Trans>
        </AccessLevelHeading>
        <ul class="dashed">
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>ALL_WORKSITES</Trans>
              </strong>
              <Trans>ALL_WORKSITES_DATA</Trans>
            </AccessLevelDescription>
          </li>
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>MULTIPLE_WORKSITES</Trans>
              </strong>
              <Trans>MULTIPLE_WORKSITES_DATA</Trans>
            </AccessLevelDescription>
          </li>
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>SINGLE_WORKSITE</Trans>
              </strong>
              <Trans>SINGLE_WORKSITE_DATA</Trans>
            </AccessLevelDescription>
          </li>
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>INDIVIDUAL_EMPLOYEE</Trans>
              </strong>
              <Trans>INDIVIDUAL_EMPLOYEE_DATA</Trans>
            </AccessLevelDescription>
          </li>
        </ul>
      </AccessLevelsText>
    );
  };

  return (
    <UserTypeOverviewWrapper>
      <HeaderTitle>
        <Trans>User Type Overview</Trans>
      </HeaderTitle>
      <BodyDetails>
        <BodyDetilsDiv>
          <Row>
            <Col md={6}>
              <DropdownComponent
                options={isSuperAdmin ? allUserTypeAccessLevels : worksiteUserTypeAccessLevels}
                label={"User Type Access Level"}
                placeholder={"Select an Access Level"}
                handleFilter={(userTypeAccessLevel) => {
                  setAddUserTypeModalData({
                    ...addUserTypeModalData,
                    accessLevel: userTypeAccessLevel.value,
                  });
                }}
                value={allUserTypeAccessLevels.filter(
                  (element) => element.value === addUserTypeModalData.accessLevel
                )}
              />
              <p className="asterisk_user">*</p>
            </Col>
            {isSuperAdmin && addUserTypeModalData.accessLevel !== "ALL_ACCOUNTS" && (
              <Col md={6}>
                <DropdownFilter
                  name="accountId"
                  filter={{
                    type: "Account",
                    label: "Account",
                    key: "companyName",
                    data: dropdownAccountList,
                  }}
                  value={addUserTypeModalData.accountId}
                  useAsDropdown={true}
                  handleFilter={(value) => {
                    setAddUserTypeModalData({ ...addUserTypeModalData, accountId: value });
                  }}
                  style={{ position: "relative" }}
                />
                <p className="asterisk_user">*</p>
              </Col>
            )}
          </Row>
          <p></p>
          <Row>
            <Col md={6}>
              <InputField
                name="UserTypeName"
                placeholder={t("USER_TYPE_NAME")}
                size="small"
                label={t("USER_TYPE_NAME")}
                value={addUserTypeModalData.name}
                onChange={(e) => {
                  setAddUserTypeModalData({
                    ...addUserTypeModalData,
                    name: returnValidValidStr(e.target.value),
                  });
                }}
                maxLength="64"
              />
              <p className="asterisk_user">*</p>
            </Col>
            <Col md={6}>
              <InputField
                name="UserTypeDescription"
                placeholder="User Type Description"
                size="small"
                label={t("User Type Description")}
                value={addUserTypeModalData.description}
                onChange={(e) => {
                  setAddUserTypeModalData({
                    ...addUserTypeModalData,
                    description: returnValidValidStr(e.target.value),
                  });
                }}
                maxLength="128"
              />
            </Col>
          </Row>
        </BodyDetilsDiv>
      </BodyDetails>

      <DivSlideButton>
        <SlideButton
          buttonName="Access Levels"
          divContent={<ComponentData />}
          iconName="information-tooltip"
        />
      </DivSlideButton>
    </UserTypeOverviewWrapper>
  );
}

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewStep));
