import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import AssetTypeList from "./AssetTypeList.component";
import ViewAssetTypes from "./ViewAssetTypes.component";
import BreadCrumb from "../../../components/BreadCrumb.component";
import { updateMenu, updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setAddAssetTypeModal, setModalWaitingToOpen, setAddAssetModal } from "../../../redux/modals/action";
import AddAssetTypeModal from "./Modal/AddAssetTypeModal/AddAssetTypeModal.component";
import CommonSuccessMessage from "../common/CommonSuccessMessage.component";
// Button Component
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const ViewAssetTypesManagement = ({
  history,
  loginDetails,
  updateMenu,
  isSuperAdmin,
  isSiteAdmin,
  updateActiveNavItem,
  setAddAssetTypeModal,
  addAssetTypeModal,
  setModalWaitingToOpen,
  setAddAssetModal,
}) => {
  const [filterAccountId, setFilterAccountId] = useState("all");

  useEffect(() => {
    updateActiveNavItem("assetType");
  }, []);

  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setIsError] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <BreadCrumb />
      <Row style={{ paddingTop: "8px" }} className="m-0">
        <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"view"}>
          <div className="mr-2 pl-0">
            <Button
              label={t("Manage Assets")}
              onClick={() => {
                updateActiveNavItem("assets");
                history.push("/app/management");
              }}
              showIcon={false}
              buttonType="secondary"
            />
          </div>
        </PermissionCheck>
        <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_TYPE_MANAGEMENT"} actionName={"add"}>
          <div className="mr-2 pl-0">
            <Button
              iconClass="add"
              label={t("Add Asset Type")}
              onClick={() => setAddAssetTypeModal(true)}
              showIcon
              buttonType="secondary"
            />
          </div>
        </PermissionCheck>
      </Row>
      <Row style={{ paddingTop: "10px" }}>
        <Col lg={3}>
          <div className="assetTypeList_hieght">
            <AssetTypeList
              filterAccountId={filterAccountId}
              style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            />
          </div>
        </Col>
        <Col lg={9}>
          <ViewAssetTypes setFilterAccountId={setFilterAccountId} />
        </Col>
      </Row>
      <AddAssetTypeModal
        show={addAssetTypeModal}
        toogleModalClose={() => {
          setAddAssetTypeModal(false);
          setModalWaitingToOpen(false);
          setAddAssetModal(false);
        }}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <CommonSuccessMessage />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  addAssetTypeModal: state.modals.addAssetTypeModal,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypesManagement));
