//TODO: When time permits optimize this file
import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import { ThemeContext } from "styled-components";
import { Tooltip, CircularProgress } from "@material-ui/core";

import Button from "../../../components/Button/Button.component";
import MultiCardsWorksite from "./MultiCardsWorksite.component";
import WorksiteOverviewTab from "./WorksiteOverviewTab.component";
import toasterConstant from "../../../constant/messages/toaster.json";
import SnackBar from "../SnackBar";
import {
  worksiteStatusUpdate,
  resetWorksitesState,
  worksiteNameUpdate,
  preDeactivatedWorksite,
  deactivateWorksite,
} from "../../../redux/worksites/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import { ManagementRightBottomWrapper, TextEditIcon } from "./WorksiteOverview.styles.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const { worksite_name_update, worksite_activated_message, worksite_deactivated_message } = toasterConstant;

const WorksiteOverview = ({
  loginDetails,
  selectWorksiteOverview,
  statusSuccess,
  worksiteStatusUpdate,
  resetWorksitesState,
  worksiteNameUpdate,
  updateNameLoading,
  updateNameSuccess,
  filterAccountId,
  isSuperAdmin,
  WorksiteLoading,
  WorksiteDetailLoading,
  editForm,
  setEditAlertModal,
  accountListById,
  permissions,
  preDeactivatedWorksite,
  deactivateWorksite,
  preDeactivatedWorksiteList,
  deactivateWorksiteSuccess,
}) => {
  const { name, _id, status, createdAt } = selectWorksiteOverview
    ? selectWorksiteOverview
    : {
        name: "",
        createdDate: new Date(),
        accountId: "",
        _id: "",
        s3Enable: false,
        status: true,
      };
  const [statusButton, setStatusButton] = useState(status);
  const [WorkSiteName, setWorkSiteName] = useState("");
  const [IsEdit, setIsEdit] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  //const [disableSwitch, setDisableSwitch] = useState(false);
  const changeStatus = (e) => {
    if (editForm) {
      let id = e.target.id;
      let status = e.target.checked;
      // updateSiteStatus(e);

      if (!status) {
        preDeactivatedWorksite(id, selectWorksiteOverview?.accountId?._id);
      } else {
        worksiteStatusUpdate(id, status);
      }
      //setDisableSwitch(true);

      //setStatusButton(!statusButton);
    } else {
      setEditAlertModal(true);
    }
  };

  useEffect(() => {
    if (deactivateWorksiteSuccess) setStatusButton(false);
  }, [deactivateWorksiteSuccess]);

  useEffect(() => {
    if (statusSuccess) setStatusButton(true);
  }, [statusSuccess]);

  const currentPermissionObj = permissions?.[0]?.permissions?.find(
    (permissionObj) => permissionObj.name === "WORKSITE_MANAGEMENT"
  );
  const worksitesEditPermission = currentPermissionObj?.action["edit"];

  useEffect(() => {
    setStatusButton(status);
    setWorkSiteName(name);
  }, [selectWorksiteOverview]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleSave = () => {
    setIsSubmitted(true);
    if (WorkSiteName) {
      worksiteNameUpdate(_id, WorkSiteName);
    }
  };

  const handleCancel = () => {
    setWorkSiteName(name);
    setIsEdit(false);
  };

  // useEffect(() => {
  //   if (updateNameSuccess) {
  //     setIsEdit(false);
  //   }
  //   //reset state on unload of the page
  //   return () => {
  //     resetWorksitesState();
  //   };
  // }, [updateNameSuccess]);

  useEffect(() => {
    if (updateNameSuccess) {
      setIsEdit(false);
      setTimeout(() => {
        resetWorksitesState();
      }, 3000);
    }
  }, [updateNameSuccess]);

  // const handleCloseSnackBar = () => {
  //   resetWorksitesState();
  // };
  const { t } = useTranslation();
  const handleMessage = () => {
    if (updateNameSuccess) {
      return t(worksite_name_update);
    } else if (statusSuccess && statusButton) {
      return t(worksite_activated_message);
    } else if (statusSuccess && !statusButton) {
      return t(worksite_deactivated_message);
    } else if (deactivateWorksiteSuccess) {
      return t(worksite_deactivated_message);
    }
  };

  const themeContext = useContext(ThemeContext);
  const styles = {
    title: {
      fontFamily: "Kanit",
      fontSize: "26px",
      color: themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      paddingTop: "2px",
    },
    subTitle: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      paddingTop: "2px",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: themeContext?.colors?.grayL4 || "#9A9A9A",
      textAlign: "left",
      paddingTop: "2px",
    },
    safetyEnabled: {
      fontFamily: "Open Sans",
      fontSize: "15px",
      color: themeContext?.colors?.themePrimary || "#0d5fbe",
      textAlign: "left",
      fontWeight: "bold",
      paddingTop: "7px",
    },
  };

  const [showSnackBar, setShowSnackBar] = useState(false);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, []);

  const handleCloseSnackBar = () => {
    resetWorksitesState();
    setShowSnackBar(false);
    //setDisableSwitch(false);
  };

  useEffect(() => {
    if (handleMessage()) {
      setShowSnackBar(true);
    }
  }, [updateNameSuccess, statusSuccess, statusButton, deactivateWorksiteSuccess]);

  return (
    <>
      <ManagementRightBottomWrapper>
        <Card className="worksite-height">
          <Card.Body>
            {(WorksiteLoading || WorksiteDetailLoading) && (
              <Row>
                <Col lg={5} className="worksite-overview">
                  <Skeleton height={40} />
                  <Skeleton height={10} count={1} />
                </Col>
                <Col lg={5}>
                  <Skeleton height={50} width={200} />
                </Col>
                <Col lg={2}>
                  <Skeleton height={60} width={60} />
                </Col>
              </Row>
            )}
            {!WorksiteLoading && !WorksiteDetailLoading && selectWorksiteOverview && (
              <Row>
                <Col lg={5} className="worksite-overview">
                  <h1 style={styles.title}>
                    {IsEdit ? (
                      <div>
                        <Form.Control
                          value={WorkSiteName}
                          onChange={(e) => setWorkSiteName(e.target.value)}
                          error={IsSubmitted && !WorkSiteName}
                          fullWidth
                          helperText={IsSubmitted && !WorkSiteName && "Worksite Name is required"}
                        />
                        <div className="">
                          <Button
                            label={
                              updateNameLoading ? (
                                <CircularProgress style={{ color: "#fff" }} size={20} />
                              ) : (
                                t("Save")
                              )
                            }
                            buttonType="save"
                            showIcon
                            iconClass="save"
                            onClick={handleSave}
                          />

                          <Button
                            label={t("Cancel")}
                            buttonType="ghost"
                            showIcon={false}
                            onClick={handleCancel}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="d-flex">
                          <span className="ellipseStyle" title={WorkSiteName}>
                            {WorkSiteName}{" "}
                          </span>
                          <Tooltip title="Edit" aria-label="add">
                            <PermissionCheck
                              section="DASHBOARD"
                              permissionName="WORKSITE_MANAGEMENT"
                              actionName="edit"
                            >
                              {statusButton && (
                                <TextEditIcon className="icon icon-edit" onClick={handleEdit}></TextEditIcon>
                              )}
                            </PermissionCheck>
                          </Tooltip>
                        </span>
                      </>
                    )}
                  </h1>
                  {!isSuperAdmin && (
                    <div style={styles.date}>
                      <Trans>Account ID</Trans>: {loginDetails ? loginDetails.accountNo : ""}
                    </div>
                  )}
                  <div style={styles.date}>
                    <Trans>Added On</Trans>:
                    {createdAt &&
                      moment(createdAt).utcOffset(createdAt?.split(" ")?.[0]).format(" MMMM DD YYYY")}
                  </div>

                  {isSuperAdmin && selectWorksiteOverview?.accountId?._id && (
                    <div style={{ display: "flex" }}>
                      <div style={styles.date} className="userPage_a">
                        <Trans>AccountLabel</Trans>
                      </div>
                      <div
                        style={{
                          ...styles?.date,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                          paddingLeft: "4px",
                          paddingTop: 0,
                        }}
                      >
                        {accountListById?.[selectWorksiteOverview?.accountId._id]?.companyName}
                      </div>
                    </div>
                  )}
                </Col>

                <Col lg={5}>
                  <MultiCardsWorksite data={selectWorksiteOverview} />
                </Col>

                <Col lg={2}>
                  <PermissionCheck
                    section="DASHBOARD"
                    permissionName="WORKSITE_MANAGEMENT"
                    actionName="deactivate"
                  >
                    <>
                      <p className="m-0  statusStyle">
                        <Trans>Status</Trans>
                      </p>

                      <SwitchComponent id={_id} onChange={changeStatus} checked={statusButton} />
                    </>
                  </PermissionCheck>
                </Col>
              </Row>
            )}

            {selectWorksiteOverview && !WorksiteDetailLoading && (
              <Row style={{ padding: "10px", paddingTop: "0px" }}>
                <WorksiteOverviewTab
                  OverviewDetails={selectWorksiteOverview}
                  statusButton={statusButton && worksitesEditPermission}
                  filterAccountId={filterAccountId}
                />
              </Row>
            )}
          </Card.Body>
          {!selectWorksiteOverview && !WorksiteLoading && !WorksiteDetailLoading && (
            <div className="noSection">
              <div align="center" className="text-muted h5">
                <Trans>NO_WORKSITE_IN_ACCOUNT</Trans>
              </div>
              <div align="center" className="text-muted h5">
                <div className="clickDesktop">
                  <Trans>CLICK_ADD_WORKSITE_BUTTON</Trans>
                </div>
                <div className="tapMobile">
                  <Trans>CLICK_ADD_WORKSITE_BUTTON_MOB</Trans>
                </div>
              </div>
            </div>
          )}
          {showSnackBar && (
            <SnackBar
              isSuccess={updateNameSuccess || (statusSuccess && statusButton)}
              isFailed={!statusButton || deactivateWorksiteSuccess}
              label={handleMessage()}
              handleClose={() => handleCloseSnackBar()}
            />
          )}
        </Card>
      </ManagementRightBottomWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  selectWorksiteOverview: state.worksites.WorksiteOverview,
  statusMessage: state.worksites.StatusMessage,
  statusSuccess: state.worksites.StatusSuccess,
  statusError: state.worksites.StatusError,
  updateNameLoading: state.worksites.UpdateNameLoading,
  updateNameSuccess: state.worksites.UpdateNameSuccess,
  isSuperAdmin: state.user.isSuperAdmin,
  WorksiteLoading: state.worksites.WorksiteLoading,
  WorksiteDetailLoading: state.worksites.worksiteDetailLoading,
  editForm: state.forms.editForm,
  accountListById: state.dropdownFilters.accountListById,
  permissions: state.user?.loginDetails?.userData?.permissions,
  preDeactivatedWorksiteList: state.worksites.preDeactivatedWorksiteList,
  deactivateWorksiteSuccess: state.worksites.deactivateWorksiteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  worksiteNameUpdate: (id, name) => dispatch(worksiteNameUpdate(id, name)),
  worksiteStatusUpdate: (id, status) => dispatch(worksiteStatusUpdate(id, status)),
  resetWorksitesState: () => dispatch(resetWorksitesState()),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  preDeactivatedWorksite: (siteId, accountId) => dispatch(preDeactivatedWorksite(siteId, accountId)),
  deactivateWorksite: (siteId, accountId) => dispatch(deactivateWorksite(siteId, accountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorksiteOverview);
