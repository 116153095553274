import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// import Input from "../../../../components/Input";
import InputField from "../../../../components/InputFieldComponent/InputFieldWrapper.component";
import { toValidateEmail } from "../../../../utils/validations/checkValidation";

const Email = (props) => {
  const { validated, formData, setFormData, selectedUserType } = props;
  const { t } = useTranslation();
  const customField = selectedUserType?.customFields.find((field) => field.type === "EMAILANDPASSWORD");
  //const labelText = t("Email") + (customField?.isRequired ? "*" : "");

  return (
    <Col className="asterisk_form">
      <InputField
        label={t("Email")}
        id="email"
        placeholder={t("Email")}
        type="email"
        name="email"
        value={formData?.email}
        pattern={
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
        autocomplete="new-password"
        onChange={(e) => {
          setFormData({
            ...formData,
            email: e.target.value,
          });
        }}
        isInvalid={validated && toValidateEmail(formData?.email)}
        // feedback={t(toValidateEmail(formData?.email))}

        isError={validated && toValidateEmail(formData?.email)}
        errorMessage={t(toValidateEmail(formData?.email))}
      />
      {/* {customField?.isRequired ? <p className="asterisk_user">*</p> : null} */}
    </Col>
  );
};

export default Email;
