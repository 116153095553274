// Libraries
import React from "react";

// For multiple language support
import { Trans, useTranslation } from "react-i18next";

function UserInspectionReportTab() {
  return (
    <>
      <h4 className="text-style h4texts heightMidd">
        <Trans>This feature will be here soon, kindly contact</Trans>{" "}
        <a href="mailto:support@siera.ai">support@siera.ai </a>
        <Trans>for more information</Trans>.
      </h4>
    </>
  );
}

export default UserInspectionReportTab;
