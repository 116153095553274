import React, { useState } from "react";
import "./styles.css";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../../../config";
import validationConstant from "../../../../constant/messages/validation.json";

import Button from "../../../../components/Button/Button.component";
export default function ReportDownload({ reportDetails, userData, setIsSuccess, setIsError }) {
  const { accountName, reportType, reportId, accountId } = reportDetails;
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [IsValidated, setIsValidated] = useState(false);

  const { t } = useTranslation();
  // const reportData = {
  //   token:loginDetails?.token,
  //   accountName:reportDataToDownload?.accountId?.companyName,
  //   sharedByUserName:loginDetails?.userData?.fullName,
  //   reportType:value+1,
  //   reportId:reportDataToDownload?._id,
  //   modalShow:shareReportModal,
  //   setModalShow:setShareReportModal,
  // }

  // useEffect(() => {
  //   setModalShow(false);
  // }, [apiSuccess]);

  const onEmailAdd = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setIsValidated(true);
      return;
    }
    setIsValidated(false);
    let currentEmailList = new Set([...emailList]);
    currentEmailList.add(email);
    setEmail("");
    setEmailList([...currentEmailList]);
  };

  const removeEmail = (e) => {
    let id = e.currentTarget.id;
    let currentEmailList = new Set([...emailList]);
    currentEmailList.delete(id);
    setEmailList([...currentEmailList]);
  };

  const clearAll = (e) => {
    e.preventDefault();
    setEmailList([]);
  };

  const sendReport = (e) => {
    setApiLoading(true);
    let headers = {
      "Content-Type": "application/json",
      "auth-token": userData.token,
    };

    let reportData = {
      accountId: accountId,
      accountName: accountName,
      sharedByUserName: userData.sharedByUserName,
      reportType: reportType,
      reportId: reportId,
      sharedTo: Array.from(emailList),
    };

    axios
      .post(`${baseUrl}/common/sharereport/`, reportData, {
        headers: headers,
      })
      .then((resp) => {
        document.body.click();
        setIsSuccess(t("Report has been emailed"));
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);
        setIsError(t("Something went wrong"));
      });
  };

  return (
    <div className="report-modal-container">
      <Form noValidate validated={IsValidated} onSubmit={onEmailAdd}>
        <Form.Group controlId="formBasicemail">
          <div style={{ border: "1px solid #E2E2E2" }} className="d-flex p-1">
            <Form.Control
              size="sm"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form-control email-input border-0"
              type="email"
              name="email"
              placeholder="Enter an email"
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
              pattern='(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            />
             
             <Button iconClass="add" type="submit" iconOnly showIcon />
          </div>
          <Form.Control.Feedback type="invalid" style={{ textAlign: "left" }}>
            {t(validationConstant.invalid_email)}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>

      <div className="shareEmail">
        {emailList.length > 0 && (
          <a href="#" onClick={clearAll} className="mt-2 d-block">
            <Trans>Clear All</Trans>
          </a>
        )}
        <div className="EmailListShare">
          <div className="d-block">
            {emailList?.map((email) => (
              <span className="tab" key={email}>
                <span className="mr-3">{email}</span>
                <span className="close" id={email} onClick={removeEmail}>
                  <i className="fas fa-times"></i>
                </span>
              </span>
            ))}
          </div>
        </div>
        {emailList.length > 0 && (
          <Button
            style={{ float: "right", marginBottom: "10px" }}
            buttonType="primary"
            iconClass="send"
            iconPosition="after"
            label={
              apiLoading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                t("Send")
              )
            }
            disabled={emailList.length < 1}
            onClick={sendReport}
            showIcon
          />
        )}
      </div>
    </div>
  );
}
