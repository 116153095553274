import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "./CameraSetting.css";
import { ReactComponent as AlertIcon } from "../../../../assets/AlertIcon.svg";

// Button Component
import Button from "../../../../components/Button/Button.component";

const ConfirmationModal = ({ resetS3CameraSettingLoading, setResetModal, resetModal, resetSetting }) => {
  const { t } = useTranslation();
  return (
    <Modal
      size="lg"
      className="custom-modal discard-modal"
      show={resetModal}
      onHide={() => setResetModal(false)}
      backdrop="static"
    >
      <Modal.Body className="text-center modalPopup">
        <Row className="mb-4 mt-4">
          <Col>
            <span className="mb-2 text-open-sans text-center modal-font-color">
              <AlertIcon className="w-full h-12 mb-4 cursor-pointer" />
              <Trans>Your camera settings will be reset to default.</Trans>
              <br />
              <Trans> Are you sure</Trans> ?
            </span>
          </Col>
        </Row>
        <div className="justify-content-center">
          <div className="">
            <Button
              style={{ width: "160px" }}
              label={
                resetS3CameraSettingLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status"></div>
                ) : (
                  t("Confirm")
                )
              }
              onClick={() => resetSetting()}
              showIcon={false}
              buttonType="primary"
            />
          </div>
          <div className="">
            <Button
              style={{ width: "160px" }}
              label={t("Cancel")}
              onClick={() => setResetModal(false)}
              showIcon={false}
              buttonType="ghost"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
