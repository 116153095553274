//Libraries
import React, { useState, useEffect } from "react";
import axios from "axios";

// React Bootstrap
import { Col, Row, Form } from "react-bootstrap";

// Redux
import { connect } from "react-redux";

// Custom Styles
import Styles from "./AddAssetTypeModalStyles.module.css";

// Custom components and utilities
import Input from "../../../../../components/Input";
import DropdownFilter from "../../../../../components/DropdownFilter.component";
import { setDropdownAccountId } from "../../../../../redux/dropdownFilters/action";
import {
  toValidateAssetTypeName,
  toValidateAccountDropdown,
} from "../../../../../utils/validations/checkValidation";
import validationConstant from "../../../../../constant/messages/validation.json";

// Button Component
import Button from "../../../../../components/Button/Button.component";

// Multiple language support
import { Trans, useTranslation } from "react-i18next";
//import { ReactComponent as RightArrow } from "../../../../../assets/AddIcon/RightArrow.svg";
import { apiCall } from "../../../../../utils/apiCall";
import DropdownFilterWrapper from "../../../../../components/DropdownComponent/DropdownFilterWrapper";
import CustomInputField from "../../../../../components/InputFieldComponent/InputFieldWrapper.component";
import {
  AssetTypeOverviewWrapper,
  HeadingContainer,
  ContainerRow,
} from "./AddAssetTypeOverview.component.styles";

const baseUrl = require("../../../../../config").baseUrl; //Base Url

const AddAssetTypeOverview = ({
  loginDetails,
  addAssetTypeModalData,
  setAddAssetTypeModalData,
  activeStep,
  setActiveStep,
  isSuperAdmin,
  dropdownAccountList,
  setAccountId,
  dropdownAccountId,
  validated,
  setValidated,
}) => {
  const { t } = useTranslation();
  // const [validationError, setValidationError] = useState("");
  const [isAccountValidationError, setIsAccountValidationError] = useState(false);
  const [isDuplicateNameError, setIsDuplicateNameError] = useState(false);

  const checkForAssetTypeDuplicateName = (assetTypeName = "") => {
    assetTypeName = assetTypeName.trim();

    if (!assetTypeName || (isSuperAdmin && !dropdownAccountId)) {
      setIsDuplicateNameError(false);
      return;
    }

    let accountID;
    if (isSuperAdmin) {
      accountID = dropdownAccountId;
    } else {
      accountID = loginDetails.accountId;
    }

    apiCall(`/assettype/isduplicate/${accountID}/${assetTypeName}`)
      .then((resp) => {
        if (resp?.data?.success) {
          setIsDuplicateNameError(false);
        } else {
          setIsDuplicateNameError(validationConstant.asset_type_name_duplicate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssetTypeNameChange = (value) => {
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeName: value,
    });
  };

  const handleAssetOnBlur = (e) => {
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeName: e.target.value.trim(),
    });
  };

  const handleAssetTypeDescriptionChange = (value) => {
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeDescription: value,
    });
  };

  const checkForValidation = () => {
    if (!dropdownAccountId && isSuperAdmin) {
      setIsAccountValidationError("Account");
      return true;
    }
    if (!addAssetTypeModalData.AssetTypeName) {
      return true;
    }
    if (isDuplicateNameError) {
      return true;
    }
    setIsAccountValidationError(false);
    return false;
  };

  const handleNext = () => {
    setValidated(true);
    // Handle Validation for step 1 then set active step accordingly
    if (checkForValidation()) {
      return;
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    if (dropdownAccountId) {
      setIsAccountValidationError(false);
    }
    if (addAssetTypeModalData.AssetTypeName) {
      checkForAssetTypeDuplicateName(addAssetTypeModalData.AssetTypeName);
    }
  }, [dropdownAccountId]);

  return (
    <AssetTypeOverviewWrapper>
      <HeadingContainer>
        <Trans>Asset Type Overview</Trans>
      </HeadingContainer>

      <ContainerRow>
        <Row className="p4">
          {isSuperAdmin && (
            <Col md={6} className="mb-2">
              <DropdownFilterWrapper
                options={dropdownAccountList?.map((data) => ({
                  _id: data._id,
                  value: data._id,
                  label: data.companyName,
                }))}
                label={t("Account")}
                isError={isAccountValidationError}
                // isValidationError={isAccountValidationError}
                value={dropdownAccountId}
                onChange={(value) => {
                  setAccountId(value);
                }}
                placeholder="Select an Account"
                errorMessage="This field is required"
              />
            </Col>
          )}
        </Row>
        <Row className="p4">
          <Col md={6} className="mb-2">
            <CustomInputField
              label={t("Asset Type Name")}
              name="assetTypeName"
              value={addAssetTypeModalData?.AssetTypeName || ""}
              onChange={(e) => {
                checkForAssetTypeDuplicateName(e.target.value);
                handleAssetTypeNameChange(e.target.value);
              }}
              type="text"
              onBlur={handleAssetOnBlur}
              placeholder={t("Please enter asset type name")}
              minLength={1}
              maxLength={64}
              isError={
                validated &&
                (toValidateAssetTypeName(addAssetTypeModalData?.AssetTypeName) || isDuplicateNameError)
              }
              errorMessage={
                validated &&
                (toValidateAssetTypeName(addAssetTypeModalData?.AssetTypeName) || isDuplicateNameError)
              }
              required
              autofocus
            />
          </Col>
          <Col md={6} className="mb-2">
            <CustomInputField
              label={t("Asset Type Description")}
              name="assetTypeDescription"
              value={addAssetTypeModalData?.AssetTypeDescription || ""}
              onChange={(e) => {
                handleAssetTypeDescriptionChange(e.target.value);
              }}
              placeholder={t("Please enter asset type description")}
              maxLength={"128"}
            />
          </Col>
        </Row>
      </ContainerRow>
      <div className={`${Styles.footerActions}`}>
        <div className={`${Styles.rightSideActions}`}>
          <Button
            label={t("Next")}
            onClick={handleNext}
            iconClass="arrow-right"
            showIcon
            iconPosition="after"
            buttonType="primary"
          />
        </div>
      </div>
    </AssetTypeOverviewWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAssetTypeOverview);
