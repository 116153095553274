import React, { useState } from "react";
//import axios from "axios";
import { withRouter } from "react-router-dom";
// import Required from '../../../assets/asterix/required.svg'
//import { ReactComponent as DeleteIcon } from "../../../assets/AddIcon/Delete Button.svg";
//import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { Container } from "react-bootstrap";

// Redux
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoginDetails } from "../../../redux/user/user.selectors";
/* import { selectAssetTypeList } from "../../../redux/assetType/assetType.selector";
import { LiveTvSharp } from "@material-ui/icons"; */
import { Trans, useTranslation } from "react-i18next";
import { apiCall } from "../../../utils/apiCall";
import {
  AssetMediaDiv,
  HeadingContainer,
  FileUploaderWrapper,
  FileUploaderDiv,
  FileUploaderCard,
  HeaderLabel,
  RowDiv,
  Column,
  RowsContainer,
  TableContainerDiv,
  DragIconBGComponent,
  DeleteIconDiv,
} from "./AssetOverviewForm.component.styles";
//import FileUploadWithDragAndDrop from "../../../components/FileUploaderComponent/FileUploader.component";

//const baseUrl = require("../../../config").baseUrl; //Base Url

function AssetMediaForm({ formData, setFormData, loginDetails }) {
  const [uploadedFile, setUploadedFile] = React.useState([]);
  const [uploadProgress, setUploadProgress] = React.useState(false);
  const [dragText, setDragText] = React.useState("Drop your file here, or browse");
  const { t } = useTranslation();

  const fileUpload = (e) => {
    for (var i = 0; i < e.target.files.length; i++) {
      uploadedFile.push(e.target.files[i]);
      setUploadedFile([...uploadedFile]);
    }
    setTimeout(() => {
      handleSubmit();
    }, 100);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText("Drop your file here, or browse");
    setIsFileDraggedOver(false); // Add this line to hide the drag icon after dropping
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          uploadedFile.push(file);
          setUploadedFile(uploadedFile);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log("... file[" + i + "].name = " + e.dataTransfer.files[i].name);
      }
    }
    setTimeout(() => {
      handleSubmit();
    }, 100);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText("Drop Here ...");
  };

  const removeFile = (e) => {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("id");
    formData.assetMedia.splice(id, 1);
    setFormData({ ...formData });
    setUploadedFile(uploadedFile.filter((file, i) => i != id));
  };

  const handleSubmit = async () => {
    setUploadProgress(true);
    /*     const headers = {
      "auth-token": loginDetails.token,
      "Content-Type": "form-data",
    }; */
    let mediaData = new FormData();
    uploadedFile.map((data) => {
      mediaData.append("media", data);
    });
    await apiCall(`/asset/media`, { method: "POST", data: mediaData, type: "form-data" })
      .then((response) => {
        setUploadProgress(false);
        formData.assetMedia = [];
        if (response.data.success === true) {
          response.data.data.map((data) => {
            formData.assetMedia.push({ assetMediaFile: data.filename });
          });
          setFormData({ ...formData });
        } else {
          alert("not added");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [isFileDraggedOver, setIsFileDraggedOver] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleDragStart = (val) => {
    setIsFileDraggedOver(val);
  };

  return (
    <AssetMediaDiv>
      <Container>
        {/* Header For From */}
        <HeadingContainer>
          <Trans>Asset Media</Trans>
        </HeadingContainer>

        <FileUploaderWrapper>
          <FileUploaderDiv>
            <HeaderLabel>
              <Trans>Asset Documents</Trans>
            </HeaderLabel>

            <FileUploaderCard
              onDrop={handleDrop}
              onDragLeave={() => setDragText("Drop your file here, or browse")}
              onDragOver={handleDrag}
              onDragEnter={() => handleDragStart(true)}
              className={`${
                ("default-upload",
                isFileDraggedOver
                  ? "dragdropBG"
                  : uploadStatus === "failed"
                  ? "BorderError"
                  : uploadStatus === "uploading"
                  ? "BorderUploading"
                  : "BorderDefault")
              }`}
            >
              {isFileDraggedOver && (
                <DragIconBGComponent>
                  <div className="icon icon-add"></div>
                </DragIconBGComponent>
              )}
              <span className="containerSpan">
                <label className="labelContainer">
                  <input
                    onChange={fileUpload}
                    type="file"
                    className="d-none"
                    name="uploadFile"
                    multiple
                    accept=".jpg,.jpeg,.png,application/pdf"
                  />
                  <div className="icon icon-file-upload-logo pngLogoIcon"></div>
                </label>
              </span>

              <span className="dropSpan">
                <Trans>
                  Drop your file here, or <span className="browseColor">browse</span>
                </Trans>
              </span>
              <span className="dragTextFont">
                <Trans>Please upload a .JPG, .PNG or .PDF file</Trans>
              </span>
            </FileUploaderCard>
          </FileUploaderDiv>

          <FileUploaderDiv>
            <TableContainerDiv>
              <RowDiv isHeader={true}>
                <Column isHeader={true} align={"left"}>
                  <Trans>Document</Trans>
                </Column>
                <Column isHeader={true} align={"center"}>
                  <Trans>Status</Trans>
                </Column>
                <Column isHeader={true} align={"center"}>
                  <Trans>Actions</Trans>
                </Column>
              </RowDiv>

              <RowsContainer>
                {uploadedFile.map((file, i) => (
                  <RowDiv key={i}>
                    <Column
                      align={"left"}
                      textleft={"left"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      <Trans>{file.name}</Trans>
                    </Column>
                    <Column
                      align={"center"}
                      textleft={"center"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      {uploadProgress ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        t("Uploaded")
                      )}
                    </Column>

                    <Column
                      align={"left"}
                      textleft={"left"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      <div id={i} onClick={removeFile} className="text-center" style={{ cursor: "pointer" }}>
                        <DeleteIconDiv className="icon icon-delete" />
                      </div>
                    </Column>
                  </RowDiv>
                ))}
              </RowsContainer>
            </TableContainerDiv>
          </FileUploaderDiv>
        </FileUploaderWrapper>
        {/* Main Content */}

        {/* Main Content Ends */}
      </Container>
    </AssetMediaDiv>
  );
}

const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetMediaForm));
