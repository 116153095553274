// Libraries
import React, { useState } from "react";

// React Bootstrap
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
// Content for Modal
import AddUserModalContent from "./AddUserModalContent.component";

// Button Component
import Button from "../../../../../components/Button/Button.component";

function AddUserModal(props) {
  return (
    <>
      <UserCentralModal show={props.show} toogleModalClose={props.toogleModalClose} />
    </>
  );
}

function UserCentralModal(props) {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  return (
    <Modal
      className="modal_bulk_upload"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add User New Modal</Modal.Title>

        <Button label={t("Cancel")} onClick={props.toogleModalClose} showIcon={false} buttonType="ghost" />
      </Modal.Header>

      <Modal.Body className="bulk_upload_body">
        <AddUserModalContent activeStep={activeStep} setActiveStep={setActiveStep} />
      </Modal.Body>
    </Modal>
  );
}

export default AddUserModal;
