import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import ViewAssetTypeOverviewTab from "./ViewAssetTypeOverviewTab.component";
import { Typography } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { ThemeContext } from "styled-components";
import styled from "styled-components";
const AssetTypePageStyled = styled.div`
  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;

const NoSectionStyle = styled.div`
  p {
    .clickDesktop,
    .tapMobile {
      font-family: "Open Sans", sans-serif;
      font-size: 14px !important;
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    }
  }
`;

const ViewAssetTypeOverview = (props) => {
  const { assetTypeOverview, AssetTypeLoading, accountListById, isSuperAdmin } = props;
  const { createdAt, AssetTypeCreatedOn } = assetTypeOverview || {};
  const dateOfCreation = createdAt || AssetTypeCreatedOn;
  const themeContext = useContext(ThemeContext);
  const styles = {
    title: {
      fontFamily: "Kanit",
      fontSize: "26px",
      color: themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      paddingTop: "2px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    subTitle: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      paddingTop: "2px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: " -webkit-box",
      webkitLineClamp: "3",
      webkitBoxOrient: "vertical",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: themeContext?.colors?.grayL4 || "#9A9A9A",
      textAlign: "left",
      paddingTop: "2px",
    },
    subText: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      paddingTop: "2px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      // whiteSpace: "nowrap",
      display: " -webkit-box",
      webkitLineClamp: "2",
      webkitBoxOrient: "vertical",
    },
    safetyEnabled: {
      fontFamily: "Open Sans",
      fontSize: "15px",
      color: themeContext?.colors?.themePrimary || "#0D5FBE",
      textAlign: "left",
      fontWeight: "bold",
      paddingTop: "7px",
    },
    message: {
      color: "rgba(0,0,0,0.5)",
    },
  };
  const addedOn =
    assetTypeOverview &&
    dateOfCreation &&
    moment(dateOfCreation)
      .utcOffset(dateOfCreation && dateOfCreation.split && dateOfCreation.split(" ")[0])
      ?.format(" MMMM DD YYYY");

  return (
    <AssetTypePageStyled>
      <Card className="assetType-height mt-1" style={{ overflow: "hidden" }}>
        <Card.Body>
          {AssetTypeLoading && (
            <span>
              <Row>
                <Col md={12}>
                  <Skeleton height={20} />
                  <Skeleton height={10} count={2} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="mt-4">
                  <Skeleton height={20} width={100} />
                  <Skeleton height={10} count={4} />
                </Col>
              </Row>
            </span>
          )}
          {assetTypeOverview && !AssetTypeLoading && (
            <>
              <Row>
                <Col md={12}>
                  <h3 style={styles.title}>
                    <span title={assetTypeOverview ? assetTypeOverview.AssetTypeName : ""}>
                      {assetTypeOverview ? assetTypeOverview.AssetTypeName : ""}
                    </span>
                  </h3>
                  <div style={styles.subText}>
                    <span title={assetTypeOverview ? assetTypeOverview.AssetTypeDescription : ""}>
                      {assetTypeOverview ? assetTypeOverview.AssetTypeDescription : ""}
                    </span>
                  </div>
                  <div style={styles.date}>
                    <Trans>Added On</Trans>: {addedOn}{" "}
                  </div>
                  <div style={styles.date}>
                    <Trans>Created By</Trans>: SIERA.AI
                  </div>

                  {isSuperAdmin && assetTypeOverview?.accountId && (
                    <div style={{ display: "flex" }}>
                      <div style={styles.date} className="userPage_a">
                        <Trans>AccountLabel</Trans>
                      </div>
                      <div
                        style={{
                          ...styles.date,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                          paddingLeft: "4px",
                          paddingTop: 0,
                        }}
                      >
                        {accountListById?.[assetTypeOverview?.accountId]?.companyName}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <ViewAssetTypeOverviewTab
                  typeAttributes={assetTypeOverview?.AssetTypeAttributes}
                  assignedChecklists={assetTypeOverview?.checklists}
                />
              </Row>
            </>
          )}

          {!assetTypeOverview && !AssetTypeLoading && (
            <Row className="noSection">
              <Col>
                <NoSectionStyle>
                  <Typography align="center" style={styles.message}>
                    <Trans>NO_ASSET_TYPE_IN_ACCOUNT</Trans>
                  </Typography>
                  <Typography align="center" style={styles.message}>
                    <div className="clickDesktop">
                      <Trans>CLICK_ADD_ASSET_TYPE_BUTTON</Trans>
                    </div>
                    <div className="tapMobile">
                      <Trans>CLICK_ADD_ASSET_TYPE_BUTTON_MOB</Trans>
                    </div>
                  </Typography>
                </NoSectionStyle>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </AssetTypePageStyled>
  );
};

const mapStateToProps = (state) => ({
  assetTypeOverview: state.assets.AssetTypeOverview,
  AssetTypeLoading: state.assets.assetTypeDetailLoading,
  accountListById: state.dropdownFilters.accountListById,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypeOverview));
