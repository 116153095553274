import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { Row, Col } from "react-bootstrap";
import EditButton from "../../../assets/AddIcon/Add Icon.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateAccounts } from "../../../redux/accounts/action";
import { s3BucketLogo } from "../../../config";
import DropdownFilter from "../../../components/DropdownFilter.component";
import UpdateButtons from "../../../components/UpdateButtons.component";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as IconRight } from "../../../assets/AddIcon/IconRight.svg";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { setEditAlertModal } from "../../../redux/modals/action";
import { setEditForm } from "../../../redux/forms/action";
import { apiCall } from "../../../utils/apiCall";
import Input from "../../../components/Input";
import units from "../../../constant/units";
import { ManagementPanelWrapper } from "./AccountOverviewTab.component.styles";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const timeZone = require("../../../constant/timeZone");
const baseUrl = require("../../../config").baseUrl; //Base Url

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const labelStyle = {
  position: "relative",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
  left: "3px",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
    boxShadow: "unset",
    borderBottom: "1px solid #ccc",
    fontSize: "30px",
  },
  labelWrapped: {
    fontSize: "0.875rem",
  },
}));

function AccountOverviewTab({
  AccountOverview,
  updateAccounts,
  updateAccountLoading,
  accountDetails,
  setAccountDetails,
  editOverview,
  imageLoaded,
  setImageLoaded,
  AccountLoading,
  editForm,
  setEditForm,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (!AccountOverview) {
      return;
    }
    let { logo, enterpriseUrl, unitType, _id, timeZone } = AccountOverview;
    setAccountDetails({
      ...accountDetails,
      _id,
      logo,
      enterpriseUrl,
      unitType,
      timeZone,
    });
  }, [AccountOverview, updateAccountLoading]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    if (errors.enterpriseUrl === "NotAvailable") {
      return;
    }
    let bodyFormData = new FormData();
    bodyFormData.append("accountData", JSON.stringify({ ...accountDetails }));
    updateAccounts(event.target.id, bodyFormData, setEditForm);
  };

  // Enterprise URL Check
  const handleEnterpriseUrl = (e) => {
    const fullUrl = e.target.value;
    const actualUrl = fullUrl.substring(fullUrl.indexOf("/") + 1);
    setAccountDetails({ ...accountDetails, enterpriseUrl: actualUrl });
    apiCall(`/account/urlAvailabilty/${AccountOverview._id}/${actualUrl}`)
      .then((response) => {
        if (response.data.success === true) {
          setErrors({ enterpriseUrl: "Available" });
        } else if (response.data.success === false) {
          setErrors({ enterpriseUrl: "NotAvailable" });
        }
      })
      .catch((error) => {
        setErrors({ enterpriseUrl: "NotAvailable" });
      });
  };

  const { t, i18n } = useTranslation();

  return (
    <div className={classes.root}>
      <ManagementPanelWrapper>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="ant example"
            className="tabpanelSize"
          >
            <Tab className="buttontabpanels buttonTabSize" label={t("Account Overview")} {...a11yProps(0)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          {AccountLoading && (
            <div>
              <Skeleton className="m-2" count={6} width={"90%"} />
            </div>
          )}
          {!AccountLoading && AccountOverview && (
            <>
              <Row className="worksite-overview-height mt-3">
                <Col md={6}>
                  <div className="text-left mb-2">
                    <Input
                      className="enterpriseUrlStyle"
                      key={"enterpriseUrl"}
                      id={"enterpriseUrl"}
                      label={t("Enterprise URL")}
                      fullWidth
                      required={false}
                      name={"enterpriseUrl"}
                      onChange={handleEnterpriseUrl}
                      value={accountDetails.enterpriseUrl}
                      disabled={editForm}
                      startAdornment={"manage.siera.ai/#/"}
                    />

                    {/* <span><Required style={{marginTop:'4px'}} /></span> */}
                    <div style={{ marginTop: "2px" }} className="text-danger">
                      {errors.enterpriseUrl === t("Please enter enterprise url") ? errors.enterpriseUrl : ""}
                    </div>
                    {errors.enterpriseUrl === "Available" ? (
                      <div style={{ marginTop: "8px" }}>
                        <Trans>URL Availability</Trans>: <IconRight />
                        <span style={{ color: "green" }}>
                          <Trans>You are good to go</Trans>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {errors.enterpriseUrl === "NotAvailable" ? (
                      <div style={{ marginTop: "8px" }}>
                        <Trans>URL Availability</Trans>:{" "}
                        <span style={{ color: "red" }}>
                          <Trans>Enterprise URL is not available</Trans>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                {/* <Col md={4}>
              <div style={{ textAlign: 'left', backgroundColor: '#F9F9F9' }}>
                <div style={{ fontFamily: 'Open Sans', fontSize: '11px', color: '#919191' }}>Address</div>
                <div style={{ fontFamily: 'Open Sans', fontSize: '14px', color: 'black' }}>1601 Rutherford Ln, Suite G100, Austin, TX</div>
              </div>
            </Col> */}
                <Col md={6}>
                  <div style={{ marginTop: "-8px" }}>
                    <Input
                      key={"unitType"}
                      id={"unitType"}
                      fullWidth
                      required={true}
                      name={"unitType"}
                      onChange={(value) => setAccountDetails({ ...accountDetails, unitType: value })}
                      value={accountDetails.unitType ? accountDetails.unitType : "Imperial"}
                      type={"select"}
                      options={units}
                      disabled={editForm}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <label style={labelStyle}>
                    <Trans>Account Logo</Trans>
                  </label>
                  <div className="div_logo_company">
                    {AccountOverview && AccountOverview.logo ? (
                      <>
                        <img
                          style={imageLoaded ? {} : { display: "none", width: "200px" }}
                          alt="company logo"
                          src={
                            AccountOverview && AccountOverview.logo ? s3BucketLogo + AccountOverview.logo : ""
                          }
                          onLoad={() => setImageLoaded(true)}
                        />
                        {!imageLoaded && (
                          <div className="spinner-border" style={{ marginTop: "12%" }} role="status"></div>
                        )}
                      </>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </Col>
                <Col md={6} className="dropdow_acc_f">
                  <DropdownFilter
                    disabled={editForm}
                    value={accountDetails.timeZone}
                    useAsDropdown={true}
                    filter={{
                      type: "Time Zone",
                      name: "label",
                      key: "label",
                      data: timeZone,
                    }}
                    label={t("filter")}
                    handleFilter={(value) => setAccountDetails({ ...accountDetails, timeZone: value })}
                  />
                </Col>
              </Row>

              <PermissionCheck section="DASHBOARD" permissionName={"ACCOUNT_MANAGEMENT"} actionName="edit">
                <Row>
                  <Col md={5}></Col>
                  <Col md={5}></Col>
                  <Row className="editWorks editworksiteT worksiteRight">
                    <Col md={4}></Col>
                    <Col md={4}></Col>

                    <UpdateButtons
                      editOverview={editForm}
                      setEditOverview={setEditForm}
                      statusButton={true}
                      handleUpdate={handleUpdate}
                      IsLoading={updateAccountLoading}
                      overviewDetail={AccountOverview}
                    />
                  </Row>
                </Row>
              </PermissionCheck>
            </>
          )}
        </TabPanel>
      </ManagementPanelWrapper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  AccountOverview: state.accounts.AccountOverview,
  updateAccountLoading: state.accounts.UpdateAccountLoading,
  AccountLoading: state.accounts.AccountLoading,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccounts: (id, data, setEditForm) => dispatch(updateAccounts(id, data, setEditForm)),
  setEditForm: (data) => dispatch(setEditForm(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountOverviewTab));
