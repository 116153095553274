import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { setManagementActionBar } from "../../../redux/mobileView/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setEditAlertModal } from "../../../redux/modals/action";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import managementStyles from "./management.module.css";

const ManagementTabHeader = styled.div`
  div {
    border-bottom: 2px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .tabbaritemactive {
    border-bottom: 3px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    font-weight: 500;
  }
  .selectStyleCss:before {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    position: relative;
    z-index: 1;
  }
  .selectStyleCss select {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  @media screen and (max-width: 1100px) {
    div {
      border-bottom: 0px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    }
  }
`;

const DotIcon = styled.div`
  font-size: 30px !important;
  position: relative;
  top: -5px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
const CancelIcon = styled.div`
  font-size: 30px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
function ManagementTabBar({
  isSuperAdmin,
  isAccountOwner,
  isAccountAdmin,
  activeTab,
  showActionBar,
  setManagementActionBar,
  updateActiveNavItem,
  menuList,
  editForm,
  editFormSecond,
  setEditAlertModal,
}) {
  const { t } = useTranslation();
  const handleSelectDropdown = (e) => {
    if (activeTab == e.target.value) {
      return;
    }
    if (!editForm || !editFormSecond) {
      setEditAlertModal(true);
      return;
    }
    updateActiveNavItem(e.target.value);
  };
  const handleChangeTab = (e) => {
    if (e.target.id == "main_tab") {
      return;
    }
    if (activeTab == e.target.id) {
      return;
    }
    if (!editForm || !editFormSecond) {
      setEditAlertModal(true);
      return;
    }
    updateActiveNavItem(e.target.id);
  };

  return (
    <>
      <ManagementTabHeader>
        <div className={`${managementStyles.tabbar}`} onClick={handleChangeTab} id="main_tab">
          <PermissionCheck section="DASHBOARD" permissionName={"ACCOUNT_MANAGEMENT"} actionName="view">
            <p
              id="accounts"
              className={`${managementStyles.tabbaritem} ${
                activeTab === "accounts" ? "tabbaritemactive" : ""
              }`}
            >
              <Trans>Accounts</Trans>
            </p>
          </PermissionCheck>
          <PermissionCheck section="DASHBOARD" permissionName={"WORKSITE_MANAGEMENT"} actionName="view">
            <p
              id="worksites"
              className={`${managementStyles.tabbaritem} ${
                activeTab === "worksites" ? "tabbaritemactive" : ""
              }`}
            >
              <Trans>Worksites</Trans>
            </p>
          </PermissionCheck>
          <PermissionCheck section="DASHBOARD" permissionName={"ASSET_MANAGEMENT"} actionName="view">
            <p
              id="assets"
              className={`${managementStyles.tabbaritem} ${activeTab === "assets" ? "tabbaritemactive" : ""}`}
            >
              <Trans>Assets</Trans>
            </p>
          </PermissionCheck>
          <PermissionCheck section="DASHBOARD" permissionName={"USER_MANAGEMENT"} actionName="view">
            <p
              id="users"
              className={`${managementStyles.tabbaritem} ${activeTab === "users" ? "tabbaritemactive" : ""}`}
            >
              <Trans>Users</Trans>
            </p>
          </PermissionCheck>
        </div>

        <div className={`${managementStyles.mobiletabbar}`}>
          <div style={{ width: "95%" }} className={`${managementStyles.mainTabSelect} selectStyleCss`}>
            <select
              value={activeTab}
              onChange={(val) => handleSelectDropdown(val)}
              className={`${managementStyles.mobileTabPanelSize}`}
            >
              {isSuperAdmin && <option value="accounts">{t("Accounts")}</option>}
              {(isSuperAdmin || isAccountOwner || isAccountAdmin) && (
                <option value="worksites">{t("Worksites")}</option>
              )}
              <option value="assets">{t("Assets")}</option>
              <option value="users">{t("Users")}</option>
            </select>
          </div>
          <div style={{ width: "50px" }} className={`${managementStyles.threeDot}`}>
            <p onClick={() => setManagementActionBar(!showActionBar)}>
              {showActionBar ? (
                <small className={`${managementStyles.crossStyle}`}>
                  <CancelIcon className="icon icon-cancel"></CancelIcon>
                </small>
              ) : (
                <DotIcon className="icon icon-ellipsis-v"></DotIcon>
              )}
            </p>
          </div>
        </div>
      </ManagementTabHeader>
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
  activeTab: state.sideMenu.activeNavItem,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isAccountAdmin: state.user.isAccountAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  menuList: state.sideMenu.menuState,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  setManagementActionBar: (data) => dispatch(setManagementActionBar(data)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementTabBar));
