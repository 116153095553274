import { assetManagementTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";

//To get all asset types
const getAssetTypeLoading = () => ({
  type: assetManagementTypes.GET_ASSET_TYPE_LOADING,
});

const getAssetTypeSuccess = (data, isResetState, pageNo) => ({
  type: assetManagementTypes.GET_ASSET_TYPE_SUCCESS,
  data,
  isResetState,
  pageNo,
});

const getAssetTypeError = (err) => ({
  type: assetManagementTypes.GET_ASSET_TYPE_ERROR,
  err,
});

export const getAssetTypes = (paginationData, isResetState) => {
  return async (dispatch, getState) => {
    dispatch(getAssetTypeLoading());
    const state = getState();
    const { pageNo, pageSize } = paginationData;
    const accountId = state?.user?.loginDetails?.accountId;
    const dropdownAccountId = state?.dropdownFilters?.accountId;
    const isSuperAdmin = state?.user?.isSuperAdmin;

    const url = `/assetType/list/${isSuperAdmin ? dropdownAccountId : accountId}/${pageNo}/${pageSize}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult = await cache.get(url);
      if (cacheResult) {
        dispatch(getAssetTypeSuccess(cacheResult, isResetState, pageNo));
        return;
      }
    }
    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (resp?.data) {
            cache.set(url, resp.data);
          }
        }
        dispatch(getAssetTypeSuccess(resp.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getAssetTypeError(err));
        console.log(err);
      });
  };
};

// get asset detail by Id
const getAssetTypeDetailLoading = () => ({
  type: assetManagementTypes.GET_ASSET_TYPE_DETAIL_LOADING,
});

const getAssetTypeDetailSuccess = (data) => ({
  type: assetManagementTypes.GET_ASSET_TYPE_DETAIL_SUCCESS,
  data,
});

const getAssetTypeDetailError = (err) => ({
  type: assetManagementTypes.GET_ASSET_TYPE_DETAIL_ERROR,
  err,
});

export const getAssetTypeDetailById = (assetTypeId) => {
  return async (dispatch, getState) => {
    dispatch(getAssetTypeDetailLoading());
    const url = `/assetType/getassettype/${assetTypeId}`;

    apiCall(url)
      .then((resp) => {
        console.log(resp);
        //What is this?
        if (!resp?.data?.data) {
          resp.data.data = [];
        }
        dispatch(getAssetTypeDetailSuccess(resp.data?.data[0]));
      })
      .catch((err) => {
        dispatch(getAssetTypeDetailError(err));
        console.log(err);
      });
  };
};

// Update Asset
export const updateAsset = (data) => ({
  type: assetManagementTypes.UPDATE_ASSET,
  data: data,
});

//To get all assets details
const getAllAssetLoading = () => ({
  type: assetManagementTypes.GET_ALL_ASSETS_LOADING,
});

const getAllAssetSuccess = (data, isResetState, pageNo) => ({
  type: assetManagementTypes.GET_ALL_ASSETS_SUCCESS,
  data,
  isResetState,
  pageNo,
});

export const updateAssetItem = (data) => ({
  type: assetManagementTypes.UPDATE_ASSET_ITEM,
  data,
});

const getAllAssetError = (err) => ({
  type: assetManagementTypes.GET_ALL_ASSETS_ERROR,
  err,
});

export const getAllAsset = (paginationData, isResetState, skipCache) => {
  return async (dispatch, getState) => {
    const state = getState();
    let siteID = state?.user?.loginDetails?.userData?.authorizedSites?.[0];

    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;
    let accountId = state?.user?.loginDetails?.accountId;
    if (isSuperAdmin) {
      accountId = state?.dropdownFilters?.accountId;
    }
    if (!isSiteAdmin) {
      siteID = state?.dropdownFilters?.worksiteId;
    }

    const assetTypeFilterId = state?.dropdownFilters?.assetTypeId;

    const { pageNo, pageSize, searchKey } = paginationData;

    if (!accountId) {
      return;
    }
    dispatch(getAllAssetLoading());
    const url = `/asset/list/${accountId}/${assetTypeFilterId}/${
      siteID ? siteID : "all"
    }/${pageNo}/${pageSize}/${searchKey ? encodeURIComponent(searchKey) : "all"}`;
    const cache = CacheManager.getInstance();

    if (cache && !skipCache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getAllAssetSuccess(cacheResult1, isResetState, pageNo));
        return;
      }
    }
    apiCall(url)
      .then((resp) => {
        //What is this?
        if (!resp?.data?.data) {
          resp.data.data = [];
        }
        if (cache) {
          if (resp?.data?.data?.length > 0) {
            cache.set(url, resp.data);
          }
        }
        dispatch(getAllAssetSuccess(resp.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getAllAssetError(err));
        console.log(err);
      });
  };
};

const getAllAssetsListSuccess = (data, isResetState, pageNo) => ({
  type: assetManagementTypes.GET_ALL_ASSETS_LIST_SUCCESS,
  data,
});

export const getAllAssetsList = () => {
  return async (dispatch, getState) => {
    dispatch(getAllAssetLoading());
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;
    const accountId = isSuperAdmin ? state?.dropdownFilters?.accountId : state?.user?.loginDetails?.accountId;
    const siteID = !isSiteAdmin ? state?.dropdownFilters?.worksiteId : "all";
    apiCall(`/asset/list/${accountId}/all/${siteID || "all"}/all/all/all`)
      .then((resp) => {
        //What is this?
        if (!resp?.data?.data) {
          resp.data.data = [];
        }
        dispatch(getAllAssetsListSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getAllAssetError(err));
        console.log(err);
      });
  };
};

// get asset detail by Id
const getAssetDetailLoading = () => ({
  type: assetManagementTypes.GET_ASSET_DETAIL_LOADING,
});

const getAssetDetailSuccess = (data) => ({
  type: assetManagementTypes.GET_ASSET_DETAIL_SUCCESS,
  data,
});

const getAssetDetailError = (err) => ({
  type: assetManagementTypes.GET_ASSET_DETAIL_ERROR,
  err,
});

export const getAssetDetailById = (assetId) => {
  return async (dispatch, getState) => {
    dispatch(getAssetDetailLoading());
    const url = `/asset/getassets/${assetId}`;

    apiCall(url)
      .then((resp) => {
        console.log(resp);
        //What is this?
        if (!resp?.data?.data) {
          resp.data.data = [];
        }
        dispatch(getAssetDetailSuccess(resp.data?.data));
      })
      .catch((err) => {
        dispatch(getAssetDetailError(err));
        console.log(err);
      });
  };
};

//to update asset status
const assetStatusUpdateLoading = () => ({
  type: assetManagementTypes.UPDATE_ASSET_STATUS_LOADING,
});

const assetStatusUpdateSuccess = (data) => ({
  type: assetManagementTypes.UPDATE_ASSET_STATUS_SUCCESS,
  data,
});

const assetStatusUpdateFailed = (data) => ({
  type: assetManagementTypes.UPDATE_ASSET_STATUS_FAILED,
  data,
});

const assetStatusUpdateError = (err) => ({
  type: assetManagementTypes.UPDATE_ASSET_STATUS_ERROR,
  err,
});

//To add or edit asset image
const toAddAssetImageLoading = () => ({
  type: assetManagementTypes.ADD_ASSET_IMAGE_LOADING,
});

const toAddAssetImageSuccess = (data) => ({
  type: assetManagementTypes.ADD_ASSET_IMAGE_SUCCESS,
  data,
});

const toAddAssetImageError = (err) => ({
  type: assetManagementTypes.ADD_ASSET_IMAGE_ERROR,
  err,
});

export const assetStatusUpdate = (id, status) => {
  return async (dispatch, getState) => {
    dispatch(assetStatusUpdateLoading());

    try {
      const resp = await apiCall(`/asset/changestatus/${id}`, {
        method: "PUT",
        data: status,
      });
      console.log(resp);
      if (resp.data.success) {
        await CacheRefs.removeCacheForAssets();
        dispatch(
          assetStatusUpdateSuccess({
            id: id,
            message: resp.data.message,
          })
        );
      } else {
        dispatch(assetStatusUpdateFailed({ message: resp.data.message, data: resp.data.data }));
        // dispatch(assetStatusUpdateFailed({ id: id, message: resp.data.message, data: resp.data.data }));
        // dispatch(assetStatusUpdateSuccess({ id: id, message: resp.data.message }));
      }
    } catch (err) {
      dispatch(assetStatusUpdateError(err));
      console.log(err);
    }
  };
};

//To add or edit asset image
export const toAddAssetImage = (value, id = 0) => {
  return async (dispatch, getState) => {
    dispatch(toAddAssetImageLoading());
    const formData = new FormData();
    formData.append("assetImg", value);

    try {
      const resp = await apiCall(`/asset/assetImg/${id}`, { method: "PUT", data: formData });
      //dispatch(getAllAsset({ pageNo: 1, pageSize: 20 }));
      await CacheRefs.removeCacheForAssets();
      dispatch(toAddAssetImageSuccess({ data: resp.data, id: id }));
    } catch (err) {
      dispatch(toAddAssetImageError(err));
      console.log(err);
    }
  };
};

//to get asset checklists by checklist ID
const getAssetCheckListByIdLoading = () => ({
  type: assetManagementTypes.GET_ASSET_CHECKLISTS_LOADING,
});

const getAssetCheckListByIdSuccess = (data) => ({
  type: assetManagementTypes.GET_ASSET_CHECKLISTS_SUCCESS,
  data,
});

const getAssetCheckListByIdError = (err) => ({
  type: assetManagementTypes.GET_ASSET_CHECKLISTS_ERROR,
  err,
});

export const getAssetCheckListById = (id) => {
  return async (dispatch, getState) => {
    dispatch(getAssetCheckListByIdLoading());

    apiCall(`/checklist/${id}`)
      .then((resp) => {
        console.log(resp);
        dispatch(getAssetCheckListByIdSuccess(resp?.data?.data));
      })
      .catch((err) => {
        dispatch(getAssetCheckListByIdError(err));
        console.log(err);
      });
  };
};

//To upload multiple media
const toUploadMediaLoading = () => ({
  type: assetManagementTypes.POST_INSPECTION_MEDIA_LOADING,
});

const toUploadMediaSuccess = (data) => ({
  type: assetManagementTypes.POST_INSPECTION_MEDIA_SUCCESS,
  data,
});

const toUploadMediaError = (err) => ({
  type: assetManagementTypes.POST_INSPECTION_MEDIA_ERROR,
  err,
});

export const toUploadMedia = (data) => {
  return async (dispatch, getState) => {
    dispatch(toUploadMediaLoading());
    const formData = new FormData();
    data.map((file) => formData.append("media", file));

    apiCall(`/checklist/uploadMedia`, { method: "POST", data: formData })
      .then((resp) => {
        console.log(resp);
        dispatch(toUploadMediaSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(toUploadMediaError(err));
        console.log(err);
      });
  };
};

//To submit the answers for each question
const postAnswersLoading = () => ({
  type: assetManagementTypes.POST_ANSWER_LOADING,
});

const postAnswersSuccess = (data) => ({
  type: assetManagementTypes.POST_ANSWER_SUCCESS,
  data,
});

const postAnswersError = (err) => ({
  type: assetManagementTypes.POST_ANSWER_ERROR,
  err,
});

export const postAnswers = (data) => {
  return async (dispatch, getState) => {
    dispatch(postAnswersLoading());

    apiCall(`/checklist/checklistResponse`, { method: "POST", data: data })
      .then((resp) => {
        console.log(resp);
        dispatch(postAnswersSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(postAnswersError(err));
        console.log(err);
      });
  };
};

//To generate CSV
const toGenerateCSVLoading = () => ({
  type: assetManagementTypes.GENERATE_CSV_LOADING,
});

const toGenerateCSVSuccess = (data) => ({
  type: assetManagementTypes.GENERATE_CSV_SUCCESS,
  data,
});

const toGenerateCSVError = (err) => ({
  type: assetManagementTypes.GENERATE_CSV_ERROR,
  err,
});
export const toGenerateCSV = () => {
  return async (dispatch, getState) => {
    dispatch(toGenerateCSVLoading());
    const state = getState();
    const siteID = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    let isSuperAdmin = state?.user?.isSuperAdmin;
    let accountId = isSuperAdmin ? "all" : state?.user?.loginDetails?.accountId;

    const url = `/asset/exportData/${accountId}/${siteID ? siteID : 0}`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult = await cache.get(url);
      if (cacheResult) {
        dispatch(toGenerateCSVSuccess(cacheResult));
        return;
      }
    }
    apiCall(url)
      .then((resp) => {
        dispatch(toGenerateCSVSuccess(resp?.data));
        if (cache) {
          if (resp?.data?.length > 0) {
            cache.set(url, resp?.data, CacheRefs.getTtlShort());
          }
        }
      })
      .catch((err) => {
        dispatch(toGenerateCSVError(err));
        console.log(err);
      });
  };
};

//To get the selected asset
export const getSelectedAsset = (data) => ({
  type: assetManagementTypes.STORE_SELECTED_ASSET,
  data,
});

//To get the selected asset type
export const getSelectedAssetType = (data) => ({
  type: assetManagementTypes.STORE_SELECTED_ASSET_TYPE,
  data,
});

//To store selected asset for inspection
export const toStoreInspectionAsset = (data) => ({
  type: assetManagementTypes.TO_STORE_INSPECTION_ASSET,
  data,
});

//To reset all asset state
export const resetAllAssetStatus = () => ({
  type: assetManagementTypes.RESET_ALL_ASSET_STATUS,
});

//To add a asset
export const assetAdded = (data) => ({
  type: assetManagementTypes.ASSET_ADDED,
  data,
});

export const updateAssetOverview = (data) => ({
  type: assetManagementTypes.UPDATE_ASSET_OVERVIEW,
  data: data,
});

// Asset Type Checklist mapping
const updateAssetTypeChecklistLoading = () => ({
  type: assetManagementTypes.UPDATE_ASSET_TYPE_CHECKLIST_LOADING,
});

const updateAssetTypeChecklistSuccess = (data) => ({
  type: assetManagementTypes.UPDATE_ASSET_TYPE_CHECKLIST_SUCCESS,
  data: data,
});

const updateAssetTypeChecklistError = () => ({
  type: assetManagementTypes.UPDATE_ASSET_TYPE_CHECKLIST_ERROR,
});

export const updateAssetTypeChecklist = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(updateAssetTypeChecklistLoading());

    const assetTypeId = state?.assets?.AssetTypeOverview?._id;

    try {
      const resp = await apiCall(`/assetType/mapchecklistassettype/${assetTypeId}`, {
        method: "PUT",
        data: data,
      });

      if (resp?.data?.success) {
        //console.log("ASSET TYPE CHECKLISTS", data);
        await CacheRefs.removeCacheForAssetTypes();
        await CacheRefs.removeCacheForAssets();
        dispatch(updateAssetTypeChecklistSuccess(data?.checklists || []));
      } else {
        dispatch(updateAssetTypeChecklistError());
      }
    } catch (err) {
      console.log("updateAssetTypeChecklist-----------", err);
      dispatch(updateAssetTypeChecklistError());
    }
  };
};

export const resetUpdateAssetTypeChecklistState = () => ({
  type: assetManagementTypes.RESET_UPDATE_ASSET_TYPE_CHECKLIST_STATE,
});

//below is to avoid the intermittent issue on the User successfully Added modal
export const resetAssetSuccessFlag = () => ({
  type: assetManagementTypes.RESET_ASSETS_SUCCESS_FLAG,
});
