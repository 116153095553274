import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import {
  TableContainerDiv,
  RowsContainer,
  HeadingContainer,
  SubmitButtonDiv,
} from "./DownloadBulkLogsFilters.styles";
import Button from "../../../../components/Button/Button.component";
import DropdownComponent from "../../../../components/DropdownComponent/Dropdown.component";
import DateRangePickerFromTo from "../../../../components/DatePicker.component";

import {
  getDropdownWorksiteList,
  getDropdownAssetList,
  getUsers,
} from "../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../utils/apiCall";

const DownloadBulkLogsFilters = ({
  isSuperAdmin,
  dropdownAccountList,
  getDropdownWorksiteList,
  worksiteList,
  getDropdownAssetList,
  assetList,
  userData,
  setShowDownloadBulkLog,
  setDownloadBulkLogsSuccess,
  userList,
  getUsers,
}) => {
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedWorksite, setSelectedWorksite] = useState({ value: null, label: "All" });
  const [selectedAsset, setSelectedAsset] = useState({ value: null, label: "All" });
  const [worksitesListWithAll, setWorksitesListWithAll] = useState([]);
  const [assetListWithAll, setAssetListWithAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setFilterBy] = useState(null);

  const [selectedUser, setSelectedUser] = useState({ value: "all", label: "All Users" });
  const [userListWithAll, setUserListWithAll] = useState([]);

  useEffect(() => {
    if (selectedAccount?.value) {
      getDropdownWorksiteList(selectedAccount.value);
      getUsers(selectedAccount?.value, selectedWorksite?.value || "all", null, 1, 100, "");
    }
  }, [selectedAccount?.value, getDropdownWorksiteList, selectedWorksite?.value, getUsers]);

  useEffect(() => {
    getDropdownAssetList(selectedAccount?.value, selectedWorksite?.value, null);
  }, [selectedWorksite, getDropdownAssetList, selectedAccount?.value]);

  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  const getTimezoneDifference = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset(); // in minutes

    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;

    const sign = offset <= 0 ? "+" : "-";
    return `${sign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  const submitHandler = async () => {
    setIsLoading(true);
    const resp = await apiCall(`/asyncreq/loginrecs/csvReport`, {
      method: "POST",
      data: {
        accountId: selectedAccount?.value,
        siteId: selectedWorksite?.value,
        assetId: selectedAsset.value,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        createdBy: userData?._id,
        emailTo: [userData?.email],
        tz: getTimezoneDifference(),
      },
    });
    setIsLoading(false);

    if (resp.data.success) {
      setShowDownloadBulkLog(false);
      setDownloadBulkLogsSuccess(true);
    }
  };

  useEffect(() => {
    if (worksiteList?.length > 0 && worksiteList?.[0]?._id !== -1) {
      const optionListTemp = worksiteList.map((option) => ({
        label: option.name,
        value: option._id,
      }));
      optionListTemp.unshift({ value: null, label: "All" });
      setWorksitesListWithAll(optionListTemp);
    }
  }, [worksiteList]);

  useEffect(() => {
    if (assetList?.length > 0 && assetList?.[0]?._id !== -1) {
      const optionListTemp = assetList.map((option) => ({
        label: option.assetName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: null, label: "All" });
      setAssetListWithAll(optionListTemp);
    } else {
      setAssetListWithAll([{ value: null, label: "All" }]);
    }
  }, [assetList]);

  useEffect(() => {
    if (userList?.length > 0 && userList?.[0]?._id !== -1) {
      const optionListTemp = userList.map((option) => ({
        label: option.fullName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "all", label: "All Users" });
      setUserListWithAll(optionListTemp);
    } else {
      setUserListWithAll([{ value: "all", label: "All Users" }]);
    }
  }, [userList]);

  return (
    <>
      <TableContainerDiv>
        <HeadingContainer>
          <Trans>Login Records</Trans>
        </HeadingContainer>

        <RowsContainer className="userTypeDetailsHeight">
          <Row className="p-4">
            {isSuperAdmin && (
              <Col md={6} className="mb-2">
                <DropdownComponent
                  label={t("Account")}
                  placeholder={"Select an Account"}
                  options={dropdownAccountList
                    ?.map((data) => ({
                      value: data._id,
                      label: data.companyName,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  value={selectedAccount}
                  handleFilter={(selectedOption) => {
                    setSelectedAccount(selectedOption);
                  }}
                  errorMessage="This field is required"
                />
              </Col>
            )}

            <Col md={6} className="mb-2">
              <DropdownComponent
                label={"Worksite"}
                placeholder={"Select a worksite"}
                options={worksitesListWithAll}
                value={selectedWorksite}
                handleFilter={(selectedWorksite) => {
                  setSelectedWorksite(selectedWorksite);
                }}
                errorMessage="This field is required"
              />
            </Col>

            <Col md={12} className="mb-2 calender z-10">
              <DateRangePickerFromTo
                setDate={(date) => onDateChange(date)}
                startDate={startDate}
                endDate={endDate}
                minDate={moment().subtract(30, "days").toDate()}
                size="small"
              />
            </Col>

            <Col lg={6} className="mb-2">
              <DropdownComponent
                label={"Filter By"}
                placeholder={"Select a Type"}
                options={[
                  {
                    value: "assets",
                    label: "Assets",
                  },
                  {
                    value: "users",
                    label: "Users",
                  },
                ]}
                value={filterBy}
                handleFilter={(option) => {
                  setFilterBy(option);
                }}
                size="medium"
              />
            </Col>
            {filterBy?.value === "users" && (
              <Col lg={6} className="mb-2">
                <DropdownComponent
                  label={"Users"}
                  placeholder={"Select a User"}
                  options={userListWithAll}
                  value={selectedUser}
                  handleFilter={(selectedUser) => {
                    setSelectedUser(selectedUser);
                  }}
                  size="medium"
                />
              </Col>
            )}

            {filterBy?.value === "assets" && (
              <Col lg={6} className="mb-2">
                <DropdownComponent
                  label={"Assets"}
                  placeholder={"Select an Asset"}
                  options={assetListWithAll}
                  value={selectedAsset}
                  handleFilter={(selectedData) => {
                    setSelectedAsset(selectedData);
                  }}
                  size="medium"
                />
              </Col>
            )}
          </Row>

          <SubmitButtonDiv>
            <Button
              iconClass="save"
              onClick={submitHandler}
              showIcon
              buttonType="primary"
              disabled={!selectedAccount?.value || isLoading}
              isLoading={isLoading}
              label={isLoading ? <CircularProgress size={15} style={{ color: "#fff" }} /> : t("Submit")}
            />
          </SubmitButtonDiv>
        </RowsContainer>
      </TableContainerDiv>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  worksiteList: state.modalDropdownFilters.worksiteList,
  getWorksiteListSuccess: state.modalDropdownFilters.getWorksiteListSuccess,
  getWorksiteListLoading: state.modalDropdownFilters.getWorksiteListLoading,
  getWorksiteListFailed: state.modalDropdownFilters.getWorksiteListFailed,

  assetList: state.modalDropdownFilters.assetList,
  getAssetListSuccess: state.modalDropdownFilters.getAssetListSuccess,
  getAssetListLoading: state.modalDropdownFilters.getAssetListLoading,
  getAssetListFailed: state.modalDropdownFilters.getAssetListFailed,
  userData: state.user.loginDetails.userData,
  userList: state.modalDropdownFilters.userList,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownWorksiteList: (accountId) => dispatch(getDropdownWorksiteList(accountId)),
  getDropdownAssetList: (accountId, worksiteId, assetTypeId) =>
    dispatch(getDropdownAssetList(accountId, worksiteId, assetTypeId)),
  getUsers: (accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey) =>
    dispatch(getUsers(accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadBulkLogsFilters);
