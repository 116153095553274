import { useState, useEffect } from "react";
import { uniq, map } from "lodash";
import {
  SelectedItemsWrapper,
  ClearAllButton,
  SelectedItemText,
  RemoveItem,
  SelectedItem,
} from "./MultiSelector.styles";
import SimpleDropdownFilter from "../SimpleDropdownFilter.component";

const MultiSelector = (props) => {
  const {
    options = [],
    placeholder,
    isDisabled,
    label,
    changeHandler,
    returnValueArray,
    selectedOptions,
    clearAllText,
    resetState,
    setResetState,
    maxMenuHeight,
  } = props;
  const [selectedItems, setSelectedItems] = useState(selectedOptions || []);
  const [showSelectionBox, setShowSelectionBox] = useState(false);
  const [optionList, setOptionList] = useState(options);

  useEffect(() => {
    setSelectedItems(selectedOptions);
    const selectedLabelArr = map(selectedOptions, "label");
    const finalOptions = options.filter((option) => !selectedLabelArr.includes(option.label));
    setOptionList(finalOptions);
  }, [selectedOptions.length]);

  useEffect(() => {
    if (resetState) {
      setSelectedItems(selectedOptions);
      const selectedLabelArr = map(selectedOptions, "label");
      const finalOptions = options.filter((option) => !selectedLabelArr.includes(option.label));
      setOptionList(finalOptions);
      setResetState(false);
    }
  }, [resetState]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      if (returnValueArray) {
        const valueArr = selectedItems.map((item) => {
          return item.value;
        });
        changeHandler(valueArr);
      } else {
        changeHandler(selectedItems);
      }
    } else {
      changeHandler([]);
    }
  }, [selectedItems]);

  const setOrderedList = (filteredOptions, setTarget) => {
    const filteredOptionsLocal = uniq(filteredOptions, "label");

    filteredOptionsLocal.sort((a, b) => a.label.localeCompare(b.label));

    setTarget(filteredOptionsLocal);
  };

  const handleFilter = (item) => {
    const filteredOptions = optionList.filter((option) => {
      return option.value !== item.value;
    });
    setOptionList(filteredOptions);
    setOrderedList([...selectedItems, item], setSelectedItems);
  };

  useEffect(() => {
    setShowSelectionBox(selectedItems.length > 0);
  }, [selectedItems]);

  const clearAllHandler = () => {
    setSelectedItems([]);
    setOrderedList(options, setOptionList);
  };

  const removeSelectionHandler = (item) => {
    setOrderedList(
      selectedItems.filter((selectedItem) => selectedItem.value !== item.value),
      setSelectedItems
    );
    setOrderedList([...optionList, item], setOptionList);
  };

  return (
    <>
      <SimpleDropdownFilter
        useAsDropdown={true}
        options={optionList}
        placeholder={placeholder}
        handleFilter={handleFilter}
        disabled={isDisabled}
        label={label}
        maxMenuHeight={maxMenuHeight}
      />
      {showSelectionBox && (
        <SelectedItemsWrapper>
          {!isDisabled ? <ClearAllButton onClick={clearAllHandler}>{clearAllText}</ClearAllButton> : null}
          {selectedItems.map((item, i) => {
            return (
              <SelectedItem key={i}>
                <SelectedItemText>{item.label}</SelectedItemText>
                {!isDisabled ? <RemoveItem onClick={() => removeSelectionHandler(item)}>X</RemoveItem> : null}
              </SelectedItem>
            );
          })}
        </SelectedItemsWrapper>
      )}
    </>
  );
};

MultiSelector.defaultProps = {
  placeholder: "Please select",
  clearAllText: "Clear All",
  optionList: [
    {
      label: "Please add an option",
      value: 1,
    },
  ],
};

export default MultiSelector;
