export const tableHeader = [
  { name: "Worksite", space: 1, feildName: "siteName" },
  { name: "Asset Type", space: 1, feildName: "assetType" },
  { name: "Asset Name", space: 1, feildName: "assetName" },
  { name: "Date, Time", space: 1, feildName: "impactRecordedAt" },
  { name: "Actions", space: 2, feildName: "inspectionCountMonthly" },
];

export const FilterByTypeLabelData = {
  asset: "Asset",
  assetType: "Asset Type",
  inspectionResult: "Inspection Result",
};

export const filterByTypeData = [
  { name: "Asset", _id: "asset" },
  { name: "Asset Type", _id: "assetType" },
];

export const carouselData = [
  {
    name: "Incidents",
    iconClass: "total-incidents",
    color: "#0d5fbe",
    feildName: "totalUnauthorizedIncident",
    type: "ThemePrimary",
    to: "#/app/report",
  },
  {
    name: "Usage",
    iconClass: "unauthorized-user",
    color: "#323232",
    feildName: "totalUnauthorizedUsages",
    type: "ThemePrimary",
    to: "#/app/report",
  },
  {
    name: "Impacts",
    iconClass: "incidentmedium",
    color: "#323232",
    feildName: "totalImpactUnauthorized",
    type: "ThemePrimary",
    to: "#/app/report",
  },
  {
    name: "Near-Miss",
    iconClass: "incidentlow",
    color: "#323232",
    feildName: "TotalNearMiss",
    type: "ThemePrimary",
    to: "#/app/report",
  },
];

export const mobileRowHeader = [
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 1,
    isNestedVar3: true,
    feildName1: "assetId",
    feildName2: "assetTypeId",
    feildName3: "AssetTypeName",
  },
  {
    name: "Asset Name",
    keyName: "assetName",
    space: 1,
    isNestedVar2: true,
    feildName1: "assetId",
    feildName2: "assetName",
  },
  {
    name: "Inspected By",
    sapce: 1,
    isNestedVar2: true,
    feildName1: "inspectorId",
    feildName2: "fullName",
  },
  {
    name: "Date",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    feildName: "createdAt",
  },
];

export const tableRowData = [
  {
    name: "Worksite",
    keyName: "siteName",
    space: 1,
    feildName: "siteName",
    mobile: true,
  },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 1,
    feildName: "assetType",
    mobile: true,
  },
  {
    name: "Asset Impacted",
    keyName: "assetImpacted",
    space: 1,
    feildName: "assetName",
    mobile: true,
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    feildName: "impactRecordedAt",
    mobile: true,
  },
];

export const managementCountBannerData = {
  accountViewer: [
    {
      name: "Worksites",
      iconClass: "worksites",
      type: "ThemePrimary",
      feildName: "sites",
    },
    {
      name: "Assets",
      iconClass: "assets",
      type: "ThemePrimary",
      feildName: "assets",
    },
    { name: "Users", iconClass: "users", type: "ThemePrimary", feildName: "users" },
  ],
  superAdmin: [
    {
      name: "Accounts",
      iconClass: "accounts",
      type: "ThemePrimary",
      feildName: "accounts",
    },
    {
      name: "Worksites",
      iconClass: "worksites",
      type: "ThemePrimary",
      feildName: "sites",
    },
    {
      name: "Assets",
      iconClass: "assets",
      type: "ThemePrimary",
      feildName: "assets",
    },
    { name: "Users", iconClass: "users", type: "ThemePrimary", feildName: "users" },
  ],
  siteAdmin: [
    {
      name: "Assets",
      iconClass: "assets",
      type: "ThemePrimary",
      feildName: "assets",
    },
    { name: "Users", iconClass: "users", type: "ThemePrimary", feildName: "users" },
  ],
  accountOwner: [
    {
      name: "Worksites",
      iconClass: "worksites",
      type: "ThemePrimary",
      feildName: "sites",
    },
    {
      name: "Assets",
      iconClass: "assets",
      type: "ThemePrimary",
      feildName: "assets",
    },
    { name: "Users", iconClass: "users", type: "ThemePrimary", feildName: "users" },
  ],
};
