import { Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import Input from "../../../../components/Input";
import { toValidatePassword, toValidateConfirmPassword } from "../../../../utils/validations/checkValidation";
import { getRandomPassword } from "../../../../utils";
import CustomInputField from "../../../../components/InputFieldComponent/InputFieldWrapper.component";

const DashboardLogin = (props) => {
  const { validated, formData, setFormData } = props;
  const { t } = useTranslation();

  const handlePasswordChange = (e) => {
    setFormData({
      ...formData,
      password: e?.target?.value,
    });
  };

  const handleConfirmPasswordChange = (e) => {
    setFormData({
      ...formData,
      passwordVerification: e.target.value,
    });
  };

  const handleAutoGeneratePassword = (e) => {
    let newPassword = getRandomPassword();
    let { autoGeneratePassword } = formData;
    setFormData({
      ...formData,
      autoGeneratePassword: !autoGeneratePassword,
      password: !autoGeneratePassword ? newPassword : "",
      passwordVerification: !autoGeneratePassword ? newPassword : "",
    });
  };

  return (
    <>
      <Col>
        <input
          checked={formData.autoGeneratePassword}
          onChange={(e) => handleAutoGeneratePassword(e)}
          name="autogeneratePassword"
          type="checkbox"
          style={{ marginRight: "5px" }}
        />
        <label for="autogeneratePassword">
          <Trans>Auto-generate Password</Trans>?
        </label>
      </Col>
      <Col className="asterisk_form">
        <CustomInputField
          label="Password"
          required
          id="password"
          placeholder="Password"
          variant="standard"
          type="password"
          name="password"
          autocomplete="new-password"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,64}$"
          onChange={(e) => handlePasswordChange(e)}
          isInvalid={validated && toValidatePassword(formData.password)}
          feedback={validated && t(toValidatePassword(formData.password))}
          value={formData.password}
          isError={validated && toValidatePassword(formData.password)}
          errorMessage={validated && toValidatePassword(formData.password)}
        />
      </Col>

      <Col className="asterisk_form">
        <CustomInputField
          label={t("Confirm Password")}
          id="passwordVerification"
          placeholder={t("Confirm Password")}
          variant="standard"
          type="password"
          name="passwordVerification"
          autocomplete="new-password"
          onChange={(e) => handleConfirmPasswordChange(e)}
          isInvalid={validated && toValidateConfirmPassword(formData.password, formData.passwordVerification)}
          feedback={
            validated && t(toValidateConfirmPassword(formData.password, formData.passwordVerification))
          }
          value={formData.passwordVerification}
          isError={validated && toValidateConfirmPassword(formData.password, formData.passwordVerification)}
          errorMessage={
            validated && t(toValidateConfirmPassword(formData.password, formData.passwordVerification))
          }
        />
      </Col>
    </>
  );
};

export default DashboardLogin;
