import React from "react";
import DropdownFilter from "../../../components/DropdownFilter.component";
import { Col } from "react-bootstrap";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateCurrentAssetStatus } from "../../../redux/asset/asset.action";
import { assetStatusUpdate } from "../../../redux/assets/action";
import { useTranslation } from "react-i18next";
import {
  getDropdownWorksiteList,
  getDropdownAccountList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
} from "../../../redux/dropdownFilters/action";

function AssetFilters({
  loginDetails,
  dropdownAccountList,
  dropdownWorksiteList,
  dropdownAssetTypeList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  isSuperAdmin,
  showWorksiteDropdown,
}) {
  const { t } = useTranslation();

  return (
    <>
      {isSuperAdmin && (
        <Col>
          <DropdownFilter
            filter={{
              type: "Account",
              name: "companyName",
              key: "companyName",
              data: dropdownAccountList,
            }}
            label={t("filter")}
            handleFilter={(value) => setDropdownAccountId(value)}
          />
        </Col>
      )}

      {showWorksiteDropdown && (
        <Col>
          <DropdownFilter
            filter={{
              type: "Worksite",
              key: "name",
              data: dropdownWorksiteList,
            }}
            label={t("filter")}
            handleFilter={(value) => setDropdownWorksiteId(value)}
          />
        </Col>
      )}
      <Col>
        <DropdownFilter
          filter={{
            type: "Asset Type",
            key: "AssetTypeName",
            data: dropdownAssetTypeList,
          }}
          label={t("filter")}
          handleFilter={(value) => setDropdownAssetTypeId(value)}
        />
      </Col>
      {!showWorksiteDropdown && <Col md={3}></Col>}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetList: state.assets.Assets,
  assetTotalCount: state.assets.totalCount,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAccountList: state.dropdownFilters.accountList,
  isSuperAdmin: state.user.isSuperAdmin,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
});

const mapDispatchToProps = (dispatch) => ({
  updateCurrentAssetStatus: (data) => dispatch(updateCurrentAssetStatus(data)),
  assetStatusUpdate: (id, status) => dispatch(assetStatusUpdate(id, status)),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownAssetTypeId: (assetTypeId) => dispatch(setDropdownAssetTypeId(assetTypeId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetFilters));
