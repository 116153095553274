import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Redux
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoginDetails } from "../../../redux/user/user.selectors";
import { selectAssetTypeOverview } from "../../../redux/assetType/assetType.selector";
import { assetTypeList, defaultAssetTypeOverview } from "../../../redux/assetType/assetType.action";
import { Trans, useTranslation } from "react-i18next";
import RadioButton from "../../../components/RadioButton/Radio.component";

function RadioYes(props) {
  const { name, handleRadio, AssetAttributeRequired, isDisabled } = props;
  const { t } = useTranslation();
  return (
    <Row>
      <FormControlLabel
        value="Yes"
        disabled={isDisabled}
        control={
          <RadioButton name={name} onClick={handleRadio} checked={AssetAttributeRequired} size="small" />
        }
        label={t("Yes")}
      />
      <FormControlLabel
        value="optional"
        disabled={isDisabled}
        control={
          <RadioButton name={name} onClick={handleRadio} checked={!AssetAttributeRequired} size="small" />
        }
        label={t("Optional")}
      />
    </Row>
  );
}

function ViewAssetTypeOverviewTable({ selectAssetTypeOverview, typeAttributes }) {
  return (
    <Card className="text-style border-0 shadow-none">
      <Card.Header className="border-0 card_assetBody table_margin">
        <Row>
          <Col lg={4}>
            <h5 style={{ paddingLeft: "30px" }}>
              <Trans>Field Name</Trans>
            </h5>
          </Col>
          <Col lg={4}>
            <h5>
              <Trans>Required</Trans>
            </h5>
          </Col>
          <Col lg={4}>
            <h5>
              <Trans>Description</Trans>
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="assetType_sec">
        {typeAttributes?.map((data, i) => (
          <Fragment key={data?._id}>
            <Row>
              <Col lg={4} style={{ verticalAlign: "middle" }}>
                <h6 className="text-left mt-2">{data?.AssetAttributeName}</h6>
              </Col>
              <Col lg={4} className="radioLabel">
                <RadioYes
                  name={i}
                  isDisabled={true}
                  handleRadio={() => {}}
                  AssetAttributeRequired={data?.AssetAttributeRequired}
                />
              </Col>
              <Col lg={4} style={{ verticalAlign: "middle" }}>
                <h6 className="text-left mt-2">{data?.AssetAttributeDescription}</h6>
              </Col>
            </Row>
            <hr className="m-0 p-0" />
          </Fragment>
        ))}
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
  selectAssetTypeOverview: selectAssetTypeOverview,
});

const mapDispatchToProps = (dispatch) => ({
  setAssetTypeList: (assetTypeLists) => dispatch(assetTypeList(assetTypeLists)),
  defaultAssetTypeOverview: () => dispatch(defaultAssetTypeOverview()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypeOverviewTable));
