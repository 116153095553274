import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Button from "../../components/Button/Button.component";
import {
  ModalWrapper,
  StyledModal,
  AlertModal,
  AlertIcon,
  MessageStyled,
  ParagraphStyled,
  ButtonStyled,
} from "./ModalPopup.component.styles";

const ModalComponent = ({
  show,
  title,
  size,
  variant,
  handleClose,
  withHeader,
  children,
  iconClass,
  message,
  paragraph,
  button1Text,
  button2Text,
  onClickA,
  onClickB,
  typeA,
  typeB,
  styleA,
  styleB,
  paragraphWidth,
  ...rest
}) => {
  const renderCancelButton =
    withHeader && !["AlertModal", "AcknowledgementModal", "TransactionalModal"].includes(variant);

  return (
    <ModalWrapper>
      <StyledModal show={show} variant={variant} onHide={handleClose} centered size={size} {...rest}>
        {withHeader && (
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>

            {renderCancelButton && (
              <Button label="Cancel" onClick={handleClose} showIcon buttonType="ghost" />
            )}
          </Modal.Header>
        )}
        <Modal.Body>
          {variant === "AlertModal" && (
            <AlertModal>
              <AlertIcon className={`icon-${iconClass}`} />
              <MessageStyled>{message}</MessageStyled>
              <ParagraphStyled width={paragraphWidth}>{paragraph}</ParagraphStyled>
              <ButtonStyled>
                {button1Text && (
                  <Button label={button1Text} onClick={onClickA} buttonType={typeA} style={styleA} />
                )}
                <br />
                {button2Text && (
                  <Button label={button2Text} onClick={onClickB} buttonType={typeB} style={styleB} />
                )}
              </ButtonStyled>
            </AlertModal>
          )}

          {variant === "AcknowledgementModal" && (
            <AlertModal>
              <MessageStyled>{message}</MessageStyled>
              <ParagraphStyled width={paragraphWidth}>{paragraph}</ParagraphStyled>
              <ButtonStyled>
                <Button label={button1Text} onClick={onClickA} buttonType={typeA} style={styleA} />
              </ButtonStyled>
            </AlertModal>
          )}

          {variant === "TransactionalModal" && (
            <AlertModal>
              <MessageStyled>{message}</MessageStyled>
              <ParagraphStyled width={paragraphWidth}>{paragraph}</ParagraphStyled>
              <ButtonStyled>
                <Button label={button1Text} onClick={onClickA} buttonType={typeA} style={styleA} />
                <br />
                <Button label={button2Text} onClick={onClickB} buttonType={typeB} style={styleB} />
              </ButtonStyled>
            </AlertModal>
          )}

          {children}
        </Modal.Body>
      </StyledModal>
    </ModalWrapper>
  );
};

ModalComponent.propTypes = {
  /**
   * Specify whether the Input should be disabled, or not
   */
  show: PropTypes.bool.isRequired,
  /**
   * Specify whether the Input should be disabled, or not
   */
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  /**
   * Specify whether the Input should be disabled, or not
   */
  title: PropTypes.string,
  /**
   * Specify whether the Input should be disabled, or not
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * Specify whether the Input should be disabled, or not
   */
  withHeader: PropTypes.bool,
  /**
   * Specify whether the Input should be disabled, or not
   */
  children: PropTypes.node.isRequired,
  /**
   *  Here we can choose tab as vertical or horizontal
   */
  variant: PropTypes.oneOf(["AlertModal", "AcknowledgementModal", "TransactionalModal"]),
  /**
   *  Here we can specify width of the paragraph
   */
  paragraphWidth: PropTypes.string,
};

ModalComponent.defaultProps = {
  size: "lg",
  withHeader: true,
  paragraphWidth: "60%",
};

export default ModalComponent;
