module.exports = {
  ASSET: {
    qrContent: "_id",
    qrLabel: "assetName",
  },
  USERS: {
    qrContent: "_id",
    qrLabel: "fullName",
  },
};
