export const accountManagementTypes = {
  //For Account List
  GET_ALL_ACCOUNT_LOADING: "GET_ALL_ACCOUNT_LOADING",
  GET_ALL_ACCOUNT_SUCCESS: "GET_ALL_ACCOUNT_SUCCESS",
  GET_ALL_ACCOUNT_ERROR: "GET_ALL_ACCOUNT_ERROR",

  SELECT_ACCOUNT: "SELECT_ACCOUNT",

  // For Getting Single account By id
  GET_ACCOUNT_DETAIL_LOADING: "GET_ACCOUNT_DETAIL_LOADING",
  GET_ACCOUNT_DETAIL_SUCCESS: "GET_ACCOUNT_DETAIL_SUCCESS",
  GET_ACCOUNT_DETAIL_ERROR: "GET_ACCOUNT_DETAIL_ERROR",

  //For Update Account
  UPDATE_ACCOUNT_LOADING: "UPDATE_ACCOUNT_LOADING",
  UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_ERROR: "UPDATE_ACCOUNT_ERROR",

  //For Adding New Account
  ADD_NEW_ASSET_LOADING: "ADD_NEW_ASSET_LOADING",
  ADD_NEW_ASSET_SUCCESS: "ADD_NEW_ASSET_SUCCESS",
  ADD_NEW_ASSET_ERROR: "ADD_NEW_ASSET_ERROR",

  //For Account Status
  UPDATE_ASSET_STATUS_LOADING: "UPDATE_ASSET_STATUS_LOADING",
  UPDATE_ASSET_STATUS_SUCCESS: "UPDATE_ASSET_STATUS_SUCCESS",
  UPDATE_ASSET_STATUS_ERROR: "UPDATE_ASSET_STATUS_ERROR",

  //For Account Image Update
  UPDATE_ASSET_IMAGE_LOADING: "UPDATE_ASSET_IMAGE_LOADING",
  UPDATE_ASSET_IMAGE_SUCCESS: "UPDATE_ASSET_IMAGE_SUCCESS",
  UPDATE_ASSET_IMAGE_ERROR: "UPDATE_ASSET_IMAGE_ERROR",

  //For Generate CSV
  GENERATE_CSV_LOADING: "GENERATE_CSV_LOADING",
  GENERATE_CSV_SUCCESS: "GENERATE_CSV_SUCCESS",
  GENERATE_CSV_ERROR: "GENERATE_CSV_ERROR",

  //For Account Name Edit
  EDIT_ASSET_NAME_LOADING: "EDIT_ASSET_NAME_LOADING",
  EDIT_ASSET_NAME_SUCCESS: "EDIT_ASSET_NAME_SUCCESS",
  EDIT_ASSET_NAME_ERROR: "EDIT_ASSET_NAME_ERROR",

  //For Account Checklist
  GET_ASSET_CHECKLISTS_LOADING: "GET_ASSET_CHECKLISTS_LOADING",
  GET_ASSET_CHECKLISTS_SUCCESS: "GET_ASSET_CHECKLISTS_SUCCESS",
  GET_ASSET_CHECKLISTS_ERROR: "GET_ASSET_CHECKLISTS_ERROR",

  //For Inspection Upload Media
  POST_INSPECTION_MEDIA_LOADING: "POST_INSPECTION_MEDIA_LOADING",
  POST_INSPECTION_MEDIA_SUCCESS: "POST_INSPECTION_MEDIA_SUCCESS",
  POST_INSPECTION_MEDIA_ERROR: "POST_INSPECTION_MEDIA_ERROR",

  //For Answer Submit
  POST_ANSWER_LOADING: "POST_ANSWER_LOADING",
  POST_ANSWER_SUCCESS: "POST_ANSWER_SUCCESS",
  POST_ANSWER_ERROR: "POST_ANSWER_ERROR",

  //For Storing Selected Account
  STORE_SELECTED_ASSET: "STORE_SELECTED_ASSET",
  DEFAULT_ASSET_SELECTED: "DEFAULT_ASSET_SELECTED",
  TO_STORE_INSPECTION_ASSET: "TO_STORE_INSPECTION_ASSET",

  //For Storing Selected Account Type
  STORE_SELECTED_ASSET_TYPE: "STORE_SELECTED_ASSET_TYPE",

  //For resetting all state
  RESET_ALL_ASSET_STATUS: "RESET_ALL_ASSET_STATUS",
  ASSET_ADDED: "ASSET_ADDED",

  // Update Account
  UPDATE_ASSET: "UPDATE_ASSET",
};
