import styled from "styled-components";

export const UserTypeEditDiv = styled.div`
  margin: 10px 20px auto;
`;

export const ModalEditWrapper = styled.div`
  .modal-header {
    padding: 10px 30px !important;
  }
`;

export const ButtonWrapper = styled.div`
  margin: auto 10px;
  width: 96%;
  text-align: end;
`;
