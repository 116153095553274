import { checklistManagementTypes } from "./types";
import { baseUrl } from "../../../src/config";
import axios from "axios";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";
//To get all checklist
const getChecklistLoading = () => ({
  type: checklistManagementTypes.GET_CHECKLIST_LOADING,
});

const getChecklistSuccess = (data, isResetState, pageNo) => ({
  type: checklistManagementTypes.GET_CHECKLIST_SUCCESS,
  data,
  isResetState,
  pageNo,
});

const getChecklistError = (err) => ({
  type: checklistManagementTypes.GET_CHECKLIST_ERROR,
  err,
});

export const getAllChecklist = (paginationData, isResetState, accId) => {
  //console.log(paginationData, "----------->>>>>>>");
  return async (dispatch, getState) => {
    dispatch(getChecklistLoading());
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const token = state?.user?.loginDetails?.token;
    let accountId = state?.user?.loginDetails?.accountId;
    if (isSuperAdmin) {
      accountId = state?.dropdownFilters?.accountId;
    }
    const headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };
    const { pageNo, pageSize, searchKey } = paginationData;
    const urlChkListPart = `/checklist/list/${accId ? accId : accountId}/${pageNo}/${pageSize}/${
      searchKey ? encodeURIComponent(searchKey) : "all"
    }`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(urlChkListPart);
      if (cacheResult1) {
        dispatch(getChecklistSuccess(cacheResult1, isResetState, pageNo));
        return;
      }
    }
    axios
      .get(`${baseUrl}${urlChkListPart}`, {
        headers: headers,
      })
      .then((response) => {
        //What is this
        if (!response.data.data) {
          response.data.data = [];
        }
        if (cache) {
          if (response?.data?.data?.length > 0) {
            cache.set(urlChkListPart, response.data);
          }
        }
        dispatch(getChecklistSuccess(response.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getChecklistError(err));
      });
  };
};

// To get checklist templates
const getChecklistTemplatesLoading = () => ({
  type: checklistManagementTypes.GET_CHECKLIST_TEMPLATES_LOADING,
});

const getChecklistTemplatesSuccess = (data, isResetState, pageNo) => ({
  type: checklistManagementTypes.GET_CHECKLIST_TEMPLATES_SUCCESS,
  data,
  isResetState,
  pageNo,
});

const getChecklistTemplatesError = (err) => ({
  type: checklistManagementTypes.GET_CHECKLIST_TEMPLATES_ERROR,
  err,
});

export const getAllChecklistTemplates = (paginationData, isResetState) => {
  return async (dispatch, getState) => {
    dispatch(getChecklistTemplatesLoading());
    const { pageNo, pageSize } = paginationData;

    apiCall(`/checkListTemplate/getChecklistTemplate`)
      .then((response) => {
        //What is this
        if (!response.data.data) {
          response.data.data = [];
        }
        dispatch(getChecklistTemplatesSuccess(response.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getChecklistTemplatesError(err));
      });
  };
};

export const selectChecklistTemplateOverview = (id) => ({
  type: checklistManagementTypes.CHECKLIST_TEMPLATE_OVERVIEW_SUCCESS,
  data: id,
});

//To Create Checklist
const createChecklistLoading = () => ({
  type: checklistManagementTypes.CREATE_CHECKLIST_LOADING,
});

const createChecklistSuccess = (data) => ({
  type: checklistManagementTypes.CREATE_CHECKLIST_SUCCESS,
  data,
});

const resetChecklistSuccess = () => ({
  type: checklistManagementTypes.RESEST_CHECKLIST_SUCCESS,
});

const createChecklistError = (err) => ({
  type: checklistManagementTypes.CREATE_CHECKLIST_ERROR,
  err,
});

export const createChecklist = (data, setAddChecklistModal) => {
  return async (dispatch, getState) => {
    dispatch(createChecklistLoading());

    try {
      const response = await apiCall(`/checklist`, { method: "POST", data: data });
      await CacheRefs.removeCacheForChecklists();
      dispatch(createChecklistSuccess(response.data));
      setTimeout(() => {
        dispatch(resetChecklistSuccess());
        setAddChecklistModal(false);
      }, 1000);
    } catch (err) {
      dispatch(createChecklistError(err));
    }
  };
};

//To Update Checklist
const updateChecklistLoading = () => ({
  type: checklistManagementTypes.UPDATE_CHECKLIST_LOADING,
});

const updateChecklistSuccess = (data) => ({
  type: checklistManagementTypes.UPDATE_CHECKLIST_SUCCESS,
  data,
});

const updateChecklistError = (err) => ({
  type: checklistManagementTypes.UPDATE_CHECKLIST_ERROR,
  err,
});

export const updateChecklist = (data, setAddChecklistModal) => {
  return async (dispatch, getState) => {
    dispatch(updateChecklistLoading());
    const token = getState()?.user?.loginDetails.token;
    const headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    try {
      const response = await axios.put(`${baseUrl}/checklist/updateChecklistBulk/${data.checklistId}`, data, {
        headers: headers,
      });
      await CacheRefs.removeCacheForChecklists();
      dispatch(updateChecklistSuccess(response.data.data));
      setAddChecklistModal(false);
    } catch (err) {
      dispatch(updateChecklistError(err));
    }
  };
};

//To Add or Update Question
const addUpdateQuestionLoading = () => ({
  type: checklistManagementTypes.ADD_UPDATE_QUESTION_LOADING,
});

const addUpdateQuestionSuccess = (data) => ({
  type: checklistManagementTypes.ADD_UPDATE_QUESTION_SUCCESS,
  sectionData: data,
});

const addUpdateQuestionError = (err) => ({
  type: checklistManagementTypes.ADD_UPDATE_QUESTION_ERROR,
  err,
});

export const addUpdateQuestion = (data) => {
  return async (dispatch, getState) => {
    dispatch(addUpdateQuestionLoading());

    try {
      const response = await apiCall(`/checklist/updateQuestion`, { method: "POST", data: data });
      console.log(response.data);
      await CacheRefs.removeCacheForChecklists();
      dispatch(addUpdateQuestionSuccess(response.data.sections));
    } catch (err) {
      dispatch(addUpdateQuestionError(err));
    }
  };
};

//To Add or Update Question
const addUpdateSectionLoading = () => ({
  type: checklistManagementTypes.ADD_UPDATE_SECTION_LOADING,
});

const addUpdateSectionSuccess = (data) => ({
  type: checklistManagementTypes.ADD_UPDATE_SECTION_SUCCESS,
  data,
});

const addUpdateSectionError = (err) => ({
  type: checklistManagementTypes.ADD_UPDATE_SECTION_ERROR,
  err,
});

export const addUpdateSection = (data) => {
  return async (dispatch, getState) => {
    dispatch(addUpdateSectionLoading());

    try {
      const response = await apiCall(`/checklist/updateSection`, { method: "POST", data: data });
      //dispatch(addUpdateSectionSuccess(response.data));
      await CacheRefs.removeCacheForChecklists();
      console.log(response.data, "___)))))))(((***&&&");
    } catch (err) {
      dispatch(addUpdateSectionError(err));
    }
  };
};

//Checklist Overview
export const selectChecklistOverviewLoading = () => ({
  type: checklistManagementTypes.CHECKLIST_OVERVIEW_LOADING,
});

export const selectChecklistOverview = (id) => ({
  type: checklistManagementTypes.CHECKLIST_OVERVIEW_SUCCESS,
  data: id,
});

// Response Type Dropdown
const getResponseTypeLoading = () => ({
  type: checklistManagementTypes.GET_RESPONSE_TYPE_LOADING,
});

const getResponseTypeSuccess = (data) => ({
  type: checklistManagementTypes.GET_RESPONSE_TYPE_SUCCESS,
  data,
});

export const getResponseType = () => {
  return async (dispatch, getState) => {
    dispatch(getResponseTypeLoading());

    apiCall(`/responseType`)
      .then((response) => {
        dispatch(getResponseTypeSuccess(response.data.data));
      })
      .catch((err) => {
        //dispatch(getChecklistError(err));
      });
  };
};

// Remove Section
const deleteSectionLoading = () => ({
  type: checklistManagementTypes.DELETE_SECTION_LOADING,
});

const deleteSectionSuccess = (data) => ({
  type: checklistManagementTypes.DELETE_SECTION_SUCCESS,
  data,
});

const deleteSectionError = (err) => ({
  type: checklistManagementTypes.DELETE_SECTION_ERROR,
  err,
});

export const deleteSection = (checklistId, sectionId) => {
  return async (dispatch, getState) => {
    dispatch(deleteSectionLoading());

    try {
      const response = await apiCall(`/checklist/section/${checklistId}/${sectionId}`, { method: "DELETE" });
      await CacheRefs.removeCacheForChecklists();
      dispatch(deleteSectionSuccess(response.data));
    } catch (err) {
      dispatch(deleteSectionError(err));
    }
  };
};

// Remove Question
const deleteQuestionLoading = () => ({
  type: checklistManagementTypes.DELETE_QUESTION_LOADING,
});

const deleteQuestionSuccess = (data) => ({
  type: checklistManagementTypes.DELETE_QUESTION_SUCCESS,
  data,
});

const deleteQuestionError = (err) => ({
  type: checklistManagementTypes.DELETE_QUESTION_ERROR,
  err,
});

export const deleteQuestion = (sectionId, questionId) => {
  return async (dispatch, getState) => {
    dispatch(deleteQuestionLoading());

    try {
      const response = await apiCall(`/checklist/question/${sectionId}/${questionId}`, { method: "DELETE" });
      await CacheRefs.removeCacheForChecklists();
      dispatch(deleteQuestionSuccess(response.data));
    } catch (err) {
      dispatch(deleteQuestionError(err));
    }
  };
};

// For edit template
export const setUpdateEditTemplate = (data) => ({
  type: checklistManagementTypes.UPDATE_EDIT_TEMPLATE,
  data,
});

//below is to avoid the intermittent issue on the User successfully Added modal
export const resetChecklistSuccessFlag = () => ({
  type: checklistManagementTypes.RESET_CHECKLISTS_SUCCESS_FLAG,
});
