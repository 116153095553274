import React, { useState, useEffect, Component, useContext } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "./PhoneInput";
import Select, { components } from "react-select";
import { ReactComponent as AddAssetTypeIcon } from "../assets/QuickManagement/add_asset_type_a.svg";
import { ReactComponent as AddWorksiteIcon } from "../assets/QuickManagement/Add_Worksite.svg";
import { useHistory } from "react-router-dom";
import {
  setAddAssetModal,
  setAddSiteModal,
  setAddUserModal,
  setAddAssetTypeModal,
  setModalWaitingToOpen,
  setAddAssetModalRedirect,
} from "../redux/modals/action";
import { updateActiveNavItem } from "../redux/sideMenu/menu.action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import validationConstant from "../constant/messages/validation.json";
import styled from "styled-components";
import { ThemeContext } from "styled-components";
import { ErrorLabel } from "./Input.styles";
import PermissionCheck from "../components/PermissionCheck/PermissionCheck";

// function noAssetTypeLayout(history){
//   return <>
//     <p style={{textAlign: "left"}}>There are no asset types in your account</p>
//     <p style={{textAlign: "left",cursor:"pointer"}} onClick={() => history.push("/app/management/assettype/add")}><AddAssetTypeIcon style={{width: "16px",marginRight:"10px",}} />--Add an Asset Type--</p>
//   </>
// }

import Tooltip from "@material-ui/core/Tooltip";

const StyledInputGroup = styled(InputGroup.Text)`
  font-size: 14px;
  padding-left: 4px !important;
  padding-right: 4px !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
`;

const { Option } = components;
const customOption = (props) => {
  return (
    <Tooltip title={props.label}>
      <span>
        <Option {...props} />
      </span>
    </Tooltip>
  );
};

const isFirstCharacterVowel = (character) => {
  character = character?.toLowerCase?.();
  if (character == "a" || character == "e" || character == "i" || character == "o" || character == "u") {
    return true;
  }
  return false;
};

function Input({
  id,
  label,
  startAdornment,
  onChange,
  value,
  isValid,
  isInvalid,
  disabled,
  feedback,
  placeholder,
  type,
  options,
  inputRef,
  fullNameInputRef,
  accountNameInputRef,
  filter,
  isValidationError,
  isDisabled,
  setAddAssetModal,
  setAddSiteModal,
  setAddAssetTypeModal,
  addAssetModal,
  addSiteModal,
  setAddUserModal,
  addUserModal,
  worksiteLoading,
  worksiteLoadingSuccess,
  setModalWaitingToOpen,
  updateActiveNavItem,
  activeNavItem,
  isSuperAdmin,
  isAccountOwner,
  setAddAssetModalRedirect,
  showAddItem = true,
  bordered,
  showLabel,
  sortData,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  const themeContext = useContext(ThemeContext);
  const labelStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
    textAlign: "left",
    marginBottom: "0px",
    fontSize: "12px",
    color: themeContext?.colors?.grayL4 || "#9A9A9A",
    left: "3px",
  };

  const feedbackStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
    textAlign: "left",
    marginBottom: "0px",
    fontSize: "12px",
    color: themeContext?.colors?.redPrimary || "#DD0000",
    left: "3px",
    marginTop: "0px",
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      //borderBottom: '0px dotted green',
      color: state.isSelected
        ? themeContext?.colors?.grayL1 || "#323232"
        : themeContext?.colors?.grayL1 || "#323232",
      background: state.isSelected
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      textAlign: "left",
      backgroundColor: state.isSelected
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      fontFamily: "Open Sans, sans-serif",
      whiteSpace: "nowrap",
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover": {
        background: themeContext?.colors?.grayL6 || "#F2F2F2",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "28px",
      textAlign: "left",
      backgroundColor: state.disabled
        ? themeContext?.colors?.grayL7 || "#FFFFFF"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      border: state.isFocused
        ? `1px solid ${themeContext?.colors?.themePrimary || "#0d5fbe"}`
        : `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
      boxShadow: state.isFocused
        ? themeContext?.colors?.themePrimary || "#0d5fbe"
        : themeContext?.colors?.themePrimary || "#0d5fbe",
      "&:focus": {
        borderColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      },
      "&:hover": {
        borderColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "max-content",
      fontFamily: "Open Sans, sans-serif",
      //  position:'relative',
      top: "auto",
      transform: "translateY(0%)",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: "0px",
      paddingRight: "2px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      textAlign: "left",
      color: state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL1 || "#323232",
      fontFamily: "Open Sans, sans-serif",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      display: "grid",
    }),
  };
  useEffect(() => {
    console.debug("worksites", worksiteLoading, worksiteLoadingSuccess);
    if (addAssetModal) {
      //console.log("set asset");
      setModalWaitingToOpen("Asset");
    } else if (addUserModal) {
      setModalWaitingToOpen("User");
    }
  }, [addAssetModal, addSiteModal, worksiteLoading]);

  const [dropdownOption, setDropdownOption] = useState([]);

  useEffect(() => {
    sortData &&
      options?.length > 0 &&
      options.sort((a, b) => {
        let fa = a?.label?.toLowerCase(),
          fb = b?.label?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    setDropdownOption(options);
  }, [options, sortData]);

  const history = useHistory();
  const { t } = useTranslation();

  const MenuList = (props) => {
    switch (label) {
      case "Asset Type":
        return (
          <components.MenuList {...props}>
            {props.options.length === 0 ? (
              <p
                style={{
                  textAlign: "left",
                  padding: "5px 10px 5px 10px",
                  margin: "0",
                }}
              >
                {t(validationConstant.no_asset_type)}
              </p>
            ) : (
              ""
            )}
            <PermissionCheck section="DASHBOARD" permissionName={"ASSET_TYPE_MANAGEMENT"} actionName="add">
              <p
                style={{
                  textAlign: "left",
                  cursor: "pointer",
                  padding: "5px 10px 5px 10px",
                  margin: "0",
                }}
                onClick={() => {
                  setAddAssetTypeModal(true);
                  history.push("/app/management/assettype");
                }}
              >
                <AddAssetTypeIcon style={{ width: "20px", marginRight: "10px" }} />
                {t("AssetTypeAddAssetText")}
              </p>
            </PermissionCheck>
            {props.children}
          </components.MenuList>
        );
      case "Worksite":
        return (
          <components.MenuList {...props}>
            {props.options.length === 0 ? (
              <p
                style={{
                  textAlign: "left",
                  padding: "5px 10px 5px 10px",
                  margin: "0",
                }}
              >
                {t(validationConstant.no_worksite)}
              </p>
            ) : (
              ""
            )}
            <PermissionCheck section="DASHBOARD" permissionName={"WORKSITE_MANAGEMENT"} actionName="add">
              {(isAccountOwner || isSuperAdmin) && (
                <p
                  style={{
                    textAlign: "left",
                    cursor: "pointer",
                    padding: "5px 10px 5px 10px",
                    margin: "0",
                  }}
                  onClick={() => {
                    activeNavItem != "dashboard" && updateActiveNavItem("worksites");
                    setAddAssetModal(false);
                    setAddUserModal(false);
                    setAddSiteModal(true);
                  }}
                >
                  <AddWorksiteIcon style={{ width: "20px", marginRight: "10px" }} />
                  {t("Add Worksite")}
                </p>
              )}
            </PermissionCheck>
            {props.children}
          </components.MenuList>
        );
      default:
        return null;
    }
  };

  return type === "select" ? (
    <div className={`input-select-control ${bordered && "bordered-input"}`}>
      {id === "unitType" && (
        <label
          style={{
            margin: "0",
            fontFamily: `"Open Sans", sans-serif`,
            fontStyle: "normal",

            fontSize: "12px",
            marginLeft: "0px",

            textAlign: "left",
            marginBottom: "0px",
            color: "#919191",
            left: "3px",
            display: "block",
            marginTop: "8px",
          }}
        >
          Unit Type
        </label>
      )}
      {id === "timeZone" && (
        <label
          style={{
            margin: "0",
            fontFamily: `"Open Sans", sans-serif`,
            fontStyle: "normal",

            fontSize: "12px",
            marginLeft: "0px",

            textAlign: "left",
            marginBottom: "0px",
            color: "#919191",
            left: "3px",
            display: "block",
            marginTop: "8px",
          }}
        >
          Timezone
        </label>
      )}
      {showLabel && (
        <label style={labelStyle} className="text-open-sans">
          <Trans>{label}</Trans>
        </label>
      )}
      <Select
        required={true}
        //  placeholder={isFirstCharacterVowel(label?.charAt?.(0)) ? t(`Select an ${label}`) : t(`Select a ${label}`)}
        placeholder={
          isFirstCharacterVowel(label?.charAt?.(0))
            ? t(`Select an ${label}`)
            : label === "Worksite Manager"
            ? t(`Choose site manager`)
            : t(`Select a ${label}`)
        }
        styles={customStyles}
        className="text-open-sans input-select-control"
        classNamePrefix={label}
        maxMenuHeight={182}
        menuPlacement={"auto"}
        //   menuPosition={'absolute'}
        //  menuPortalTarget={document.body}
        menuPosition={"fixed"}
        //   openMenuOnFocus
        //   openMenuOnClick
        //   autoFocus
        isDisabled={disabled}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        value={
          dropdownOption &&
          dropdownOption
            .map((option) => {
              if (option._id === value) {
                return option;
              }
              return undefined;
            })
            ?.filter((i) => i)
        }
        name="filter"
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => (option ? option["_id"] : "")}
        options={dropdownOption}
        onChange={(value) => {
          onChange(value && value._id ? value._id : value);
        }}
        noOptionsMessage={
          label === "Asset Type"
            ? () => ""
            : () => (
                <>
                  <Trans>No Options</Trans>
                </>
              )
        }
        components={{
          ...((label === "Asset Type" || label === "Worksite") && showAddItem && { MenuList }),
          Option: customOption,
        }}
      />
      {isValidationError && isValidationError.includes(label) && (
        <ErrorLabel className="text-danger" id="text-danger">
          <span className="text-danger">
            <Trans>Please Select {label}</Trans>
          </span>
        </ErrorLabel>
      )}
    </div>
  ) : (
    <Form.Group
      //  className={`inputBoxDiv hide-detail ${bordered && "bordered-input"}`}
      className="text-open-sans mb-0"
      controlId={id}
    >
      <Form.Label style={labelStyle}>
        <Trans>{label}</Trans>
      </Form.Label>
      <InputGroup>
        {startAdornment && (
          <InputGroup.Prepend>
            <StyledInputGroup id="basic-addon3">{startAdornment}</StyledInputGroup>
          </InputGroup.Prepend>
        )}
        {type === "phone" ? (
          <PhoneInput
            placeholder={t(placeholder)}
            autofocus
            name={id}
            onChange={onChange}
            value={value}
            disabled={disabled}
            isInvalid={isInvalid}
            feedback={feedback}
            {...props}
          />
        ) : (
          <Form.Control
            fullWidth
            // className="inputBox"
            placeholder={t(placeholder)}
            autofocus
            // style={{width:"100%"}}
            name={id}
            minLength={1}
            maxLength={64}
            onChange={onChange}
            value={value}
            disabled={disabled}
            isInvalid={isInvalid}
            type={type === "password" ? (showPassword ? "text" : type) : type}
            ref={fullNameInputRef || accountNameInputRef || null}
            {...props}
          />
        )}
        {type === "password" && (
          <InputGroup.Append
            style={{ position: "absolute", right: "0", top: "17px" }}
            onClick={() => setShowPassword(!showPassword)}
            className="icon-back"
          >
            <InputGroup.Text id="basic-addon3" className="relative z-10">
              {showPassword ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
            </InputGroup.Text>
          </InputGroup.Append>
        )}
        <Form.Control.Feedback style={feedbackStyle} type="invalid">
          {feedback}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}

const mapStateToProps = (state) => ({
  addAssetModal: state.modals.addAssetModal,
  addSiteModal: state.modals.addSiteModal,
  addUserModal: state.modals.addUserModal,
  worksiteLoading: state.worksites.AddWorksiteLoading,
  worksiteLoadingSuccess: state.worksites.AddWorksiteSuccess,
  activeNavItem: state.sideMenu.activeNavItem,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
});

const mapDispatchToProps = (dispatch) => ({
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setAddAssetModalRedirect: (data) => dispatch(setAddAssetModalRedirect(data)),
});

Input.defaultProps = {
  sortData: true,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Input));
