import React, { useState, useEffect } from "react";
import { Row, Col, Table, Accordion, Card, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Input from "../../../../../components/Input";
import { setDropdownAccountId } from "../../../../../redux/dropdownFilters/action";
import FileUploadWithDragAndDrop from "../../../../../components/FileUploadWithDragAndDrop";
import SubmitIcon from "../../../../../assets/AddIcon/SubmitIcon.png";
import Information from "../../../../../assets/Information_Icon.svg";
import { uploadBulkAssets } from "../../../../../redux/bulkUpload/action";

// Button Component
import Button from "../../../../../components/Button/Button.component";

function AssetBulkUploadUploadFiles(props) {
  let {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    dropdownAccountList,
    dropdownWorksiteList,
    dropdownAssetTypeList,
    dropdownFilterProductList,
    setDropdownAccountId,
    uploadBulkAssets,
    assetBulkUploadFileLoading,
  } = props;

  const [accountId, setAccountId] = useState("");
  const [isValidationError, setValidationError] = useState([]);
  const [worksite, setWorksite] = useState(loginDetails?.userData?.authorizedSites?.[0] || "");
  const [assetType, setAssetType] = useState("");
  const [productType, setProductType] = useState("");
  const [csvFile, setcsvFile] = useState(null);
  const [csvFileUploadingStatus, setcsvFileUploadingStatus] = useState(false);
  const [isAccordianVisible, setisAccordianVisible] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isCSVFileError, setIsCSVFileError] = useState(false);

  const { t } = useTranslation();

  const handleAccountFilterChange = (value) => {
    setAccountId(value);
    setDropdownAccountId(value);
  };

  const handleBulkUploadAsset = () => {
    setIsSubmitClicked(true);
    console.log(isValidationError?.length);
    if (isValidationError?.length != 0 || !csvFile) {
      return;
    }
    console.log("Upload");

    let data = new FormData();
    data.append("templatefile", csvFile);
    data.append(
      "templateData",
      JSON.stringify({
        accountId: accountId || loginDetails?.accountId,
        siteId: isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : worksite,
        assetTypeId: assetType,
        productType: productType,
      })
    );

    uploadBulkAssets(data);
  };

  useEffect(() => {
    if (isSubmitClicked) {
      let errors = [];
      if (isSuperAdmin && !accountId) {
        errors.push("Account");
      }
      if (!isSiteAdmin && !worksite) {
        errors.push("Worksite");
      }
      if (!assetType) {
        errors.push("Asset Type");
      }
      if (!productType) {
        errors.push("SIERA.AI Product");
      }
      if (!csvFile) {
        setIsCSVFileError(true);
      }
      setValidationError(errors);
    }
  }, [accountId, worksite, assetType, productType, isSubmitClicked]);

  useEffect(() => {
    setWorksite("");
    setAssetType("");
    setProductType("");
  }, [accountId]);

  useEffect(() => {
    if (csvFile) {
      setIsCSVFileError(false);
    }
  }, [csvFile]);

  return (
    <>
      <Row>
        {isSuperAdmin && (
          <Col md={6} className="asterisk_form">
            <Input
              showLabel={true}
              required={true}
              label={t("Account")}
              type="select"
              isValidationError={isValidationError}
              feedback={t("Please select an account")}
              value={accountId}
              onChange={(value) => handleAccountFilterChange(value)}
              options={dropdownAccountList?.map((option) => ({
                _id: option._id,
                label: option.companyName,
              }))}
              // inputRef = {accountRef}
            />
            <p className="asterisk_user">*</p>
          </Col>
        )}

        {!isSiteAdmin && (
          <Col md={6} className="asterisk_form">
            <Input
              showLabel={true}
              label={t("Worksite")}
              required={true}
              isValidationError={isValidationError}
              displayEmpty
              value={worksite}
              onChange={(value) => setWorksite(value)}
              type="select"
              options={dropdownWorksiteList?.map((option) => ({
                _id: option._id,
                label: option.name,
              }))}
              feedback={t("Please select a Worksite")}
            />
            <p className="asterisk_user">*</p>
          </Col>
        )}

        <Col md={6} className="asterisk_form">
          <Input
            showLabel={true}
            required={true}
            label={t("Asset Type")}
            isValidationError={isValidationError}
            value={assetType}
            displayEmpty
            onChange={(value) => setAssetType(value)}
            type="select"
            options={dropdownAssetTypeList?.map((option) => ({
              _id: option._id,
              label: option.AssetTypeName,
            }))}
            feedback={t("Please select an asset type")}
            // inputRef={inputRef}
          />
          <p className="asterisk_user">*</p>
        </Col>

        <Col md={6} className="asterisk_form">
          <Input
            showLabel={true}
            label={t("SIERA.AI Product")}
            type="select"
            options={dropdownFilterProductList?.map((option) => ({
              _id: option._id,
              label: option.name,
            }))}
            as="select"
            className="selectBox"
            required={true}
            isValidationError={isValidationError}
            value={productType}
            onChange={(value) => {
              setProductType(value);
            }}
            feedback={t("Please select a Product")}
          />
          <p className="asterisk_user">*</p>
        </Col>
      </Row>
      <Row>
        <FileUploadWithDragAndDrop
          label={t("Asset Bulk Upload CSV Files")}
          setcsvFile={setcsvFile}
          csvFile={csvFile}
          isCSVFileError={isCSVFileError}
        />
      </Row>
      <Row className="bottom_bulk_row" style={{ width: "100%", margin: "0px" }}>
        <Col md={8} className="instruction-bottom" style={{ padding: "0px" }}>
          <Accordion className="text-open-sans accordion_bulk">
            <Card style={{ border: "none", padding: "0px" }}>
              <Card.Header
                className={isAccordianVisible ? "bulk_information_header" : "bulk_information_header_white"}
              >
                <Accordion.Toggle
                  onClick={() => setisAccordianVisible(!isAccordianVisible)}
                  as={Button}
                  variant="button"
                  tabIndex="0"
                  eventKey="0"
                  className="info_button"
                >
                  <img src={Information} />
                  <Trans>IMPORTANT INSTRUCTIONS</Trans>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="bulk_information">
                  <div className="line_vertical_a" style={{ height: "40px" }}></div>
                  <Trans>
                    Please select the correct worksite,asset type and the SIERA.AI Product before submitting
                    the file to avoid errors in the asset's information
                  </Trans>
                  .
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col className="download-top" style={{ padding: "0px" }}>
          {assetBulkUploadFileLoading ? (
            <Button label={<div className="spinner-border" role="status"></div>} showIcon={false} buttonType="save" />
          ) : (
            <Button
              label={t("Submit")}
              onClick={() => {
                handleBulkUploadAsset();
              }}
              showIcon
              iconClass="save"
              buttonType="save"
            />
          )}
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownFilterProductList: state.dropdownFilters.productList,
  assetBulkUploadFileLoading: state.bulkUpload.assetBulkUploadFileUploadLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  uploadBulkAssets: (data) => dispatch(uploadBulkAssets(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBulkUploadUploadFiles));
