let units = [
  {
    label: "Metric Units",
    _id: "Metric",
  },
  {
    label: "Imperial Units",
    _id: "Imperial",
  },
];

module.exports = units;
