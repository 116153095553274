import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TabWrapper, DropdownDiv } from "./SieraTab.Component.styles";
import DropdownComponent from "../DropdownComponent/Dropdown.component";
import { ThemeContext } from "styled-components";

const SieraTabs = ({ children, variant, defaultActiveKey, align, onSelect, activeKey, ...rest }) => {
  const themeContext = useContext(ThemeContext);

  const varientStyle = {
    vertical: {
      displayTab: "block",
      flex: "",
      widthNav: "30%",
      widthTab: "70%",
      floatNav: "left",
      floatTab: "right",
      linebar: "0px",
      borderRad: "0px",
      borderRadBotLeft: "4px",
      paddingTab: "0px 0px 0px 10px",
      backgroundColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      colorText: themeContext?.colors?.grayL7 || "#FFFFFF",
      bgColorHover: themeContext?.colors?.grayL6 || "#F2F2F2",
      bgColorHoverN: themeContext?.colors?.grayL5 || "#CCCCCC",
      borderBotoomCo: "none",
      fontWeight: "initial",
      padddingAch: "0.5rem 1rem",
      marginRig: "initial",
      borderBottomNav: `0px solid ${themeContext?.colors?.grayL6 || "#F2f2f2"}`,
    },
    horizontal: {
      displayTab: "flex",
      flex: "1",
      widthNav: "100%",
      widthTab: "100%",
      floatNav: "none",
      floatTab: "none",
      linebar: "2px",
      borderRad: "4px",
      borderRadBotLeft: "0px",
      paddingTab: "10px 0px 0px 0px",
      backgroundColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      colorText: themeContext?.colors?.grayL7 || "#FFFFFF",
      bgColorHover: themeContext?.colors?.grayL6 || "#F2F2F2",
      bgColorHoverN: themeContext?.colors?.grayL5 || "#CCCCCC",
      borderBotoomCo: "none",
      fontWeight: "initial",
      padddingAch: "0.5rem 1rem",
      marginRig: "initial",
      borderBottomNav: `0px solid ${themeContext?.colors?.grayL6 || "#F2f2f2"}`,
    },
    horizontalNew: {
      displayTab: "flex",
      flex: "",
      widthNav: "100%",
      widthTab: "100%",
      floatNav: "none",
      floatTab: "none",
      linebar: "0px",
      borderRad: "0px",
      borderRadBotLeft: "0px",
      paddingTab: "10px 0px 0px 0px",
      backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
      colorText: themeContext?.colors?.grayL1 || "#323232",
      bgColorHover: themeContext?.colors?.grayL7 || "#FFFFFF",
      bgColorHoverN: themeContext?.colors?.grayL7 || "#FFFFFF",
      borderBotoomCo: themeContext?.colors?.themePrimary || "#0d5fbe",
      fontWeight: "500",
      padddingAch: "10px 0px",
      marginRig: "20px",
      borderBottomNav: `1px solid ${themeContext?.colors?.grayL6 || "#F2f2f2"}`,
    },
  };

  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(defaultActiveKey);

  useEffect(() => {
    if (activeKey !== undefined) {
      setSelectedTab(activeKey);
    }
  }, [activeKey]);

  const getContent = (eventKey) => {
    const selectedChild = React.Children.toArray(children).find((child) => child.props.eventKey === eventKey);
    return selectedChild ? selectedChild.props.children : null;
  };

  const handleTabSelect = (eventKey) => {
    setSelectedTab(eventKey);
    onSelect && onSelect(eventKey);
  };

  return (
    <TabWrapper variant={variant} varientStyle={varientStyle} align={align} {...rest}>
      {window.innerWidth > 768 ? (
        <Tabs activeKey={selectedTab} onSelect={handleTabSelect} {...rest}>
          {children}
        </Tabs>
      ) : (
        <DropdownDiv>
          <DropdownComponent
            options={React.Children.map(children, (child) => ({
              label: child?.props?.title,
              value: child?.props?.eventKey,
            }))}
            value={{
              value: selectedTab,
              label: React.Children.toArray(children).find((child) => child.props.eventKey === selectedTab)
                ?.props.title,
            }}
            onChange={(selectedOption) => handleTabSelect(selectedOption.value)}
          />
          {getContent(selectedTab)}
        </DropdownDiv>
      )}
    </TabWrapper>
  );
};

SieraTabs.propTypes = {
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["vertical", "horizontal", "horizontalNew"]),
  align: PropTypes.oneOf(["left", "middle", "right"]),
  onSelect: PropTypes.func,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SieraTabs.defaultProps = { variant: "horizontal", align: "left" };

export default SieraTabs;
