import React, { useState, useEffect } from "react";
import DropdownComponent from "./Dropdown.component";
import { isIterable } from "../../utils";
import { useTranslation } from "react-i18next";

const DropdownWrapper = ({
  filter,
  sortData,
  useAsDropdown,
  selectFirstAsDefault,
  handleFilter,
  value,
  isLoading,
  disabled,
  isError,
  errorMessage,
  size,
}) => {
  const [dropdownOption, setDropdownOption] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let sortedData = filter.data || [];
    if (sortData && sortedData.length > 0) {
      sortedData = [...sortedData].sort((a, b) =>
        a[filter.key || "name"].toLowerCase() < b[filter.key || "name"].toLowerCase() ? -1 : 1
      );
    }
    setDropdownOption(sortedData);
  }, [sortData, filter.data, filter.key]);

  const filteredOptions = dropdownOption.map((option) => ({
    ...option,
    label: option[filter.key],
    value: option._id,
  }));

  const defaultOption = { label: t("All"), value: "all" };

  // Determine if the default option should be shown
  const showDefaultOption = !filter.placeholder && (!value || value === "all");

  // Determine the default value
  let defaultValue = null;
  if (!filter.placeholder && selectFirstAsDefault && filteredOptions.length > 0 && !value) {
    defaultValue = filteredOptions[0];
  } else if (!filter.placeholder && value === "all") {
    defaultValue = defaultOption;
  } else {
    defaultValue = filteredOptions.find((option) => option.value === value);
  }

  // Render custom options with tooltips
  const customOptions = filteredOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  return (
    <DropdownComponent
      label={filter.label || filter.type}
      value={defaultValue ? [defaultValue] : []}
      disabled={disabled}
      selectedOption
      isLoading={isLoading}
      isClearable={false}
      isSearchable={true}
      placeholder={filter.placeholder || ""}
      size="medium"
      name="filter"
      options={
        useAsDropdown ? customOptions : (showDefaultOption ? [] : [defaultOption]).concat(customOptions)
      }
      onChange={(selectedOption) => handleFilter(selectedOption ? selectedOption.value : null)}
      isError={isError}
      errorMessage={errorMessage}
    />
  );
};

export default DropdownWrapper;
