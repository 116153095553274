import { billingTypes } from "./types";
import { STRIPE_BASE_URL, baseUrl } from "../../../src/config";
import axios from "axios";
import { apiCall } from "../../utils/apiCall";

//To get all billing plans
const getPlansLoading = () => ({
  type: billingTypes.GET_ALL_PLANS_LOADING,
});

const getPlansSuccess = (data) => ({
  type: billingTypes.GET_ALL_PLANS_SUCCESS,
  data,
});

const getPlansError = (err) => ({
  type: billingTypes.GET_ALL_PLANS_ERROR,
  err,
});

export const getPlans = () => {
  return async (dispatch) => {
    dispatch(getPlansLoading());
    axios({
      url: `${STRIPE_BASE_URL}/v1/plans`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer sk_test_225BWNJuNqACvPjkMnYeSr9j00QRerCs6G`,
      },
    }).then((res) => dispatch(getPlansSuccess(res.data)));
  };
};

//To create customer in stripe
const postCustomerLoading = () => ({
  type: billingTypes.POST_CREATE_CUSTOMER_LOADING,
});

const postCustomerSuccess = (data) => ({
  type: billingTypes.POST_CREATE_CUSTOMER_SUCCESS,
  data,
});

const postCustomerError = (err) => ({
  type: billingTypes.POST_CREATE_CUSTOMER_ERROR,
  err,
});

export const postCustomer = (data) => {
  return async (dispatch, getState) => {
    dispatch(postCustomerLoading());
    const state = getState();
    const isStripe = state?.user?.loginDetails?.stripeId;
    const subId = state?.user?.loginDetails?.stripeSubscriptionID;
    const action = isStripe ? "stripe/updateCustomerPlan" : "stripe/createCustomer";
    const isStripeData = {
      subscriptionId: subId,
      priceId: data.plan,
      accountid: data.account,
    };
    const value = isStripe ? isStripeData : data;

    apiCall(`/${action}`, { medthod: "POST", data: value })
      .then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          dispatch(postCustomerSuccess(resp.data));
        } else {
          dispatch(postCustomerError({ message: resp.data.message }));
        }
      })
      .catch((err) => {
        dispatch(postCustomerError(err));
        console.log(err);
      });
  };
};

//To update to basic plan
const basicPlanLoading = () => ({
  type: billingTypes.UPDATE_BASIC_PLAN_LOADING,
});

const basicPlanSuccess = (data) => ({
  type: billingTypes.UPDATE_BASIC_PLAN_SUCCESS,
  data,
});

const basicPlanError = (err) => ({
  type: billingTypes.UPDATE_BASIC_PLAN_ERROR,
  err,
});

export const basicPlan = (data) => {
  return async (dispatch, getState) => {
    dispatch(basicPlanLoading());

    apiCall(`/stripe/createCustomerBasic`, { method: "POST", data: data })
      .then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          dispatch(basicPlanSuccess(resp.data));
        } else {
          dispatch(basicPlanError({ message: resp.data.message }));
        }
      })
      .catch((err) => {
        dispatch(basicPlanError(err));
        console.log(err);
      });
  };
};

//To get invoices
const getInvoicesLoading = () => ({
  type: billingTypes.GET_INVOICES_LOADING,
});

const getInvoicesSuccess = (data) => ({
  type: billingTypes.GET_INVOICES_SUCCESS,
  data,
});

const getInvoicesError = (err) => ({
  type: billingTypes.GET_INVOICES_ERROR,
  err,
});

export const getInvoices = (id) => {
  return async (dispatch, getState) => {
    dispatch(getInvoicesLoading());
    const cusId = getState()?.user?.loginDetails?.stripeId;

    apiCall(`/stripe/getInvoiceList/${cusId}`)
      .then((resp) => {
        console.log(resp);
        dispatch(getInvoicesSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getInvoicesError(err));
        console.log(err);
      });
  };
};

//store selected plan
export const selectedPlan = (data) => ({
  type: billingTypes.STORE_SELECTED_PLAN,
  data,
});

//To reset the state
export const resetBillingState = () => ({
  type: billingTypes.RESET_STATE,
});

// Get Billing info
const getBillingInfoSuccess = (data) => ({
  type: billingTypes.GET_BILLING_INFO,
  data,
});

export const getBillingInfo = () => {
  //console.log("BILLING RESPONSE");

  return async (dispatch, getState) => {
    const token = getState()?.user?.loginDetails?.token;
    //dispatch(getPlansLoading());
    axios({
      url: `${baseUrl}/account/getBillingInfo/0/0`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    })
      .then((res) => {
        console.log(res);
        dispatch(getBillingInfoSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
