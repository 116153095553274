import React from "react";
import { Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import AddIcon from "../../../assets/AddIcon/Add Icon.svg";
import AccountManagementStyles from "./AccountStyles.module.css";
import Button from "../../../components/Button/Button.component";
import ManagementPanelStyles from "../../../view/Dashboard/Management/panels/ManagementPanel.module.css";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

function AccountsActionBar({ showActionBar }) {
  const { t } = useTranslation();
  return (
    <>
      {/* For desktop */}
      <PermissionCheck section="DASHBOARD" permissionName={"ACCOUNT_MANAGEMENT"} actionName="add">
        <Row className={`m-0 p-0 ${AccountManagementStyles.desktopAccountsActionBar}`}>
          <div className="mr-2 pl-0">
            <Button
              disabled
              iconClass="add"
              label={t("add account")}
              onClick={() => {}}
              showIcon
              buttonType="secondary"
            />
          </div>
        </Row>

        {/* For Mobile */}
        {!showActionBar ? (
          ""
        ) : (
          <Row className={`m-0 p-0 ${AccountManagementStyles.mobileAccountsActionBar}`}>
            <div className="col" style={{ width: "100%" }}>
              <div className={`mr-2 pl-0 ${ManagementPanelStyles.mobileViewButton}`}>
                <Button
                  style={{ display: "none" }}
                  disabled={true}
                  className={`buttonAdd buttonDisabled ripple ${AccountManagementStyles.addButton}`}
                  variant="outline-primary"
                >
                  <img src={AddIcon} className={`${AccountManagementStyles.addIcon}`}></img>
                  <Trans>ADD ACCOUNT</Trans>
                </Button>
              </div>
            </div>
          </Row>
        )}
      </PermissionCheck>
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsActionBar));
