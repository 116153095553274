/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA LANDING PAGE USER ACTION TYPES
 */

export const userTypes = {
  //  1. SIGNUP ACTION TYPE: FOR SIGNUP
  SIGN_UP: "SIGN_UP",
  // 2. LOGIN ACTION TYPE: FOR LOGIN
  LOGIN: "LOGIN",
  RESET_LOGIN_LOGIN: "RESET_LOGIN_LOGIN",
  // 3. UPDATE ACCOUNT ACTION TYPE: FOR UPDATING ACCOUNT
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  // 4. RESET ALL STATE
  LOGOUT: "USER_LOGOUT",
  //5. update plan
  UPDATE_PLAN: "UPDATE_PLAN",
  //6. update stripe
  UPDATE_STRIPE: "UPDATE_STRIPE",

  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_LOADING: "REFRESH_TOKEN_LOADING",
  UPDATE_LOGIN_USER: "UPDATE_LOGIN_USER",
  SET_IS_ONBOARDED_FLAG: "SET_IS_ONBOARDED_FLAG",

  UPDATE_ACCOUNT_OWNER_SUCCESS: "UPDATE_ACCOUNT_OWNER_SUCCESS",
  UPDATE_ACCOUNT_OWNER_ERROR: "UPDATE_ACCOUNT_OWNER_ERROR",
  UPDATE_ACCOUNT_OWNER_LOADING: "UPDATE_ACCOUNT_OWNER_LOADING",
  UPDATE_ACCOUNT_OWNER_RESET: "UPDATE_ACCOUNT_OWNER_RESET",
};
