import { Dialog, Transition } from "@headlessui/react";
import { BeakerIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import MeterReadingInput from "./Input/MeterReading.component";
import NumericInput from "./Input/NumericInput.component";
import TextInput from "./Input/TextInput.component";
import ButtonInput from "./Input/ButtonInput.component";
import MessagePopup from "./MessagePopup.component";
import InspectionResult from "./Result.component";
import ReviewInspection from "./ReviewInspection.component";
import InspectionLimitError from "./InspectionLimitError.component";
import { apiCall } from "../../../../utils/apiCall";
import planConstant from "../../../../constant/planConstant.json";
import permission from "../../../../constant/permission";
import { ReactComponent as ArrowIcon } from "../../../../assets/ArrowRight.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/cancel.svg";
import { CacheRefs } from "../../../../utils/cache/CacheRefs";

const { inspectionPlan } = planConstant;

export default function InspectionModal({
  uploadImage,
  deleteImage,
  postInspectionResponse,
  postInspectionRepsonseLoading,
  deleteImageLoading,
  uploadImageLoading,
  inspectData,
  getChecklistLoading,
  logoutUser,
  setCurrentLocalChecklist,
  currentLocalChecklist,
  setShowInspection,
}) {
  const {
    accountId,
    inspectorId,
    assetId,
    userFullName,
    currentChecklist,
    checklistId,
    inspectionPlanDetail,
  } = inspectData;

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openMessagePopup, setOpenMessagePopup] = useState(false);
  const [lastAnsweredQuestion, setLastAnsweredQuestion] = useState(-1);
  const [isInspectionValid, setIsInspectionValid] = useState(false);
  const [inspectionCountLoading, setInspectionCountLoading] = useState(false);
  const [sectionId, setSectionId] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [checklistResponseForm, setChecklistResponseForm] = useState([...currentChecklist]);
  const [finalReview, setFinalReview] = useState(false);
  const [resultPage, setResultPage] = useState(false);
  const [inspectionPass, setInspectionPass] = useState(false);

  useEffect(() => {
    setChecklistResponseForm([...currentChecklist]);
  }, [currentChecklist]);

  useEffect(() => {
    setCurrentQuestion(checklistResponseForm[0]);
    setSectionId(checklistResponseForm[0]?.sectionId);
  }, [checklistResponseForm]);

  const handleSubmitAnswer = () => {
    console.log("--------------", checklistResponseForm);
    if (currentIndex + 1 == checklistResponseForm.length) {
      setFinalReview(true);
      return;
    }
    if (sectionId != checklistResponseForm[currentIndex + 1]?.sectionId) {
      setSectionId(checklistResponseForm[currentIndex + 1]?.sectionId);
    }
    if (currentIndex >= lastAnsweredQuestion) {
      setLastAnsweredQuestion(currentIndex);
    }
    setCurrentIndex(currentIndex + 1);
    setCurrentQuestion(checklistResponseForm[currentIndex + 1]);
  };

  const setNext = () => {
    if (currentIndex >= lastAnsweredQuestion) {
      return;
    }
    setCurrentIndex(currentIndex + 1);
    setCurrentQuestion(checklistResponseForm[currentIndex + 1]);
    console.log(currentIndex + 1, "--------------", checklistResponseForm);
  };

  const setPrevious = () => {
    if (currentIndex == 0) {
      return;
    }
    setCurrentIndex(currentIndex - 1);
    setCurrentQuestion(checklistResponseForm[currentIndex - 1]);
    console.log(currentIndex - 1);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const getProgressBarPercent = () => {
    let percent = (currentIndex / checklistResponseForm.length) * 100;
    return `${percent}%`;
  };

  const getQuestionTypeComponent = (data, { handleSubmitAnswer }) => {
    if (data?.isMeterReading) {
      return (
        <MeterReadingInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion >= currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
          assetId={assetId}
        />
      );
    } else if (data?.answers == "DropDown") {
      return (
        <ButtonInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion >= currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
        />
      );
    } else if (data?.answers == "Text") {
      return (
        <TextInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion >= currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
        />
      );
    } else if (data?.answers == "Numeric") {
      return (
        <NumericInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion >= currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
        />
      );
    }
  };

  const resultAfterSubmit = (resultStatus) => {
    setInspectionPass(resultStatus);
    setFinalReview(false);
    setResultPage(true);
  };

  const continueClick = () => {
    setIsOpen(false);
    setFinalReview(false);
    setResultPage(false);
    setCurrentIndex(0);
    setLastAnsweredQuestion(0);
    setChecklistResponseForm([...currentChecklist]);
  };

  const onSubmit = () => {
    console.log(checklistResponseForm, "----------<<<<<<<>>>>>>>>>");
    let newResponse = {};
    checklistResponseForm.map((data) => {
      let currentQuestion = newResponse[data.sectionId];
      if (currentQuestion) {
        newResponse[data.sectionId].push(data);
      } else {
        newResponse[data.sectionId] = [data];
      }
    });
    let sectionResponse = [];
    Object.keys(newResponse).map((key) => {
      let newSection = newResponse[key];
      let newQuestions = [];
      newSection?.map((ques) => {
        newQuestions.push({
          failedResponse: ques.failedResponse,
          isfailedResponse: ques.isFailedResponse,
          question: ques.question,
          questionId: ques.id,
          response: ques.response,
          responseId: ques.responseTypeId,
          responseMedia: ques.responseMedia,
          sectionId: ques.sectionId,
        });
      });
      sectionResponse.push({
        sectionId: newSection[0]?.sectionId,
        sectionName: newSection[0]?.sectionName,
        questions: [...newQuestions],
      });
    });
    console.log(currentChecklist, "-------------<<<<<<<<|-|-|-|-|-|-|-|-|-|-|-|-|-|-|");

    const finalInspectionResponse = {
      accountId: accountId,
      inspectorId: inspectorId,
      assetId: assetId,
      checklistId: checklistId,
      response: sectionResponse,
    };

    console.log(finalInspectionResponse, "-------------<<<<<<<<|-|-|-|-|-|-|-|-|-|-|-|-|-|-|");
    postInspectionResponse(finalInspectionResponse, (resultStatus) => {
      resultAfterSubmit(resultStatus);
      CacheRefs.removeCacheForChecklists();
    });
  };

  const reviewResponse = () => {
    setFinalReview(false);
    setResultPage(false);
  };

  useEffect(() => {
    setOpenMessagePopup(true);
  }, [sectionId]);

  const isInspectValidAsPlan = (noInspecDone) => {
    let noOfLimit =
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan] &&
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan].maxAllowed;
    if (!noInspecDone) {
      noInspecDone = 0;
    }
    if (!noOfLimit) {
      return false;
    } else if (noOfLimit > noInspecDone) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!accountId) {
      return;
    }
    setInspectionCountLoading(true);
    apiCall(`/checklist/inspectionCount/${accountId}`).then((data) => {
      setInspectionCountLoading(false);
      setIsInspectionValid(isInspectValidAsPlan(data?.data?.count));
    });
  }, [accountId, currentChecklist]);

  return (
    <>
      {isInspectionValid && !inspectionCountLoading && (
        <div className="mt-5 flex items-center justify-center">
          <button
            type="button"
            onClick={openModal}
            disabled={getChecklistLoading}
            className="border-none shadow-[0_6px_20px_rgba(0,0,0,0.6)] inspection-circle bg-yellow-500  uppercase rounded-full h-40 w-40   text-zinc-700 flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            {getChecklistLoading ? (
              <svg
                role="status"
                className="inline w-18 h-18 text-gray-200 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#d49710"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#ffffff"
                />
              </svg>
            ) : (
              <>
                <span className="font-kanit text-xl">
                  Start <br></br>Inspection
                </span>
              </>
            )}
          </button>
        </div>
      )}
      {!isInspectionValid && !inspectionCountLoading && (
        <InspectionLimitError setShowInspection={setShowInspection} />
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[1200] overflow-y-scroll"
          onClose={closeModal}
          style={{ background: "rgba(0,0,0,0.8)" }}
        >
          {openMessagePopup && (
            <MessagePopup data={currentQuestion} onClick={() => setOpenMessagePopup(false)} />
          )}
          <div className=" text-center">
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full p-6  overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl opacity-100  max-w-[97%] md:max-w-[70%] lg:max-w-[70%]">
                <div className="flex flex-wrap justify-end mb-2" onClick={() => setIsOpen(false)}>
                  {/*  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    */}
                  <CloseIcon className="h-5 icon-color-black" />
                </div>

                {!finalReview && !resultPage && (
                  <>
                    <div className="bg-zinc-200 h-1 mb-2">
                      <div
                        className="bg-primary-background h-1"
                        style={{ width: getProgressBarPercent() }}
                      ></div>
                    </div>
                    <div className="flex items-center" style={{ position: "relative" }}>
                      {uploadImageLoading && (
                        <div
                          style={{
                            opacity: "0.1",
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            backgroundColor: "#444",
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            zIndex: 9999999,
                          }}
                        />
                      )}
                      <div style={{ cursor: "pointer" }} className="mr-4" onClick={() => setPrevious()}>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                                </svg>*/}
                        <ArrowIcon
                          className={`h-8 rotate-icon-180 ${
                            currentIndex !== 0 ? "icon-color-black" : "icon-color-default"
                          }`}
                        />
                      </div>
                      <div className=" modal-height-width heading-name">
                        <Dialog.Title
                          as="h3"
                          className="mt-4 p-1 m-[auto] text-sm text-sm  w-fit leading-6 flex justify-center bg-color-grey uppercase  rounded-sm text-black-text"
                        >
                          <span className="font-sans text-sm"> {currentQuestion?.message}</span>
                        </Dialog.Title>
                        <div style={{ marginTop: "2vh" }}>
                          {getQuestionTypeComponent(currentQuestion, {
                            handleSubmitAnswer: handleSubmitAnswer,
                          })}
                        </div>
                      </div>
                      <div style={{ cursor: "pointer" }} className="ml-4" onClick={() => setNext()}>
                        {/*  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                                </svg>*/}
                        <ArrowIcon
                          className={`h-8  ${
                            currentIndex !== checklistResponseForm.length - 1
                              ? "icon-color-black"
                              : "icon-color-default"
                          }`}
                        />
                      </div>
                    </div>
                  </>
                )}
                {finalReview && (
                  <ReviewInspection
                    postInspectionRepsonseLoading={postInspectionRepsonseLoading}
                    onSubmit={onSubmit}
                    reviewResponse={reviewResponse}
                  />
                )}
                {resultPage && (
                  <InspectionResult
                    continueClick={continueClick}
                    logoutUser={logoutUser}
                    inspectionPass={inspectionPass}
                    userFullName={userFullName}
                    setChecklistResponseForm={setChecklistResponseForm}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
