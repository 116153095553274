import React, { useState } from "react";
import "./styles.css";
import { Form, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../../../config";
import validationConstant from "../../../../constant/messages/validation.json";
import { ReactComponent as SendButtonSVG } from "../../../../assets/Send_Button.svg";
import { ReactComponent as AddIcon } from "../../../../assets/add.svg";

export default function ErrorLogsReportShare({
  userData,
  localAccountId,
  date,
  setIsError,
  setShowDownloadAllReportModal,
}) {
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [IsValidated, setIsValidated] = useState(false);
  const { t } = useTranslation();

  const onEmailAdd = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setIsValidated(true);
      return;
    }
    setIsValidated(false);
    let currentEmailList = new Set([...emailList]);
    currentEmailList.add(email);
    setEmail("");
    setEmailList([...currentEmailList]);
  };

  const removeEmail = (e) => {
    let id = e.currentTarget.id;
    let currentEmailList = new Set([...emailList]);
    currentEmailList.delete(id);
    setEmailList([...currentEmailList]);
  };

  const clearAll = (e) => {
    e.preventDefault();
    setEmailList([]);
  };

  const sendReport = (e) => {
    setApiLoading(true);
    let headers = {
      "Content-Type": "application/json",
      "auth-token": userData.token,
    };

    const reportData = {
      accountId: localAccountId,
      siteId: "all",
      assetId: "all",
      startDate: date.startDate,
      endDate: date.endDate,
      emailTo: Array.from(emailList),
    };

    axios
      .post(`${baseUrl}/common/shareErrorLogs/`, reportData, {
        headers: headers,
      })
      .then((resp) => {
        document.body.click();
        //setIsSuccess(t("Report has been emailed"));
        setApiLoading(false);
        setShowDownloadAllReportModal(true);
      })
      .catch((err) => {
        setApiLoading(false);
        setIsError(t("Something went wrong"));
      });
  };

  return (
    <div className="report-modal-container">
      <Form noValidate validated={IsValidated} onSubmit={onEmailAdd}>
        <Form.Group controlId="formBasicemail">
          <div style={{ border: "1px solid #E2E2E2" }} className="d-flex p-1">
            <Form.Control
              size="sm"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form-control email-input border-0"
              type="email"
              name="email"
              placeholder="Enter an email"
              pattern='(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            />
            <Button
              className="d-flex align-items-center pl-2 pr-2"
              style={{ backgroundColor: "#0D5FBE" }}
              size="sm"
              type="submit"
            >
              <AddIcon fill="white" height={14} />
            </Button>
          </div>
          <Form.Control.Feedback type="invalid" style={{ textAlign: "left" }}>
            {t(validationConstant.invalid_email)}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>

      <div className="shareEmail">
        {emailList.length > 0 && (
          <a href="#" onClick={clearAll} className="mt-2 d-block">
            <Trans>Clear All</Trans>
          </a>
        )}
        <div className="EmailListShare">
          <div className="d-block">
            {emailList?.map((email) => (
              <span className="tab" key={email}>
                <span className="mr-3">{email}</span>
                <span className="close" id={email} onClick={removeEmail}>
                  <i className="fas fa-times"></i>
                </span>
              </span>
            ))}
          </div>
        </div>
        {emailList.length > 0 && (
          <Button
            className="d-block send-button buttonSubmit ripple mb-2 mt-1 float-right"
            disabled={emailList.length < 1}
            onClick={sendReport}
          >
            {" "}
            <Trans>SEND</Trans>
            {apiLoading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <SendButtonSVG
                style={{
                  marginLeft: "3px",
                  fill: "#FFFFFF",
                }}
                width={"16px"}
              />
            )}
          </Button>
        )}
      </div>
    </div>
  );
}
