/**
 * TODO: Revist this file when time permits
 * Remove unused variables
 * Fix lint
 */

import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { updateMenu, updateMenuReport } from "../../../../../redux/sideMenu/menu.action";
import {
  setAddSiteModal,
  setAddAssetModal,
  setAddAssetTypeModal,
  setAddUserModal,
  setModalWaitingToOpen,
  setAddChecklistModal,
} from "../../../../../redux/modals/action";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { assetTypeList, defaultAssetTypeOverview } from "../../../../../redux/assetType/assetType.action";
import DashboardStyle from "../DashboardStyle.module.css";
import {
  QuickAccesManageDiv,
  QuickAccessMain,
  QucickAccessIconDiv,
  QuickAccessDivIcon,
  QuickAccessDivName,
  QuickAccessIconName,
} from "./QuickManagement.component.styles";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";

function QuickAccess(props) {
  let {
    history,
    loginDetails,
    isSuperAdmin,
    updateMenu,
    setAddSiteModal,
    setAddAssetModal,
    setAddUserModal,
    setAddAssetTypeModal,
    setAddChecklistModal,
  } = props;

  const [managementNavigationData, setManagementNavigationData] = useState([]);

  const { t } = useTranslation();

  const accountIconClick = (i) => {
    updateMenu({ section: "management", subSection: i });
    history.push("/app/management");
    //setAddSiteModal(true)
  };

  useEffect(() => {
    console.log(managementNavigationData);
  }, [managementNavigationData]);

  useEffect(() => {
    setManagementNavigationData([
      {
        name: t("Add Account"),
        icon: "icon-add-worksite",
        onClick: (i) => accountIconClick(i),
      },
      {
        name: t("Add Worksite"),
        icon: "icon-add-asset",
        onClick: () => setAddSiteModal(true),
        permissionName: "WORKSITE_MANAGEMENT",
      },
      {
        name: t("Add Asset"),
        icon: "icon-add-worksite",
        onClick: (i) => setAddAssetModal(true),
        permissionName: "ASSET_MANAGEMENT",
      },
      {
        name: t("Add User"),
        icon: "icon-add-user",
        onClick: (i) => setAddUserModal(true),
        permissionName: "USER_MANAGEMENT",
      },
      {
        name: t("Add Asset Type"),
        icon: "icon-add-asset-type-op3",
        permissionName: "ASSET_TYPE_MANAGEMENT",
        // url: "/app/management/assettype/add",
        onClick: (i) => setAddAssetTypeModal(true),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails, isSuperAdmin]);

  return (
    <QuickAccesManageDiv>
      <QuickAccessMain>
        {managementNavigationData?.map((data, i) => (
          <PermissionCheck section="DASHBOARD" permissionName={data.permissionName} actionName="add">
            <QucickAccessIconDiv key={i}>
              {data.url ? (
                <NavLink to={data.url}>
                  <QuickAccessDivIcon className={`icon ${data.icon}`}></QuickAccessDivIcon>
                  <QuickAccessDivName title={data.name}>{data.name}</QuickAccessDivName>
                </NavLink>
              ) : (
                <QuickAccessIconName id={i} onClick={() => data.onClick(i)}>
                  <QuickAccessDivIcon className={`icon ${data.icon}`}></QuickAccessDivIcon>
                  <QuickAccessDivName title={data.name}>{data.name}</QuickAccessDivName>
                </QuickAccessIconName>
              )}
            </QucickAccessIconDiv>
          </PermissionCheck>
        ))}
        <PermissionCheck section="DASHBOARD" permissionName="CHECKLIST_SETTINGS" actionName="add">
          <QucickAccessIconDiv>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className={`${DashboardStyle.divButtonStyle}`}>
                <QuickAccessDivIcon className={`icon icon-add-checklist`}></QuickAccessDivIcon>
                <QuickAccessDivName>
                  <span title="Add Checklist">
                    <Trans>Add Checklist</Trans>
                  </span>
                </QuickAccessDivName>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={() => setAddChecklistModal(true)}>
                  New Checklist
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => history.push("/app/checklistmanagement/templates")}>
                  From Template
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </QucickAccessIconDiv>
        </PermissionCheck>
      </QuickAccessMain>
    </QuickAccesManageDiv>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isSiteAdmin: state.user.isSiteAdmin,
  menuList: state.sideMenu.reportMenuState,
  addAssetModal: state.modals.addAssetModal,
  addSiteModal: state.modals.addSiteModal,
  addUserModal: state.modals.addUserModal,
  addAssetTypeModal: state.modals.addAssetTypeModal,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateMenuReport: (menuDetails) => dispatch(updateMenuReport(menuDetails)),
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
  setAssetTypeList: (assetTypeLists) => dispatch(assetTypeList(assetTypeLists)),
  defaultAssetTypeOverview: () => dispatch(defaultAssetTypeOverview()),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickAccess));
