import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import "react-pro-sidebar/dist/css/styles.css";
import DropdownComponent from "../../../../components/DropdownComponent/Dropdown.component";
import { DesktopMenuAccordian, ResponsiveMenuDropdown } from "./SystemSetting.component.styles";

const menuItems = [
  {
    id: "tabletSettings",
    label: "Tablet Settings",
    route: "s2-settings",
    subMenuRoutes: ["report-settings", "auto-logout-settings", "impact-threshold-settings"],
    subMenu: [
      {
        id: "impactThresholdSettings",
        label: "Impact Threshold Settings",
        route: "impact-threshold-settings",
      },
      {
        id: "autologoutSettings",
        label: "Auto-Logout Settings",
        route: "auto-logout-settings",
      },
      {
        id: "reportSettings",
        label: "Report Settings",
        route: "report-settings",
      },
    ],
  },
  {
    id: "s3Settings",
    label: "S3 Settings",
    route: "s3-settings",
  },
  {
    id: "accessControlSettings",
    label: "Access Control Settings",
    route: "asset-lockout-settings",
  },
  {
    id: "notificationSettings",
    label: "Notification Settings",
    route: "notification-settings",
    subMenuRoutes: [
      "operator-certification-expiration-alert-settings",
      "worksite-summary-alert-settings",
      "account-summary-alert-settings",
      "unauthorized-access-alert-settings",
      "near-miss-alert-settings",
      "impact-alert-settings",
      "inspection-alert-settings",
    ],

    subMenu: [
      {
        id: "inspectionAlertSettings",
        label: "Inspection Alerts",
        route: "inspection-alert-settings",
      },
      {
        id: "impactAlertSettings",
        label: "Impact Alerts",
        route: "impact-alert-settings",
      },
      {
        id: "nearMissAlertSettings",
        label: "Near-Miss Alerts",
        route: "near-miss-alert-settings",
      },
      {
        id: "unauthorizedAlertSettings",
        label: "Unauthorized Access Alerts",
        route: "unauthorized-access-alert-settings",
      },
      {
        id: "accountSummaryAlertSettings",
        label: "Account Summary Alerts",
        route: "account-summary-alert-settings",
      },
      {
        id: "WorksiteSummaryAlertSettings",
        label: "Worksite Summary Alerts",
        route: "worksite-summary-alert-settings",
      },
      {
        id: "operatorCertificationExpirationAlertSettings",
        label: "Operator Certificate Expiration Alerts",
        route: "operator-certification-expiration-alert-settings",
      },
    ],
  },
];

const MenuBar = ({ history, permissions, isAccountOwner, isSuperAdmin }) => {
  const [selectedRoute, setSelectedRoute] = useState("impact-threshold-settings");
  const [expandedPanel, setExpandedPanel] = useState("");

  const userManagementObj = permissions[0].permissions.find(
    (permission) => permission.name === "USER_MANAGEMENT"
  );

  useEffect(() => {
    const route = history?.location?.pathname?.replace("/app/system-setting/", "");
    setSelectedRoute(route);

    menuItems.forEach((menuItem) => {
      if (menuItem.subMenuRoutes?.includes(route)) {
        setExpandedPanel(menuItem.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.pathname]);

  const settingsRoutes = {
    tabletSettings: ["report-settings", "auto-logout-settings", "impact-threshold-settings"],
    notificationSettings: [
      "operator-certification-expiration-alert-settings",
      "worksite-summary-alert-settings",
      "account-summary-alert-settings",
      "unauthorized-access-alert-settings",
      "near-miss-alert-settings",
      "impact-alert-settings",
      "inspection-alert-settings",
    ],
  };

  const clickHandler = (route) => {
    history.push(route);
    setSelectedRoute(route);
  };
  const onOpenChangeHandler = (id) => {
    setExpandedPanel(id);
  };
  const options = menuItems.map((menuItem, index) => {
    return menuItem.subMenu
      ? menuItem.subMenu.map((subMenu, subMenuIndex) => {
          return { value: subMenu.route, label: subMenu.label };
        })
      : { value: menuItem.route, label: menuItem.label };
  });

  const getMenuItem = (subMenu) => {
    return (
      <>
        {subMenu.id === "accountSummaryAlertSettings" && !(isAccountOwner || isSuperAdmin) ? null : (
          <MenuItem
            onClick={() => clickHandler(subMenu.route)}
            active={subMenu.route === selectedRoute}
            title={subMenu.label}
          >
            {subMenu.label}
          </MenuItem>
        )}
      </>
    );
  };

  return (
    <ProSidebar>
      <ResponsiveMenuDropdown>
        <DropdownComponent
          label={"Menu"}
          options={options.flat()}
          value={options?.label}
          // id={key}
          // name="failedResponse"
          handleFilter={(selectedData) => clickHandler(selectedData.value)}
        />
      </ResponsiveMenuDropdown>
      {/*<select onChange={(e) => clickHandler(e.target.value)}>
        {menuItems.map((menuItem, index) => {
          return menuItem.subMenu ? (
            menuItem.subMenu.map((subMenu, subMenuIndex) => {
              return <option value={subMenu.route}>{subMenu.label}</option>;
            })
          ) : (
            <option value={menuItem.route}>{menuItem.label}</option>
          );
        })}
      </select>*/}
      <DesktopMenuAccordian>
        <Menu>
          {menuItems.map((menuItem, index) => {
            return (
              <>
                {menuItem.subMenu ? (
                  <SubMenu
                    title={menuItem.label}
                    onOpenChange={() => onOpenChangeHandler(menuItem.id)}
                    open={expandedPanel === menuItem.id}
                  >
                    {menuItem.subMenu.map((subMenu, subMenuIndex) => {
                      return (
                        <Fragment key={subMenuIndex}>
                          {subMenu.id === "operatorCertificationExpirationAlertSettings" ||
                          subMenu.id === "WorksiteSummaryAlertSettings" ||
                          subMenu.id === "accountSummaryAlertSettings"
                            ? userManagementObj?.action?.view
                              ? getMenuItem(subMenu)
                              : null
                            : getMenuItem(subMenu)}
                        </Fragment>
                      );
                    })}
                  </SubMenu>
                ) : (
                  <MenuItem
                    onClick={() => clickHandler(menuItem.route)}
                    active={
                      menuItem.route === selectedRoute ||
                      (menuItem.route === "s2-settings" &&
                        settingsRoutes.tabletSettings.includes(selectedRoute))
                    }
                    title={menuItem.label}
                  >
                    {menuItem.label}
                  </MenuItem>
                )}
              </>
            );
          })}
        </Menu>
      </DesktopMenuAccordian>
    </ProSidebar>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);

//export default MenuBar;
