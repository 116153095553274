import React, { useState, useRef, useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";

import { Row, Button, Col, Table, Accordion, Card, Form } from "react-bootstrap";

import FileUploadIcon from "../../assets/fileuploadicon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/AddIcon/Delete Button.svg";
import validationConstant from "../../constant/messages/validation.json";

function FileUploadWithDragAndDrop({ label, csvFile, setcsvFile, isCSVFileError }) {
  const { t } = useTranslation();
  const [noFileCsvErrorMsg, setnoFileCsvErrorMsg] = useState("");
  const [dragText, setDragText] = useState(t("Please upload .CSV file"));
  const csvFileUploadInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop your file here, or browse"));
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          // uploadedFile.push(file)
          const str = file?.name;
          const compareStr = str.slice(str.length - 4, str.length);
          if (compareStr == ".csv") {
            setcsvFile(file);
            setnoFileCsvErrorMsg("");
          } else {
            setcsvFile(null);
            setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
          }
          // setcsvFile(file)
          // setUploadedFile(uploadedFile)
          console.log("... file[" + i + "].name = " + file.name);
          // console.log(uploadedFile[0], '=====');
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log("... file[" + i + "].name = " + e.dataTransfer.files[i].name);
      }
    }
  };

  const handlecsvFileUpload = (e) => {
    console.log(e?.target?.files[0]?.name);
    const str = e?.target?.files[0]?.name;
    const compareStr = str.slice(str.length - 4, str.length);
    if (compareStr == ".csv") {
      setcsvFile(e.target.files[0]);
      setnoFileCsvErrorMsg("");
    } else {
      setcsvFile(null);
      setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop Here..."));
  };

  useEffect(() => {
    if (isCSVFileError) {
      setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
    }
  }, [isCSVFileError]);

  return (
    <>
      <Col md={6}>
        <label className="userBulkcsv">{label}</label>
        <Card
          className="text-center p-4 uploadStyleCode"
          onDrop={(e) => handleDrop(e)}
          onDragLeave={() => setDragText(t("Drop your file here, or browse"))}
          onDragOver={(e) => handleDrag(e)}
          style={
            noFileCsvErrorMsg == "" ? { border: "2px dashed #0D5FBE" } : { border: "2px dashed #dc3545" }
          }
        >
          {/* <span>{csvFile?.name || ""}</span>*/}
          <span className="custom-file customFile mt-4 mb-4">
            <label style={{ cursor: "pointer" }}>
              <input
                ref={csvFileUploadInputRef}
                onChange={(e) => {
                  handlecsvFileUpload(e);
                }}
                type="file"
                accept=".csv"
                className="d-none"
                name="uploadFile"
              />
              {/*  <NoteAddIcon fontSize='large' />*/}
              <img src={FileUploadIcon} />
            </label>
          </span>
          <span className="uploadFileName">
            <Trans>
              Drop your file here, or{" "}
              <span onClick={() => csvFileUploadInputRef?.current?.click()}>browse</span>
            </Trans>
          </span>
          <span className="uploadFileNameFile">
            <Trans>{dragText}</Trans>
          </span>
        </Card>
        <p style={{ color: "#dc3545" }}>{noFileCsvErrorMsg}</p>
      </Col>
      <Col md={6}>
        <div className="table-responsive-xl table_TD tableFixHead uploadHight tableMediaRes">
          <Table striped bordered hove borderless={true}>
            <thead
              className="border-0 documentTableH"
              style={{ fontFamily: "Open Sans", fontSize: "16px", backgroundColor: "#F2F2F2" }}
            >
              <tr className="font-weight-bolder">
                <th>
                  <Trans>Document</Trans>
                </th>
                <th>
                  <Trans>Status</Trans>
                </th>
                <th>
                  <Trans>Actions</Trans>
                </th>
              </tr>
            </thead>
            <tbody style={{ fontFamily: "Open Sans", fontSize: "14px" }}>
              {/* Rendering file details */}
              {csvFile ? (
                <>
                  <td data-label="Document">
                    <p className="elipsis_asset">{csvFile.name}</p>
                  </td>
                  <td
                    data-label="Status"
                    style={{ fontFamily: "Open Sans", fontSize: "11px", color: "#919191" }}
                  >
                    <Trans>Selected</Trans>
                  </td>
                  <td data-label="Actions">
                    <div
                      onClick={() => {
                        setcsvFile(null);
                        csvFileUploadInputRef.current.value = null;
                        setnoFileCsvErrorMsg(t("Please upload a valid csv file"));
                      }}
                      className="text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <DeleteIcon />
                    </div>
                  </td>
                </>
              ) : (
                ""
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </>
  );
}

export default FileUploadWithDragAndDrop;
