import React, { useEffect, useState } from "react";
// import Select, { components } from "react-select";
// import Tooltip from "@material-ui/core/Tooltip";
import Select from "../components/DropdownComponent/Dropdown.component";

// const { Option } = components;
// const customOption = (props) => {
//   return (
//     <Tooltip title={props.label}>
//       <span>
//         <Option {...props} />
//       </span>
//     </Tooltip>
//   );
// };

function SimpleDropdownFilter({
  filter,
  handleFilter,
  useAsDropdown,
  disabled,
  selectFirstAsDefault,
  value,
  placeholder,
  options,
  label,
  isLoading,
  isClearable,
  isSearchable = true,
  classNamePrefix,
  sortData,
  maxMenuHeight,
  ...rest
}) {
  const [dropdownOption, setDropdownOption] = useState([]);
  useEffect(() => {
    sortData &&
      options?.length > 0 &&
      options.sort((a, b) => {
        let fa = a?.label?.toLowerCase(),
          fb = b?.label?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    setDropdownOption(options);
  }, [options, sortData]);

  return (
    <Select
      label={label}
      placeholder={placeholder}
      value={dropdownOption.filter((option) => option.value === value)}
      isDisabled={disabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      options={dropdownOption}
      onChange={handleFilter}
      maxMenuHeight={maxMenuHeight}
      size="medium"
      {...rest}
    />
  );
}

SimpleDropdownFilter.defaultProps = {
  sortData: true,
};

export default SimpleDropdownFilter;
