//TODO - When time permits, review and optmize this file. Remove unused/commented code. Create cleaner sub components

import React from "react";
import { Row, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { Trans } from "react-i18next";

import { ReactComponent as BulkUpload } from "../../../assets/Blue_Bulk Upload.svg";
import { ReactComponent as ManageAssetType } from "../../../assets/Manage_Asset_Types.svg";

import { getAllAsset } from "../../../redux/assets/action";
import { toGenerateCSV } from "../../../redux/assets/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { ReactComponent as Download } from "../../../assets/Download.svg";
import { ReactComponent as PrintQR } from "../../../assets/Blue_Print.svg";
import { assetTypeList, defaultAssetTypeOverview } from "../../../redux/assetType/assetType.action";
import { setQrcodeFilterBy } from "../../../redux/dropdownFilters/action";
import { setAddAssetModal } from "../../../redux/modals/action";
import { setModalWaitingToOpen } from "../../../redux/modals/action";

// custom css
import ManagementPanelStyles from "../../../view/Dashboard/Management/panels/ManagementPanel.module.css";

function AssetActionBar({
  setAddAssetModal,
  setWorksiteBulkUploadShow,
  loginDetails,
  isPlanValidToExport,
  generateCSVData,
  basicPlan,
  history,
  headersData,
  handleDownloadCSV,
  setModalShow,
  showActionBar,
}) {
  return (
    <>
      {!showActionBar ? (
        ""
      ) : (
        <Row className="m-0 p-0">
          <div className={`mr-2 pl-0 ${ManagementPanelStyles.mobileViewButton}`}>
            <Button
              className="buttonAdd button-svg-hover ripple"
              onClick={() => history.push("/app/management/assettype")}
              variant="outline-primary"
            >
              <ManageAssetType style={{ width: 20, marginRight: 10 }} />
              <Trans>MANAGE ASSET TYPES</Trans>
            </Button>
          </div>
          <div className={`mr-2 pl-0 ${ManagementPanelStyles.mobileViewButton}`}>
            <Button
              className="buttonAdd ripple"
              onClick={() => setAddAssetModal(true)}
              variant="outline-primary"
            >
              <AddIcon className="buttonIcon" /> <Trans>ADD ASSET</Trans>
            </Button>
          </div>
          <div className={`mr-2 pl-0 ${ManagementPanelStyles.mobileViewButton}`}>
            <Button
              className="buttonAdd ripple"
              onClick={() => setWorksiteBulkUploadShow(true)}
              variant="outline-primary"
            >
              <BulkUpload /> <Trans>BULK UPLOAD</Trans>
            </Button>
          </div>
          <div className={`mr-2 pl-0 ${ManagementPanelStyles.mobileViewButton}`}>
            <span>
              {isPlanValidToExport() ? (
                <CSVLink
                  data={generateCSVData}
                  style={
                    basicPlan
                      ? {
                          width: "200px",
                          textDecoration: "none",
                          pointerEvents: "none",
                          color: "#ccc",
                          borderColor: "#ccc",
                          opacity: 0.65,
                        }
                      : {
                          width: "200px",
                          textDecoration: "none",
                        }
                  }
                  headers={headersData}
                  onClick={handleDownloadCSV}
                  filename={"Asset-Ledger.csv"}
                  className="buttonAdd_a button-svg-hover ripple"
                >
                  <Download
                    style={
                      !basicPlan
                        ? { width: 20, marginRight: 10 }
                        : {
                            fill: "#c6c6c6",
                            width: "18px",
                            stroke: "#c6c6c6",
                            strokeWidth: "3px",
                            marginBottom: "3px",
                            marginRight: 10,
                          }
                    }
                  />
                  <Trans>EXPORT LEDGER</Trans>
                </CSVLink>
              ) : (
                <Button
                  onClick={() => setModalShow(true)}
                  className="buttonAdd ripple"
                  variant="outline-primary"
                >
                  <Download
                    style={
                      //!basicPlan
                      //  ? { width: 20, marginRight: 10 }
                      // : {
                      {
                        fill: "#c6c6c6",
                        width: "20",
                        // stroke: "#c6c6c6",
                        strokeWidth: "3px",
                        marginBottom: "3px",
                        marginRight: 10,
                      }
                    }
                  />
                  <Trans>EXPORT LEDGER</Trans>
                </Button>
              )}
            </span>
          </div>
          {/* <Col  md={{ offset: 5 }}><SearchAWorksite /></Col> */}
          <div className={`mr-2 pl-0 mb-2 ${ManagementPanelStyles.mobileViewButton}`}>
            <Button
              className="buttonAdd button-svg-hover ripple"
              onClick={() => {
                setQrcodeFilterBy("assets");
                history.push("/app/management/qrcode");
              }}
              // variant="outline-primary"
            >
              <PrintQR style={{ width: 20, marginRight: 10 }} />
              <Trans>PRINT QR CODES</Trans>
            </Button>
          </div>
        </Row>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  accountList: state.account.accountList,
  menuList: state.sideMenu.menuState,
  assetLoading: state.assets.AssetLoading,
  generateCSVData: state.assets.GeneratingCSVData,
  generateCSVLoading: state.assets.GenerateCSVLoading,
  inspectionPlanDetail: state.config.inspectionPlan,
  isSuperAdmin: state.user.isSuperAdmin,
  addAssetModal: state.modals.addAssetModal,
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({
  setAssetTypeList: (assetTypeLists) => dispatch(assetTypeList(assetTypeLists)),
  defaultAssetTypeOverview: () => dispatch(defaultAssetTypeOverview()),
  getAllAsset: () => dispatch(getAllAsset()),
  toGenerateCSV: () => dispatch(toGenerateCSV()),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setQrcodeFilterBy: (data) => dispatch(setQrcodeFilterBy(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetActionBar));
