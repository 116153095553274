import { Style } from "@material-ui/icons";
import React from "react";
import Styles from "./CustomStepper.module.css";
import styled from "styled-components";
const SteperStyledAddAsset = styled.div`
  .stepperIconColor {
    background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .stepIconActive {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
  .stepHeading {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .stepHeadingActive {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .stepHeadingComplete {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-weight: 300;
  }
  .stepSeparator span {
    background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .stepSeparator span.stepSeparatorComplete {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;
function CustomStepper({ allSteps, activeStep }) {
  return (
    <SteperStyledAddAsset>
      <div className={`${Styles.stepperContainer}`}>
        {allSteps?.map?.((currentStep, index) => (
          <>
            <div>
              <span className={`${Styles.stepContent}`}>
                <span style={{ display: "flex" }}>
                  <div
                    className={`stepperIconColor ${Styles.stepIconContainer} ${
                      index <= activeStep ? `stepIconActive` : ""
                    }`}
                  >
                    {currentStep?.icon}
                  </div>
                </span>
                <span className={`${Styles.stepContentStepper}`}>
                  <span
                    className={`stepHeading ${Styles.stepHeading} ${
                      index < activeStep
                        ? `stepHeadingComplete`
                        : index === activeStep
                        ? `stepHeadingActive`
                        : ""
                    }`}
                  >
                    Step {index + 1}
                  </span>
                  <br></br>
                  <span
                    className={`stepHeading ${Styles.stepHeading} ${
                      index < activeStep
                        ? `stepHeadingComplete`
                        : index === activeStep
                        ? `stepHeadingActive`
                        : ""
                    }`}
                  >
                    {currentStep?.heading}
                  </span>
                </span>
              </span>
            </div>
            {index !== allSteps?.length - 1 && (
              <div className={`stepSeparator ${Styles.stepSeparator}`}>
                <span className={index < activeStep ? `stepSeparatorComplete` : ""}></span>
              </div>
            )}
          </>
        ))}
      </div>
    </SteperStyledAddAsset>
  );
}

export default CustomStepper;
