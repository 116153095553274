import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { CameraAltOutlined } from "@material-ui/icons";
import ProfileImage from "../../../assets/image.png";
import { apiCall } from "../../../utils/apiCall";
import { isInvalidUrl } from "../../../utils/validations/checkValidation";
import timeZone from "../../../constant/timeZone";
import { updateUser } from "../../../redux/user/user.action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { s3BucketLogo } from "../../../config";
import units from "../../../constant/units";
import Input from "../../../components/Input";
import useAlert from "../../../utils/useAlert";
import toasterConstant from "../../../constant/messages/toaster.json";
import styled from "styled-components";
import {
  AccounttProfileContainer,
  InputProfileDiv,
  InputProfileDivCont,
  MFAWrapper,
  SwitchWrapper,
  OptionsWrapper,
} from "./AccountProfile.Styles";
import Button from "../../../components/Button/Button.component";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import RadioButton from "../../../components/RadioButton/Radio.component";

const { account_profile_update, account_update_error } = toasterConstant;
const inputs = [
  { id: "companyName", label: "Account Name", maxLength: "64" },
  // { id: "ownerName", label: "Account Owner’s Name" },
  // { id: "address", label: "Headquarter’s Address" },
  { id: "unitType", label: "Preferred Units", options: units, type: "select" },
  {
    id: "enterpriseUrl",
    label: "Enterprise URL",
    startAdornment: "manage.siera.ai/#/",
    marginTop: "10px",
    maxLength: "64",
  },
  { id: "timeZone", label: "Time Zone", options: timeZone, type: "select" },
];

const InputWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
`;

function AccountProfile(props) {
  const { account, updateUser, updateActiveNavItem } = props;
  const inputRef = useRef();
  const accountNameInputRef = useRef(null);
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const [state, setState] = useState({
    companyName: "",
    ownerName: "",
    address: "",
    enterpriseUrl: "",
    timeZone: "",
    unitType: "",
    logo: "",
  });

  const [formErrors, setFormErrors] = useState({
    companyNameError: false,
    unitTypeError: false,
    timeZoneError: false,
    enterpriseUrlError: false,
  });

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUrlAvailable, setIsUrlAvailable] = useState(true);

  useEffect(() => {
    if (account) {
      setState({
        companyName: account?.companyName,
        ownerName: account?.userData?.fullName,
        address: account?.address,
        enterpriseUrl: account?.enterpriseUrl,
        timeZone: account?.timeZone,
        ...account,
      });
    }
    if (editMode) {
      accountNameInputRef.current.focus();
    }
  }, [account, editMode]);

  const isFormValid = (e) => {
    const { companyName, unitType, timeZone, enterpriseUrl } = state;
    let isValid = true;
    if (!companyName) {
      handleFormError("companyName");
      isValid = false;
    }
    if (!unitType) {
      handleFormError("unitType");
      isValid = false;
    }
    if (!timeZone) {
      handleFormError("timeZone");
      isValid = false;
    }
    if (enterpriseUrl === "") {
      isValid = true;
    } else if (!isUrlAvailable) {
      isValid = false;
    }
    return isValid;
  };

  const handleFormError = (fieldName) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [`${fieldName}Error`]: true,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setIsLoading(true);
      setUrlAvailability(state.enterpriseUrl);
      if (await checkUrlAvailability(state.enterpriseUrl)) {
        let formData = new FormData();
        formData.append(
          "accountData",
          JSON.stringify({
            companyName: state.companyName,
            ownerName: state.ownerName,
            address: state.address,
            enterpriseUrl: state.enterpriseUrl,
            timeZone: state.timeZone,
            unitType: state.unitType,
            mfaData: state.mfaData,
          })
        );
        if (selectedFile) {
          formData.append("logo", selectedFile);
        }
        apiCall(`/account/${account.accountId}`, {
          method: "PUT",
          data: formData,
          type: "file",
        })
          .then((data) => {
            localStorage.setItem("unitType", data?.data?.data?.unitType);
            if (data?.data) {
              updateUser({
                ...data?.data?.data,
              });
            }
            showAlert("success", t(account_profile_update));
            setIsLoading(false);
            setEditMode(false);
          })
          .catch((err) => {
            showAlert("error", t(account_update_error));
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    } else {
      alert(t("Please enter all the details"));
    }
  };

  const handleDropdownChange = (value, name) => {
    if (name == "enterpriseUrl") {
      checkUrlAvailability(value);
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { id, name, value, checked } = e.target;
    // console.log(isAlphanumeric(value))
    if (name == "enterpriseUrl") {
      // alert(value.trim())
      // if(value.trim()){
      // }
      if (isInvalidUrl(value)) return;

      checkUrlAvailability(encodeURI(value.trim()));
      setState((prevState) => ({
        ...prevState,
        [name]: encodeURI(value.trim()),
      }));
    } else if (name === "status" || name === "isMandatory") {
      const value = name === "status" ? checked : id === "required";
      setState((prevState) => ({
        ...prevState,
        mfaData: {
          ...(prevState?.mfaData
            ? prevState.mfaData
            : {
                status: false,
                type: "EMAIL",
                isMandatory: false,
              }),
          [name]: !!value,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!state.enterpriseUrl || state.enterpriseUrl === account.enterpriseUrl) {
      setIsUrlAvailable(true);
    } else {
      //wtf
      setUrlAvailability(state.enterpriseUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.enterpriseUrl]);

  const setUrlAvailability = async (result) => setIsUrlAvailable(await checkUrlAvailability(result));

  const checkUrlAvailability = async (enterpriseUrl) => {
    // Add debounce to reduce unnecessary calls when time permits
    if (enterpriseUrl === "") {
      return true;
    }
    let result = await apiCall(`/account/urlAvailabilty/${account.accountId}/${enterpriseUrl}`, {
      method: "GET",
      cancenPreviousRequest: true,
    });
    setIsUrlAvailable(result?.data?.success);
    return result?.data?.success;
  };

  useEffect(() => {
    setFormErrors({
      ...formErrors,
      enterpriseUrlError: !isUrlAvailable,
    });
  }, [isUrlAvailable]);

  useEffect(() => {
    console.log(formErrors);
    console.log(state);
  }, [formErrors]);

  useEffect(() => {
    apiCall(`/account/${account.accountId}`)
      .then((resp) => {
        console.log(resp);
        updateUser({
          ...resp.data.data,
        });
      })
      .catch((err) => {
        // dispatch(getUsersError(err));
        console.log(err);
      });

    updateActiveNavItem("accountProfile");
  }, []);
  return (
    <Form validated={true} onSubmit={(e) => e.preventDefault()} className="no-flex">
      <AccounttProfileContainer>
        <div className=" mb-4">
          <div className="p-1 account-label">
            <label className="form-label">
              <Trans>Account Logo</Trans>
            </label>
          </div>
          <div
            className="company-logo avatar mb-4"
            style={{
              backgroundImage: selectedFile
                ? `url(${selectedFile ? URL.createObjectURL(selectedFile) : ProfileImage})`
                : state.logo
                ? `url(${s3BucketLogo}${state.logo})`
                : ProfileImage,
              // state.logo
              //   ? `url(${s3BucketLogo}${state.logo})`
              //   : `url(${selectedFile
              //     ? URL.createObjectURL(selectedFile)
              //     : ProfileImage
              //   })`,
            }}
          >
            {editMode && (
              <div className="change-image" onClick={() => inputRef?.current?.click?.()}>
                <CameraAltOutlined />
                <div>
                  <span>
                    <Trans>Change Image</Trans>
                  </span>
                  <input type="file" ref={inputRef} accept="image/*" onChange={handleImageChange} />
                </div>
              </div>
            )}
          </div>
        </div>
        <InputProfileDiv>
          {inputs.map((input) => (
            <InputProfileDivCont>
              <InputWrapper marginTop={input.marginTop}>
                <Input
                  key={input.id}
                  id={input.id}
                  label={input.label}
                  maxLength={input.maxLength}
                  fullWidth
                  required={
                    input.id === "companyName" || input.id === "unitType" || input.id === "timeZone" || false
                  }
                  placeholder={t(`Enter ${input.label}`)}
                  autofocus
                  name={input.id}
                  onChange={
                    input.type == "select"
                      ? (value) => handleDropdownChange(value, input.id)
                      : (e) => handleInputChange(e)
                  }
                  value={state[input.id]}
                  disabled={!editMode}
                  feedback={input.id !== "enterpriseUrl" && `${input.label} is required`}
                  type={input.type}
                  options={input.options}
                  startAdornment={input.startAdornment}
                  isInvalid={state[input.id] && formErrors[`${input.id}Error`]}
                  accountNameInputRef={input.id == "companyName" ? accountNameInputRef : null}
                />
              </InputWrapper>

              {state.enterpriseUrl && input.id === "enterpriseUrl" && (
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <div style={{ marginTop: "8px" }}>
                    <Trans>URL Availability</Trans>:
                    {isUrlAvailable ? (
                      <>
                        <span style={{ color: "green", marginLeft: "5px" }}>
                          <Trans>You are good to go</Trans>
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          <Trans>Enterprise URL is not available</Trans>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </InputProfileDivCont>
          ))}

          <MFAWrapper>
            <SwitchWrapper>
              Multi-Factor Authentication{" "}
              <SwitchComponent
                onChange={handleInputChange}
                checked={state?.mfaData?.status}
                disabled={!editMode}
                name="status"
              />
            </SwitchWrapper>
            {state?.mfaData?.status && (
              <OptionsWrapper>
                <RadioButton
                  onClick={handleInputChange}
                  checked={state?.mfaData?.isMandatory}
                  label="Required"
                  name="isMandatory"
                  size="small"
                  id="required"
                  disabled={!editMode}
                />
                <RadioButton
                  onClick={handleInputChange}
                  checked={!state?.mfaData?.isMandatory}
                  label="Optional"
                  name="isMandatory"
                  size="small"
                  id="optional"
                  disabled={!editMode}
                />
              </OptionsWrapper>
            )}
          </MFAWrapper>
        </InputProfileDiv>
        {!editMode ? (
          <div className="profile-button-style">
            <div className="mt-2 text-left">
              <Button
                label={t("Edit")}
                iconClass="edit"
                onClick={() => setEditMode(true)}
                showIcon
                buttonType="secondary"
              />
            </div>
          </div>
        ) : (
          <div className="profile-button-style">
            <div className="mt-2">
              <Button
                label={t("save")}
                iconClass="save"
                disabled={isLoading}
                onClick={submit}
                showIcon
                buttonType="save"
              />
            </div>
            <div className="mt-2">
              <Button
                label={t("Cancel")}
                onClick={() => {
                  setSelectedFile(null);
                  setEditMode(false);
                }}
                showIcon={false}
                buttonType="ghost"
              />
            </div>
          </div>
        )}
      </AccounttProfileContainer>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state?.user?.loginDetails,
    token: state?.user?.loginDetails?.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile);
