import React from "react";
import { Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//bootstrap imports
import { Row, Button } from "react-bootstrap";

//redux
import { setAddSiteModal } from "../../../redux/modals/action";

// Icons and images
import { ReactComponent as BulkUpload } from "../../../assets/Blue_Bulk Upload.svg";
import { ReactComponent as AddIcon } from "../../../assets/AddIcon/Add Icon.svg";
// custom css
import ManagementPanelStyles from "../../../view/Dashboard/Management/panels/ManagementPanel.module.css";

function WorksiteActionBar({ showActionBar, setAddSiteModal, setWorksiteBulkUploadShow }) {
  return (
    <>
      {!showActionBar ? (
        ""
      ) : (
        <Row className="m-0 p-0">
          <div className={`mr-2 pl-0 ${ManagementPanelStyles.mobileViewButton}`}>
            <Button
              className="buttonAdd ripple"
              onClick={() => setAddSiteModal(true)}
              variant="outline-primary"
            >
              <AddIcon className="buttonIcon" />
              <Trans>ADD WORKSITE</Trans>
            </Button>
          </div>
          <div className={`mr-2 pl-0 mb-2 ${ManagementPanelStyles.mobileViewButton}`}>
            <Button
              className="buttonAdd buttonDisabled ripple"
              onClick={() => setWorksiteBulkUploadShow(true)}
              variant="outline-primary"
              style={{
                color: "#ccc",
                borderColor: "#ccc",
              }}
              disabled
            >
              <BulkUpload style={{ fill: "#CCC" }} /> <Trans>BULK UPLOAD</Trans>
            </Button>
          </div>
          {/* <Col  md={{ offset: 5 }}><SearchAWorksite /></Col> */}
        </Row>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksiteActionBar));
