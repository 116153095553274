import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col, Form, FormControl } from "react-bootstrap";
import AccountOverviewTab from "./AccountOverviewTab.component";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import WorksiteSvg from "../../../assets/AddIcon/worksites.svg";
import AssetSvg from "../../../assets/AddIcon/assets.svg";
import UserSvg from "../../../assets/AddIcon/users.svg";
import Skeleton from "react-loading-skeleton";
import { setEditAlertModal } from "../../../redux/modals/action";
import { setEditForm } from "../../../redux/forms/action";
import moment from "moment";
import { ThemeContext } from "styled-components";
import {
  CarouselWrapperDiv,
  FormControlStyle,
  WorksiteIconconDiv,
  AssetIconconDiv,
  UserIconconDiv,
} from "./AccountOverview.component.styles";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const AccountOverview = ({
  AccountOverview,
  imageLoaded,
  setImageLoaded,
  AccountLoading,
  AccountDetailLoading,
  setEditForm,
  editForm,
}) => {
  const [editOverview, setEditOverview] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});
  const themeContext = useContext(ThemeContext);

  const styles = {
    vertical: {
      borderLeft: `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
      position: "relative",
      height: "30px",
      top: "7px",
    },
    labelStyle: {
      position: "relative",
      height: "0px",
      width: "100%",
      textAlign: "left",
      marginBottom: "0px",
      fontSize: "12px",
      color: themeContext?.colors?.grayL4 || "#9A9A9A",
      fontFamily: "Open Sans, sans-serif",
    },
    title: {
      fontFamily: "Kanit",
      fontSize: "46px !important",
      color: themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      paddingTop: "2px",
    },
    subTitle: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      paddingTop: "2px",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: themeContext?.colors?.grayL4 || "#9A9A9A",
      textAlign: "left",
      paddingTop: "2px",
    },
    safetyEnabled: {
      fontFamily: "Open Sans",
      fontSize: "15px",
      color: themeContext?.colors?.themePrimary || "#0d5fbe",
      textAlign: "left",
      fontWeight: "bold",
      paddingTop: "7px",
    },
  };

  useEffect(() => {
    let { companyName, enterpriseUrl, unitType, timeZone } = AccountOverview
      ? AccountOverview
      : { companyName: "" };
    setAccountDetails({
      companyName,
      enterpriseUrl,
      unitType,
      timeZone,
    });
    setEditOverview(true);
  }, [AccountOverview, editForm]);

  return (
    <>
      <Card>
        <Card.Body>
          {(AccountLoading || AccountDetailLoading) && (
            <Row>
              <Col md={4}>
                <Skeleton height={40} />
                <Skeleton height={10} count={1} />
              </Col>
              <Col md={6}>
                <Skeleton height={50} width={200} />
              </Col>
              <Col md={1}></Col>
            </Row>
          )}
          {AccountOverview && !AccountLoading && !AccountDetailLoading && (
            <Row>
              <Col md={4} style={{ zIndex: editForm ? 0 : 1 }}>
                {!editForm ? (
                  <div style={styles.date}>
                    <label style={styles.labelStyle}>
                      <Trans>Account Name</Trans>
                    </label>
                    <FormControlStyle>
                      <Form.Control
                        className="formControlStyle"
                        autoFocus
                        minLength={"1"}
                        maxLength={"64"}
                        value={accountDetails.companyName}
                        onChange={(e) =>
                          setAccountDetails({
                            ...accountDetails,
                            companyName: e.target.value,
                          })
                        }
                        fullWidth
                      />
                    </FormControlStyle>
                  </div>
                ) : (
                  <div style={styles.title} className="title-style ellipseStyle">
                    <span title={AccountOverview && AccountOverview.companyName}>
                      {AccountOverview && AccountOverview.companyName}
                    </span>
                  </div>
                )}
                <div style={styles.subTitle}>{AccountOverview && AccountOverview.companyDescription}</div>
                <div style={styles.date}>
                  <Trans>Added On</Trans>:
                  {moment(AccountOverview?.createdAt)
                    .utcOffset(
                      AccountOverview?.timeZone &&
                        AccountOverview?.timeZone.split &&
                        AccountOverview?.timeZone.split(" ")[0]
                    )
                    .format(" MMMM DD YYYY")}
                </div>
                {/* <div style={styles.safetyEnabled}>SIERA Safety Enabled : {}</div> */}
              </Col>
              <Col md={6}>
                {/* <MultiCards /> */}
                <CarouselWrapperDiv>
                  <Carousel responsive={responsive} className="shadow-sm borderCar Account_TabOver">
                    <div className="fexDiv">
                      <Col className="count-card">
                        <Trans>Worksites</Trans>
                        <br />
                        <WorksiteIconconDiv className="icon icon-worksites">
                          <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                            {AccountOverview && AccountOverview.sites && AccountOverview.sites.length}
                          </span>
                        </WorksiteIconconDiv>
                      </Col>
                      <div style={styles.vertical}></div>
                    </div>
                    <div className="fexDiv">
                      <Col className="count-card">
                        <Trans>Assets</Trans>
                        <br />
                        <AssetIconconDiv className="icon icon-assets">
                          <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                            {AccountOverview && AccountOverview.asset && AccountOverview.asset.length}
                          </span>
                        </AssetIconconDiv>
                      </Col>
                      <div style={styles.vertical}></div>
                    </div>
                    <div className="fexDiv">
                      <Col className="count-card">
                        <Trans>Users</Trans>
                        <br />
                        <UserIconconDiv className="icon icon-users">
                          <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                            {AccountOverview && AccountOverview.users && AccountOverview.users.length}
                          </span>
                        </UserIconconDiv>
                      </Col>
                      <div style={styles.vertical}></div>
                    </div>
                  </Carousel>
                </CarouselWrapperDiv>
              </Col>
              <Col md={1}>{/* <SwitchGreenLabel /> */}</Col>
            </Row>
          )}
          {!AccountDetailLoading && AccountOverview && (
            <div style={{ paddingTop: "10px" }}>
              <AccountOverviewTab
                accountDetails={accountDetails}
                setAccountDetails={setAccountDetails}
                setEditOverview={setEditOverview}
                editOverview={editOverview}
                imageLoaded={imageLoaded}
                setImageLoaded={(data) => setImageLoaded(data)}
              />
            </div>
          )}
          {!AccountOverview && !AccountLoading && !AccountDetailLoading && (
            <div className="noSection">
              <Typography align="center" style={styles.message}>
                <Trans>NO_ACCOUNT_IN_ACCOUNT</Trans>
                {/* <Trans>CLICK_ADD_ACCOUNT_BUTTON</Trans> */}
              </Typography>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  AccountOverview: state.accounts.AccountOverview,
  AccountLoading: state.accounts.AccountLoading,
  AccountDetailLoading: state.accounts.accountDetailLoading,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  setEditForm: (data) => dispatch(setEditForm(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountOverview));
