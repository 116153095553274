import React from "react";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";

import { RegisterTitle, RegisterSubTitle, RegisterSubTitle2 } from "./BackToLogin.styles";
import { ReactComponent as RightCircledIcon } from "../../../assets/rightCircledIcon/Group 3.svg";
import Button from "../../../components/Button/Button.component";

// Password Recovery Component
const BackToLogin = ({ history }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="forgot-pass forgot-pass-height">
        {/* Password Recover Title */}
        <RegisterTitle>
          <Trans>Password Recovery</Trans>
        </RegisterTitle>
        {/* Password Recover Sub Title */}
        <RegisterSubTitle>
          <Trans>Great! Your password has been reset</Trans>.
        </RegisterSubTitle>
        {/* Password Recover Sub Title 2 */}
        <RegisterSubTitle2>
          <Trans>Please login to your account</Trans>.
        </RegisterSubTitle2>
        <Container>
          <form>
            <Row>
              <Col md={3}></Col>
              <Col md={6}>
                {/* Success Tick Icon */}
                <RightCircledIcon style={{ width: "60px", height: "60px" }} />
              </Col>
              <Col md={3}></Col>
            </Row>
            <Row>
              <Col md={3}></Col>
              <Col md={6}>
                {/* Back To Login Button redirecting to Home Page*/}

                <Button
                  onClick={() => history.push("/")}
                  label={t("Login")}
                  buttonType="primary"
                  size="large"
                  showIcon={false}
                />
              </Col>
              <Col md={3}></Col>
            </Row>
          </form>
        </Container>
      </div>
    </>
  );
};

export default withRouter(BackToLogin);
