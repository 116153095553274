import React, { useState, useEffect } from "react";
import {
  CarouselContainer,
  CarouselWrapper,
  CarouselHeading,
  CarouselIconNumber,
  IconCarousel,
} from "./Carousel.component.styles";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselComponent = (props) => {
  const {
    size,
    type,
    responsive,
    countData,
    carouselData,
    swipeable,
    autoPlay,
    autoPlaySpeed,
    infinite,
    slidesToSlide,
    iconColor,
    ...rest
  } = props;

  const [refreshIndex, setRefreshIndex] = useState(0);

  // useEffect(() => {
  //   let intervalId = null;

  //   if (!autoPlay) {
  //     intervalId = setInterval(() => {
  //       setRefreshIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  //     }, 5000);
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [autoPlay, carouselData.length]);

  // Update responsive settings based on the current screen size
  const updatedResponsive = {
    ...responsive,
    superLargeDesktop: {
      ...responsive.superLargeDesktop,
      slidesToSlide: Math.min(responsive.superLargeDesktop.items, slidesToSlide),
    },
    desktop: {
      ...responsive.desktop,
      slidesToSlide: Math.min(responsive.desktop.items, slidesToSlide),
    },
    tablet: {
      ...responsive.tablet,
      slidesToSlide: Math.min(responsive.tablet.items, slidesToSlide),
    },
    mobile: {
      ...responsive.mobile,
      slidesToSlide: Math.min(responsive.mobile.items, slidesToSlide),
    },
  };

  return (
    <CarouselWrapper>
      <Carousel
        responsive={updatedResponsive}
        autoPlaySpeed={autoPlaySpeed}
        slidesToSlide={slidesToSlide}
        swipeable={swipeable}
        autoPlay={autoPlay}
        infinite={infinite}
        key={autoPlay ? undefined : refreshIndex}
        {...rest}
      >
        {carouselData?.map((data, index) => (
          <CarouselContainer href={data.to} onClick={data.onClick} key={index}>
            <CarouselHeading label={data.name} size={size} title={data.name}>
              {data.name}
            </CarouselHeading>
            <CarouselIconNumber size={size} type={data.type}>
              <IconCarousel
                size={size}
                className={`icon icon-${data.iconClass}`}
                iconColor={data.iconColor}
              ></IconCarousel>
              {countData?.[data?.feildName]}
            </CarouselIconNumber>
          </CarouselContainer>
        ))}
      </Carousel>
    </CarouselWrapper>
  );
};
CarouselComponent.propTypes = {
  /**
   * How large should the Carousel Component be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * responsive settings
   */
  responsive: PropTypes.object,
  /**
   * Data to display object
   */
  countData: PropTypes.object,
  /**
   * Carousel settings data
   */
  carouselData: PropTypes.array,
  /**
   * Optionally disable/enable swiping on mobile
   */
  swipeable: PropTypes.bool,
  /**
   * Auto play
   */
  autoPlay: PropTypes.bool,
  /**
   * The unit is ms
   */
  autoPlaySpeed: PropTypes.number,
  /**
   * How many slides to slide.
   */
  slidesToSlide: PropTypes.number,
  /**
   * Enables infinite scrolling in both directions. Carousel items are cloned in the DOM to achieve this.
   */
  infinite: PropTypes.bool,
};

CarouselComponent.defaultProps = {
  size: "large",
  type: "ThemePrimary",
  responsive: {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  },
  countData: {},
  carouselData: [],
  swipeable: true,
  autoPlay: true,
  autoPlaySpeed: 5000,
  slidesToSlide: 1,
  infinite: true,
};

export default CarouselComponent;
