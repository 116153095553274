import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//PS : while This component is not being used , its css is being used in analyticsPage component...!!
function ChartsOverview({}) {
  return (
    <>
      {/* <Row style={{ paddingTop: "20px" }}>
            <Col lg={3}>
            <div className="assetTypeList_hieght">
                <AssetTypeList
                filterAccountId={filterAccountId}
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                />
            </div>
            </Col>
            <Col lg={9}>
            <ViewAssetTypes setFilterAccountId={setFilterAccountId} />
            </Col>
            </Row> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  // loginDetails: state.user.loginDetails,
});

const mapDispatchToProps = (dispatch) => ({
  // updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsOverview));
