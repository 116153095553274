//TODO - Clean up, Refactor,  Optimize this file when time permits and remove this comment afterwards

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "../../../../components/Button/Button.component";

// Redux
import { getAllChecklist } from "../../../../redux/checklists/action";
import {
  updateAssetTypeChecklist,
  resetUpdateAssetTypeChecklistState,
} from "../../../../redux/assets/action";
import Checkbox from "../../../../components/Checkbox/Checkbox.component";
import RadioButton from "../../../../components/RadioButton/Radio.component";
import toasterConstant from "../../../../constant/messages/toaster.json";

import Styles from "../../Asset/Modal/AddAssetTypeModal/AddAssetTypeModalStyles.module.css";
import { Trans, useTranslation } from "react-i18next";

//Custom Components
import Input from "../../../../components/Input";
import SnackBar from "../../SnackBar";
import AssetTypeChecklistUpdateAllModal from "../../Modals/AssetTypeChecklistUpdateAllModal";
import AssetTypeChecklistDeleteAllModal from "../../Modals/AssetTypeChecklistDeleteAllModal";
import Information from "../../../../assets/Information_Icon.svg";
import styled from "styled-components";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";

const DeleteIconStyled = styled.div`
  font-size: 20px !important;
  cursor: pointer;
`;
const { asset_type_checklist_success, asset_type_checklist_failed } = toasterConstant;
function AssetTypeChecklistsTab({
  AssetTypeOverview,
  allChecklists,
  dropdownAccountId,
  loginDetails,
  getAllChecklist,
  assignedChecklists,
  updateAssetTypeChecklist,
  UpdateAssetTypeChecklistLoading,
  UpdateAssetTypeChecklistSuccess,
  UpdateAssetTypeChecklistError,
  resetUpdateAssetTypeChecklistState,
  isSuperAdmin,
}) {
  const { t } = useTranslation();
  const [checklistData, setchecklistData] = useState([]);
  const [checklistAPIData, setChecklistAPIData] = useState([]);
  const [finalUnassignedChecklist, setFinalUnassignedChecklist] = useState([]);
  const [CurrentSelectedChecklist, setCurrentSelectedChecklist] = useState("");
  const [defaultChecklist, setDefaultChecklist] = useState();
  //const [isLoading, setIsLoading] = useState(false);
  const [showAddChecklistLayout, setShowAddChecklistLayout] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isUpdateToAllAssetType, setIsUpdateToAllAssetType] = useState(false);
  const [originalChecklistData, setOriginalChecklistData] = useState([]);
  const [originalDefaultChecklist, setoriginalDefaultChecklist] = useState();
  const [snackBarStatus, setSnackBarStatus] = useState("");
  const [showUpdateAllModal, setShowUpdateAllModal] = useState(false);
  const [showDeleteAllChecklistModal, setShowDeleteAllChecklistModal] = useState(false);
  const tableBodyContainer = useRef(null);
  const [enableAddChecklistButton, setEnableAddChecklistButton] = useState(false);
  const handleAddChecklist = () => {
    if (checklistData?.length == 0) {
      setDefaultChecklist(CurrentSelectedChecklist);
    }

    let newChecklistData = allChecklists?.filter((checklist) => {
      if (checklist?._id === CurrentSelectedChecklist) {
        return {
          checklistName: checklist?.checklistName,
          checklistDescription: checklist?.checklistDescription,
          _id: checklist?._id,
        };
      }
    });

    setShowAddChecklistLayout(false);
    setchecklistData([...checklistData, ...newChecklistData]);
    setCurrentSelectedChecklist("");
    //console.log("TABLE BODY", tableBodyContainer);
    tableBodyContainer &&
      tableBodyContainer.current &&
      tableBodyContainer.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
    // tableBodyContainer.current.lastElementChild.scrollIntoView({ behavior: 'smooth' });
  };

  const handleRemoveChecklist = (checklistId) => {
    if (checklistId == defaultChecklist) {
      if (checklistData[0]?._id == checklistId) {
        setDefaultChecklist(checklistData[1]?._id);
      } else {
        setDefaultChecklist(checklistData[0]?._id);
      }
    }
    let newChecklistData = checklistData?.filter((checklist) => {
      if (checklist?._id == checklistId) {
        return;
      } else {
        return checklist;
      }
    });
    // console.log(newChecklistData)
    if (newChecklistData) setchecklistData([...newChecklistData]);
    else setchecklistData([]);
  };

  const setDefaultChecklistData = () => {
    // setchecklistData(res)
  };

  const checkForDataChange = () => {
    let originalSet = new Set();
    let newSet = new Set();

    let temp = originalChecklistData?.map((currentChecklist) => {
      originalSet.add(currentChecklist?._id);
    });

    let temp2 = checklistData?.map((currentChecklist) => {
      newSet.add(currentChecklist?._id);
    });

    let compareSet = new Set([...originalSet, ...newSet]);

    if (compareSet.size === originalSet.size && compareSet.size === newSet.size) {
      setIsEditEnabled(true);
    }
  };

  const handleAddChecklistButton = () => {
    if (finalUnassignedChecklist?.length > 0) {
      setIsEditEnabled(true);
      setShowAddChecklistLayout(true);
    } else {
      updateAddCheckListButtonAvailibility();
    }
  };

  const handleCancelButton = () => {
    setchecklistData(originalChecklistData);
    setDefaultChecklist(originalDefaultChecklist);
    setShowAddChecklistLayout(false);
    setIsEditEnabled(false);
    setIsUpdateToAllAssetType(false);
  };

  const handleSaveAssetTypeChecklist = () => {
    setShowAddChecklistLayout(false);

    if (isUpdateToAllAssetType && checklistAPIData?.checklists?.length == 0) {
      setShowDeleteAllChecklistModal(true);
      return;
    }
    if (isUpdateToAllAssetType) {
      setShowUpdateAllModal(true);
      return;
    }
    updateAssetTypeChecklist(checklistAPIData);
  };

  const handleModalYesResponse = () => {
    setShowUpdateAllModal(false);
    setShowDeleteAllChecklistModal(false);
    setIsUpdateToAllAssetType(false);
    updateAssetTypeChecklist(checklistAPIData);
  };

  const updateAddCheckListButtonAvailibility = () => {
    if (finalUnassignedChecklist?.length > 0) {
      setEnableAddChecklistButton(true);
    } else {
      setEnableAddChecklistButton(false);
    }
  };

  useEffect(() => {
    // console.log("ALL CHECKLISTS", allChecklists)
    // console.log("ASSIGNED CHECKLIST", assignedChecklists)
    if (!checklistData) {
      return;
    }
    let finalChecklist = [];
    let finalAssignedChecklist = [];
    let selectedCheckListIds = checklistData.length > 0 && checklistData?.map?.((list) => list?._id);
    // console.log("SELECTED CHECKLIST IDS", selectedCheckListIds)
    let filteredCheckList =
      allChecklists && allChecklists?.filter?.((list) => !selectedCheckListIds?.includes?.(list._id));
    let checklistResult =
      filteredCheckList &&
      filteredCheckList.map((data) => {
        if ((AssetTypeOverview?.accountId || dropdownAccountId) === data.accountId) {
          finalChecklist.push({ _id: data._id, label: data.checklistName });
        }
      });
    setFinalUnassignedChecklist([...finalChecklist]);

    // checkForDataChange()
  }, [checklistData]);

  useEffect(() => {
    let newAssignedChecklist = assignedChecklists?.map?.((assignedChecklist) => {
      let foundChecklist = allChecklists?.filter?.(
        (currentChecklist) => currentChecklist?._id === assignedChecklist?.checklistId
      );

      if (assignedChecklist?.isDefaultChecklist) {
        setDefaultChecklist(assignedChecklist?.checklistId);
        setoriginalDefaultChecklist(assignedChecklist?.checklistId);
      }

      return {
        checklistName: foundChecklist[0]?.checklistName,
        checklistDescription: foundChecklist[0]?.checklistDescription,
        isDefaultChecklist: assignedChecklist?.isDefaultChecklist,
        _id: assignedChecklist?.checklistId,
      };
    });

    setchecklistData(newAssignedChecklist);
    setOriginalChecklistData([...newAssignedChecklist]);
  }, [allChecklists]);

  useEffect(() => {
    // console.log("CHECKLISTS", finalUnassignedChecklist)
    updateAddCheckListButtonAvailibility();
  }, [finalUnassignedChecklist]);

  useEffect(() => {
    getAllChecklist(
      {
        pageNo: 0,
        pageSize: 0,
        searchKey: "all",
      },
      true
    );
  }, []);

  useEffect(() => {
    // console.log("Checklist Data", checklistData)

    let apiChecklistData = checklistData?.map((checklist) => {
      return {
        isDefaultChecklist: checklist?._id === defaultChecklist,
        checklistId: checklist?._id,
      };
    });
    // if (apiChecklistData?.length === 0) {
    //     setChecklistAPIData({ applyToAll: isUpdateToAllAssetType })
    // } else {
    //console.log("SETTING CHECKLIST DATA", isSuperAdmin);
    if (isSuperAdmin) {
      setChecklistAPIData({
        accountId: dropdownAccountId,
        applyToAll: isUpdateToAllAssetType,
        checklists: apiChecklistData,
      });
    } else {
      setChecklistAPIData({ applyToAll: isUpdateToAllAssetType, checklists: apiChecklistData });
    }
    // }
  }, [checklistData, defaultChecklist, isUpdateToAllAssetType]);

  useEffect(() => {
    if (checklistAPIData?.length > 0) {
      // setAddAssetTypeModalData({
      //     ...addAssetTypeModalData,
      //     checklists: [
      //         ...checklistAPIData
      //     ]
      // })
      if (checklistData?.length === 1) {
        setDefaultChecklist(checklistData[0]?._id);
      }
    } else {
      // let newChecklistData = addAssetTypeModalData
      // delete newChecklistData?.checklists
      // setAddAssetTypeModalData({ ...newChecklistData })
    }
    //console.log("CHECKLIST : ", checklistAPIData);
  }, [checklistAPIData]);

  useEffect(() => {
    handleAddChecklist();
  }, [CurrentSelectedChecklist]);

  useEffect(() => {
    // console.log("ORIGINAL CHECKLIST DATA", originalChecklistData)
    // console.log("CHECKLIST DATA", checklistData)
  }, [originalChecklistData]);

  useEffect(() => {
    if (UpdateAssetTypeChecklistSuccess) {
      setIsEditEnabled(false);
      setOriginalChecklistData(checklistData);
      setoriginalDefaultChecklist(defaultChecklist);
    }
  }, [UpdateAssetTypeChecklistSuccess]);

  useEffect(() => {
    if (UpdateAssetTypeChecklistError) {
      setSnackBarStatus("FAILED");
    }
    if (UpdateAssetTypeChecklistSuccess) {
      setSnackBarStatus("SUCCESS");
    }
  }, [UpdateAssetTypeChecklistSuccess, UpdateAssetTypeChecklistError]);

  // console.log("Assigned Checklists", assignedChecklists)

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <Trans>
        Select this option when you want to update the checklists assigned on all your assets of this type
      </Trans>
    </Tooltip>
  );
  return (
    <>
      <div className="mb-2 assetType-checklist-height">
        <Card className="text-style border-0 shadow-none" style={{ width: "100%" }}>
          {/* When there are no checklist */}
          {checklistData?.length === 0 && !showAddChecklistLayout && (
            <>
              <div className="noSection_cD mt-4 mb-4 text-center">
                <p style={{ marginBottom: "0" }}>
                  <Trans>There is no checklist added to this asset</Trans>.
                </p>
                <p>
                  <Trans>Click '+ ADD CHECKLIST' to add a checklist</Trans>
                </p>
              </div>
            </>
          )}

          <>
            <div className={`${Styles.AssetOverviewTable}`}>
              <div>
                {(checklistData?.length > 0 || showAddChecklistLayout) && (
                  <table className="table grid  assetTablesz assetOverCheck add-asset-type-table">
                    <thead>
                      <tr>
                        <th>
                          <Trans>Checklist Name</Trans>
                        </th>
                        <th>
                          <Trans>Checklist Description</Trans>
                        </th>
                        <th>
                          <Trans>Default Checklist</Trans>
                        </th>
                        <th className="text-right">
                          <Trans>Action</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ overflowX: "hidden" }} ref={tableBodyContainer}>
                      {checklistData.map((checklist, i) => (
                        <>
                          <tr>
                            <td>
                              <p className="elip-span">
                                <span title={checklist?.checklistName}>{checklist?.checklistName}</span>
                              </p>
                            </td>
                            <td>
                              <p className="elip-span">
                                <span title={checklist?.checklistDescription}>
                                  {checklist?.checklistDescription}
                                </span>
                              </p>
                            </td>
                            <td>
                              <RadioButton
                                checked={defaultChecklist == checklist?._id}
                                onClick={() => {
                                  isEditEnabled && setDefaultChecklist(checklist?._id);
                                }}
                                size="small"
                                id={"id_" + i}
                              />
                            </td>
                            <td className="text-right">
                              <DeleteIconStyled
                                className="icon icon-delete"
                                onClick={() => {
                                  isEditEnabled && handleRemoveChecklist(checklist?._id);
                                }}
                                style={{
                                  cursor: `${isEditEnabled ? "pointer" : "initial"}`,
                                  color: `${isEditEnabled ? "#0D5FBE" : "#CCCCCC"}`,
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      ))}

                      <div ref={tableBodyContainer}>
                        <span></span>
                      </div>
                    </tbody>
                  </table>
                )}
              </div>
              <div className="add-asset-checklist-select">
                <div style={{ width: "99%", margin: "auto", marginTop: "-12px" }}>
                  {showAddChecklistLayout && (
                    <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <Col lg={6} className="selectBoxleft">
                        <Input
                          options={finalUnassignedChecklist}
                          type="select"
                          as="select"
                          className="selectBox"
                          label={"Checklist"}
                          value={CurrentSelectedChecklist}
                          onChange={(value) => {
                            setCurrentSelectedChecklist(value);
                          }}
                          required={true}
                          //inputRef = {accountRef}
                        />
                      </Col>

                      <Col lg={2} style={{ paddingleft: "0px" }} className="deleteBoxright text-right">
                        {/* <button
                                                        style={{ backgroundColor: "#0D5FBE" }}
                                                        className="buttonSave mb-1"
                                                        type="submit"
                                                        onClick={() => handleAddChecklist()}>
                                                        <Trans>ADD</Trans>
                                                    </button> */}
                        <DeleteIconStyled
                          className="icon icon-delete"
                          onClick={() => {
                            setShowAddChecklistLayout(false);
                          }}
                          style={{ cursor: "pointer", marginRight: "6px" }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </>
        </Card>
      </div>

      {/* Add Asset Type Modal Actions */}
      <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_TYPE_MANAGEMENT"} actionName={"edit"}>
        <div className={`${Styles.footerActions}, ${Styles.footerActionsAssetStatus}`}>
          <div>
            <Button
              disabled={!enableAddChecklistButton}
              label={t("Add Checklist")}
              onClick={() => {
                handleAddChecklistButton();
              }}
              iconClass="add"
              showIcon
              buttonType="secondary"
            />
          </div>
          <div className={`${Styles.rightSideActions}`}>
            <div>
              {isEditEnabled ? (
                UpdateAssetTypeChecklistLoading ? (
                  <>
                    <button className="buttonSave" type="submit" onClick={() => {}}>
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </button>
                  </>
                ) : (
                  <>
                    <div className="update-apply" style={{ display: "inline-block", marginRight: "5px" }}>
                      <Checkbox
                        label={t("Update checklists on all assets")}
                        checked={isUpdateToAllAssetType}
                        onClick={() => {
                          setIsUpdateToAllAssetType(!isUpdateToAllAssetType);
                        }}
                      />{" "}
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 350, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <img className="mr-2 h-4 ml-2 cursor-pointer" src={Information} />
                      </OverlayTrigger>
                    </div>

                    <Button
                      label={t("Cancel")}
                      onClick={() => {
                        handleCancelButton();
                      }}
                      showIcon={false}
                      buttonType="ghost"
                    />

                    <Button
                      label={
                        UpdateAssetTypeChecklistLoading ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          t("SAVE")
                        )
                      }
                      onClick={() => {
                        handleSaveAssetTypeChecklist();
                      }}
                      iconClass="save"
                      showIcon
                      buttonType="save"
                    />
                  </>
                )
              ) : (
                <div>
                  <Button
                    label={t("edit")}
                    onClick={() => {
                      setIsEditEnabled(true);
                    }}
                    iconClass="edit"
                    showIcon
                    buttonType="primary"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </PermissionCheck>

      <SnackBar
        isFailed={snackBarStatus === "FAILED"}
        isSuccess={snackBarStatus === "SUCCESS"}
        label={
          snackBarStatus === "SUCCESS" ? t(asset_type_checklist_success) : t(asset_type_checklist_failed)
        }
        handleClose={() => {
          setSnackBarStatus("");
          resetUpdateAssetTypeChecklistState();
        }}
      />

      <AssetTypeChecklistUpdateAllModal
        showModal={showUpdateAllModal}
        setShowModal={setShowUpdateAllModal}
        handleYes={() => {
          handleModalYesResponse();
        }}
      />

      <AssetTypeChecklistDeleteAllModal
        showModal={showDeleteAllChecklistModal}
        setShowModal={setShowDeleteAllChecklistModal}
        handleYes={() => {
          handleModalYesResponse();
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  allChecklists: state.checklist.checklists,
  assetOverview: state.assets.AssetOverview,
  editFormSecond: state.forms.editFormSecond,
  isSuperAdmin: state.user.isSuperAdmin,
  AssetTypeOverview: state.assets.AssetTypeOverview,
  UpdateAssetTypeChecklistLoading: state.assets.UpdateAssetTypeChecklistLoading,
  UpdateAssetTypeChecklistSuccess: state.assets.UpdateAssetTypeChecklistSuccess,
  UpdateAssetTypeChecklistError: state.assets.UpdateAssetTypeChecklistError,
  dropdownAccountId: state.dropdownFilters.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
  updateAssetTypeChecklist: (data) => dispatch(updateAssetTypeChecklist(data)),
  resetUpdateAssetTypeChecklistState: () => dispatch(resetUpdateAssetTypeChecklistState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetTypeChecklistsTab));
