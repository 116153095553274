import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { CircularProgress } from "@material-ui/core";
import Breadcrumb from "../../../../components/BreadCrumb.component";
import alertConstant from "../../../../constant/messages/toaster.json";
import validationConstant from "../../../../constant/messages/validation.json";
import { Trans, useTranslation } from "react-i18next";

//Redux
import { connect } from "react-redux";
import { postCustomer } from "../../../../redux/billing/action";
import { updatePlan } from "../../../../redux/user/user.action";
import SnackBar from "../../SnackBar";

const CheckoutForm = ({
  postCustomer,
  selectedPlan,
  loginDetails,
  customerLoading,
  updatePlan,
  setIsFailed,
}) => {
  const [IsLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    setIsLoading(true);
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setIsLoading(false);
      setIsFailed(true);
    } else {
      setIsLoading(false);
      let id = selectedPlan && selectedPlan;
      let name = loginDetails && loginDetails.userData && loginDetails.userData.fullName;
      let email = loginDetails && loginDetails.userData && loginDetails.userData.email;
      let accountID = loginDetails && loginDetails.accountId;
      let data = {
        email: email,
        name: name,
        plan: id,
        quantity: 1,
        coupon: "",
        payment_method: paymentMethod && paymentMethod.id,
        account: accountID,
      };
      postCustomer(data);
      updatePlan(id);
    }
  };

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#87bbfd" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        // color: "#ffc7ee",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_OPTIONS} />
      <button type="submit" disabled={!stripe}>
        {IsLoading || customerLoading ? <CircularProgress size={15} style={{ color: "#fff" }} /> : t("Pay")}
      </button>
    </form>
  );
};

const Payment = ({
  postCustomer,
  selectedPlan,
  loginDetails,
  customerSuccess,
  customerLoading,
  history,
  updatePlan,
}) => {
  const { t } = useTranslation();
  const [IsFailed, setIsFailed] = useState(false);
  useEffect(() => {
    if (customerSuccess) {
      history.push("/app/billing");
    }
  }, [customerSuccess]);

  const handleCloseSnackBar = () => {
    setIsFailed(false);
  };

  const handleMessage = (IsFailed) => {
    if (IsFailed) {
      return t(validationConstant.card_detail_invalid);
    }
  };

  return (
    <>
      <Breadcrumb />
      <div className="payment-cover">
        <p className="payment-details">
          <Trans>Please enter your credit card details below</Trans>
        </p>
        <CheckoutForm
          postCustomer={postCustomer}
          selectedPlan={selectedPlan}
          loginDetails={loginDetails}
          customerLoading={customerLoading}
          updatePlan={updatePlan}
          setIsFailed={setIsFailed}
        />{" "}
      </div>
      <SnackBar isFailed={IsFailed} label={handleMessage(IsFailed)} handleClose={handleCloseSnackBar} />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedPlan: state.billing.SelectedPlan,
  loginDetails: state.user.loginDetails,
  customerLoading: state.billing.CustomerLoading,
  customerSuccess: state.billing.CustomerSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  postCustomer: (data) => dispatch(postCustomer(data)),
  updatePlan: (data) => dispatch(updatePlan(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
