import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { login } from "../../../redux/user/user.action";
import validationConstant from "../../../constant/messages/validation.json";
import OTPInputs from "./OTPInputs.component";
import Button from "../../../components/Button/Button.component";
import SnackBar from "../../Dashboard/SnackBar";
import CountdownDate from "./CountdownDate.component";
import InputFieldWrapper from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import InputPasswordPin from "../../../components/InputFieldComponent/InputPasswordPin.component";
import { LoginContainer, LoginPasswordDiv, LockedOutMessage, ErrorContainer } from "./Login.component.style";
const baseUrl = require("../../../config").baseUrl; //Base Url
const OTP_FIELD_COUNT = 6;

// Login Form Component
function LoginForm({ history, login, companyUrl }) {
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [isOtpResendLoading, setIsOtpResendLoading] = useState(false);
  const [operationSuccess, setOperationSuccess] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [isTooManyRequests, setIsTooManyRequests] = useState(false);
  const [isTooManyResendRequests, setIsTooManyResendRequests] = useState(false);
  const [expiringAt, setExpiringAt] = useState(null);
  const [showNewOtp, setShowNewOtp] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  //const [wrongAttemptCounter, setWrongAttemptCounter] = useState(0);

  const { t } = useTranslation();

  // Setting Password State
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, password: event.target.value });
  };

  // Password Visibility Handler
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  // Setting User State with email and password
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const resetMFAFlags = () => {
    setIsTooManyRequests(false);
    setOtpErrorMessage(null);
    setIsOtpLoading(false);
    setShowOtpField(false);
    setShowNewOtp(false);
    setIsTooManyResendRequests(false);
    setToasterMessage("");
    setErrors({
      login: "",
      password: "",
    });
  };

  useEffect(() => {
    resetMFAFlags();
  }, [user.email]);

  // Setting Errors State with email and password
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  // Handle Submit upon Form Submission and redirect to Dashboard page with Enterprise URL

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    const isFormValid = validate();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setIsValidated(true);
      return;
    }
    setIsValidated(false);
    event.preventDefault();
    if (isFormValid) {
      const { email, password } = user;
      setIsLoading(true);
      axios
        .post(`${baseUrl}/user/login`, { email: email, password: password })
        .then((response) => {
          setIsLoading(false);
          if (response.data.success === true) {
            if (response.data?.data?.status === "OTP_GENERATED") {
              setShowOtpField(true);
            } else {
              login(response.data);
              history.push(`/app/dashboard`);
            }
          } else {
            /*  if (response.data?.data?.status === "WRONG_PASSWORD_ATTEMPT") {
              setWrongAttemptCounter(response?.data?.data?.count);
              setIsTooManyRequests(true);
              //setExpiringAt(response?.data?.data?.expiringAt);
            } else  */

            if (
              response.data?.data?.status === "TOO_MANY_ATTEMPTS" ||
              response.data?.data?.status === "TOO_MANY_PASSWORD_ATTEMPTS"
            ) {
              setIsTooManyRequests(true);
              setExpiringAt(response?.data?.data?.expiringAt);
            } else {
              setErrors({ login: validationConstant.login_error });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setErrors({ login: t("ERROR_EXECUTING_REQUEST") });
        });
    }
  };

  // Required Validations, Email Pattern Validations handler
  const validate = () => {
    let isValid = true;

    if (!user.password) {
      isValid = false;
      setErrors({ password: t(validationConstant.password_required) });
    }

    var pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    );
    if (!user.email || !pattern.test(user.email)) {
      isValid = false;
      setErrors({ email: t(validationConstant.invalid_email) });
    }

    return isValid;
  };

  const handleClose = () => {
    setOperationSuccess(false);
  };

  const resendOtp = () => {
    setOtpErrorMessage(null);
    setIsOtpResendLoading(true);
    axios
      .post(`${baseUrl}/user/resendOTP`, { email: user.email })
      .then((response) => {
        setIsOtpResendLoading(false);
        if (response.data?.success) {
          setShowNewOtp(false);
          setOperationSuccess(true);
          setOtpErrorMessage(null);
          setToasterMessage(response.data?.message);
        } else if (response?.data?.data?.status === "TOO_MANY_ATTEMPTS") {
          setIsTooManyRequests(true);
          setExpiringAt(response?.data?.data?.expiringAt);
        } else if (response?.data?.data?.status === "TOO_MANY_RESEND_ATTEMPTS") {
          setIsTooManyResendRequests(true);
          setExpiringAt(response?.data?.data?.expiringAt);
        } else if (response.data?.message) {
          setToasterMessage(response.data?.message);
        } else {
          setToasterMessage(t("ERROR_EXECUTING_REQUEST"));
        }
      })
      .catch((error) => {
        console.error("Error occurred", error);
        setOtpErrorMessage(t("TECHNICAL_DIFFICULTY_MESSAGE"));
        setIsOtpResendLoading(false);
      })
      .finally(() => {
        setIsOtpResendLoading(false);
      });
  };

  const submitOtp = (otp) => {
    setIsOtpLoading(true);
    axios
      .post(`${baseUrl}/user/verifyOTP`, { email: user.email, otp })
      .then((response) => {
        setIsTooManyResendRequests(false);
        setExpiringAt(null);

        if (response?.data?.success === true) {
          if (response?.data?.data?.status === "OTP_EXPIRED") {
            setOtpErrorMessage(response?.data?.message);
            setShowNewOtp(true);
          } else {
            setIsOtpLoading(false);
            login(response?.data);
            history.push(`/app/dashboard`);
          }
        } else {
          setIsOtpLoading(false);
          if (response?.data?.data?.status === "OTP_EXPIRED") {
            setOtpErrorMessage(response?.data?.message);
            setShowNewOtp(true);
          } else if (response?.data?.data?.status === "TOO_MANY_ATTEMPTS") {
            setIsTooManyRequests(true);
            setExpiringAt(response?.data?.data?.expiringAt);
          } else {
            setOtpErrorMessage(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.error("Error occurred", error);
        setOtpErrorMessage(t("TECHNICAL_DIFFICULTY_MESSAGE"));
        setIsOtpLoading(false);
      })
      .finally(() => {
        setIsOtpLoading(false);
      });
  };

  const dateExpiredCallback = () => {
    setIsTooManyRequests(false);
    setIsTooManyResendRequests(false);
    setExpiringAt(null);
    setErrors({ login: "" });
  };

  return (
    <LoginContainer>
      <div>
        {!showOtpField && (
          <>
            <Form onSubmit={handleSubmit} noValidate validated={isValidated}>
              <LoginPasswordDiv>
                <InputFieldWrapper
                  autoFocus={true}
                  placeholder={t("Email")}
                  minLength="1"
                  maxLength="64"
                  type="text"
                  size="medium"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  isError={!!errors.email}
                  value={user.email}
                  errorMessage={t(validationConstant.invalid_email)}
                />
                <InputPasswordPin
                  type={values.showPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  size="medium"
                  minLength="1"
                  maxLength="64"
                  onChange={handleChange("password")}
                  required={true}
                  errorMessage={t(validationConstant.password_required)}
                />
              </LoginPasswordDiv>

              {isTooManyRequests && expiringAt ? (
                <LockedOutMessage>
                  You have been locked out due to too many incorrect attempts. Please wait for
                  <CountdownDate
                    targetDate={new Date(expiringAt)?.getTime()}
                    dateExpiredCallback={dateExpiredCallback}
                  />
                  before trying again.
                </LockedOutMessage>
              ) : (
                <ErrorContainer>{errors ? t(errors.login) : ""}</ErrorContainer>
              )}

              {/* Submit Button */}
              <Button
                style={{ width: "100%" }}
                label={
                  isLoading ? (
                    <CircularProgress title="Login" size={15} style={{ color: "#fff" }} />
                  ) : (
                    t("Login")
                  )
                }
                disabled={isTooManyRequests || isTooManyResendRequests}
                showIcon={false}
                buttonType="primary"
              />
            </Form>
            <Button
              style={{ backgroundColor: "transparent", border: "0px", width: "100%", marginTop: "10px" }}
              onClick={() => {
                history.push(companyUrl ? `/${companyUrl}/forgotpassword` : `/forgotpassword`);
              }}
              label={t("Forgot Password")}
              showIcon={false}
              buttonType="ghost"
            />
          </>
        )}
        {showOtpField && (
          <OTPInputs
            fieldsCount={OTP_FIELD_COUNT}
            resendOtp={resendOtp}
            submitOtp={submitOtp}
            errorMessage={otpErrorMessage}
            setErrorMessage={setOtpErrorMessage}
            email={user.email}
            isOtpLoading={isOtpLoading}
            isOtpResendLoading={isOtpResendLoading}
            isTooManyRequests={isTooManyRequests}
            expiringAt={expiringAt}
            setIsTooManyRequests={setIsTooManyRequests}
            isTooManyResendRequests={isTooManyResendRequests}
            setIsTooManyResendRequests={setIsTooManyResendRequests}
            setExpiringAt={setExpiringAt}
            setShowOtpField={setShowOtpField}
            showNewOtp={showNewOtp}
            resetMFAFlags={resetMFAFlags}
          />
        )}
      </div>
      <SnackBar isSuccess={operationSuccess} label={toasterMessage} handleClose={handleClose} />
    </LoginContainer>
  );
}

// Dispatching action to the action creators
const mapDispatchToProps = (dispatch) => ({
  login: (userDetails) => dispatch(login(userDetails)),
});

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));
