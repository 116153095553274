import React, { useState } from "react";
import { Card } from "react-bootstrap";
import FlipCard from "../../../components/FlipCard";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import "./AccountUser.css";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import { MFAWrapper, ProfileDivStyled, SwitchWrapper } from "./UserProfile.styles";
import SimpleDropdownFilter from "../../../components/SimpleDropdownFilter.component";

export default function UserProfile() {
  const [face, setFace] = useState("front");
  return (
    <ProfileDivStyled>
      <div className="profile-div">
        <div className="left-profile-div">
          <div>
            <div>
              <div className="div-skew-style">
                <FlipCard
                  front={() => <EditProfile setFace={setFace} />}
                  back={() => <ChangePassword face={face} setFace={setFace} />}
                  currentFace={face}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="skew-div-style"></div>
      </div>
    </ProfileDivStyled>
  );
}
