/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  Enterprise Landing Page Header Styling
 */
import styled from "styled-components";

// Request a Demo Button Styling
export const Button = styled.button`
  background-color: #ffffff;
  width: 194px;
  margin: 10px 118px;
  border-radius: 4px;
  height: 40px;
  font-family: "Kanit";
  font-size: 16px;
  font-style: normal;
  color: #323232;
`;

export const NavbarStyledCss = styled.div`
  .stick-topNAV {
    background-image: linear-gradient(
      30deg,
      ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
      ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} 100%
    );
    box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    z-index: 1020;
    height: 10vh;
    @media screen and (max-width: 1100px) {
      .stick-topNAV {
        height: auto;
      }
    }
  }
  .navbar-nav .dropdown-menu {
    /* background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};*/
  }
`;
