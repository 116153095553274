import React from "react";

// Bootstrap
import { Navbar } from "react-bootstrap";

// Styled Components
import { CopyrightText } from "./Footer.styles";

import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Navbar for the Footer */}
      <Navbar
        className="footer-height"
        style={{
          justifyContent: "center",
          backgroundColor: "#E6E6E6",
          boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)",
        }}
        collapseOnSelect
        expand="lg"
      >
        {/* Siera Copyright Text on the Footer */}
        <CopyrightText>
          <Trans>Proudly Built in Austin, TX. Copyright </Trans>
          {new Date().getFullYear()}.&nbsp;
          <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
        </CopyrightText>
      </Navbar>
    </>
  );
};

export default Footer;
