/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA LANDING PAGE USER SELECTORS FOR SUBSCRIBING DATA ON COMPONENTS
 */

import { createSelector } from "reselect";

// Selecting the Current State from user reducer
const selectUser = (state) => state.user;

// 1. Selecting Login Details for token, accountId
export const selectLoginDetails = createSelector([selectUser], (user) => user.loginDetails);

// 1. Selecting User Details for name, other credentials
export const selectUserDetails = createSelector([selectUser], (user) => user.userDetails);
