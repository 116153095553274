import { assetManagementTypes } from "./asset.types";
import { baseUrl } from "../../../src/config";
import axios from "axios";
import { getAllAsset } from "../assets/action";
import { apiCall } from "../../utils/apiCall";
import { CacheRefs } from "../../utils/cache/CacheRefs";
// Selecting all asset
export const assetList = (asset) => ({
  type: assetManagementTypes.LIST_ASSET,
  payload: asset,
});

// Selected asset overview
const assetOverviewLoading = (assetId) => ({
  type: assetManagementTypes.ASSET_OVERVIEW_LOADING,
  payload: assetId,
});

const assetOverviewSuccess = (assetId) => ({
  type: assetManagementTypes.ASSET_OVERVIEW,
  payload: assetId,
});

export const assetOverview = (assetId) => {
  return async (dispatch) => {
    dispatch(assetOverviewLoading());
    if (assetId) {
      dispatch(assetOverviewSuccess(assetId));
    }
  };
};

// Action to add new asset in state
export const addAsset = (asset) => ({
  type: assetManagementTypes.ADD_ASSET,
  payload: asset,
});

// Selected default asset overview from the asset list
export const defaultAssetOverview = () => ({
  type: assetManagementTypes.DEFAULT_ASSET_OVERVIEW,
});

//To edit asset name
const toEditAssetNameLoading = () => ({
  type: assetManagementTypes.EDIT_ASSET_LOADING,
});

const toEditAssetNameSuccess = (data) => ({
  type: assetManagementTypes.EDIT_ASSET_SUCCESS,
  data,
});

const toEditAssetNameError = (err) => ({
  type: assetManagementTypes.EDIT_ASSET_ERROR,
  err,
});

//To add or edit asset image
const toAddAssetImageLoading = () => ({
  type: assetManagementTypes.ADD_ASSET_IMAGE_LOADING,
});

const toAddAssetImageSuccess = (data) => ({
  type: assetManagementTypes.ADD_ASSET_IMAGE_SUCCESS,
  data,
});

const toAddAssetImageError = (err) => ({
  type: assetManagementTypes.ADD_ASSET_IMAGE_ERROR,
  err,
});

//To edit asset name
export const toEditAssetName = (value, id) => {
  return async (dispatch, getState) => {
    dispatch(toEditAssetNameLoading());
    const data = {
      assetName: value,
    };
    try {
      const resp = await apiCall(`/asset/updateasset/${id}`, {
        method: "PUT",
        data: data,
      });
      console.log(resp);
      await CacheRefs.removeCacheForAssets();
      dispatch(toEditAssetNameSuccess({ data, id }));
    } catch (err) {
      dispatch(toEditAssetNameError(err));
      console.log(err);
    }
  };
};

//To add or edit asset image
export const toAddAssetImage = (value, id = 0) => {
  return async (dispatch, getState) => {
    dispatch(toAddAssetImageLoading());
    const formData = new FormData();
    formData.append("assetImg", value);

    try {
      const resp = await apiCall(`/asset/assetImg/${id}`, {
        method: "PUT",
        data: formData,
        type: "file",
      });
      //dispatch(getAllAsset({ pageNo: 1, pageSize: 20 }));
      await CacheRefs.removeCacheForAssets();
      dispatch(toAddAssetImageSuccess({ data: resp.data, id: id }));
    } catch (err) {
      dispatch(toAddAssetImageError(err));
      console.log(err);
    }
  };
};

export const resetState = () => ({
  type: assetManagementTypes.RESET_STATE,
});

export const updateCurrentAssetStatus = (data) => ({
  type: assetManagementTypes.UPDATE_ASSET_STATUS,
  data,
});
