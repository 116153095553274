import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import ChecklistTemplatesStyle from "./Templates/ChecklistTemplatesStyle.module.css";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import {
  ChecklistTabBarMenu,
  ChecklistTabSection,
  SectionnameChecklist,
  SectionChecklistTableWrapper,
  RowDiv,
  Column,
  RowsContainer,
} from "./ChecklistOverviewTab.styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
    boxShadow: "unset",
    borderBottom: "1px solid #ccc",
    fontSize: "30px",
  },
  labelWrapped: {
    fontSize: "0.875rem",
  },
}));

function ChecklistOverviewTab({ checklistOverview }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(0);
  }, [checklistOverview]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ChecklistTabBarMenu>
      {checklistOverview ? (
        <ChecklistTabSection>
          <AppBar position="static" color="default" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="ant example"
              className="tabpanelSize"
            >
              {checklistOverview &&
                checklistOverview.sections &&
                checklistOverview.sections.map((data, i) => (
                  <Tab
                    className={`buttontabpanels ${ChecklistTemplatesStyle.buttonTabElipses}`}
                    title={data.sectionName}
                    label={data.sectionName}
                    {...a11yProps(0)}
                  />
                ))}
            </Tabs>
          </AppBar>

          {checklistOverview.sections &&
            checklistOverview.sections.map((data, i) => (
              <TabPanel value={value} index={i} className="checklistTabPanel">
                <SectionnameChecklist>
                  <span className="headerColor" title={data.sectionName}>
                    {data.sectionName}
                  </span>
                </SectionnameChecklist>
                <SectionChecklistTableWrapper>
                  <RowDiv isHeader={true}>
                    <Column isHeader={true} align={"left"}>
                      <Trans>Question Details</Trans>
                    </Column>
                    <Column isHeader={true} align={"left"}>
                      <Trans>Response Type</Trans>
                    </Column>
                    <Column isHeader={true} align={"center"}>
                      <Trans>Enable Ignition</Trans>
                    </Column>
                    <Column isHeader={true} align={"center"}>
                      <Trans>Critical Question</Trans>
                    </Column>
                    <Column isHeader={true} align={"center"}>
                      <Trans>Fail Response</Trans>
                    </Column>
                    <Column isHeader={true} align={"center"}>
                      <Trans>Response Time</Trans>
                    </Column>
                  </RowDiv>

                  <RowsContainer>
                    {data.questions && data.questions[0]
                      ? data.questions.map((question, i) => (
                          <RowDiv key={i}>
                            <Column
                              align={"left"}
                              textleft={"left"}
                              widthResp={"75%"}
                              floatResp={"left"}
                              RmarginResp={"5%"}
                            >
                              <span title={question.questionText}>{question.questionText}</span>
                            </Column>
                            <Column
                              align={"left"}
                              textleft={"left"}
                              widthResp={"75%"}
                              floatResp={"left"}
                              RmarginResp={"5%"}
                            >
                              <span title={question.responseTypeId && question.responseTypeId.responseText}>
                                {question.responseTypeId && question.responseTypeId.responseText}
                              </span>
                            </Column>
                            <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                              <SwitchComponent checked={data.enableIgnition} disabled={true} />
                              {data.enableIgnition}
                            </Column>
                            <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                              <SwitchComponent
                                checked={question?.isQuestionMarkAsChecklistFail}
                                disabled={true}
                              />
                              {question?.isQuestionMarkAsChecklistFail}
                            </Column>
                            <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                              {question?.isQuestionMarkAsChecklistFail && question?.failedResponse}
                            </Column>
                            <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                              {data.sectionQuestionResponseTimeStatus &&
                                question.questionResponseTime + " seconds"}
                            </Column>
                          </RowDiv>
                        ))
                      : ""}
                  </RowsContainer>
                </SectionChecklistTableWrapper>
              </TabPanel>
            ))}
        </ChecklistTabSection>
      ) : (
        "Loading..."
      )}
    </ChecklistTabBarMenu>
  );
}

export default withRouter(ChecklistOverviewTab);
