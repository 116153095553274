import { connect } from "react-redux";
import { Trans } from "react-i18next";

import { NoAssetWrapper } from "./NoAssetFound.styles";

const NoSettingsDataFound = ({ type, isSuperAdmin, dropdownAccountId, settingsDropdownAccountId }) => {
  return (
    <>
      {(isSuperAdmin ? settingsDropdownAccountId : dropdownAccountId) ? (
        <NoAssetWrapper>
          <p>No {type || "Asset"} Found</p>
          <p>Please add {type === "User" ? "a user" : "an asset"} to view this setting</p>
        </NoAssetWrapper>
      ) : (
        <NoAssetWrapper>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  settingsDropdownAccountId: state.dropdownFilters?.settings?.accountId,
  dropdownAccountId: state.dropdownFilters?.accountId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NoSettingsDataFound);
