import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { languageConstant } from "../../../constant/i18n/language";
import { useTranslation, withTranslation } from "react-i18next";
import { changeLanguage } from "../../../redux/config/action";
import styled from "styled-components";
import { Trans } from "react-i18next";

const SpanText = styled.div`
  visibility: hidden;
  font-family: "Open Sans", sans-serif;
  position: relative;
  text-align: center;
  opacity: 0;
  color: rgb(255, 255, 255);
  transition: opacity 0.3s ease-in-out 0s;
  font-weight: 400;
  font-size: 12px !important;
`;

const IconWrapper = styled.div`
  width: 55px;
  &:hover ~ ${SpanText} {
    visibility: visible;
    opacity: 1;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    margin-top: -2px;
    font-size: 12px !important;
  }
  button {
    color: rgb(255, 255, 255);
    margin-top: 8px;
    padding: 0px 8px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 4px;
    :after {
      display: none;
    }
    :hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }
  }
`;

// Language Selector Component
const LanguageSelectorDropdown = ({ changeLanguage, selectedLanguage }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage && selectedLanguage.value);
  }, [selectedLanguage]);

  return (
    <>
      {/* React Flag Select
    <ReactFlagsSelect
      className="text-dark mt-0"
      defaultCountry="US"
      countries={["US", "GB", "CN", "IN", "ES"]}
      customLabels={{
        US: "EN",
        GB: "EN (Coming Soon)",
        CN: "MA (Coming Soon)",
        IN: "HI (Coming Soon)",
        ES: "ES (Coming Soon)",
      }}
      alignOptions="left"
      showSelectedLabel={false}
      selectedSize={34}
    /> */}
      <Dropdown>
        <IconWrapper>
          <Dropdown.Toggle value={0} variant="" id="dropdown-basic">
            <img
              style={{ height: "20px", paddingBottom: "3px" }}
              src={languageConstant[selectedLanguage.id].flag}
            />
          </Dropdown.Toggle>
        </IconWrapper>
        <SpanText>
          <Trans>Language</Trans>
        </SpanText>

        <Dropdown.Menu value={0}>
          {languageConstant.map((data, i) => (
            <Dropdown.Item onClick={() => changeLanguage(data)} id={data.id} key={i}>
              <img style={{ height: "30px" }} src={data.flag} /> {data.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLanguage: state.config.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (selectedLanguage) => dispatch(changeLanguage(selectedLanguage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectorDropdown);
