/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import SettingsSection from "../../../../components/SettingsSection";
import {
  getInspectionAlertSettingsByAssetId,
  updateInspectionAlertSettingsByAssetId,
  resetInspectionAlertSettingsByAssetId,
} from "../../../../redux/notification/action";
import { isEqual } from "../../../../utils/validations/utilities";
import { SettingWrapper } from "./NotificationSetting.component.styles";
import ActionsBar from "./ActionsBar";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, SettingsContainer, SettingsFooter } from "../Settings.Styles";
import SnackBar from "../../SnackBar";
import validationConstant from "../../../../constant/messages/validation.json";
import NoSettingsDataFound from "../common/NoAssetFound.component";
import { bulkSettingsUpdate, resetNotificationSettingsFlags } from "../../../../redux/config/action";
import { getSelectedOptions, handleRecipientsChangeUtil } from "./common";
import Recipients from "./emailerRecipients/Recipients";

const InspectionAlertSettings = ({
  notificationSetting,
  dropdownWorksiteList,
  getInspectionAlertSettingsByAssetId,
  updateInspectionAlertSettingsByAssetId,
  resetInspectionAlertSettingsByAssetId,
  loading,
  resetNotificationSettingsFlags,
  dropdownAssetId,
  bulkSettingsUpdate,
  notificationBulkUpdateSuccess,
  notificationUpdateLoading,
}) => {
  const [inspectionAlertSettings, setInspectionAlertSettings] = useState(null);
  const [worksiteId, setWorksiteId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getInspectionAlertSettingsByAssetId(selectedAssetId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  useEffect(() => {
    if (selectedAssetId) {
      getInspectionAlertSettingsByAssetId(selectedAssetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  const { t } = useTranslation();

  const setDefaultNotificationSettingsWorksite = () => {
    setClearSelection(true);
    setInspectionAlertSettings(notificationSetting?.inspectionAlertSettings);
  };

  const hideButtonsForWorksiteSettings = isEqual(
    notificationSetting?.inspectionAlertSettings,
    inspectionAlertSettings
  );

  useEffect(() => {
    if (notificationSetting) {
      setDefaultNotificationSettingsWorksite();
    }
  }, [notificationSetting.inspectionAlertSettings]);

  const handleInspectionAlertSettingsChange = (field, value) => {
    setInspectionAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const displaySettings = {
    inspectionAlertSettings: {
      // title: "InspectionAlerts",
      // description: "InspectionAlertsDescription",
      tableHeader: ["Inspection Result", "Alert"],
      onChange: handleInspectionAlertSettingsChange,
      tableData: [
        {
          label: "Pass",
          value: !!inspectionAlertSettings?.pass,
          field: "pass",
          icon: "icon-dot",
          color: "#11A70B",
        },
        {
          label: "Fail",
          value: !!inspectionAlertSettings?.fail,
          field: "fail",
          icon: "icon-dot",
          color: "#DD0000",
        },
        {
          label: "Timed out",
          value: !!inspectionAlertSettings?.timedout,
          field: "timedout",
          icon: "icon-dot",
          color: "#323232",
        },
      ],
    },
  };

  const handleAssetIdFilter = (assetId, worksiteId, assetTypeId) => {
    setSelectedAssetId(assetId);
    //setDropdownWorksiteId(worksiteId);
  };

  const onUpdate = () => {
    if (Object.keys(applyToAllObj).length !== 0) {
      const selectedOptions = getSelectedOptions(applyToAllObj);

      const updatableSettings = {
        ...selectedOptions,
        settings: { notificationSettings: { inspectionAlertSettings } },
      };

      bulkSettingsUpdate(updatableSettings);
    }
    updateInspectionAlertSettingsByAssetId(
      selectedAssetId,
      {
        notificationSettings: {
          inspectionAlertSettings,
        },
      },
      setIsUpdateLoading
    );
  };

  const onReset = () => {
    resetInspectionAlertSettingsByAssetId(selectedAssetId, setIsUpdateLoading);
  };

  const handleCloseSnackBar = () => {
    setApplyToAllObj({});
    resetNotificationSettingsFlags();
    setIsUpdateLoading(false);
    setCheckApplyToAll(false);
  };

  const handleRecipientsChange = (userType, isChecked) => {
    handleRecipientsChangeUtil(userType, isChecked, inspectionAlertSettings, setInspectionAlertSettings);
  };

  const handleCustomEmailsChange = (email) => {
    const temp = {
      ...inspectionAlertSettings,
      customEmails: [...email],
    };
    setInspectionAlertSettings(temp);
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("An alert will be sent when the selected inspection result generates on an asset.")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />
      {dropdownAssetId && typeof dropdownAssetId === "boolean" && dropdownAssetId === true ? (
        <NoSettingsDataFound />
      ) : (
        <>
          <SettingsContainer>
            <SettingWrapper>
              <SettingsSection
                {...displaySettings.inspectionAlertSettings}
                isLoading={notificationUpdateLoading || loading}
              />
              {selectedAssetId && (
                <Recipients
                  customEmails={inspectionAlertSettings?.customEmails}
                  handleCustomEmailsChange={handleCustomEmailsChange}
                  recipients={inspectionAlertSettings?.userTypes}
                  handleRecipientsChange={handleRecipientsChange}
                />
              )}
            </SettingWrapper>
          </SettingsContainer>
          <SettingsFooter>
            <ActionsBar
              dropdownWorksiteList={dropdownWorksiteList}
              /* setDropdownWorksiteId={setDropdownWorksiteId} */
              setWorksiteId={setWorksiteId}
              showAppyToAll={true}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleUpdate={onUpdate}
              handleCancel={setDefaultNotificationSettingsWorksite}
              isLoading={loading}
              worksiteId={worksiteId}
              showWorksiteSelection={false}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={checkApplyToAll}
              setCheckApplyToAll={setCheckApplyToAll}
              handleReset={onReset}
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </SettingsFooter>
          <SnackBar
            isSuccess={notificationBulkUpdateSuccess || isUpdateLoading}
            label={t(validationConstant.notification_settings_updated)}
            handleClose={handleCloseSnackBar}
          />
        </>
      )}
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    accountList: state.accounts.Accounts,
    dropdownWorksiteList: state.dropdownFilters.worksiteList,
    notificationSetting: state.notificationSetting,
    loading: state.notificationSetting.loading,
    dropdownAssetId: state.dropdownFilters?.settings?.assetId,
    notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
    notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInspectionAlertSettingsByAssetId: (assetId) => dispatch(getInspectionAlertSettingsByAssetId(assetId)),
  updateInspectionAlertSettingsByAssetId: (assetId, data, setIsUpdateLoading) =>
    dispatch(updateInspectionAlertSettingsByAssetId(assetId, data, setIsUpdateLoading)),
  resetInspectionAlertSettingsByAssetId: (assetId, setIsUpdateLoading) =>
    dispatch(resetInspectionAlertSettingsByAssetId(assetId, setIsUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
  bulkSettingsUpdate: (data, setIsUpdateLoading) => dispatch(bulkSettingsUpdate(data, setIsUpdateLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionAlertSettings);
