import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { SaveAltOutlined } from "@material-ui/icons";
import { updateUser } from "../../../redux/user/user.action";
import { apiCall } from "../../../utils/apiCall";
import toasterConstant from "../../../constant/messages/toaster.json";
import { toValidatePassword, toValidateConfirmPassword } from "../../../utils/validations/checkValidation";
import Input from "../../../components/Input";
import useAlert from "../../../utils/useAlert";

import {
  ChangePasswordContainer,
  ChangePasswordTop,
  ChangePassHead,
  ChangePassDetail,
  ChangePasswordSection,
  ChangePasswordSectionDiv,
  ButtonContainerStyle,
  ButtonContainerDiv,
} from "./ChangePassword.styles";
// Button Component
import Button from "../../../components/Button/Button.component";

// Input Component
import InputField from "../../../components/InputFieldComponent/InputField.component";

const { password_update, password_update_error, current_password_error } = toasterConstant;

function ChangePassword(props) {
  const { user, token, face, setFace } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordForm, setPasswordForm] = useState({});
  const [validated, setValidated] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    setPasswordForm({
      currentPassword: "",
      newPassword: "",
      verifyPassword: "",
    });
    setValidated(false);
    setCurrentPasswordError("");
  }, [face]);

  const submit = (e) => {
    e.preventDefault();
    setCurrentPasswordError("");
    setValidated(true);
    const isInvalidPassword = toValidatePassword(passwordForm.newPassword);
    const isInvalidVerifyPassword = toValidateConfirmPassword(
      passwordForm.newPassword,
      passwordForm.verifyPassword
    );
    if (isInvalidPassword || isInvalidVerifyPassword) {
      e.preventDefault();
      e.stopPropagation();
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    apiCall(`/user/password/profile/change`, {
      method: "POST",
      url: "",
      data: { ...passwordForm, userId: user._id },
    })
      .then((data) => {
        setIsLoading(false);
        if (data?.data.success) {
          setCurrentPasswordError("");
          showAlert("success", t(password_update));
          setFace("front");
        } else {
          setCurrentPasswordError(t(data?.data.txt || data?.data.message || current_password_error));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showAlert("error", err?.response?.message ?? t(password_update_error));
      });
  };

  const isSaveDisabled = () => {
    const { currentPassword, newPassword, verifyPassword } = passwordForm;
    return !currentPassword || !newPassword || !verifyPassword;
  };

  return (
    <Form noValidate id="changeCurrentPassowrdForm" onSubmit={submit} className="no-flex">
      <ChangePasswordContainer>
        <ChangePasswordTop>
          <ChangePassHead>
            {" "}
            <Trans>Password Reset</Trans>
          </ChangePassHead>
          <ChangePassDetail>
            <Trans>Password Rules</Trans>
          </ChangePassDetail>
        </ChangePasswordTop>

        <ChangePasswordSection>
          <ChangePasswordSectionDiv>
            <InputField
              required
              label="Current Password"
              name={"currentPassword"}
              minLength={"8"}
              type="password"
              placeholder={t("Enter your current password")}
              value={passwordForm.currentPassword}
              onChange={(e) =>
                setPasswordForm({
                  ...passwordForm,
                  currentPassword: e.target.value,
                })
              }
              isError={currentPasswordError}
              errorMessage={currentPasswordError}
              // iconNameRight="eye-slash"
              // showIconRight
            />
          </ChangePasswordSectionDiv>
          <ChangePasswordSectionDiv>
            <InputField
              required
              label="New Password"
              name={"password"}
              minLength={"8"}
              type="password"
              placeholder={t("Enter your new password")}
              value={passwordForm.newPassword}
              onChange={(e) =>
                setPasswordForm({
                  ...passwordForm,
                  newPassword: e.target.value,
                })
              }
              isError={validated && toValidatePassword(passwordForm.newPassword)}
              errorMessage={validated && toValidatePassword(passwordForm.newPassword)}
            />
          </ChangePasswordSectionDiv>
          <ChangePasswordSectionDiv>
            <InputField
              name="passwordVerification"
              label="Confirm Password"
              required
              minLength={"8"}
              type="password"
              placeholder={t("Confirm your new password")}
              autocomplete="new-password"
              value={passwordForm.verifyPassword}
              onChange={(e) =>
                setPasswordForm({
                  ...passwordForm,
                  verifyPassword: e.target.value,
                })
              }
              isInvalid={
                validated && toValidateConfirmPassword(passwordForm.newPassword, passwordForm.verifyPassword)
              }
              feedback={
                validated && toValidateConfirmPassword(passwordForm.newPassword, passwordForm.verifyPassword)
              }
            />
          </ChangePasswordSectionDiv>
        </ChangePasswordSection>

        <ButtonContainerStyle>
          <ButtonContainerDiv>
            <Button
              label={t("save")}
              form="changeCurrentPassowrdForm"
              iconClass="save"
              disabled={isLoading || isSaveDisabled()}
              showIcon
              buttonType="save"
            />
          </ButtonContainerDiv>
          <ButtonContainerDiv>
            <Button
              label={t("Cancel")}
              onClick={() => setFace("front")}
              showIcon={false}
              buttonType="ghost"
            />
          </ButtonContainerDiv>
        </ButtonContainerStyle>
      </ChangePasswordContainer>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state?.user?.loginDetails?.userData,
    token: state?.user?.loginDetails?.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
