import styled from "styled-components";
export const AssetTypeDetailsWrapper = styled.div`
  width: 95%;
  margin: auto;
  .fontFamilyHeader span.sortable[title="Field Name"] {
    padding-left: 8px;
  }
  .enterTitle {
    margin-left: 8px;
    display: block !important;
  }
  .rowHeight {
    margin-bottom: 20px;
  }
`;
export const DeleteIconStyled = styled.div`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  cursor: pointer;
  padding-top: 8px;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  padding-top: 8px;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
`;
