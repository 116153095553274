import React, { useState } from "react";
import ImpactTableWrapper from "../../../../../components/DataTableComponent/DataTable.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { toDownloadFiles } from "../../../../../redux/config/action";

import PopoverCustom from "../../../../../components/PopoverCustom.component";
import ReportDownload from "../../../Report/ReportShare/ReportShareModal.component";
import { getTimeWithTimeZone } from "../../../../../utils/timezoneConvert";

import awsConstant from "../../../../../constant/awsFolder.json";

import { ImpactSeverty, ImpactGDiv, NoReportsDiv } from "./IncidentDashboard.component.styles";

const ImpactTable = ({
  data,
  loginDetails,
  isSiteAdmin,
  toDownloadFiles,
  fileDownloadLoading,
  isSingleAccountMultiSiteUser,
  setIsSuccess,
  setIsError,
}) => {
  const tableHeader = [
    { name: "Account", space: 2, feildName: "accountName" },
    { name: "Worksite", space: 1, feildName: "worksite" },
    { name: "Incident By", space: 1, feildName: "assetId" },
    { name: "Asset Name", space: 1, feildName: "assetName" },
    { name: "Date, Time", space: 1, feildName: "createdAt" },
    { name: "Impact (g)", space: 1, feildName: "impactG" },
    { name: "Severity", space: 1, feildName: "impactSeverity" },
    { name: "Actions", space: 2, feildName: "inspectionCountMonthly" },
  ];

  const [loadingReportId, setLoadingReportId] = useState("");

  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.impactReport, `${id}.pdf`);
  };

  const viewReportClickHandler = (e, row) => {
    localStorage.setItem("unitType", loginDetails?.unitType);
    if (
      row?.impactSeverity?.toLowerCase?.() === "low" ||
      row?.impactSeverity?.toLowerCase?.() === "medium" ||
      row?.impactSeverity?.toLowerCase?.() === "high"
    ) {
      localStorage.setItem("impactReportObject", JSON.stringify(row));
      window.open("/#/app/impact-report", "_blank");
    } else {
      localStorage.setItem("nearMissReportObject", JSON.stringify(row));
      window.open("/#/app/near-miss-report", "_blank");
    }
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i title="View" onClick={(e) => viewReportClickHandler(e, row)} className="icon icon-view"></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
            ></i>

            {/* <i className="icon icon-share"></i> */}
            <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 2,
                        reportId: data?._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>
          </div>
        )}
      </>
    );
  };

  const columns = tableHeader
    .filter(
      (data) =>
        !(
          (isSingleAccountMultiSiteUser && data.name === "Account") ||
          (isSiteAdmin && (data.name === "Worksite" || data.name === "Account"))
        )
    )

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: data.name,
          selector: (row) => row?.companyName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Worksite") {
        return {
          name: data.name,
          selector: (row) => row?.siteName || "NA",
          sortable: true,
        };
      }

      if (data.name == "Incident By") {
        return {
          name: data.name,
          selector: (row) => row?.fullName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Name") {
        return {
          name: data.name,
          selector: (row) => row?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Date, Time") {
        return {
          name: data.name,
          selector: (row) => {
            const impactDate = row?.createdAt;
            const timeZone = row?.siteId?.timeZone || "NA";
            const formattedDate = !impactDate
              ? "NA"
              : `${moment(impactDate)
                  .utcOffset(timeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm A")} ${getTimeWithTimeZone(timeZone)}`;

            return <span title={!data.isDate ? impactDate : formattedDate}>{formattedDate}&nbsp;</span>;
          },
          sortable: true,
        };
      }

      //   if (data.name === "Impact (g)") {
      //     return {
      //       name: data.name,
      //       selector: (row) => row?.impactG || "NA",
      //       sortable: true,
      //     };
      //   }

      if (data.name === "Impact (g)") {
        return {
          name: data.name,
          centered: true,
          selector: (row) => {
            const impactG = row?.impactG || "NA";
            return (
              <ImpactGDiv title={impactG} impactG={impactG}>
                {impactG}
              </ImpactGDiv>
            );
          },
          sortable: true,
        };
      }

      if (data.name === "Severity") {
        return {
          name: data.name,
          selector: (row) => {
            const severity = row?.impactSeverity || "NA";
            return (
              <ImpactSeverty title={severity} severity={severity}>
                {severity}
              </ImpactSeverty>
            );
          },
          sortable: true,
        };
      }

      if (data.name === "Actions") {
        return {
          name: data.name,
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      return {
        name: data.name,
        selector: (row) => row[data.feildName],
        sortable: true,
      };
    });
  // columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Incident By", className: "inspectedByStyle" },
    { columnName: "Asset Name", className: "assetNameStyle" },
  ];

  const mobileCheckboxAccordion = ["Asset Name", "Impact (g)", "Severity"];

  if (!Array.isArray(data)) {
    return <NoReportsDiv>No Incident Reports Available</NoReportsDiv>;
  }

  return (
    <>
      <ImpactTableWrapper
        title=""
        columns={columns}
        data={data}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordion={mobileCheckboxAccordion}
        responsive
        dense
        loading
        rowHeight={"565"}
        scrollable={true}
        fixedHeader
        mobileCheckboxOnly
        mobileDateTimeOnly
        mobileActionItemOnly
        noDataMessage="No Incident Reports Available"
        rowsPerPageOptions={[5]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isSiteAdmin: state.user.isSiteAdmin,
  isAccountViewer: state.user.isAccountViewer,

  fileDownloadLoading: state.config.fileDownloadLoading,

  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImpactTable));
