import styled from "styled-components";
export const UserTypeOverviewWrapper = styled.div`
  margin: auto;
  .userTypeEdit {
    margin: 10px 20px auto;
  }
`;

export const HeaderTitle = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} 100%
  );
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  line-height: 45px;
  font-size: 22px !important;
  border-radius: 4px;
  padding: 0px 15px;
  text-align: left;
`;

export const BodyDetails = styled.div`
  width: 100%;
  margin: 20px auto;
  .userTypeDetailsHeight {
    height: auto;
  }
`;

export const BodyFooterButton = styled.div`
  margin: 20px auto;
  overflow: auto;
`;
export const ButtonPosition = styled.div`
  float: right;
`;
export const BodyDetilsDiv = styled.div`
  margin: auto;
  width: 95%;
`;

export const ModalBodyWrapper = styled.div`
  padding: 0 15px;
  .userTypeDetailsHeight {
    overflow-y: auto;
    height: calc(100vh - 585px);
  }
`;

export const AccessLevelsText = styled.div`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  border-left: 2px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  padding-left: 5px;
  ul {
    margin: 0;
    padding-left: 10px;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    text-indent: -5px;
    margin-right: 10px;
    display: flex;
  }
  ul.dashed > li:before {
    content: "---";
    text-indent: -5px;
    margin-right: 15px;
    letter-spacing: -2px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
`;

export const AccessLevelHeading = styled.p`
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const AccessLevelDescription = styled.p`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const DivSlideButton = styled.div`
  width: 100%;
  display: block;
  float: left;
  margin-bottom: 10px;
`;


