import React from "react";
import { Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import InspectionsPeformed from "../../../assets/AddIcon/Gray 2_Inspections.svg";
import InspectionsPassed from "../../../assets/AddIcon/Gray 2_Inspections_Pass.svg";
import InspectionsFailed from "../../../assets/AddIcon/Gray 2_Inspections_Fail.svg";
import { Trans, useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  CarouselWrapperDiv,
  InspectionPerformedIcon,
  InspectionPassedIcon,
  InspectionFailedIcon,
} from "./UserMultiCard.component.styles";

const responsive_b = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const UserMultiCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <CarouselWrapperDiv>
      <Carousel responsive={responsive_b} infinite={true} className="shadow-sm-cra borderCar user_carusel">
        <div className="fexDiv">
          <Col className="count-card">
            <span className="name-carousel">
              <Trans>Inspections Performed</Trans>
            </span>
            <div className="img-carousel">
              <InspectionPerformedIcon className="icon icon-inspections-performed">
                {
                  <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                    {data?.totalInspection ? data?.totalInspection : 0}
                  </span>
                }
              </InspectionPerformedIcon>
            </div>
          </Col>
        </div>

        <div className="fexDiv">
          <Col className="count-card">
            <span className="name-carousel">
              <Trans>Inspections Passed</Trans>
            </span>

            <div className="img-carousel">
              <InspectionPassedIcon className="icon icon-inspection-passed">
                {
                  <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                    {data?.PassInspection ? data.PassInspection : 0}
                  </span>
                }
              </InspectionPassedIcon>
            </div>
          </Col>
        </div>

        <div className="fexDiv">
          <Col className="count-card">
            <span className="name-carousel">
              <Trans>Inspections Failed</Trans>
            </span>
            <div className="img-carousel">
              <InspectionFailedIcon className="icon icon-inspection-failed">
                {
                  <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                    {data?.FailedInspection ? data.FailedInspection : 0}
                  </span>
                }
              </InspectionFailedIcon>
            </div>
          </Col>
        </div>

        <div className="fexDiv">
          <Col className="count-card">
            <span className="name-carousel">
              <Trans>Inspections Timed Out</Trans>
            </span>
            <div className="img-carousel">
              <InspectionFailedIcon className="icon icon-inspection-timed-out">
                {
                  <span className="h3" style={{ verticalAlign: "middle", color: "#323232" }}>
                    {data?.TimedoutInspection ? data.TimedoutInspection : 0}
                  </span>
                }
              </InspectionFailedIcon>
            </div>
          </Col>
        </div>
      </Carousel>
    </CarouselWrapperDiv>
  );
};

export default UserMultiCard;
