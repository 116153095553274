import React from "react";
import ViewAssetTypeOverview from "./ViewAssetTypeOverview.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import AccountNameFilter from "../../../components/DropdownFilter.component";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import { useTranslation } from "react-i18next";

const ViewAssetTypes = ({ isSuperAdmin, dropdownAccountList, setDropdownAccountId, dropdownAccountId }) => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}>
        {isSuperAdmin && (
          <Row>
            <Col md={4}>
              <AccountNameFilter
                filter={{
                  type: "Account",
                  key: "companyName",
                  data: dropdownAccountList,
                }}
                label={t("filter")}
                handleFilter={(value) => setDropdownAccountId(value)}
                value={dropdownAccountId}
              />
            </Col>
          </Row>
        )}
        <ViewAssetTypeOverview />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypes));
