import styled from "styled-components";

export const ProfileDivStyled = styled.div`
  .tabpanelSize .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .MuiTab-textColorPrimary,
  .tabpanelSize .MuiTab-textColorPrimary.Mui-selected {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .profile-div {
  }
  .front,
  .back,
  .skew-div-style {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
`;
