import React from "react";
import { Col } from "react-bootstrap";
import WorksiteSvg from "../../../assets/AddIcon/worksites.svg";
import AssetSvg from "../../../assets/AddIcon/assets.svg";
import UserSvg from "../../../assets/AddIcon/users.svg";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCount } from "../../../redux/count/count.selector";
import Carousel from "react-multi-carousel";
import { Trans, useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import {
  CarouselWrapperDiv,
  WorksiteIconconDiv,
  AssetIconconDiv,
  UserIconconDiv,
} from "./WorksiteCard.component.styles";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const styles = {
  vertical: {
    borderLeft: "1px solid #C6C6C6",
    position: "relative",
    height: "44px",
    top: "7px",
  },
};
const WorksiteCard = ({ selectWorksiteOverview, count }) => {
  const { t } = useTranslation();
  return (
    <>
      <CarouselWrapperDiv>
        <Carousel responsive={responsive} className="shadow-sm borderCar">
          <div className="fexDiv">
            <Col className="count-card">
              <Trans>Worksites</Trans>
              <br />
              <WorksiteIconconDiv className="icon icon-worksites">
                <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                  {count?.sites || 0}
                </span>
              </WorksiteIconconDiv>
            </Col>
            <div style={styles.vertical} className="borderLeftStyle"></div>
          </div>
          <div className="fexDiv">
            <Col className="count-card">
              <Trans>Assets</Trans>
              <br />
              <AssetIconconDiv className="icon icon-assets">
                <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                  {count.assets || 0}
                </span>
              </AssetIconconDiv>
            </Col>
            <div style={styles.vertical} className="borderLeftStyle"></div>
          </div>
          <div className="fexDiv">
            <Col className="count-card">
              <Trans>Users</Trans>
              <br />
              <UserIconconDiv className="icon icon-users">
                <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                  {count.users || 0}
                </span>
              </UserIconconDiv>
            </Col>
            <div style={styles.vertical} className="borderLeftStyle"></div>
          </div>
        </Carousel>
      </CarouselWrapperDiv>
    </>
  );
};

const mapStateToProps = (state) => ({
  count: state.counts.allCount,
});

export default connect(mapStateToProps, null)(WorksiteCard);
