import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { camPositionToCamNumber } from "../constants";

import SwitchComponent from "../../../../../components/Switchbox/SwitchComponent";
import {
  SettingTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  SettingContent,
  SpanHeadStyle,
  SpanDescStyle,
  CameraPositionDiv,
  OnOffText,
  SwitchWrapper,
} from "./CameraPreferences.styles";

function CameraPreferences({
  cameraPosition,
  setSwitchStatus,
  selectedCamera,
  switchStatusObject,
  isSystemSettingsEditable,
}) {
  const [cameraPreference, setCameraPreference] = useState(switchStatusObject[selectedCamera]);

  useEffect(() => {
    setCameraPreference(switchStatusObject[selectedCamera]);
  }, [switchStatusObject, selectedCamera]);

  const cameraPreferenceObj = cameraPreference?.object;

  const updateSwitchStatus = (currentCamSwitchStatus) => {
    switchStatusObject[selectedCamera] = currentCamSwitchStatus;
    setSwitchStatus({ ...switchStatusObject });
  };

  return (
    <SettingTableDescription>
      <CameraPositionDiv>
        <span className="camera-num-span text-kanit">Camera {camPositionToCamNumber[selectedCamera]}</span>
        <span className="span-cam-position text-opan-sans">{selectedCamera}</span>
      </CameraPositionDiv>
      <RowsContainer>
        <RowDiv>
          <Column align={"left"} textleft={"left"}>
            <SettingContent>
              <SpanHeadStyle>
                <Trans>Object Detections</Trans>
              </SpanHeadStyle>

              <SpanDescStyle>
                <Trans>Enable/disable detections on this camera based on vehicle's motion</Trans>
              </SpanDescStyle>
            </SettingContent>
          </Column>
          <Column align={"right"} isHeader textleft={"right"}>
            <SwitchWrapper>
              <OnOffText>
                {cameraPreferenceObj?.inMotion || cameraPreferenceObj?.inStationary ? "On" : "Off"}
              </OnOffText>
              <SwitchComponent
                disabled={!isSystemSettingsEditable}
                id={"_id"}
                checked={cameraPreferenceObj?.inMotion || cameraPreferenceObj?.inStationary}
                onChange={() =>
                  updateSwitchStatus({
                    ...cameraPreference,
                    object: {
                      inStationary: false,
                      inMotion: !(cameraPreferenceObj?.inMotion || cameraPreferenceObj?.inStationary),
                    },
                  })
                }
              />
            </SwitchWrapper>
          </Column>
        </RowDiv>
        <hr />
        <RowDiv>
          <Column align={"left"} textleft={"left"}>
            <SettingContent>
              <span className="heading-name">
                <Trans>When in motion</Trans>
              </span>
            </SettingContent>
          </Column>
          <Column align={"right"} isHeader textleft={"right"}>
            <SwitchWrapper>
              <OnOffText>{cameraPreferenceObj?.inMotion ? "On" : "Off"}</OnOffText>
              <SwitchComponent
                disabled={!isSystemSettingsEditable}
                id={"_id"}
                checked={cameraPreferenceObj?.inMotion}
                onChange={() =>
                  updateSwitchStatus({
                    ...cameraPreference,
                    object: {
                      ...cameraPreferenceObj,
                      inMotion: !cameraPreferenceObj?.inMotion,
                    },
                  })
                }
              />
            </SwitchWrapper>
          </Column>
        </RowDiv>
        <hr />
        <RowDiv>
          <Column align={"left"} textleft={"left"}>
            <SettingContent>
              <span className="heading-name">
                <Trans>When stationary</Trans>
              </span>
            </SettingContent>
          </Column>
          <Column align={"right"} isHeader textleft={"right"}>
            <SwitchWrapper>
              <OnOffText>{cameraPreference?.object?.inStationary ? "On" : "Off"}</OnOffText>
              <SwitchComponent
                disabled={!isSystemSettingsEditable}
                id={"_id"}
                checked={cameraPreference?.object?.inStationary}
                onChange={() =>
                  updateSwitchStatus({
                    ...cameraPreference,
                    object: {
                      ...cameraPreferenceObj,
                      inStationary: !cameraPreferenceObj?.inStationary,
                    },
                  })
                }
              />
            </SwitchWrapper>
          </Column>
        </RowDiv>
        <br />
        <RowDiv>
          <Column align={"left"} textleft={"left"}>
            <SettingContent>
              <SpanHeadStyle>
                <Trans>Pedestrian Detections</Trans>
              </SpanHeadStyle>

              <SpanDescStyle>
                <Trans>Enable/disable detections on this camera based on vehicle's motion</Trans>
              </SpanDescStyle>
            </SettingContent>
          </Column>
          <Column align={"right"} isHeader textleft={"right"}>
            <SwitchWrapper>
              <OnOffText>
                {cameraPreference?.pedestrian?.inMotion || cameraPreference?.pedestrian?.inStationary
                  ? "On"
                  : "Off"}
              </OnOffText>
              <SwitchComponent
                disabled={!isSystemSettingsEditable}
                id={"_id"}
                checked={cameraPreference?.pedestrian?.inMotion || cameraPreference?.pedestrian?.inStationary}
                onChange={() =>
                  updateSwitchStatus({
                    ...cameraPreference,
                    pedestrian: {
                      inStationary: false,
                      inMotion: !(
                        cameraPreference?.pedestrian?.inMotion || cameraPreference?.pedestrian?.inStationary
                      ),
                    },
                  })
                }
              />
            </SwitchWrapper>
          </Column>
        </RowDiv>
        <hr />
        <RowDiv>
          <Column align={"left"} textleft={"left"}>
            <SettingContent>
              <span className="heading-name">
                <Trans>When in motion</Trans>
              </span>
            </SettingContent>
          </Column>
          <Column align={"right"} textleft={"right"}>
            <SwitchWrapper>
              <OnOffText>{cameraPreference?.pedestrian?.inMotion ? "On" : "Off"}</OnOffText>
              <SwitchComponent
                disabled={!isSystemSettingsEditable}
                id={"_id"}
                checked={cameraPreference?.pedestrian?.inMotion}
                onChange={() =>
                  updateSwitchStatus({
                    ...cameraPreference,
                    pedestrian: {
                      ...cameraPreference.pedestrian,
                      inMotion: !cameraPreference?.pedestrian?.inMotion,
                    },
                  })
                }
              />
            </SwitchWrapper>
          </Column>
        </RowDiv>
        <hr />
        <RowDiv>
          <Column align={"left"} textleft={"left"}>
            <SettingContent>
              <span className="heading-name">
                <Trans>When stationary</Trans>
              </span>
            </SettingContent>
          </Column>
          <Column align={"right"} textleft={"right"}>
            <SwitchWrapper>
              <OnOffText>{cameraPreference?.pedestrian?.inStationary ? "On" : "Off"}</OnOffText>
              <SwitchComponent
                disabled={!isSystemSettingsEditable}
                id={"_id"}
                checked={cameraPreference?.pedestrian?.inStationary}
                onChange={() =>
                  updateSwitchStatus({
                    ...cameraPreference,
                    pedestrian: {
                      ...cameraPreference.pedestrian,
                      inStationary: !cameraPreference?.pedestrian?.inStationary,
                    },
                  })
                }
              />
            </SwitchWrapper>
          </Column>
        </RowDiv>
      </RowsContainer>
    </SettingTableDescription>
  );
}

export default CameraPreferences;
