import React, { useEffect, useState } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconContainer, HeadingAnalytics, TittleDiv, RefreshButton } from "./MongoChart.component.styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import permissions from "../../../../../../../constant/permission";
import { BlankMessageDivStyle } from "../../ChartsOverview.component.styles";
import { getJwtTokenForCharts } from "../../../../../../../redux/analyticsPage/action";
import { getStartEndDateFromDuration } from "../../ChartsFilters/daurationFilter/DurationFilter.utils";
import { Trans, useTranslation } from "react-i18next";
import { dachartsBaseUrl } from "../../../../../../../config";
function MongoChart({
  chartId,
  title,
  jwtToken,
  dropdownFilterAccountId,
  dropdownFilterWorksiteId,
  loginDetails,
  isSiteAdmin,
  getJwtTokenForCharts,
  duration,
  isSuperAdmin,
  showWorksiteDropdown,
}) {
  const uniqueChartId = `chart-data-${chartId}`; // Generate a unique ID for each chart
  const [chart, setChart] = useState(null);
  const [chartData, setChartData] = useState(null);
  const DEFAULT_REFRESH_INTERVAL_IN_SEC = 3600; // 600;
  const DEFAULT_AUTO_REFRESH_VALUE = true;
  const getFilterQueryObj = () => {
    let query = { type: "daily" };
    if (isSuperAdmin) {
      if (dropdownFilterAccountId && dropdownFilterAccountId.trim().toLowerCase() !== "all") {
        query = { ...query, acId: { $oid: dropdownFilterAccountId } };
      }
    } else {
      query = { ...query, acId: { $oid: loginDetails.userData.accountId } };
    }

    if (showWorksiteDropdown) {
      if (dropdownFilterWorksiteId && dropdownFilterWorksiteId.trim().toLowerCase() != "all") {
        query = { ...query, wsId: { $oid: dropdownFilterWorksiteId } };
      }
    } else {
      if (loginDetails?.userData?.authorizedSites && loginDetails?.userData?.authorizedSites[0]) {
        const siteId = loginDetails?.userData?.authorizedSites[0];
        query = { ...query, wsId: { $oid: siteId } };
      }
    }
    console.log("duration.label", duration.label);
    if (duration && duration.id != 14) {
      const dateInfoObj = getStartEndDateFromDuration(duration);
      console.log("dateInfoObj", dateInfoObj);
      if (dateInfoObj?.startDate) {
        query = {
          ...query,
          date: {
            ...query.date,
            $gte: dateInfoObj.startDate,
          },
        };
      }
      if (dateInfoObj?.endDate) {
        query = {
          ...query,
          date: {
            ...query.date,
            $lte: dateInfoObj.endDate,
          },
        };
      }
    }

    console.log("query", query);
    return query;
  };
  useEffect(() => {
    if (chartId && jwtToken) {
      console.log("title", title);
      // console.log(jwtToken);
      // const isObjectEmpty = (objectName) => {
      //   return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
      // };
      const sdk = new ChartsEmbedSDK({
        // baseUrl: "https://charts.mongodb.com/charts-test_project1-akfri",
        baseUrl: dachartsBaseUrl,
        showAttribution: false,
        getUserToken: function () {
          // return await login(getUser(), getPass());
          return jwtToken;
        },
      });

      const chart = sdk.createChart({
        chartId: chartId,
        ////you can even apply filters while creating the chart
        filter: getFilterQueryObj(),
        refreshInterval: DEFAULT_REFRESH_INTERVAL_IN_SEC,
      });

      chart
        .render(document.getElementById(uniqueChartId))
        .then((charts) => {
          //commented below out because now we are applying filter right when we create chart so not even for a sec do we see all charts data ...!!
          // console.log("MongoCharts::", charts);
          // const query = getFilterQueryObj();
          // console.log("query", query);
          // if (query && !isObjectEmpty(query)) {
          //   chart.setFilter(query);
          // }
          chart
            .getData()
            .then((data) => {
              // console.log("MongoChart:data", data);
              // if(checkIfDataPresent(data)){
              setChartData(data);
              // }else{
              //   setChartData({documents:[{value:0}]})
              // }
            })
            .catch((err) => {
              console.log(err);
            });

          // chart.setMaxDataAge(DEFAULT_REFRESH_INTERVAL_IN_SEC)
          // chart.setAutoRefresh(true)

          setChart(chart);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [jwtToken, chartId, uniqueChartId, dropdownFilterAccountId, dropdownFilterWorksiteId, duration]);
  const checkIfDataPresent = (data) => {
    if (data) {
      if (data?.documents && data?.documents?.length > 0) {
        if (data?.documents[0]?.value || data?.documents[0]?.value != 0) {
          return true;
        }
      }
    }
    return false;
  };
  const [rotation, setRotation] = useState(0);
  const [isRotating, setIsRotating] = useState(false);

  const onRefresh = () => {
    setIsRotating(true);
    try {
      getJwtTokenForCharts();
      chart.setMaxDataAge(1);
      chart.setAutoRefresh(false);
      chart
        ?.refresh()
        .then(() => {
          chart.setMaxDataAge(DEFAULT_REFRESH_INTERVAL_IN_SEC);
          chart.setAutoRefresh(true);
        })
        .catch((err) => {
          console.log(err);
          chart.setMaxDataAge(DEFAULT_REFRESH_INTERVAL_IN_SEC);
          chart.setAutoRefresh(true);
        });
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      setIsRotating(false);
      setRotation(rotation + 360);
    }, 2000); // Rotate back after 10 seconds
  };

  return (
    <div className="App" style={{ position: "relative" }}>
      <HeadingAnalytics>
        <TittleDiv> {title}</TittleDiv>
        <IconContainer>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh</Tooltip>}>
            <RefreshButton
              rotating={isRotating}
              onClick={onRefresh}
              style={{ transform: `rotate(${rotation}deg)` }}
              className="icon icon-update"
            ></RefreshButton>
          </OverlayTrigger>
        </IconContainer>
      </HeadingAnalytics>
      {!checkIfDataPresent(chartData) && (
        <BlankMessageDivStyle>
          <p>
            <Trans>NO_DATA_FILTERS</Trans>
          </p>
        </BlankMessageDivStyle>
      )}
      {/* <button onClick={onRefresh}>Refresh</button> */}
      {chartId ? (
        <div id={uniqueChartId} style={{ height: 400 }}></div>
      ) : (
        ""
        //if chart not present then handle no chart present ...!!
        // <div  style={{ height: 400 }}>
        //   <img
        //     height="250"
        //     src="https://icons-for-free.com/iconfiles/png/512/bar+chart+black+background+business+data+diagram+graph-1320086870657814851.png"
        //   />
        // </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  jwtToken: state.analyticsPage?.getJwtToken?.success,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  loginDetails: state.user.loginDetails,
  isSiteAdmin: state.user.isSiteAdmin,
  duration: state.dropdownFilters.duration,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  isSuperAdmin: state.user.isSuperAdmin,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
});

const mapDispatchToProps = (dispatch) => ({
  // updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  getJwtTokenForCharts: () => dispatch(getJwtTokenForCharts()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MongoChart));
