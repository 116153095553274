import React from "react";
import { Trans } from "react-i18next";

import {
  UserTypeDetailsDiv,
  UserTypeDetailsTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  RadioWrapper,
} from "./styles/UserTypeDetailsTabView.styles";
import RadioButton from "../../../../../components/RadioButton/Radio.component";
import { tableHeader } from "../const/usertypeConstants";

const UserTypeDetailsTable = (props) => {
  const { customFields, userTypeDetailsTableCB, isDisabled, type } = props;

  const changeHandler = (data, isOptional) => {
    customFields.forEach((field) => {
      const tempField = field;
      if (data.name === field.name) {
        if (isOptional === "Optional") {
          tempField.isRequired = false;
        } else {
          tempField.isRequired = true;
        }
        userTypeDetailsTableCB(customFields);
      }
    });
  };

  const getFieldTypeUIString = (type) => {
    switch (type) {
      case "PHONE":
        return "Phone";
      case "PIN":
        return "PIN";
      case "EMAILANDPASSWORD":
        return "Email & Password";
      default:
        return "";
    }
  };

  return (
    <UserTypeDetailsDiv>
      <UserTypeDetailsTableDescription>
        <RowDiv isHeader={true}>
          {tableHeader.map((data) => (
            <Column isHeader={true} textleft={data.textleft}>
              <Trans>{data.name}</Trans>
            </Column>
          ))}
        </RowDiv>

        <RowsContainer className="userTypeDetailsHeight">
          {customFields?.map((data) => (
            <RowDiv>
              <Column textleft={"left"}>
                <Trans>{data.name}</Trans>
              </Column>
              <Column textleft={"left"}>{getFieldTypeUIString(data.type)}</Column>
              <Column textleft={"left"}>
                <RadioWrapper>
                  <RadioButton
                    id={data.name + "Yes_" + type}
                    checked={data.isRequired}
                    label="Yes"
                    onClick={() => {
                      changeHandler(data, "Yes");
                    }}
                    size="small"
                    disabled={data.isDisabled || isDisabled}
                  />
                  <RadioButton
                    id={data.name + "Optional_" + type}
                    checked={!data.isRequired}
                    label="Optional"
                    onClick={() => {
                      changeHandler(data, "Optional");
                    }}
                    size="small"
                    disabled={data.isDisabled || isDisabled}
                  />
                </RadioWrapper>
              </Column>
              <Column textleft={"left"}>{data.description}</Column>
            </RowDiv>
          ))}
        </RowsContainer>
      </UserTypeDetailsTableDescription>
    </UserTypeDetailsDiv>
  );
};

export default UserTypeDetailsTable;
