import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import ViewUserTypeOverviewTab from "./ViewUserTypeOverviewTab.component";
import UserTypeAccessLevelView from "./UserTypeAccessLevelView";
import SwitchComponent from "../../../../../components/Switchbox/SwitchComponent";
import {
  toggleUserTypeStatus,
  resetUserTypeFlags,
  preDeactivatedUserType,
  deactivateUserType,
  getUserTypeDetailById,
} from "../../../../../redux/userType/action";
import {
  UserTypePageContainer,
  UserTypeTopHeader,
  UserTypeHeadDetail,
  UserTypeDataDescp,
  UserTypeTabDiv,
  ActionWrapper,
  DetailsWrapper,
} from "./ViewUserTypeOverview.component.styles";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import SnackBar from "../../../SnackBar";
import toasterConstants from "../../../../../constant/messages/toaster.json";
import { getUserTypeList } from "../../../../../redux/userType/action";

const {
  user_type_toggle_success_message,
  user_type_toggle_activated_message,
  user_type_toggle_deactivated_message,
  user_type_toggle_failed_message,
} = toasterConstants;
const ViewUserTypeOverview = (props) => {
  const {
    userTypeOverview,
    UserTypeLoading,
    toggleUserTypeStatus,
    toggleUserTypeStatusSuccess,
    toggleUserTypeStatusError,
    resetUserTypeFlags,
    getUserTypeList,
    accountId,
    isSuperAdmin,
    selectedAccessLevel,
    preDeactivatedUserType,
    DeactivatedUsersList,
    deactivateUserType,
    DeactivateUserTypeSuccess,
    getUserTypeDetailById,
    UserTypeUpdateSuccess,
  } = props;
  const { createdAt, UserTypeCreatedOn, isActive, _id, createdBy } = userTypeOverview || {};
  const dateOfCreation = createdAt || UserTypeCreatedOn;
  const { t } = useTranslation();

  const addedOn =
    userTypeOverview &&
    dateOfCreation &&
    moment(dateOfCreation)
      .utcOffset(dateOfCreation && dateOfCreation.split && dateOfCreation.split(" ")[0])
      ?.format(" MMMM DD, YYYY");

  const [showAlertModal, setShowAlertModal] = useState(false);

  const statusChangeHandler = () => {
    const updatedStatus = {
      isActive: !isActive,
    };

    if (!updatedStatus.isActive) {
      preDeactivatedUserType(_id);
    } else {
      toggleUserTypeStatus(_id, updatedStatus);
    }
  };

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, []);

  const handleMessage = () => {
    if (DeactivateUserTypeSuccess) {
      return t(user_type_toggle_deactivated_message);
    } else if (toggleUserTypeStatusSuccess) {
      return t(user_type_toggle_activated_message);
    } else if (toggleUserTypeStatusError) {
      return t(user_type_toggle_failed_message);
    } else if (UserTypeUpdateSuccess) {
      return t(user_type_toggle_success_message);
    }
  };

  const handleCloseSnackBar = () => {
    resetUserTypeFlags();
  };

  useEffect(() => {
    if (toggleUserTypeStatusSuccess || DeactivateUserTypeSuccess || UserTypeUpdateSuccess) {
      const hideLoading = true;
      getUserTypeList(hideLoading);
    }
    if (DeactivateUserTypeSuccess) {
      getUserTypeDetailById(_id);
    }
  }, [toggleUserTypeStatusSuccess, DeactivateUserTypeSuccess, UserTypeUpdateSuccess]);

  return (
    <UserTypePageContainer>
      <>
        <>
          {UserTypeLoading && (
            <span>
              <Row>
                <Col md={12}>
                  <Skeleton height={20} />
                  <Skeleton height={10} count={2} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="mt-4">
                  <Skeleton height={20} width={100} />
                  <Skeleton height={10} count={4} />
                </Col>
              </Row>
            </span>
          )}
          {isSuperAdmin && (!accountId || accountId === "all") && selectedAccessLevel !== "ALL_ACCOUNTS" ? (
            <div
              style={{
                minHeight: "calc(100vh - 322px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Please select an account to view the User Type details
            </div>
          ) : (
            userTypeOverview &&
            !UserTypeLoading && (
              <>
                <UserTypeTopHeader>
                  <DetailsWrapper>
                    <UserTypeHeadDetail title={userTypeOverview ? userTypeOverview.name : ""}>
                      <Trans> {userTypeOverview ? userTypeOverview.name : ""}</Trans>
                    </UserTypeHeadDetail>
                    <UserTypeDataDescp>
                      <Trans>Description</Trans>:
                      <span title={userTypeOverview ? userTypeOverview.description : ""}>
                        <Trans> {userTypeOverview ? userTypeOverview.description : ""}</Trans>
                      </span>
                    </UserTypeDataDescp>

                    {userTypeOverview?.accessLevel && (
                      <UserTypeDataDescp>
                        <Trans>Access Level</Trans>:&nbsp;
                        <span title={userTypeOverview?.accessLevel}>
                          <Trans>
                            <UserTypeAccessLevelView
                              type={userTypeOverview?.accessLevel}
                            ></UserTypeAccessLevelView>
                          </Trans>
                        </span>
                      </UserTypeDataDescp>
                    )}

                    <UserTypeDataDescp>
                      <Trans>Added By</Trans>:&nbsp;
                      <span title="SIERA.AI">
                        <Trans>{createdBy?.fullName || "SIERA.AI"}</Trans>
                      </span>
                    </UserTypeDataDescp>
                    <UserTypeDataDescp>
                      <Trans>Added On</Trans>:
                      <span title={addedOn}>
                        <Trans>{addedOn}</Trans>
                      </span>
                    </UserTypeDataDescp>
                  </DetailsWrapper>
                  <ActionWrapper>
                    {selectedAccessLevel !== "ALL_ACCOUNTS" && (
                      <PermissionCheck
                        section={"DASHBOARD"}
                        permissionName={"USER_TYPE_MANAGEMENT"}
                        actionName={"deactivate"}
                      >
                        <p className="m-0  statusStyle">
                          <Trans>Status</Trans>
                        </p>
                        <SwitchComponent id={"1"} onChange={statusChangeHandler} checked={isActive} />
                      </PermissionCheck>
                    )}
                  </ActionWrapper>
                </UserTypeTopHeader>
                <UserTypeTabDiv>
                  <ViewUserTypeOverviewTab
                    typeAttributes={userTypeOverview?.AssetTypeAttributes}
                    assignedChecklists={userTypeOverview?.checklists}
                  />
                </UserTypeTabDiv>
              </>
            )
          )}
        </>
        <SnackBar
          isSuccess={toggleUserTypeStatusSuccess || UserTypeUpdateSuccess}
          isFailed={toggleUserTypeStatusError || DeactivateUserTypeSuccess}
          label={handleMessage()}
          handleClose={handleCloseSnackBar}
        />
      </>
    </UserTypePageContainer>
  );
};

const mapStateToProps = (state) => ({
  userTypeOverview: state.userType.UserTypeDetail,
  UserTypeLoading: state.userType.UserTypeDetailLoading,
  toggleUserTypeStatusSuccess: state.userType.toggleUserTypeStatusSuccess,
  toggleUserTypeStatusError: state.userType.toggleUserTypeStatusError,
  accountId: state.dropdownFilters.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
  DeactivatedUsersList: state.userType.DeactivatedUsersList,
  DeactivateUserTypeSuccess: state.userType.DeactivateUserTypeSuccess,
  UserTypeUpdateSuccess: state.userType.UserTypeUpdateSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  toggleUserTypeStatus: (userId, data) => dispatch(toggleUserTypeStatus(userId, data)),
  resetUserTypeFlags: () => dispatch(resetUserTypeFlags()),
  getUserTypeList: (hideLoading) => dispatch(getUserTypeList(hideLoading)),
  preDeactivatedUserType: (userTypeId) => dispatch(preDeactivatedUserType(userTypeId)),
  deactivateUserType: (userTypeId) => dispatch(deactivateUserType(userTypeId)),
  getUserTypeDetailById: (id) => dispatch(getUserTypeDetailById(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewUserTypeOverview));
