import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import ViewAssetTypeOverviewTable from "./ViewAssetTypeOverviewTable.component";
import { useTranslation } from "react-i18next";
import AssetTypeChecklistsTab from "../AssetType/Tabs/AssetTypeChecklistsTab.component";
/* custom style */
import "../AssetStatus/AssetStatus.css";
import { AntTabAssetType } from "./ViewAssetTypeOverviewTab.component.styles";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#0d5fbe",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",

    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#0D5FBE",
      opacity: 1,
    },
    "&$selected": {
      color: "#323232",
      fontWeight: "bold",
      fontFamily: "kanit",
    },
    "&:focus": {
      color: "#0D5FBE",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  padding: {
    padding: theme.spacing(1),
  },
  // demo1: {
  //   paddingTop: "20px",
  //    backgroundColor: "#ffffff",
  //  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

export default function ViewAssetTypeOverviewTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AntTabAssetType>
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          className="checklist-tab-name"
        >
          <AntTab label={t("Asset Type Overview")} />
          <AntTab label={t("Asset Type Checklists")} />
        </AntTabs>

        <TabPanel value={value} index={0} className="asset-type-table-height">
          <ViewAssetTypeOverviewTable {...props} />
        </TabPanel>
        <TabPanel value={value} index={1} className="asset-type-table-height">
          <AssetTypeChecklistsTab {...props} />
        </TabPanel>
      </AntTabAssetType>
    </div>
  );
}
