import React from "react";
import PropTypes from "prop-types";
import { StyledButton, StyledLink, Label } from "./IconButton.component.styles";

const ButtonIcon = ({ icon, label, onClick, disabled, href, target }) => {
  const ButtonElement = href ? StyledLink : StyledButton;

  return (
    <ButtonElement
      className={`icon ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      href={href}
      disabled={disabled}
      target={target}
    >
      <span className={icon}></span>
      <Label>{label}</Label>
    </ButtonElement>
  );
};

ButtonIcon.propTypes = {
  /**
   * Icon representing the class name for an icon.
   */
  icon: PropTypes.string.isRequired,
  /**
   * Text label associated with the button icon.
   */
  label: PropTypes.string,
  /**
   * Function to be executed when the button icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Specifies if the button icon is disabled or not.
   */
  disabled: PropTypes.bool,
  /**
   * URL link to navigate when the button icon is clicked (if it behaves as an anchor).
   */
  href: PropTypes.string,
  /**
   * Target attribute for the anchor link, specifying where to open the linked document.
   */
  target: PropTypes.string,
};

export default ButtonIcon;
