import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import SiearTabs from "../../../../../components/TabComponent/SieraTabs.Component";
import SiearTab from "../../../../../components/TabComponent/SieraTab.Component";
import UserTypeDetailsTable from "../common/UserTypeDetailsTable.component";
import Button from "../../../../../components/Button/Button.component";
import PermissionsMatrix from "../common/PermissionsMatrix.component";
import EditUserTypeModal from "../edit/EditUserTypeModal.component";
import { ButtonPanel } from "./View.styles";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";

const ViewUserTypeOverviewTab = (props) => {
  const { userTypeDetailPermissions, customFields, UserTypeDetail } = props;
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("userTypePermission");
  const [addUserTypeModalData, setAddUserTypeModalData] = useState(UserTypeDetail);
  const [selectedValues, setSelectedValues] = useState(null);
  const [addUserTypeModal, setAddUserTypeModal] = useState(false);

  useEffect(() => {
    setSelectedValues(userTypeDetailPermissions);
  }, [userTypeDetailPermissions]);

  useEffect(() => {
    setAddUserTypeModalData(UserTypeDetail);
  }, [UserTypeDetail]);

  return (
    <>
      <SiearTabs
        align="middle"
        variant="horizontalNew"
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
      >
        <SiearTab eventKey="userTypePermission" title="User Type Permissions">
          <PermissionsMatrix
            addUserTypeModalData={addUserTypeModalData}
            setAddUserTypeModalData={setAddUserTypeModalData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            matrixData={userTypeDetailPermissions}
            isDisabled={true}
          />
        </SiearTab>
        <SiearTab eventKey="userTypeDetails" title="User Type Details">
          <UserTypeDetailsTable
            customFields={customFields}
            userTypeDetailsTableCB={() => {}}
            isDisabled={true}
          />
          {/*  <UserTypeDetailsTabView /> */}
        </SiearTab>
      </SiearTabs>

      <EditUserTypeModal
        show={addUserTypeModal}
        toggleModalClose={() => {
          setAddUserTypeModal(false);
        }}
      />
      <PermissionCheck section={"DASHBOARD"} permissionName={"USER_TYPE_MANAGEMENT"} actionName={"edit"}>
        <ButtonPanel>
          <Button
            iconClass="edit"
            label={t("Edit")}
            onClick={() => setAddUserTypeModal(true)}
            showIcon
            buttonType="primary"
            disabled={!UserTypeDetail.isActive}
          />
        </ButtonPanel>
      </PermissionCheck>
    </>
  );
};

const mapStateToProps = (state) => ({
  userTypeDetailPermissions: state.userType.UserTypeDetail.permissions,
  customFields: state.userType.UserTypeDetail.customFields,
  UserTypeDetail: state.userType.UserTypeDetail,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserTypeOverviewTab);
