import React from "react";
import { QRCodeLabel, QRCodeWrapper } from "./QrCode.styles";

const operatorBaseURL = require("../../config").operatorBaseUrl; //Base Url
const assetBaseURL = require("../../config").redirectAssetQrScan; // Operator asset qr scan redirect

const QRCode = require("qrcode.react");

const getTrimmedLabel = (label) => {
  if (label && label.length > 12) {
    return label.substring(0, 8) + "..." + label.substring(label.length - 3);
  } else {
    return label;
  }
};

const QrCode = ({ isUser, qrCodeDetails, size, inputEl }) => {
  const { label, id } = qrCodeDetails;
  return (
    <div id="qrCodeIdWrapper">
      <QRCodeWrapper className="" ref={inputEl}>
        <QRCode id="qrCodeId" size={size || 55} value={(isUser ? operatorBaseURL : assetBaseURL) + id} />

        <QRCodeLabel className="text-center">
          <span title={label}>{getTrimmedLabel(label)}</span>
        </QRCodeLabel>
      </QRCodeWrapper>
    </div>
  );
};

export default QrCode;
