import styled from "styled-components";
export const AntTabAssetType = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  button.Mui-selected span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  button.MuiButtonBase-root span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  span.MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .card_assetBody {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .assetType_sec {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .noSection_cD p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz thead {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz thead th {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .add-asset-checklist-select .deleteBoxright svg .cls-1 {
    fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  table.assetTablesz tbody td {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  td.text-right svg .cls-1 {
   /* fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;*/
  }
`;
