import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/Button/Button.component";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ReactComponent as IconRight } from "../../../assets/AddIcon/IconRight.svg";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoginDetails, selectUserDetails } from "../../../redux/user/user.selectors";
import { updateUser, login } from "../../../redux/user/user.action";
import alertConstant from "../../../constant/messages/toaster.json";
import validationConstant from "../../../constant/messages/validation.json";
import { apiCall } from "../../../utils/apiCall";
import Input from "../../../components/Input";
import units from "../../../constant/units";
import { MFAWrapper, SwitchWrapper, OptionsWrapper } from "./SignupForm.component.styles";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import RadioButton from "../../../components/RadioButton/Radio.component";

const timeZone = require("../../../constant/timeZone");

const labelStyle = {
  position: "relative",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
  left: "3px",
};

function SignupForm(props) {
  let { loginDetails, login, setSuccessMsg } = props;
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const [accountData, setAccountData] = useState({
    companyName: "",
    enterpriseUrl: "",
    unitType: "Imperial",
    companyDescription: "",
    address: "",
    mfaData: {},
  });

  const companyNameInputRef = useRef(null);
  useEffect(() => {
    companyNameInputRef.current && companyNameInputRef.current.focus();
  }, []);

  useEffect(() => {
    setAccountData({
      ...accountData,
      mfaData: {
        ...loginDetails?.mfaData,
      },
    });
  }, [loginDetails]);

  const fileSelectHandler = (event) => {
    setImage({ logo: event.target.files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.append("logo", image.logo);
    bodyFormData.append("accountData", JSON.stringify(accountData));
    if (validate()) {
      await apiCall(`/account/${loginDetails.accountId}`, {
        method: "PUT",
        data: bodyFormData,
        type: "form-data",
      })
        .then(function (response) {
          if (response.data.success) {
            //handle success
            loginDetails.formPending = false;
            loginDetails.logo = response.data.data.logo;
            loginDetails.companyName = response.data.data.companyName;
            loginDetails.enterpriseUrl = response.data.data.enterpriseUrl;
            loginDetails.unitType = response.data.data.unitType;
            loginDetails.unitType = response.data.data.unitType;
            loginDetails.mfaData = response.data.data.mfaData;
            login({ ...loginDetails });
            setSuccessMsg(alertConstant.account_profile_update);
            props.onHide(false);
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  };

  // Enterprise URL Check
  const handleEnterpriseUrl = (e) => {
    const fullUrl = e.target.value.trim();
    const actualUrl = fullUrl.substring(fullUrl.indexOf("/") + 1);
    if (accountData.enterpriseUrl === actualUrl) {
      return;
    }
    setAccountData({ ...accountData, enterpriseUrl: actualUrl });

    if (actualUrl) {
      apiCall(`/account/urlAvailabilty/${loginDetails && loginDetails.accountId}/${actualUrl}`)
        .then((response) => {
          if (response.data.success === true) {
            setErrors({ ...errors, enterpriseUrl: "Available" });
          } else if (response.data.success === false) {
            setErrors({ ...errors, enterpriseUrl: "NotAvailable" });
          }
        })
        .catch((error) => {
          setErrors({ ...errors, enterpriseUrl: "NotAvailable" });
        });
    } else {
      setErrors({ ...errors, enterpriseUrl: "" });
    }
  };

  const validate = () => {
    let isValid = true;

    if (!accountData.enterpriseUrl) {
      isValid = true;
    }

    if (!accountData.companyName) {
      isValid = false;

      setErrors((prevState) => ({
        ...prevState,
        companyName: t(validationConstant.company_required),
      }));
    }

    if (errors.enterpriseUrl === "NotAvailable") {
      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    if (accountData.enterpriseUrl) {
      setErrors((prevState) => ({
        ...prevState,
        enterpriseUrl: "",
      }));
    }
    if (accountData.companyName) {
      setErrors((prevState) => ({
        ...prevState,
        companyName: "",
      }));
    }
  }, [accountData]);

  return (
    <div>
      <Container style={{ marginBottom: "30px" }}>
        <Navbar
          expand="lg"
          variant="light"
          style={{ backgroundImage: "linear-gradient(30deg, rgb(13, 95, 190) 0%, rgb(0, 70, 150) 100%)" }}
        >
          <Navbar.Brand style={{ color: "#ffffff" }}>
            <Trans>Account Details</Trans>
          </Navbar.Brand>
        </Navbar>
        <form onSubmit={handleSubmit}>
          <Row className="mt-3">
            <Col md={6}>
              {/* Company Name*/}
              <div>
                <Input
                  fullWidth
                  inputRef={companyNameInputRef}
                  id="outlined-basic"
                  label={t("Company Name")}
                  variant="standard"
                  name="companyName"
                  minLength="1"
                  maxLength="64"
                  value={accountData.companyName}
                  placeholder={t("Please enter your Company Name")}
                  type="text"
                  onChange={(e) => setAccountData({ ...accountData, companyName: e.target.value })}
                  // isInvalid={validated && toValidateFullName(errors.companyName)}
                  // feedback={validated && toValidateFullName(errors.companyName)}
                />
                <div className="text-danger">{errors ? errors.companyName : ""}</div>
                <p className="asterisk_user">*</p>
              </div>

              {/* Enterprise URL*/}
              <div style={{ marginTop: "12px" }} className="asterisk_form">
                <Input
                  key={"enterpriseUrl"}
                  id={"enterpriseUrl"}
                  label={t("Enterprise URL")}
                  fullWidth
                  required={false}
                  name={"enterpriseUrl"}
                  onChange={handleEnterpriseUrl}
                  value={accountData.enterpriseUrl}
                  startAdornment={"manage.siera.ai/#/"}
                />
                <label className="text-open-sans" style={labelStyle}>
                  <Trans>Create your own URL to access SIERA Dashboard</Trans>
                </label>

                {/* <span><Required style={{marginTop:'4px'}} /></span> */}
                <div style={{ marginTop: "2px" }} className="text-danger">
                  {errors.enterpriseUrl === t("Please enter enterprise url") ? errors.enterpriseUrl : ""}
                </div>
                {accountData.enterpriseUrl && errors.enterpriseUrl === "Available" ? (
                  <div style={{ marginTop: "8px" }}>
                    <Trans>URL Availability</Trans>: <IconRight />
                    <span style={{ color: "green" }}>
                      <Trans>You are good to go</Trans>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {accountData.enterpriseUrl && errors.enterpriseUrl === "NotAvailable" ? (
                  <div style={{ marginTop: "8px" }}>
                    <Trans>URL Availability</Trans>:{" "}
                    <span style={{ color: "red" }}>
                      <Trans>Enterprise URL is not available</Trans>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/* <p className="asterisk_user">*</p> 
                </FormControl>*/}
              </div>

              <div>
                <Input
                  key={"unitType"}
                  id={"unitType"}
                  fullWidth
                  required={true}
                  name={"unitType"}
                  onChange={(value) => setAccountData({ ...accountData, unitType: value })}
                  value={accountData.unitType ? accountData.unitType : "Imperial"}
                  type={"select"}
                  options={units}
                />
              </div>

              <div className="filterinput">
                <Input
                  key={"timeZone"}
                  id={"timeZone"}
                  fullWidth
                  required={true}
                  name={"timeZone"}
                  onChange={(value) => setAccountData({ ...accountData, timeZone: value })}
                  value={accountData.timeZone || timeZone[0]._id}
                  type={"select"}
                  options={timeZone}
                />
              </div>
            </Col>
            <Col md={6}>
              {/* Logo*/}
              <div style={{ marginTop: "12px" }}>
                <div style={{ fontFamily: "Open Sans", fontSize: "16px", color: "#919191" }}>Logo</div>
              </div>
              <div style={{ marginTop: "12px" }} className="profile_filename">
                <input type="file" onChange={fileSelectHandler} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <MFAWrapper>
                <SwitchWrapper>
                  Multi-Factor Authentication{" "}
                  <SwitchComponent
                    onChange={(e) =>
                      setAccountData({
                        ...accountData,
                        mfaData: { ...accountData?.mfaData, status: e.target.checked },
                      })
                    }
                    checked={accountData?.mfaData?.status}
                    name="status"
                  />
                </SwitchWrapper>
                {accountData?.mfaData?.status && (
                  <OptionsWrapper>
                    <RadioButton
                      onChange={(e) =>
                        setAccountData({
                          ...accountData,
                          mfaData: { ...accountData?.mfaData, isMandatory: e.target.checked },
                        })
                      }
                      checked={accountData?.mfaData?.isMandatory}
                      label="Required"
                      name="isMandatory"
                      size="small"
                      id="required"
                    />
                    <RadioButton
                      onChange={(e) =>
                        setAccountData({
                          ...accountData,
                          mfaData: { ...accountData?.mfaData, isMandatory: !e.target.checked },
                        })
                      }
                      checked={!accountData?.mfaData?.isMandatory}
                      label="Optional"
                      name="isMandatory"
                      size="small"
                      id="optional"
                    />
                  </OptionsWrapper>
                )}
              </MFAWrapper>
            </Col>
          </Row>

          <Row style={{ marginTop: "30px" }}>
            <Col></Col>
            <Col className="text-right">
              <Button label={t("Submit")} showIcon iconClass="save" buttonType="save" />
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
  userDetails: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  login: (userDetails) => dispatch(login(userDetails)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupForm));
