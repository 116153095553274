import styled from "styled-components";

export const UserTypePageContainer = styled.div`
  clear: both;
  width: 100%;
  /* margin-top: 5px; */
  float: left;
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-radius: 4px;
  padding: 5px 15px;
`;

export const UserTypeTopHeader = styled.div`
  display: flex;
`;

export const UserTypeHeadDetail = styled.div`
  font-family: Kanit;
  font-size: 26px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UserTypeDataDescp = styled.div`
  font-family: "Open Sans";
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL2 || "#434343"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span {
  }
`;
export const UserTypeTabDiv = styled.div`
  clear: both;
  margin: 10px auto;
  float: left;
  width: 100%;
`;

export const ActionWrapper = styled.div`
  flex: 1 1 10%;
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
`;
export const DetailsWrapper = styled.div`
  flex: 1 1 90%;
`;
