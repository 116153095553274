import styled from "styled-components";
export const EditProfileContainer = styled.div``;

export const ChangePasswordContainer = styled.div``;

export const ChangePasswordTop = styled.div``;

export const ChangePassHead = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 24px !important;
  margin: 10px auto;
`;

export const ChangePassDetail = styled.div`
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 14px !important;
  text-align: left;
`;

export const ChangePasswordSection = styled.div`
  clear: both;
  width: 100%;
  display: block;
  overflow: hidden;
`;

export const ChangePasswordSectionDiv = styled.div`
  margin: 10px auto;
  overflow: hidden;
`;

export const ButtonContainerStyle = styled.div`
  display: flex;
  padding: 15px;
`;
export const ButtonContainerDiv = styled.div`
  flex: 1;
`;
