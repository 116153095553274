import { configTypes } from "./types";
import { notificationSettingsTypes } from "../notification/types";

import { apiCall } from "../../utils/apiCall";
import config, { baseUrl } from "../../../src/config";
import axios from "axios";

//To update the language name
export const changeLanguage = (data) => ({
  type: configTypes.CHANGE_LANGUAGE,
  data: data,
});

//To change the account popup modal
export const setUpdateAccountPopup = (data) => ({
  type: configTypes.UPDATE_ACCOUNT_POPUP,
  data: data,
});

//To reset the state
export const resetLanguageState = () => ({
  type: configTypes.RESET_LANGUAGE_STATE,
});

// To set the inspection plan
export const getInspectionPlanSuccess = (data) => ({
  type: configTypes.SET_INPSECTION_PLAN,
  data: data,
});

// To set the inspection plan error
export const getInspectionPlanError = () => ({
  type: configTypes.SET_INPSECTION_PLAN_ERROR,
});

//To get the plan
export const getInspectionPlan = (accountId) => {
  return async (dispatch, getState) => {
    let token =
      getState() && getState().user && getState().user.loginDetails && getState().user.loginDetails.token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };
    if (!accountId) return;
    axios
      .get(`${baseUrl}/checklist/inspectionCount/${accountId}`, {
        headers: headers,
      })
      .then((response) => {
        dispatch(getInspectionPlanSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getInspectionPlanError(err));
      });
  };
};

// To set email alert setting loading
export const setEmailAlertSettingLoading = () => ({
  type: configTypes.SET_EMAIL_ALERT_SETTING_LOADING,
});

// To set email alert setting success
export const setEmailAlertSettingSuccess = (data) => ({
  type: configTypes.SET_EMAIL_ALERT_SETTING_SUCCESS,
  data: data,
});

// To set email alert setting error
export const setEmailAlertSettingError = () => ({
  type: configTypes.SET_EMAIL_ALERT_SETTING_ERROR,
});

// To update email alert setting
export const updateEmailAlertSetting = (emailAlertSettings, setEmailAlertSettingToastMessage) => {
  return async (dispatch, getState) => {
    //console.log("SHUBHAM");
    //console.log(emailAlertSettings);

    dispatch(setEmailAlertSettingLoading());

    if (!emailAlertSettings.accountId) {
      dispatch(setEmailAlertSettingError());
      return;
    }
    apiCall(`/alertsetting/threshhold`, { method: "POST", data: emailAlertSettings })
      .then((response) => {
        // console.log("SHUBHAM")
        console.log(response);
        setEmailAlertSettingToastMessage(response.data.message);
        dispatch(setEmailAlertSettingSuccess(emailAlertSettings));
      })
      .catch((err) => {
        // console.log("SHUBHAM")
        console.log(err);
        dispatch(setEmailAlertSettingError());
      });
  };
};

//To set email alert recipients loading
export const setEmailAlertRecipientsLoading = () => ({
  type: configTypes.SET_EMAIL_ALERT_RECIPIENTS_LOADING,
});

//To set email alert recipients success
export const setEmailAlertRecipientsSuccess = (data) => ({
  type: configTypes.SET_EMAIL_ALERT_RECIPIENTS_SUCCESS,
  data: data,
});

//To set email alert recipients error
export const setEmailAlertRecipientsError = () => ({
  type: configTypes.SET_EMAIL_ALERT_RECIPIENTS_ERROR,
});

// To get email alert recipients initial values
export const getEmailAlertRecipients = (accountId) => {
  return async (dispatch, getState) => {
    dispatch(setEmailAlertRecipientsLoading());

    if (!accountId) {
      dispatch(setEmailAlertRecipientsError());
      return;
    }
    apiCall(`/alertsetting/getemailSeting/${accountId}`)
      .then((response) => {
        dispatch(setEmailAlertRecipientsSuccess(response.data.data[0]));
      })
      .catch((err) => {
        dispatch(setEmailAlertRecipientsError());
      });
  };
};

// To update email alert recipients values
export const updateEmailAlertRecipients = (emailAlertRecipients, setEmailAlertRecipientsToastMessage) => {
  return async (dispatch, getState) => {
    dispatch(setEmailAlertRecipientsLoading());

    // if(!emailAlertRecipients.accountId){
    //   dispatch(setEmailAlertRecipientsError())
    //   return
    // }
    apiCall(`/alertsetting/emailSeting`, { method: "POST", data: emailAlertRecipients })
      .then((response) => {
        setEmailAlertRecipientsToastMessage(response.data.message);
        dispatch(setEmailAlertRecipientsSuccess(emailAlertRecipients));
      })
      .catch((err) => {
        dispatch(setEmailAlertRecipientsError());
      });
  };
};

//To get time to collision loading
export const getTimeToCollisionLoading = () => ({
  type: configTypes.GET_TIME_TO_COLLISION_LOADING,
});

//To get time to collision success
export const getTimeToCollisionSuccess = (data) => ({
  type: configTypes.GET_TIME_TO_COLLISION_SUCCESS,
  data: data,
});

//To get time to collision error
export const getTimeToCollisionError = () => ({
  type: configTypes.GET_TIME_TO_COLLISION_ERROR,
});

// To get time to collision values
export const getTimeToCollision = ({ accountId, siteId, assetTypeId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(getTimeToCollisionLoading());
    let isSuperAdmin = state && state.user && state.user.isSuperAdmin;
    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (!accountId || !siteId || !assetTypeId) {
      return;
    }

    if (accountId === "all") {
      return;
    }
    apiCall(`/site/s3TTC/gets3TTC/${accountId}/${siteId}/${assetTypeId}`)
      .then((response) => {
        dispatch(getTimeToCollisionSuccess(response.data.data[0] || {}));
      })
      .catch((err) => {
        //dispatch(getTimeToCollisionError())
      });
  };
};

//To get vehicle alert loading
export const getVehicleAlertSettingLoading = () => ({
  type: configTypes.GET_VEHICLE_ALERT_SETTING_LOADING,
});

//To get vehicle alert success
export const getVehicleAlertSettingSuccess = (data) => ({
  type: configTypes.GET_VEHICLE_ALERT_SETTING_SUCCESS,
  data: data,
});

//To get vehicle alert error
export const getVehicleAlertSettingError = () => ({
  type: configTypes.GET_VEHICLE_ALERT_SETTING_ERROR,
});

// To get vehicle alert values
export const getVehicleAlertSetting = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(getVehicleAlertSettingLoading());
    let { accountId, siteId, assetTypeId } = filterData;
    let isSuperAdmin = state?.user?.isSuperAdmin;

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (!accountId || !assetTypeId) {
      return;
    }
    if ([accountId, siteId, assetTypeId].includes("all")) {
      return;
    }

    apiCall(`/site/S3VAS/getS3VAS/${accountId}/${siteId}/${assetTypeId}`)
      .then((response) => {
        dispatch(getVehicleAlertSettingSuccess(response?.data?.data[0]));
      })
      .catch((err) => {
        //dispatch(getVehicleAlertSettingError())
      });
  };
};

//To update time to collision loading
export const updateTimeToCollisionLoading = () => ({
  type: configTypes.UPDATE_TIME_TO_COLLISION_LOADING,
});

//To update time to collision success
export const updateTimeToCollisionSuccess = (data) => ({
  type: configTypes.UPDATE_TIME_TO_COLLISION_SUCCESS,
  data: data,
});

//To update time to collision error
export const updateTimeToCollisionError = () => ({
  type: configTypes.UPDATE_TIME_TO_COLLISION_ERROR,
});

//To reset time to collision state after successful update
export const updateTimeToCollisionResetState = () => ({
  type: configTypes.UPDATE_TIME_TO_COLLISION_RESET_STATE,
});

// To update time to collision values
export const updateTimeToCollision = (filterData, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(updateTimeToCollisionLoading());
    let { accountId, assetTypeId, siteId, applyToAll } = filterData;
    if (!accountId) {
      accountId = state?.user?.loginDetails?.accountId;
    }
    if (!accountId) {
      return;
    }

    const payload = {
      accountId: accountId,
      assetTypeId: assetTypeId,
      siteId: siteId,
      applyToAll: applyToAll,
      timeToCollisonSettings: data,
    };

    apiCall(`/site/s3TTC/${siteId}`, { method: "PUT", data: { ...payload } })
      .then((response) => {
        dispatch(updateTimeToCollisionSuccess(data));
        dispatch(updateTimeToCollisionResetState());
      })
      .catch((err) => {
        dispatch(getTimeToCollisionError());
      });
  };
};

//To update vehicle alert loading
export const updateVehicleAlertSettingLoading = () => ({
  type: configTypes.UPDATE_VEHICLE_ALERT_SETTING_LOADING,
});

//To update vehicle alert success
export const updateVehicleAlertSettingSuccess = (data) => ({
  type: configTypes.UPDATE_VEHICLE_ALERT_SETTING_SUCCESS,
  data: data,
});

//To update vehicle alert error
export const updateVehicleAlertSettingError = () => ({
  type: configTypes.UPDATE_VEHICLE_ALERT_SETTING_ERROR,
});

export const resetVehicleAlertSettingState = () => ({
  type: configTypes.RESET_VEHICLE_ALERT_SETTING,
});

// To update vehicle alert values
export const updateVehicleAlertSetting = (filterData, data) => {
  return async (dispatch, getState) => {
    let state = getState();
    dispatch(updateVehicleAlertSettingLoading());

    let { accountId, assetTypeId, siteId } = filterData;

    if (!accountId || !assetTypeId) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    let payload = {
      accountId: accountId,
      assetTypeId: assetTypeId,
      siteId: siteId,
      applyToAll: data?.applyToAll,
      s3VehicleAlertSettings: {
        pedestrianWarningLights: data?.pedestrianWarningLights || false,
        pedestrianWarningSounds: data?.pedestrianWarningSounds || false,
        pedestrianS2Alert: data?.pedestrianS2Alert || false,
        objectWarningLights: data?.objectWarningLights || false,
        objectWarningSounds: data?.objectWarningSounds || false,
        objectS2Alert: data?.objectS2Alert || false,
      },
    };

    if (siteId === "all") {
      return;
    }

    apiCall(`/site/s3VAS/${siteId}`, { method: "PUT", data: { ...payload } })
      .then((response) => {
        dispatch(updateVehicleAlertSettingSuccess(data));
        dispatch(resetVehicleAlertSettingState());
      })
      .catch((err) => {
        dispatch(updateVehicleAlertSettingError());
      });
  };
};

// To update s3 camera settings
export const updateS3CameraSettingLoading = () => ({
  type: configTypes.UPDATE_S3_CAMERA_SETTING_LOADING,
});

export const updateS3CameraSettingSuccess = (data) => ({
  type: configTypes.UPDATE_S3_CAMERA_SETTING_SUCCESS,
  data: data,
});

export const updateS3CameraSettingError = () => ({
  type: configTypes.UPDATE_S3_CAMERA_SETTING_ERROR,
});

//To download files
export const downloadFileLoading = () => ({
  type: configTypes.DOWNLOAD_FILE_LOADING,
});

// To download file success
export const downloadingFileSuccess = (data) => ({
  type: configTypes.DOWNLOAD_FILE_SUCCESS,
  data: data,
});

// To download files error
export const downloadingFileError = () => ({
  type: configTypes.DOWNLOAD_FILE_ERROR,
});

//To get the plan
export const toDownloadFiles = (folderName, fileName) => {
  return async (dispatch, getState) => {
    dispatch(downloadFileLoading());

    let token = getState()?.user?.loginDetails?.token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };
    if (!fileName || !folderName) return;
    axios
      .get(`${baseUrl}/common/getS3SignedUrl/${folderName}/${fileName}`, {
        headers: headers,
      })
      .then((response) => {
        dispatch(downloadingFileSuccess());
        const url = response.data;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "");
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        dispatch(downloadingFileError());
      });
  };
};

/** Start of Access Control Settings */

export const getAccessControlSettingsByAssetIdSuccess = (data) => {
  return {
    type: configTypes.GET_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS,
    data,
  };
};

export const getAccessControlSettingsByAssetIdError = (error) => {
  return {
    type: configTypes.GET_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_ERROR,
    data: error,
  };
};

export const getAccessControlSettingsByAssetId = (assetId, setIsLoading) => {
  return async (dispatch, getState) => {
    apiCall(`/systemSettings/accessControlSettings/${assetId}`)
      .then((response) => {
        dispatch(getAccessControlSettingsByAssetIdSuccess(response.data.data.accessControlSettings));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("getAccessControlSettingsByAssetId -- error", err);
        //dispatch(getWorksiteReportSettingError());
      });
  };
};

export const updateAccessControlSettingsByAssetIdSuccess = (data) => {
  return {
    type: configTypes.UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS,
    data,
  };
};

export const updateAccessControlSettingsByAssetIdError = (error) => {
  return {
    type: configTypes.UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_ERROR,
    data: error,
  };
};

export const accessControlSettingsByAssetIdLoading = () => {
  return {
    type: configTypes.ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_LOADING,
  };
};

export const updateAccessControlSettingsByAssetId = (assetId, data, setIsLoading) => {
  return async (dispatch, getState) => {
    setIsLoading(true);
    dispatch(accessControlSettingsByAssetIdLoading());
    apiCall(`/systemSettings/accessControlSettings/${assetId}`, { method: "PUT", data: { ...data } })
      .then((response) => {
        //console.log("response.data.data.accessControlSettings", response.data.data.accessControlSettings);
        dispatch(updateAccessControlSettingsByAssetIdSuccess(response.data.data.accessControlSettings));
      })
      .catch((err) => {
        console.log("updateAccessControlSettingsByAssetId -- error", err);
        dispatch(updateAccessControlSettingsByAssetIdError());
      });
  };
};

export const resetAccountSummaryAlertSettingsByAssetId = (assetId, setIsUpdating) => {
  return async (dispatch) => {
    dispatch(accessControlSettingsByAssetIdLoading(true));
    apiCall(`/systemSettings/accessControlSettings/${assetId}`, { method: "PATCH" })
      .then((response) => {
        setIsUpdating(true);
        dispatch(getAccessControlSettingsByAssetIdSuccess(response.data.data));
      })
      .catch((err) => {
        console.log("resetAccountSummaryAlertSettingsByAssetId -- error", err);
      });
  };
};

/** End of Access Control Settings */

/**Start of S3 Camera Settings */
export const getS3CameraSettingLoading = () => ({
  type: configTypes.GET_S3_CAMERA_SETTING_LOADING,
});

export const getS3CameraSettingSuccess = (data) => ({
  type: configTypes.GET_S3_CAMERA_SETTING_SUCCESS,
  data: data,
});

export const getS3CameraSettingError = () => ({
  type: configTypes.GET_S3_CAMERA_SETTING_ERROR,
});

const transformSpeedControlData = (camData) => {
  return camData.map((cam) => {
    const pedestrian = cam?.speedControlZones?.pedestrian;
    const object = cam?.speedControlZones?.object;

    const pedestrianCamHeight = cam?.nearMissDetection?.pedestrian?.middle;
    const objectCameraHeight = cam?.nearMissDetection?.object?.middle;
    return {
      ...cam,
      speedControlZones: {
        pedestrian: {
          zones: {
            one: pedestrian?.zones?.one?.min,
            two: pedestrian?.zones?.two?.min,
            three: pedestrian?.zones?.three?.min,
            four: pedestrian?.zones?.four?.min,
          },
          left: pedestrian?.left,
          right: pedestrian?.right,
          top: pedestrian?.top,
          bottom: pedestrian?.bottom,
          middle: pedestrianCamHeight,
        },
        object: {
          zones: {
            one: object?.zones?.one?.min,
            two: object?.zones?.two?.min,
            three: object?.zones?.three?.min,
            four: object?.zones?.four?.min,
          },
          left: object?.left,
          right: object?.right,
          top: object?.top,
          bottom: object?.bottom,
          middle: objectCameraHeight,
        },
      },
    };
  });
};

export const getS3CameraSettingByAssetId = (assetId) => {
  return async (dispatch, getState) => {
    dispatch(getS3CameraSettingLoading());

    if (assetId === "all" || assetId === true || !assetId) {
      return;
    }

    apiCall(`/systemSettings/cameraSettings/${assetId}`)
      .then((response) => {
        const transformedData = transformSpeedControlData(response?.data?.data?.cameraSettings);
        dispatch(getS3CameraSettingSuccess(transformedData));
      })
      .catch((err) => {
        dispatch(getS3CameraSettingError());
      });
  };
};

export const updateS3CameraSettingsByAssetId = (assetId, data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    //dispatch(getS3CameraSettingLoading());
    apiCall(`/systemSettings/cameraSettings/${assetId}`, {
      method: "PUT",
      data,
    })
      .then((response) => {
        callbackAfterUpdate();
        //console.log("responseresponseresponseresponse", response);

        const transformedData = transformSpeedControlData(response.data.data.cameraSettings);
        dispatch(updateS3CameraSettingSuccess(transformedData));
      })
      .catch((err) => {
        dispatch(updateS3CameraSettingError());
      });
  };
};

const resetS3CameraSettingLoading = () => ({
  type: configTypes.RESET_S3_CAMERA_SETTING_LOADING,
});

const resetS3CameraSettingSuccess = (data) => ({
  type: configTypes.RESET_S3_CAMERA_SETTING_SUCCESS,
  data: data,
});

export const resetS3CameraSettingError = () => ({
  type: configTypes.RESET_S3_CAMERA_SETTING_ERROR,
});

export const resetS3CameraSettingsByAssetId = (assetId, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    dispatch(resetS3CameraSettingLoading());
    apiCall(`/systemSettings/cameraSettings/${assetId}`, {
      method: "PATCH",
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(updateS3CameraSettingSuccess(response.data?.data));
          callbackAfterUpdate();
        } else {
          dispatch(updateS3CameraSettingError());
        }
      })
      .catch((err) => {
        dispatch(updateS3CameraSettingError());
      });
  };
};

export const getS3CameraSetting = (assetTypeId) => {
  return async (dispatch, getState) => {
    dispatch(getS3CameraSettingLoading());
    let state = getState();
    let token = state?.user?.loginDetails?.token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };
    if (assetTypeId === "all") {
      return;
    }

    if (!assetTypeId) {
      return;
    }
    const url = `${baseUrl}/asset/getS3CameraSetting/${assetTypeId}`;
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        dispatch(getS3CameraSettingSuccess(response?.data?.data?.s3CameraSettings));
      })
      .catch((err) => {
        console.log(err, "----------<<<<<>>>>>>>><>>>>>><>");
        dispatch(getS3CameraSettingError());
      });
  };
};
//This is exact copy of the earlier version implementation wise with minor tweaks-- DBM
export const updateS3CameraSetting = (data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    apiCall("/alertsetting/bulkupdate/updateS3CameraSetting", {
      method: "PUT",
      data,
    })
      .then((response) => {
        callbackAfterUpdate();
        dispatch(updateS3CameraSettingSuccess(data.settings.s3Settings));
      })
      .catch((err) => {
        dispatch(updateS3CameraSettingError());
      });
  };
};

const bulkUpdateCameraSettingsSuccess = (data) => ({
  type: configTypes.BULK_UPDATE_CAMERA_SETTINGS_SUCCESS,
  data: data,
});

const bulkUpdateCameraSettingsError = () => ({
  type: configTypes.BULK_UPDATE_CAMERA_SETTINGS_ERROR,
});

export const resetBulkUpdateCameraSettingsFlags = () => ({
  type: configTypes.BULK_UPDATE_CAMERA_SETTINGS_RESET_FLAGS,
});

export const bulkUpdateCameraSettings = (data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = state?.user?.isSuperAdmin
      ? state?.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;
    if (!accountId) {
      return;
    }

    const dataWithAccountId = {
      ...data,
      accountId,
    };

    apiCall("/systemSettings/bulkUpdateCameraSettings", {
      method: "PUT",
      data: dataWithAccountId,
    })
      .then((response) => {
        callbackAfterUpdate(true);
        //console.log("response----------Remove this after testing is done-------------------", response);
        dispatch(bulkUpdateCameraSettingsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateS3CameraSettingError());
      });
  };
};

// To reset s3 camera setting

/* export const resetS3CameraSetting = (assetId, actionAfterUpdate) => {
  return async(dispatch, getState) => {
    let state = getState();
    dispatch(resetS3CameraSettingLoading());
    let token = state?.user?.loginDetails?.token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    if (!assetId) {
      return;
    }

    axios
      .put(`${baseUrl}/asset/updateS3CameraSetting/${assetId}`, {}, { headers: headers })
      .then((response) => {
        actionAfterUpdate();
        dispatch(resetS3CameraSettingSuccess());
      })
      .catch((err) => {
        dispatch(resetS3CameraSettingError());
      });
  };
}; */

/**End of S3 Camera Settings */

/**Start of Bulk updates */

const bulkSettingsUpdateSuccess = () => ({
  type: notificationSettingsTypes.BULK_UPDATE_SUCCESS,
});

export const resetNotificationSettingsFlags = () => ({
  type: notificationSettingsTypes.RESET_NOTIFICATION_SETTINGS_FLAGS,
});

export const getBulkSettingsUpdateLoading = () => ({
  type: notificationSettingsTypes.BULK_UPDATE_LOADING,
});

export const bulkSettingsUpdate = (data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    dispatch(getBulkSettingsUpdateLoading());
    const state = getState();
    let accountId = state?.user?.isSuperAdmin
      ? state.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;
    data.accountId = accountId;
    apiCall("/systemSettings/bulkUpdate", {
      method: "PUT",
      data,
    })
      .then((response) => {
        callbackAfterUpdate?.(true);
        dispatch(bulkSettingsUpdateSuccess());
        //dispatch(bulkSettingsUpdateSuccess(response.data.data.notificationSettings));
      })
      .catch((err) => {
        callbackAfterUpdate?.(true);
        dispatch(updateS3CameraSettingError());
      });
  };
};

export const bulkAlertSettingsUpdate = (data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    const state = getState();
    let accountId = state?.user?.isSuperAdmin
      ? state.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;
    data.accountId = accountId;
    apiCall("/alertsetting/bulkupdate/assetPermissions", {
      method: "PUT",
      data,
    })
      .then((response) => {
        callbackAfterUpdate && callbackAfterUpdate(true);
      })
      .catch((err) => {
        dispatch(updateS3CameraSettingError());
      });
  };
};

/**End of Bulk updates */

// Bulk Update OperatorCertificateSettings
const bulkUpdateOperatorCertificateSettingLoading = () => ({
  type: configTypes.BULK_UPDATE_OPERATOR_CERTIFICATE_LOADING,
});

const bulkUpdateOperatorCertificateSettingSuccess = (data) => ({
  type: configTypes.BULK_UPDATE_OPERATOR_CERTIFICATE_SUCCESS,
  data: data,
});

const bulkUpdateOperatorCertificateSettingError = () => ({
  type: configTypes.BULK_UPDATE_OPERATOR_CERTIFICATE_ERROR,
});

export const bulkUpdateOperatorCertificateSettings = (data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    dispatch(bulkUpdateOperatorCertificateSettingLoading());
    const state = getState();
    let accountId = state?.user?.isSuperAdmin
      ? state.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;
    data.accountId = accountId;
    apiCall("/user/bulkUpdate/operatorCertificateExpiryAlertSettings", {
      method: "PUT",
      data,
    })
      .then((response) => {
        callbackAfterUpdate && callbackAfterUpdate(true);
        dispatch(bulkSettingsUpdateSuccess());
        //dispatch(bulkSettingsUpdateSuccess(response.data.data.notificationSettings));
      })
      .catch((err) => {
        dispatch(bulkUpdateOperatorCertificateSettingError());
      });
  };
};

// Bulk Update Worksite Summary Alert Settings
const bulkUpdateWorksiteSummaryAlertSettingsLoading = () => ({
  type: configTypes.BULK_UPDATE_WORKSITE_ALERT_SETTINGS_LOADING,
});

const bulkUpdateWorksiteSummaryAlertSettingsSuccess = (data) => ({
  type: configTypes.BULK_UPDATE_WORKSITE_ALERT_SETTINGS_SUCCESS,
  data: data,
});

const bulkUpdateWorksiteSummaryAlertSettingsError = () => ({
  type: configTypes.BULK_UPDATE_WORKSITE_ALERT_SETTINGS_ERROR,
});

export const bulkUpdateWorksiteSummaryAlertSettings = (data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    dispatch(bulkUpdateWorksiteSummaryAlertSettingsLoading());
    const state = getState();
    let accountId = state?.user?.isSuperAdmin
      ? state.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;
    data.accountId = accountId;
    apiCall("/site/bulkUpdate/summaryalertsettings", {
      method: "PUT",
      data,
    })
      .then((response) => {
        callbackAfterUpdate && callbackAfterUpdate(true);
        dispatch(bulkSettingsUpdateSuccess());
        //dispatch(bulkSettingsUpdateSuccess(response.data.data.notificationSettings));
      })
      .catch((err) => {
        dispatch(bulkUpdateWorksiteSummaryAlertSettingsError());
      });
  };
};
