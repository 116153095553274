import React, { useState, useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { DropdownWrapper, Label, ErrorText } from "./Dropdown.component.styles";
import { ThemeContext } from "styled-components";

import PropTypes from "prop-types";

const DropdownComponent = (props) => {
  const {
    disabled,
    errorMessage,
    placeholder,
    isError,
    label,
    classNamePrefix,
    size,
    options,
    isLoading,
    isClearable,
    handleFilter,
    value,
    isSearchable,
    readOnly,
    ...rest
  } = props;

  const themeContext = useContext(ThemeContext);

  const DropdownFontStyle = {
    small: {
      fontSize: "16px",
      labelFontSize: "12px",
      boxHeight: "32px",
      errorFontSize: "15px",
      optioFontSize: "14px",
    },
    medium: {
      fontSize: "18px",
      labelFontSize: "13px",
      boxHeight: "36px",
      errorFontSize: "16px",
      optioFontSize: "16px",
    },
    large: {
      fontSize: "20px",
      labelFontSize: "14px",
      boxHeight: "40px",
      errorFontSize: "17px",
      optioFontSize: "18px",
    },
  };

  const customStyles = {
    groupHeading: (base) => ({
      ...base,
      textAlign: "left",
      paddingLeft: "10px",
    }),

    singleValue: (provided, state) => ({
      ...provided,
      textAlign: "left",
      color: state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL1 || "#323232",
      fontSize: DropdownFontStyle[size].fontSize,
      fontFamily: "Open Sans, sans-serif",
      "&:hover": {
        color: themeContext?.colors?.grayL1 || "#323232",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      display: "grid",
      padding: "0px 6px",
      color: state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL3 || "#676767",
      "&:hover": {
        color: themeContext?.colors?.grayL1 || "#323232",
      },
      lineHeight: "17px",
    }),
    inputContainer: (provided, state) => ({
      ...provided,
      color: state.isFocused
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL3 || "#676767",
      "&:hover": {
        color: themeContext?.colors?.grayL1 || "#323232",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      "&:hover": {
        color: themeContext?.colors?.grayL1 || "#323232",
      },
      position: "relative",
    }),
    option: (provided, state) => ({
      ...provided,
      //borderBottom: '0px dotted green',
      fontSize: DropdownFontStyle[size].fontSize,
      color: state.isSelected
        ? themeContext?.colors?.grayL1 || "#323232"
        : themeContext?.colors?.grayL1 || "#323232",
      background: state.isSelected
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      textAlign: "left",
      backgroundColor: state.isSelected
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      fontFamily: "Open Sans, sans-serif",
      whiteSpace: "nowrap",
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
      "&:hover": {
        background: themeContext?.colors?.grayL6 || "#F2F2F2",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      textAlign: "left",
      minHeight: DropdownFontStyle[size].boxHeight,
      backgroundColor: state.disabled
        ? themeContext?.colors?.grayL7 || "#FFFFFF"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      color: state.isFocused
        ? themeContext?.colors?.grayL1 || "#323232"
        : themeContext?.colors?.grayL3 || "#676767",
      boxShadow: state.isFocused
        ? themeContext?.colors?.themePrimary || "#0D5FBE"
        : themeContext?.colors?.grayL3 || "#676767",

      borderColor: state.isFocused
        ? themeContext?.colors?.themePrimary || "#0D5FBE"
        : state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL3 || "#676767",

      "&:hover": {
        borderColor: state.isFocused
          ? themeContext?.colors?.themePrimary || "#0D5FBE"
          : themeContext?.colors?.grayL1 || "#323232",
        color: themeContext?.colors?.grayL1 || "#323232",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL3 || "#676767",
      textAlign: "left",
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "max-content",
      fontFamily: "Open Sans, sans-serif",
      top: "auto",
      transform: "translateY(0%)",
      fontSize: DropdownFontStyle[size].fontSize,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: "0px",
      paddingRight: "2px",
    }),
  };

  return (
    <DropdownWrapper readOnly={readOnly} size={size} isError={isError} isDisabled={disabled}>
      <Label readOnly={readOnly} size={size} isDisabled={disabled}>
        <span tooltip={label} flow="right">
          {label}
        </span>
      </Label>
      <Select
        classNamePrefix="select"
        styles={customStyles}
        readOnly={readOnly}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        value={value}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        onChange={handleFilter}
        menuPosition={"fixed"}
        components={{
          MenuList: (props) => {
            const scrollToSelected = () => {
              const selectedOption = document.querySelector(".select__option--is-selected");
              if (selectedOption) {
                selectedOption.scrollIntoView({ block: "nearest", inline: "nearest" });
              }
            };
            useEffect(() => {
              scrollToSelected();
            }, []);
            return <components.MenuList {...props} />;
          },
        }}
        tooltip={true}
        {...rest}
      />
      {isError && <ErrorText size={size}>{errorMessage}</ErrorText>}
    </DropdownWrapper>
  );
};

DropdownComponent.propTypes = {
  /**
   * Specify whether the Input should be disabled, or not
   */
  disabled: PropTypes.bool,
  /**
   * How large should the input Component be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * label of input field
   */
  label: PropTypes.string.isRequired,
  /**
   * placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Specify whether the input has any Error
   */
  isError: PropTypes.bool,
  /**
   * input error message text
   */
  errorMessage: PropTypes.string,
  /**
   *  Provide a function to be called when the input changes
   */
  onChange: PropTypes.func,
  /**
   *  Whether to enable search functionality
   */
  isSearchable: PropTypes.bool,
  /**
   *  Is the select value clearable
   */
  isClearable: PropTypes.bool,
  /**
   *  Is the select in a state of loading (async)
   */
  isLoading: PropTypes.bool,
  /**
   *  Array of options that populate the select menu
   */
  options: PropTypes.arrayOf(PropTypes.object),
  /**
   * Whether we want to show icon in Right or not.
   */
  readOnly: PropTypes.bool,
};

DropdownComponent.defaultProps = { size: "small" };

export default DropdownComponent;

export { Select, components };
