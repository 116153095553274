import styled, { css, keyframes } from "styled-components";

export const FiltersWrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    display: block;
  }
`;

export const FiltersWrapper = styled.div`
  width: calc(100% - 50px);
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const RefreshButtonDiv = styled.div`
  cursor: pointer;
  overflow: hidden;
  float: right;

  display: flex;
  align-items: center;
  height: 40px;
  .icon.icon-update {
    transition: transform 0.5s;
  }
`;

export const RefreshButton = styled.div`
  /* width: 35px;
  height: 36px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top: 3px;
  float: right;
  &::after {
    content: "W";
    display: block;
    font-family: "siera-font-icons";
    font-size: 24px;
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }*/
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateAnimationCSS = css`
  ${rotateAnimation} 2s linear forwards;
`;

export const RefreshButtonD = styled.div`
  cursor: pointer;
  position: relative;
  font-size: 26px !important;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  line-height: 15px;
  animation: ${({ rotating }) => (rotating ? rotateAnimationCSS : "none")};
  @media screen and (max-width: 800px) {
    /*left: -10px;*/
  }
`;
