import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import UserSvg from "../../../assets/AddIcon/users.svg";
import { useTranslation } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { CarouselWrapperDiv, UserIconconDiv } from "./UsersCard.component.styles";
const UsersCard = ({ userCount }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Card.Body className="p-0">
        <CarouselWrapperDiv>
          <Row style={{ fontSize: "11px", color: "#919191" }}>
            <Col className="count-card">
              {t("Users")}
              <br />

              <UserIconconDiv className="icon icon-users">
                <span className="h3" style={{ verticalAlign: "middle" }}>
                  {userCount || 0}
                </span>
              </UserIconconDiv>
            </Col>
          </Row>
        </CarouselWrapperDiv>
      </Card.Body>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  userCount: state.users.totalCount,
});

export default connect(mapStateToProps, null)(UsersCard);
