import { modalTypes } from "./types";
import { baseUrl } from "../../../src/config";
import axios from "axios";

//To update add worksite modal state
export const setAddSiteModal = (data) => ({
  type: modalTypes.ADD_SITE_MODAL,
  data: data,
});

//To update the add asset modal state
export const setAddAssetModal = (data) => ({
  type: modalTypes.ADD_ASSET_MODAL,
  data: data,
});

//To update the add user modal state
export const setAddUserModal = (data) => ({
  type: modalTypes.ADD_USER_MODAL,
  data: data,
});

//To update the add asset type modal state
export const setAddAssetTypeModal = (data) => ({
  type: modalTypes.ADD_ASSET_TYPE_MODAL,
  data: data,
});

//To update the User Type type modal state
export const setAddUserTypeModal = (data) => ({
  type: modalTypes.ADD_USER_TYPE_MODAL,
  data: data,
});

export const setUpdateUserModal = (data) => ({
  type: modalTypes.UPDATE_USER_MODAL,
  data: data,
});

//To modal which wait in background to open state
export const setModalWaitingToOpen = (data) => ({
  type: modalTypes.MODAL_WAITING_FOR_OPEN,
  data: data,
});

export const setAddAssetModalRedirect = (data) => ({
  type: modalTypes.SET_ADD_ASSET_MODAL_REDIRECT,
  data: data,
});

//To edit alert modal
export const setEditAlertModal = (data) => ({
  type: modalTypes.SET_EDIT_ALERT_MODAL,
  data: data,
});

//To update the add checklist modal state
export const setAddChecklistModal = (data) => ({
  type: modalTypes.ADD_CHECKLIST_MODAL,
  data: data,
});
