import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import AssetSvg from "../../../assets/AddIcon/assets.svg";
import { Trans, useTranslation } from "react-i18next";
import { CarouselWrapperDiv, AssetIconconDiv } from "./MultiCardsWorksite.styles.component";

const MultiCardsWorksite = ({ data }) => {
  return (
    <>
      <Card>
        <Card.Body className="p-0">
          <CarouselWrapperDiv>
            <Row style={{ fontSize: "11px", color: "#919191" }}>
              <Col className="count-card">
                <Trans>Assets</Trans>
                <br />
                <AssetIconconDiv className="icon icon-assets">
                  <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                    {data?.assets?.length || 0}
                  </span>
                </AssetIconconDiv>
              </Col>
            </Row>
          </CarouselWrapperDiv>
        </Card.Body>
      </Card>
    </>
  );
};

export default MultiCardsWorksite;
