import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as EditIcon } from "../assets/AddIcon/edit.svg";
import SubmitIcon from "../assets/AddIcon/SubmitIcon.png";
import { CircularProgress } from "@material-ui/core";

// Button Component
import Button from "../components/Button/Button.component";

import { Trans, useTranslation } from "react-i18next";
import styles from "./UpdateButtons.css";

function UpdateButtons({
  editOverview,
  setEditOverview,
  statusButton,
  handleUpdate,
  IsLoading,
  overviewDetail,
  handleCancel,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Col className="text-right">
        {!editOverview && (
          <Button
            label={t("Cancel")}
            onClick={() => {
              setEditOverview(true);
              handleCancel && handleCancel();
            }}
            showIcon={false}
            buttonType="ghost"
          />
        )}

        {editOverview ? (
          <Button
            iconClass="edit"
            label={t("Edit")}
            onClick={() => {
              setEditOverview(!editOverview);
            }}
            disabled={!statusButton}
            showIcon
            buttonType="primary"
          />
        ) : (
          <Button
            iconClass="save"
            label={t("Save")}
            id={overviewDetail ? overviewDetail._id : ""}
            onClick={(event) => {
              handleUpdate(event);
            }}
            showIcon
            buttonType="save"
          />
        )}
      </Col>
    </>
  );
}

export default UpdateButtons;
