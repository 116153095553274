import React from "react";
import styled from "styled-components";
import SelectSwitch from "../../src/assets/select-switch.svg";
import CancelSwitch from "../../src/assets/cancel-switch.svg";
import PropTypes from "prop-types";

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px;
  padding: 0px;
  margin: 0.5rem;
  input {
    display: none;
  }
  input:checked + .slider-toggle {
    background-color: #11a70b;
  }
  input:checked + .slider-toggle:hover {
    background-color: #0d8908;
  }
  input:checked + .slider-toggle:active {
    /* box-shadow: 0 0 1px #0d5fbe;
    border: 0.7px solid #0d5fbe;
    outline: 2px solid #0d8908;*/
  }
  input:checked + .slider-toggle:focus {
    border: 1px solid #0d5fbe;
  }
  input:checked + .slider-toggle.disabled {
    background-color: #c6c6c6;
    cursor: not-allowed;
  }
  input + .slider-toggle.disabled {
    background-color: #c6c6c6;
    cursor: not-allowed;
  }
  input:checked + .slider-toggle.disabled:focus {
    border: 1px solid #c6c6c6;
  }
  input:active + .slider-toggle {
    /* box-shadow: 0 0 1px #0d5fbe;
    border: 0.7px solid #0d5fbe;
    outline: 2px solid #0d8908;*/
  }
  input:checked + .slider-toggle:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  input:checked + .slider-toggle:after {
    content: "";
    background: url(${SelectSwitch});
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
    top: 48%;
    left: 30%;
    font-size: 14px;
  }
`;
const SpanWrapper = styled.span`
  padding: 0px;
  margin: 0px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #707070;
  transition: 0.4s;
  border-radius: 2rem;
  &:after {
    content: "";
    background: url(${CancelSwitch});
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 48%;
    left: 70%;
    font-size: 14px;
    font-family: Verdana, sans-serif;
  }
  &:before {
    position: absolute;
    padding: 0px;
    margin: 0px;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 2rem;
  }
`;
const SwitchComponent = ({ id, checked, onChange, name, onClick, disabled, ...rest }) => (
  <SwitchWrapper className="switch m-2">
    <input
      id={id}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      name={name}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    />
    <SpanWrapper className={disabled ? "slider-toggle disabled" : "slider-toggle"}></SpanWrapper>
  </SwitchWrapper>
);

SwitchComponent.propTypes = {
  /**
   * Specify whether the control is checked
   */
  checked: PropTypes.bool,

  /**
   * Provide an event listener that is called when the control is clicked
   */

  onChange: PropTypes.func.isRequired,

  /**
   * 	Whether this control should be disabled
   */

  disabled: PropTypes.bool,
};

SwitchComponent.defaultProps = {
  checked: true,
  disabled: false,
};

export default React.memo(SwitchComponent);
