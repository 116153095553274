import styled from "styled-components";
export const AssetTypeChecklistWrapper = styled.div`
  width: 95%;
  margin: auto;
  .noReportsDiv {
    font-size: 14px !important;
    height: 50px !important;
    margin-top: 10px;
  }
`;
export const DeleteIconStyled = styled.div`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  cursor: pointer;
  text-align: center;
`;

export const RadioWrapper = styled.div`
  text-align: center;
  label,
  div {
    margin-bottom: 0;
  }
`;

export const TableWrapper = styled.div`
  .noReportsDiv {
    display: none;
  }
`;

export const DropdownDiv = styled.div`
  display: flex;
  margin: 10px auto;
`;
export const DropdownSelect = styled.div`
  flex: 4;
  .select__control {
    width: 60%;
  }
  label {
    display: none;
  }
`;
export const DeleteButton = styled.div`
  float: right;
  width: 100px;
  align-content: center;
  align-self: center;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
`;

export const NoChecklistAdded = styled.div`
  height: calc(100vh - 550px);
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  text-align: center;
`;
