import styled from "styled-components";

export const UserTypeManagementDiv = styled.div``;
export const UserTypeMainContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const ButtonsContainer = styled.div`
  display: block;
  float: left;
  width: 100%;
  text-align: left;
  margin: 10px 0px;

  button {
    margin-right: 10px;
  }
`;
export const UerTypeMainContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const UserTypeListing = styled.div`
  width: 340px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
`;

export const UserTypeDataList = styled.div`
  border-radius: 3px;
  width: 98%;
  margin-left: 0% !important;
`;

export const SearchContainer = styled.div`
  div {
  }
`;
