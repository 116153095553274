import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Trans } from "react-i18next";
import OperatorCertificate from "./OperatorCertificate.component";
import { ReactComponent as SaveIcon } from "../../../../../assets/AddIcon/SubmitIcon.svg";
import {
  OperatorSaveCancel,
  OperatorButtonCancel,
  OperatorSaveButton,
} from "./OperatorCertificatesTab.styles";
import { toValidateUserName } from "../../../../../utils/validations/checkValidation";
import ModalComponent from "../../../../../components/ModalPopupComponent/ModalPopup.component";

export function AddEditModal(props) {
  const { toggleModal, headerLabel, certificate, saveHandler, setCertificate, show } = props;
  const [errors, setErrors] = useState({});

  const changeHandler = (field, e) => {
    if (field === "issueDate" || field === "expiryDate") {
      setCertificate({
        ...certificate,
        [field]: e,
      });
    } else {
      e.preventDefault();
      setCertificate({
        ...certificate,
        [field]: e.target.value,
      });
    }
    setErrors({ ...errors, [field]: false });
  };

  const vehicleClassesChangeHandler = (valueArr) => {
    setCertificate({
      ...certificate,
      certifiedVehicleClasses: valueArr,
    });

    setErrors({ ...errors, certifiedVehicleClasses: false });
  };

  const validateForm = () => {
    const { issueDate, expiryDate, certifiedVehicleClasses, certificateNumber, certifyingOrganization } =
      certificate || {};
    const errors = {};
    if (!issueDate) {
      errors.issueDate = true;
    }
    if (!expiryDate) {
      errors.expiryDate = true;
    }

    if (certificateNumber) {
      if (toValidateUserName(certificateNumber)) errors.certificateNumber = true;
    }

    if (certifyingOrganization) {
      if (toValidateUserName(certifyingOrganization)) errors.certifyingOrganization = true;
    }

    if (!certifiedVehicleClasses || certifiedVehicleClasses.length === 0) {
      errors.certifiedVehicleClasses = true;
    }
    setErrors(errors);
    return errors;
  };

  const validateBeforeSave = () => {
    const errors = validateForm();
    if (Object.keys(errors).length !== 0) {
      return;
    } else {
      saveHandler();
    }
  };

  return (
    <ModalComponent
      show={show}
      title={headerLabel}
      size="lg"
      handleClose={() => toggleModal(false)}
      withHeader={true}
    >
      <Container>
        <OperatorCertificate
          certificate={certificate}
          changeHandler={changeHandler}
          vehicleClassesChangeHandler={vehicleClassesChangeHandler}
          errors={errors}
        />
        <Row>
          <Col>
            <OperatorSaveCancel>
              <OperatorButtonCancel onClick={toggleModal}>CANCEL</OperatorButtonCancel>
              <OperatorSaveButton onClick={validateBeforeSave}>
                <SaveIcon />
                <Trans>SAVE</Trans>
              </OperatorSaveButton>
            </OperatorSaveCancel>
          </Col>
        </Row>
      </Container>
    </ModalComponent>
  );
}

export default AddEditModal;
