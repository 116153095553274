import React, { useEffect } from "react";
import Slider from "../CameraSettingView/slider";
import ForkliftTopViewSVG from "../../../../../assets/CameraSettings/Forklift_Topview.svg";
import "../CameraSetting.css";
import { widthDataConst, widthDataConstFeet } from "../constants";

const CameraWidthDepthView = (props) => {
  const {
    forkliftClass,
    isDepth,
    selectedCamera,
    inputValue,
    setInputValue,
    obstacle,
    value,
    setValue,
    setTabKey,
    unitType,
    isFeet,
    s3SettingsUntouchedObject,
  } = props;

  let canvas = document.getElementById("myCanvas");

  useEffect(() => {
    if (canvas) {
      return;
    }
    canvas = document.getElementById("myCanvas");
  }, [value]);

  useEffect(() => {
    let tempPositionPedestrian = {};
    let tempPositionObject = {};
    //let newS3CameraForm = { ...s3CameraForm };
    let currentWidthConst = isFeet ? widthDataConstFeet : widthDataConst;
    // newS3CameraForm[selectedCamera] = getCameraRawObject({ number: selectedCamera, name: forkliftClass }, inputValue);
    // setS3CameraForm({ ...newS3CameraForm });
    currentWidthConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["pedestrian"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionPedestrian[type] = newValue;
    });
    currentWidthConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["object"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionObject[type] = newValue;
    });
    let newValue = { ...value };
    let pos4Object = tempPositionObject?.pos4;
    let pos4Pedestrian = tempPositionPedestrian?.pos4;
    if (isNaN(pos4Object) || isNaN(pos4Pedestrian)) {
      return;
    }
    newValue["pedestrian"] = { ...tempPositionPedestrian };
    newValue["object"] = { ...tempPositionObject };
    setValue({ ...newValue });
  }, [inputValue]);

  var rangeSlider1 = document.getElementById("rs-range-line-1");
  var rangeBullet1 = document.getElementById("rs-bullet-1");
  var rangeInput1 = document.getElementById("rs-input-1");
  var rangeSlider2 = document.getElementById("rs-range-line-2");
  var rangeBullet2 = document.getElementById("rs-bullet-2");
  var rangeInput2 = document.getElementById("rs-input-2");
  var rangeSlider3 = document.getElementById("rs-range-line-3");
  var rangeBullet3 = document.getElementById("rs-bullet-3");
  var rangeInput3 = document.getElementById("rs-input-3");

  const drawingForFeet = () => {
    let { pos1, pos2, pos3 } = value[obstacle];
    pos1 = parseFloat(pos1);
    pos2 = parseFloat(pos2);
    pos3 = parseFloat(pos3);
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, 190);
    var bulletPosition1 = pos1 / rangeSlider1?.max;
    var bulletPosition2 = pos2 / rangeSlider2?.max;
    var bulletPosition3 = pos3 / rangeSlider3?.max;
    if (rangeBullet1) {
      rangeBullet1.style.left = bulletPosition1 * 102 + "px";
      ctx.lineTo(pos2 * 2.1 + 46, 141 - pos2 * 2.08);
      if (pos1 >= pos2) {
        ctx.lineTo(pos1 * 2 + 50, 141 - pos2 * 2.08);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 140 - pos1 * 2);
      }

      if (pos1 >= pos3) {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos3 * 2);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos1 * 2);
      }

      ctx.lineTo(pos3 * 2.04 + 48, 240 + pos3 * 2);

      ctx.lineTo(0, 190);
      ctx.closePath();
      ctx.fillStyle = "rgb(14, 95, 190, 0.7)";
      ctx.strokeStyle = "#7a7a51";
      ctx.stroke();
      ctx.fill();
    }
    if (rangeBullet2) {
      rangeBullet2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeBullet3) {
      rangeBullet3.style.left = bulletPosition3 * 102 + "px";
    }
    if (rangeInput1) {
      rangeInput1.style.left = bulletPosition1 * 102 + "px";
    }
    if (rangeInput2) {
      rangeInput2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeInput3) {
      rangeInput3.style.left = bulletPosition3 * 102 + "px";
    }
  };

  const drawingForMM = () => {
    let { pos1, pos2, pos3 } = value[obstacle];
    pos1 = parseInt(pos1);
    pos2 = parseInt(pos2);
    pos3 = parseInt(pos3);
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, 190);
    var bulletPosition1 = pos1 / rangeSlider1?.max;
    var bulletPosition2 = pos2 / rangeSlider2?.max;
    var bulletPosition3 = pos3 / rangeSlider3?.max;
    if (rangeBullet1) {
      rangeBullet1.style.left = bulletPosition1 * 102 + "px";
      ctx.lineTo(pos2 * 2.1 + 46, 141 - pos2 * 2.08);
      if (pos1 >= pos2) {
        ctx.lineTo(pos1 * 2 + 50, 141 - pos2 * 2.08);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 140 - pos1 * 2);
      }

      if (pos1 >= pos3) {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos3 * 2);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos1 * 2);
      }

      ctx.lineTo(pos3 * 2.04 + 48, 240 + pos3 * 2);

      ctx.lineTo(0, 190);
      ctx.closePath();
      ctx.fillStyle = "rgb(14, 95, 190, 0.7)";
      ctx.strokeStyle = "#7a7a51";
      ctx.stroke();
      ctx.fill();
    }
    if (rangeBullet2) {
      rangeBullet2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeBullet3) {
      rangeBullet3.style.left = bulletPosition3 * 102 + "px";
    }
    if (rangeInput1) {
      rangeInput1.style.left = bulletPosition1 * 102 + "px";
    }
    if (rangeInput2) {
      rangeInput2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeInput3) {
      rangeInput3.style.left = bulletPosition3 * 102 + "px";
    }
  };

  useEffect(() => {
    if (unitType?.toLowerCase() === "metric") {
      drawingForMM();
    } else {
      drawingForFeet();
    }
  }, [value, inputValue, obstacle, selectedCamera]);

  const unTouchedNearMissDetectionObj = s3SettingsUntouchedObject?.[0]?.nearMissDetection?.[obstacle];

  return (
    <div style={{ postion: "relative" }} className="camera_width_depth_style nearMiss">
      <div className="width-text">
        <p>
          Move the slider {isDepth ? "right" : "up"} or {isDepth ? "left" : "down"} to adjust the values
        </p>
      </div>
      <canvas id="myCanvas" className="canvas" width="310" height="370"></canvas>
      <img
        src={ForkliftTopViewSVG}
        style={{ width: "150px", height: "100px" }}
        className={`forklift-${forkliftClass?.toLowerCase()}`}
        alt="Forklift Top View"
      />
      <div className="square-red" />
      <div className="dot-line-1" />
      <div className="dot-line-2" />
      <div className="dot-line-3" />
      <div className="dot-line-4" />
      <div className="dot-line-5" />
      <div className="dot-line-6" />
      <div className="dot-line-7" />
      <div className="container parent">
        <Slider
          unitType={unitType}
          setTabKey={setTabKey}
          obstacle={obstacle}
          isDepth={isDepth}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos1"
          min={isFeet ? 0.495 : 150}
          max={isFeet ? 32.8 : 10000}
          untouchedValueinMM={unTouchedNearMissDetectionObj?.depth}
        />
        <Slider
          unitType={unitType}
          setTabKey={setTabKey}
          obstacle={obstacle}
          isDepth={isDepth}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos2"
          min={isFeet ? 1.97 : 600}
          max={isFeet ? 9.84 : 3000}
          untouchedValueinMM={Math.abs(unTouchedNearMissDetectionObj?.left)}
        />
        <Slider
          unitType={unitType}
          setTabKey={setTabKey}
          obstacle={obstacle}
          isDepth={isDepth}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos3"
          min={isFeet ? 1.97 : 600}
          max={isFeet ? 9.84 : 3000}
          untouchedValueinMM={Math.abs(unTouchedNearMissDetectionObj?.right)}
        />
      </div>
    </div>
  );
};

export default CameraWidthDepthView;
