import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";

import { StyledInformationIcon } from "./WorksiteButtonPanel.styles";
import Button from "../../../../../components/Button/Button.component";

const defaultShiftSettings = { shiftDuration: 8, shiftTimeUnit: "hours" };

const WorksiteButtonsPanel = (props) => {
  const { addWorksiteLoading, activeStep, setActiveStep, shiftSettings, setShiftSettings } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    setActiveStep(0);
  };

  const addShiftDuration = () => {
    setShiftSettings(defaultShiftSettings);
  };

  const isSaveDisabled = () => {
    const test =
      shiftSettings && (!shiftSettings.shiftDuration || parseInt(shiftSettings.shiftDuration, 10) === 0);
    return test;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      <Trans>Define the number of hours in a shift, in this worksite</Trans>
    </Tooltip>
  );

  return (
    <>
      <div className="w-100 float-right">
        {activeStep === 1 ? (
          <div className="text-left float-left">
            <Button
              onClick={addShiftDuration}
              label={t("Add Shift Duration")}
              showIcon
              iconClass="add"
              buttonType="primary"
              isActive={!shiftSettings}
              disabled={!!shiftSettings}
            />

            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <span>
                <StyledInformationIcon />
              </span>
            </OverlayTrigger>
          </div>
        ) : null}
        <div className="text-right float-right">
          {activeStep !== 0 && (
            <Button
              label={t("Back")}
              showIcon
              iconClass="arrow-left"
              buttonType="ghost"
              onClick={() => handleBack()}
              disabled={activeStep === 0}
            />
          )}

          {activeStep === 1 ? (
            <>
              {isLoading ? (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              ) : (
                <>
                  <Button
                    label={
                      addWorksiteLoading ? (
                        <CircularProgress size={25} style={{ color: "#fff" }} />
                      ) : (
                        t("Submit")
                      )
                    }
                    showIcon
                    iconClass="save"
                    buttonType="save"
                    disabled={activeStep === 1 && isSaveDisabled()}
                  />
                </>
              )}
            </>
          ) : (
            <Button
              style={{ float: "inline-end" }}
              label={
                addWorksiteLoading ? <CircularProgress size={25} style={{ color: "#fff" }} /> : t("Next")
              }
              iconClass="arrow-right"
              showIcon
              iconPosition="after"
              buttonType="primary"
              disabled={activeStep === 1 && isSaveDisabled()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WorksiteButtonsPanel;
