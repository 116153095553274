import React from "react";
import styled from "styled-components";
import SelectSwitch from "../../../src/assets/select-switch.svg";
import CancelSwitch from "../../../src/assets/cancel-switch.svg";
import PropTypes from "prop-types";

const SwitchSizeStyle = {
  small: {
    wrapWidth: "44px",
    wrapHeight: "20px",
    beforeWidth: "16px",
    beforeHeight: "16px",
  },
  medium: {
    wrapWidth: "48px",
    wrapHeight: "24px",
    beforeWidth: "20px",
    beforeHeight: "20px",
  },
  large: {
    wrapWidth: "52px",
    wrapHeight: "28px",
    beforeWidth: "24px",
    beforeHeight: "24px",
  },
};

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: ${(props) => SwitchSizeStyle[props.size].wrapWidth};
  height: ${(props) => SwitchSizeStyle[props.size].wrapHeight};
  padding: 0px;
  margin: 0.5rem;
  input {
    display: none;
  }
  input:checked + .slider-toggle {
    background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  }
  input:checked + .slider-toggle:hover {
    background-color: ${({ theme }) => theme.colors.greenHover || "#0d8908"};
  }
  input:checked + .slider-toggle:active {
    /* box-shadow: 0 0 1px #0d5fbe;
    border: 0.7px solid #0d5fbe;
    outline: 2px solid #0d8908;*/
  }
  input:checked + .slider-toggle:focus {
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
  input:checked + .slider-toggle.disabled {
    background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
  }
  input + .slider-toggle.disabled {
    background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
  }
  input:checked + .slider-toggle.disabled:focus {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  input:active + .slider-toggle {
    /* box-shadow: 0 0 1px #0d5fbe;
    border: 0.7px solid #0d5fbe;
    outline: 2px solid #0d8908;*/
  }
  input:checked + .slider-toggle:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  input:checked + .slider-toggle:after {
    content: "";
    background: url(${SelectSwitch});
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    top: 48%;
    left: 30%;
    font-size: 14px;
  }
`;
const SpanWrapper = styled.span`
  padding: 0px;
  margin: 0px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  transition: 0.4s;
  border-radius: 2rem;
  &:after {
    content: "";
    background: url(${CancelSwitch});
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 48%;
    left: 70%;
    font-size: 14px;
    font-family: Verdana, sans-serif;
  }
  &:before {
    position: absolute;
    padding: 0px;
    margin: 0px;
    content: "";
    height: ${(props) => SwitchSizeStyle[props.size].beforeHeight};
    width: ${(props) => SwitchSizeStyle[props.size].beforeWidth};
    left: 2px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 2rem;
  }
`;
const SwitchComponent = ({ id, checked, onChange, name, onClick, disabled, size, ...rest }) => (
  <SwitchWrapper className="switch m-2" size={size}>
    <input
      id={id}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      name={name}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    />
    <SpanWrapper size={size} className={disabled ? "slider-toggle disabled" : "slider-toggle"}></SpanWrapper>
  </SwitchWrapper>
);

SwitchComponent.propTypes = {
  /**
   * Specify whether the control is checked
   */
  checked: PropTypes.bool,

  /**
   * Provide an event listener that is called when the control is clicked
   */

  onChange: PropTypes.func.isRequired,

  /**
   * 	Whether this control should be disabled
   */

  disabled: PropTypes.bool,

  /**
   * How large should the input Component be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

SwitchComponent.defaultProps = {
  checked: true,
  disabled: false,
  size: "small",
};

export default React.memo(SwitchComponent);
