/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import SettingsSection from "../../../../components/SettingsSection";
import {
  getNotificationSettingsByAccount,
  getNotificationSettingsBySite,
  updateAccountSummaryAlertSettings,
  resetAccountSummaryAlertSettings,
} from "../../../../redux/notification/action";
import { isEqual } from "../../../../utils/validations/utilities";

import { SettingWrapper } from "./NotificationSetting.component.styles";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../redux/dropdownFilters/action";
import ActionsBar from "./ActionsBar";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, SettingsContainerAS, SettingsFooter } from "../Settings.Styles";
import SnackBar from "../../SnackBar";
import { NoAssetWrapper } from "./../common/NoAssetFound.styles";
import Recipients from "./emailerRecipients/Recipients";
import { handleRecipientsChangeUtil } from "./common";
import validationConstant from "../../../../constant/messages/validation.json";

const AccountSummarySettings = ({
  loginDetails,
  notificationSetting,
  isSuperAdmin,
  getNotificationSettingsByAccount,
  dropdownAccountId,
  updateAccountSummaryAlertSettings,
  resetAccountSummaryAlertSettings,
  loading,
}) => {
  const [accountSummaryAlertSettings, setAccountSummaryAlertSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  useEffect(() => {
    setCurrentAccountId(isSuperAdmin ? dropdownAccountId : loginDetails?.accountId);
  }, [dropdownAccountId, loginDetails?.accountId, isSuperAdmin]);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentAccountId) {
      getNotificationSettingsByAccount(currentAccountId);
    }
  }, [currentAccountId]);

  const setDefaultNotificationSettingsAccount = () => {
    setAccountSummaryAlertSettings(notificationSetting?.accountSummaryAlertSettings);
  };

  const hideButtonsForWorksiteSettings = isEqual(
    notificationSetting?.accountSummaryAlertSettings,
    accountSummaryAlertSettings
  );

  useEffect(() => {
    if (notificationSetting) {
      setDefaultNotificationSettingsAccount();
    }
  }, [notificationSetting]);

  const handleAccountSummaryAlertChange = (field, value) => {
    setAccountSummaryAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const onSubmitAccount = () => {
    const data = {
      accountSummaryAlertSettings: accountSummaryAlertSettings,
    };
    updateAccountSummaryAlertSettings(currentAccountId, data, setIsUpdateLoading);
  };

  const displaySettings = {
    accountSummaryAlert: {
      title: "accountSummaryAlert",
      description: "accountSummaryAlertDescription",
      tableHeader: ["Duration", "Alert"],
      onChange: handleAccountSummaryAlertChange,
      tableData: [
        {
          label: "Weekly",
          value: !!accountSummaryAlertSettings?.weekly,
          field: "weekly",
        },
        {
          label: "Monthly",
          value: !!accountSummaryAlertSettings?.monthly,
          field: "monthly",
        },
      ],
    },
  };

  const onReset = () => {
    resetAccountSummaryAlertSettings(currentAccountId, setIsUpdateLoading);
  };

  const handleCloseSnackBar = () => {
    setIsUpdateLoading(false);
  };

  const handleRecipientsChange = (userType, isChecked) => {
    handleRecipientsChangeUtil(
      userType,
      isChecked,
      accountSummaryAlertSettings,
      setAccountSummaryAlertSettings
    );
  };

  const handleCustomEmailsChange = (email) => {
    const temp = {
      ...accountSummaryAlertSettings,
      customEmails: [...email],
    };
    setAccountSummaryAlertSettings(temp);
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("A summary of your account will be sent based on the time span selected below.")}
        showFilters={false}
      />
      {isSuperAdmin && !dropdownAccountId ? (
        <NoAssetWrapper>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      ) : (
        <>
          <SettingsContainerAS>
            <SettingWrapper>
              <SettingsSection
                {...displaySettings.accountSummaryAlert}
                isLoading={isUpdateLoading || loading}
              />
              <Recipients
                customEmails={accountSummaryAlertSettings?.customEmails}
                handleCustomEmailsChange={handleCustomEmailsChange}
                recipients={accountSummaryAlertSettings?.userTypes}
                handleRecipientsChange={handleRecipientsChange}
              />
            </SettingWrapper>
          </SettingsContainerAS>

          <SettingsFooter>
            <ActionsBar
              showAppyToAll={false}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleUpdate={onSubmitAccount}
              handleCancel={setDefaultNotificationSettingsAccount}
              isLoading={isLoading}
              showWorksiteSelection={false}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={checkApplyToAll}
              setCheckApplyToAll={setCheckApplyToAll}
              handleReset={onReset}
            />
          </SettingsFooter>
          <SnackBar
            isSuccess={isUpdateLoading}
            label={t(validationConstant.notification_settings_updated)}
            handleClose={handleCloseSnackBar}
          />
        </>
      )}
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    isSuperAdmin: state.user.isSuperAdmin,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,
    notificationSetting: state.notificationSetting,
    dropdownAccountId: state.dropdownFilters?.settings?.accountId,
    loading: state.notificationSetting.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (assetTypeId) => dispatch(setDropdownWorksiteId(assetTypeId)),
  getNotificationSettingsByAccount: (id) => dispatch(getNotificationSettingsByAccount(id)),
  getNotificationSettingsBySite: (id) => dispatch(getNotificationSettingsBySite(id)),
  updateAccountSummaryAlertSettings: (id, data, isUpdateLoading) =>
    dispatch(updateAccountSummaryAlertSettings(id, data, isUpdateLoading)),
  resetAccountSummaryAlertSettings: (id, isUpdateLoading) =>
    dispatch(resetAccountSummaryAlertSettings(id, isUpdateLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummarySettings);
