import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import List from "../../../../components/List";
import {
  selectChecklistTemplateOverview,
  getAllChecklist,
  getAllChecklistTemplates,
  setUpdateEditTemplate,
} from "../../../../redux/checklists/action";
import { setAddChecklistModal } from "../../../../redux/modals/action";
import { useTranslation } from "react-i18next";
import ChecklistTemplatesStyle from "./ChecklistTemplatesStyle.module.css";
const listType = require("../../../../constant/listType");

function ChecklistList({
  history,
  checklistTemplates,
  selectChecklistTemplateOverview,
  filterAccountId,
  checklistTemplateOverview,
  getAllChecklist,
  totalCount,
  getAllChecklistTemplates,
  setUpdateEditTemplate,
  setAddChecklistModal,
}) {
  //const [SearchText, setSearchText] = useState("");
  const { t } = useTranslation();

  const listDetail = {
    label: t("Checklists Templates"),
    emptyListText: t("No Checklists Templates"),
    listType: listType.CHECKLIST_TEMPLATE,
  };

  useEffect(() => {
    selectChecklistTemplateOverview();
  }, [checklistTemplates]);

  const updateEditTemplate = (id) => {
    selectChecklistTemplateOverview(id);
    if (!checklistTemplateOverview) {
      return;
    }
    let newChecklist = {
      checklistName: checklistTemplateOverview.checklistTemplateName,
      checklistDescription: checklistTemplateOverview.checklistTemplateDescription,
      sections: {},
    };
    let newData = checklistTemplateOverview?.sections.map((section, i) => {
      newChecklist.sections[i] = { ...section, questions: {} };
      let sectionData = section?.questions.map((question, j) => {
        newChecklist.sections[i].questions[j] = {
          ...question,
          failedResponse: question?.failedResponse?.trim(),
          responseTypeId: question?.responseTypeId?._id,
          controlType: question?.responseTypeId?.controlType,
          failedResponseText: question?.responseTypeId?.responseText,
        };
      });
    });
    setUpdateEditTemplate(newChecklist);
    history.push("/app/checklistmanagement");
    setAddChecklistModal(true);
  };

  return (
    <div className={`checklist_list_table ${ChecklistTemplatesStyle.checklistpage}`}>
      {/* <div className="searchBar">
        <input
          id="searchQueryInput"
          type="text"
          name="searchQueryInput"
          placeholder={t("Search a template")}
          onChange={(e) => setSearchText(e.target.value)}
          value={SearchText}
        />
        <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
          <svg style={{ width: "24px", height: "24px", transform: "rotate(84deg)" }} viewBox="0 0 24 24">
            <path
              fill="#666666"
              d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
            />
          </svg>
        </button>
      </div> */}
      <div className={`${ChecklistTemplatesStyle.checklistListing}`}>
        <List
          listDetail={listDetail}
          listData={checklistTemplates}
          selectItem={(id) => selectChecklistTemplateOverview(id)}
          selectedId={checklistTemplateOverview && checklistTemplateOverview._id}
          isFilterChange={filterAccountId}
          loadData={getAllChecklistTemplates}
          totalCount={totalCount}
          updateEditTemplate={updateEditTemplate}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistTemplates: state.checklist.checklistTemplates,
  checklistTemplateOverview: state.checklist.checklistTemplateOverview,
  totalCount: state.checklist.templateTotalCount,
});

const mapDispatchToProps = (dispatch) => ({
  selectChecklistTemplateOverview: (checklistTempId) =>
    dispatch(selectChecklistTemplateOverview(checklistTempId)),
  getAllChecklistTemplates: (paginationData, isResetState) =>
    dispatch(getAllChecklistTemplates(paginationData, isResetState)),
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
  setUpdateEditTemplate: (data) => dispatch(setUpdateEditTemplate(data)),
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistList));
