import styled from "styled-components";

export const RegistrationFormWrapper = styled.div`
  .password-rules {
    text-align: left;
  }
  .label {
    text-align: left;
    .form-label {
      color: #6e6e6e;
      margin-bottom: 2px;
    }
  }
`;

export const PasswordRulesWrapper = styled.div`
  color: ${({ isError }) => (isError ? "#e01c1c" : "inherit")};
  border: ${({ isError }) => (isError ? "1px solid #e01c1c" : "1px solid transparent")};
  padding: 10px;
  border-radius: 4px;
  font-size: 14px !important;
  font-family: Open Sans;
  text-align: "center";
  transition: border 0.5s;
`;

export const FieldLabel = styled.div`
  //opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.5s;
`;
