import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import UserBulkUploadForm from "../../User/UserBulkUploadForm.component";
import UserManagement from "../../User/UserManagement.component";
import AddUserStepper from "../../User/AddUserStepper.component";
import { userList, defaultUserOverview } from "../../../../redux/userManagement/userManagement.action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { setModalWaitingToOpen, setAddUserModal, setEditAlertModal } from "../../../../redux/modals/action";
import { setShowFilters } from "../../../../redux/mobileView/action";
import ManagementPanelStyles from "./ManagementPanel.module.css";

// Button Component
import Button from "../../../../components/Button/Button.component";

import UserActionBar from "../../User/UserActionBar.component";
import UsersCard from "../../User/UsersCard.component";
import SearchBox from "../../../../components/SearchBox.component";
import UserFilters from "../../User/UserFilters.component";
import UserList from "../../User/UserList.component";
import UserOverview from "../../User/UserOverview.component";
import { ReactComponent as FilterIcon } from "../../../../assets/filtericon.svg";
import rightIcon from "../../../../assets/rightIconwhite.svg";
import CommonSuccessMessage from "../../common/CommonSuccessMessage.component";
import { ModalContentWrapperDiv, UserBulkUploadModal } from "./userPanel.component.styles";
import ModalPopup from "../../../../components/ModalPopupComponent/ModalPopup.component";

import styled from "styled-components";
const UserPanelStyled = styled.div`
  .assetMobileCss {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  }
  .filterButtonCss {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;

const FilterIconStyled = styled.div`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  position: relative;
  top: 3px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  demo1: {
    paddingTop: "10px",
    backgroundColor: "#ffffff",
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  addAccountText: {
    fontFamily: "Kanit",
    fontSize: "25px",
    fontStyle: "normal",
  },
  bulkIcon: {
    marginBottom: "3px",
    width: 18,
    stroke: "#0D5FBE",
    fill: "#0D5FBE",
    strokeWidth: "2px",
  },
}));

function CustomizedTabs(props) {
  let {
    history,
    setAddUserModal,
    updateActiveNavItem,
    setModalWaitingToOpen,
    editForm,
    setEditAlertModal,
    setShowFilters,
    showFilters,
    userName,
    showActionBar,
  } = props;

  const [worksiteBulkUploadShow, setWorksiteBulkUploadShow] = useState(false);
  const [pendingList, setPendingList] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [UserTypeFilter, setUserTypeFilter] = useState("all");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("users");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <UserPanelStyled>
      {/* {usersLoading && <Loader />} */}
      <div className={`${ManagementPanelStyles.desktopManagementPanel}`}>
        <UserActionBar
          pendingList={pendingList}
          setPendingList={setPendingList}
          setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
          history={history}
          props={props}
        />

        <UserManagement pendingList={pendingList} />
      </div>
      <div className={`${ManagementPanelStyles.mobileManagementPanel}`}>
        {showActionBar && (
          <UserActionBar
            pendingList={pendingList}
            setPendingList={setPendingList}
            setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
            history={history}
            props={props}
          />
        )}

        {!showUserDetail ? (
          <>
            <UsersCard />
            <div className={`${ManagementPanelStyles.searchBoxFilter}`}>
              <div style={{ width: "88%" }}>
                <div className={`${ManagementPanelStyles.searchMobileView}`}>
                  <SearchBox
                    searchText={SearchText}
                    setSearchText={setSearchText}
                    placeholder={t("Search a User")}
                  />
                </div>
              </div>
              <div style={{ width: "12%" }} className={`${ManagementPanelStyles.filterButtonStyle}`}>
                <p onClick={() => setShowFilters(!showFilters)}>
                  {showFilters ? (
                    <button className={`${ManagementPanelStyles.filterButtonStyleHide} filterButtonCss`}>
                      <FilterIconStyled className="icon icon-filter"></FilterIconStyled>
                    </button>
                  ) : (
                    <button className={`${ManagementPanelStyles.filterButtonStyleShow} filterButtonCss`}>
                      <FilterIconStyled className="icon icon-filter"></FilterIconStyled>
                    </button>
                  )}
                </p>
              </div>
            </div>
            <div className={`${ManagementPanelStyles.filterSelectStyle}`}>
              {showFilters ? <UserFilters /> : ""}
            </div>
            <div className={`${ManagementPanelStyles.mobileListingSection}`}>
              <UserList
                pendingList={pendingList}
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                searchText={SearchText}
                setSearchText={setSearchText}
                userTypeFilter={UserTypeFilter}
                setImageLoaded={(data) => setImageLoaded(data)}
                setShowUserDetail={() => setShowUserDetail(true)}
              />
            </div>
          </>
        ) : (
          <>
            <p className={`${ManagementPanelStyles.assetNameMobile} assetMobileCss`}>
              <span onClick={() => setShowUserDetail(false)}>Your Users</span>{" "}
              <img height="15" src={rightIcon} />
              {` ${userName || ""}`}
            </p>
            <UserOverview
              imageLoaded={imageLoaded}
              setImageLoaded={(data) => setImageLoaded(data)}
              // updateUserStatus={updateUserStatus}
            />
          </>
        )}
      </div>
      <UserCentralModal
        onHide={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        toogleModalClose={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        setIsSuccess={setIsSuccess}
        updateUserDetails={props.updateUserDetails}
        isUpdate={props.isUpdate}
      />
      <WoksiteBulkUploadModal
        show={worksiteBulkUploadShow}
        onHide={() => setWorksiteBulkUploadShow(false)}
        toogleModalClose={() => setWorksiteBulkUploadShow(false)}
      />
      <CommonSuccessMessage />
    </UserPanelStyled>
  );
}

function WoksiteBulkUploadModal(props) {
  const { t } = useTranslation();
  return (
    <Modal
      className="modal_bulk_upload"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      // style={{ paddingTop: "45%" }}
    >
      <UserBulkUploadModal>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <Trans>User Bulk Upload</Trans>
          </Modal.Title>

          <Button label={t("Cancel")} onClick={props.toogleModalClose} showIcon={false} buttonType="ghost" />
        </Modal.Header>
        <Modal.Body className="bulk_upload_body">
          <UserBulkUploadForm handleCancelModal={props.toogleModalClose} />
        </Modal.Body>
      </UserBulkUploadModal>
    </Modal>
  );
}

// export function UserCentralModal(props) {
//   const classes = useStyles();
//   const { t } = useTranslation();
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       style={{ paddingTop: "60px" }}
//       backdrop="static"
//     >
//       {/*   <Modal.Header closeButton>*/}
//       <ModalContentWrapperDiv>
//         <Modal.Header>
//           <Modal.Title className={classes.addAccountText} id="contained-modal-title-vcenter">
//             {props.isUpdate ? <Trans>Update User</Trans> : <Trans>Add User</Trans>}
//           </Modal.Title>

//           <Button label={t("Cancel")} onClick={props.toogleModalClose} showIcon={false} buttonType="ghost" />
//         </Modal.Header>
//         <Modal.Body>
//           <AddUserStepper
//             {...props}
//             toogleModalClose={props.toogleModalClose}
//             setIsSuccess={props.setIsSuccess}
//             setIsError={props.setIsError}
//             updateUserDetails={props.isUpdate && props.updateUserDetails}
//             isUpdate={props.isUpdate}
//           />
//         </Modal.Body>
//       </ModalContentWrapperDiv>
//     </Modal>
//   );
// }

export function UserCentralModal(props) {
  const { t } = useTranslation();
  return (
    <ModalPopup
      {...props}
      size="lg"
      centered
      backdrop="static"
      title={props.isUpdate ? <Trans>Update User</Trans> : <Trans>Add User</Trans>}
      handleClose={props.toogleModalClose}
    >
      <AddUserStepper
        {...props}
        toogleModalClose={props.toogleModalClose}
        setIsSuccess={props.setIsSuccess}
        setIsError={props.setIsError}
        updateUserDetails={props.isUpdate && props.updateUserDetails}
        isUpdate={props.isUpdate}
      />
    </ModalPopup>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  users: state.users.Users,
  addUserSuccess: state.users.AddUserSuccess,
  addUserSuccessFlag: state.users.addUserSuccessFlag,
  userMessage: state.users.UserMessage,
  usersLoading: state.users.UsersLoading,
  showFilters: state.mobileView.showFilters,
  userName: state?.users?.UserOverview?.fullName,
  showActionBar: state.mobileView.showManagementActionBar,
  updateUserDetails: state.modals.userDetails,
  isUpdate: state.modals.isUpdate,
  editForm: state.forms.editForm,
  newlyAddedUser: state.assets.newlyAddedUser,
});

const mapDispatchToProps = (dispatch) => ({
  userList: (users) => dispatch(userList(users)),
  defaultUserOverview: () => dispatch(defaultUserOverview()),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setShowFilters: (data) => dispatch(setShowFilters(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomizedTabs));
