import React, { useEffect, useState } from "react";
import {
  InputComponent,
  InputWrapper,
  Label,
  InputContent,
  IconLeft,
  IconRight,
  ErrorText,
  TextareaWrapper,
} from "./InputField.component.styles";
import PropTypes from "prop-types";

const InputField = (props) => {
  const {
    value,
    label,
    errorMessage,
    showIconLeft,
    placeholder,
    showIconRight,
    isDisabled,
    readOnly,
    iconNameLeft,
    iconNameRight,
    iconColor,
    size,
    onChange,
    name,
    isError,
    type,
    isTextArea,
    ...rest
  } = props;

  const [inputType, setInputType] = useState("text");
  const [passwordIconNameRight, setPasswordIconNameRight] = useState("eye-slash");
  useEffect(() => {
    setInputType(type);
  }, [type]);

  const toggleShowPassword = () => {
    if (type === "password") {
      setPasswordIconNameRight(inputType === "password" ? "eye" : "eye-slash");
      setInputType(inputType === "password" ? "text" : "password");
    }
  };

  // Render textarea if isTextArea is true, otherwise render input
  const inputElement = isTextArea ? (
    <TextareaWrapper
      value={value}
      size={size}
      name={name}
      disabled={isDisabled}
      placeholder={placeholder}
      readOnly={readOnly}
      onChange={onChange}
      {...rest}
    />
  ) : (
    <InputWrapper
      type={inputType}
      size={size}
      value={value}
      name={name}
      disabled={isDisabled}
      placeholder={placeholder}
      readOnly={readOnly}
      onChange={onChange}
      {...rest}
    />
  );

  return (
    <InputComponent
      size={size}
      isError={isError}
      disabled={isDisabled}
      readOnly={readOnly}
      title={value}
      name={name}
      {...rest}
    >
      <Label size={size}>
        <span title={label}>{label}</span>
      </Label>
      <InputContent readOnly={readOnly} size={size} isError={isError}>
        {showIconLeft && iconNameLeft && (
          <IconLeft size={size} className={`icon icon-${iconNameLeft}`} iconColor={iconColor}></IconLeft>
        )}
        {inputElement}
        {type === "password" || (showIconRight && iconNameRight) ? (
          <IconRight
            size={size}
            className={`icon icon-${type === "password" ? passwordIconNameRight : iconNameRight}`}
            iconColor={iconColor}
            onClick={toggleShowPassword}
          />
        ) : null}
      </InputContent>
      {isError && <ErrorText size={size}>{errorMessage}</ErrorText>}
    </InputComponent>
  );
};
InputField.propTypes = {
  /**
   * Specify whether the Input should be disabled, or not
   */
  isDisabled: PropTypes.bool,
  /**
   * Specify whether the input has any Error
   */
  isError: PropTypes.bool,
  /**
   * Whether we want to show icon in Left or not.
   */
  showIconLeft: PropTypes.bool,
  /**
   * How large should the input Component be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * what icon color we want in input filed?
   */
  iconColor: PropTypes.oneOf([
    "themePrimary",
    "themePrimaryDarker",
    "grayL1",
    "grayL2",
    "grayL3",
    "grayL4",
    "grayL5",
    "grayL6",
    "grayL7",
    "greenPrimary",
    "greenHover",
    "redPrimary",
    "redHover",
    "linkColor",
    "orangePrimary",
    "orangeHover",
    "PdfColor",
    "CsvColor",
  ]),
  /**
   * Whether we want to show icon in Right or not.
   */
  showIconRight: PropTypes.bool,
  /**
   * Whether we want to show icon in Right or not.
   */
  readOnly: PropTypes.bool,
  /**
   * label of input field
   */
  label: PropTypes.string.isRequired,
  /**
   * placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * input error message text
   */
  errorMessage: PropTypes.string,
  /**
   *  Provide an function to be called when the input changes
   */
  onChange: PropTypes.func,
  /**
   *  whether it is input or textarea
   */
  isTextArea: PropTypes.bool,
};

InputField.defaultProps = { size: "small", value: "", label: "", isTextArea: false };

export default InputField;
