//TODO - clean up this file when time permits and remove this comment

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Row } from "react-bootstrap";
import SignupForm from "../Management/SignupForm.component";
import Sidebar from "../../../components/Sidebar/Sidebar";

import { connect } from "react-redux";
import { setUpdateAccountPopup } from "../../../redux/config/action";
import { getAllCount } from "../../../redux/counts/action";
import { getUserTypeList } from "../../../redux/userType/action";
import { refreshToken } from "../../../redux/user/user.action";

import { ReactComponent as Logo } from "../../../assets/logo/1.svg";
import { ReactComponent as HumbergerIcon } from "../../../assets/hamburger-menu.svg";
import LanguageSelectorDropdown from "../LanguageSelectorDropdown/LanguageSelectorDropdown";
import {
  CloudText,
  HeaderTopDiv,
  HelpDropdownLanguage,
  HeaderCompnayLogo,
  ToggleButton,
  MainContentWrapper,
  AlertSetting,
  AlertText,
  BodyWrapper,
  IconHoverWrapper,
} from "./Header.styles";
import {
  getDropdownAccountList,
  getDropdownWorksiteList,
  getDropdownAssetList,
  getDropdownAssetTypeList,
  getDropdownAssetTypeByWorksite,
  setDropdownAccountId,
  setDropdownWorksiteId,
  getDropdownProductList,
} from "../../../redux/dropdownFilters/action";
import SnackBar from "../SnackBar/SnackBar";
import { Trans, useTranslation } from "react-i18next";
import "./styles.scss";
import TutorialMenu from "./TutorialMenu.component";
import { logout } from "../../../redux/user/user.action";
import Button from "../../../components/Button/Button.component";
import ButtonIcon from "../../../components/IconButtonComponent/IconButton.component";
import { AppMain } from "./Header.styles";

const TOKEN_REFRESH_INTERVAL = 18_00_000; //30 minutes 30*60*1000

// Header Component
function Header(props) {
  const {
    loginDetails,
    mainContent,
    setUpdateAccountPopup,
    updateAccountPopUp,
    isSuperAdmin,
    getDropdownAccountList,
    getDropdownAssetTypeList,
    getDropdownAssetTypeByWorksite,
    getDropdownWorksiteList,
    getDropdownProductList,
    dropdownAccountId,
    dropdownWorksiteId,
    getDropdownAssetList,
    setDropdownAccountId,
    setDropdownWorksiteId,
    errorCode,
    addSiteModal,
    addAssetModal,
    addUserModal,
    getAllCount,
    logout,
    showUserTypeEnvUpgradeLogoutModal,
    refreshToken,
  } = props;
  const history = useHistory();

  // modal close open
  const [modalShow, setModalShow] = useState(false);
  const [modalSignupShow, setModalSignupShow] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const userDetails = loginDetails;

  const handleCloseSnackBar = () => {
    setSuccessMsg(false);
  };

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    if (Number(errorCode) == 404) {
      history.push("/app/error/404");
    }
    if (Number(errorCode) == 500) {
      history.push("/app/error/500");
    }
    if (Number(errorCode) === 403) {
      setShowLogoutModal(true);
    }
  }, [errorCode]);

  useEffect(() => {
    getDropdownWorksiteList();
    getDropdownAssetList();
  }, []);

  useEffect(() => {
    getDropdownWorksiteList();
    getDropdownAssetTypeList();
    getDropdownAssetList();
  }, [dropdownAccountId]);

  useEffect(() => {
    getDropdownAssetList();
    getDropdownAssetTypeByWorksite();
  }, [dropdownWorksiteId]);

  useEffect(() => {
    if (!loginDetails) {
      history.push("/");
    }
  }, [loginDetails]);

  useEffect(() => {
    if (addAssetModal || addSiteModal || addUserModal) {
      return;
    }
    setDropdownAccountId("all");
    setDropdownWorksiteId("all");
  }, [addAssetModal, addSiteModal, addUserModal]);

  //const matches = useMediaQuery("(min-width:600px)");
  useEffect(() => {
    if (isSuperAdmin) {
      getDropdownAccountList();
      getDropdownProductList();
    }
    getAllCount();
    getUserTypeList();
  }, []);

  const hideSignUp = () => {
    setModalSignupShow(false);
    setUpdateAccountPopup(false);
  };
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, TOKEN_REFRESH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      {userDetails && userDetails.userData && (
        <AppMain>
          {/* Top App Bar */}
          <Sidebar
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            userDetails={userDetails}
          />

          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ padding: "200px", height: "500px" }}
          >
            <Modal.Body>
              <Row className="pl-4 pr-4 justify-content-center">
                <h4 className="text-style">
                  <Trans>This feature will be here soon, kindly contact</Trans>{" "}
                  <a href="mailto:support@siera.ai">support@siera.ai</a>
                  <Trans>for more information</Trans>.
                </h4>
              </Row>
            </Modal.Body>
          </Modal>

          <main>
            {/* Header Top bar blue strip */}

            <HeaderTopDiv>
              <HeaderCompnayLogo>
                {/* Toggel button on responsivep */}
                <ToggleButton className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                  <HumbergerIcon />
                </ToggleButton>
                <Logo />
                <CloudText>
                  <Trans>Dashboard</Trans>
                </CloudText>
              </HeaderCompnayLogo>

              <HelpDropdownLanguage>
                <TutorialMenu />

                <LanguageSelectorDropdown />

                <IconHoverWrapper>
                  <ButtonIcon
                    icon="icon-help-faq"
                    href="https://support.siera.ai/kb"
                    target="_blank"
                    label="Help"
                  />
                </IconHoverWrapper>
              </HelpDropdownLanguage>
            </HeaderTopDiv>

            <MainContentWrapper>
              {mainContent}
              <SignupModal
                show={modalSignupShow || updateAccountPopUp}
                onHide={hideSignUp}
                successMsg={successMsg}
                setSuccessMsg={setSuccessMsg}
              />
            </MainContentWrapper>
          </main>
        </AppMain>
      )}

      <SnackBar isSuccess={successMsg} label={t(successMsg)} handleClose={handleCloseSnackBar} />

      <Modal
        show={showLogoutModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}
        fade={true}
      >
        <Modal.Body>
          <Row className="mb-4 mt-4 ">
            <BodyWrapper>
              <AlertSetting className="icon icon-alertsettings"></AlertSetting>
              <AlertText>
                <Trans>Your Permissions have been changed by your administrator</Trans>.
                <br />
                <Trans> Please login again to access the dashboard</Trans>.
              </AlertText>
              <br />
              <Button
                style={{ width: "160px" }}
                showIcon={false}
                buttonType="primary"
                label={t("Logout")}
                onClick={logout}
              />
            </BodyWrapper>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showUserTypeEnvUpgradeLogoutModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}
        fade={true}
      >
        <Modal.Body>
          <Row className="mb-4 mt-4 ">
            <BodyWrapper style={{ padding: "0 60px" }}>
              <h1>
                <Trans>NEW_LOGIN_NEEDED</Trans>
              </h1>
              <AlertText>
                <Trans>NEW_LOGIN_NEEDED_MESSAGE1</Trans>
                <br />
                <Trans>NEW_LOGIN_NEEDED_MESSAGE2</Trans>
              </AlertText>
              <br />
              <Button
                style={{ width: "160px" }}
                showIcon={false}
                buttonType="primary"
                label={t("Logout")}
                onClick={logout}
              />
            </BodyWrapper>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function SignupModal(props) {
  // const classes = useStyles();
  const { successMsg, setSuccessMsg } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ paddingTop: "60px", paddingRight: "80px" }}
      backdrop={true}
      fade={true}
    >
      <Modal.Body>
        <SignupForm successMsg={successMsg} setSuccessMsg={setSuccessMsg} onHide={props.onHide} />
      </Modal.Body>
    </Modal>
  );
}

// Subscribing the selectors for displaying data
const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  updateAccountPopUp: state.config.updateAccountPopUp,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountId: state.dropdownFilters.accountId,
  dropdownWorksiteId: state.dropdownFilters.worksiteId,
  errorCode: state.errors.errorCode,
  addSiteModal: state.modals.addSiteModal,
  addAssetModal: state.modals.addAssetModal,
  addUserModal: state.modals.addUserModal,
  showUserTypeEnvUpgradeLogoutModal: state.user.showUserTypeEnvUpgradeLogoutModal,
});

const mapDispatchToProps = (dispatch) => ({
  setUpdateAccountPopup: (status) => dispatch(setUpdateAccountPopup(status)),
  getAllCount: () => dispatch(getAllCount()),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownAssetList: () => dispatch(getDropdownAssetList()),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  getDropdownAssetTypeByWorksite: () => dispatch(getDropdownAssetTypeByWorksite()),
  getDropdownProductList: () => dispatch(getDropdownProductList()),
  setDropdownAccountId: (id) => dispatch(setDropdownAccountId(id)),
  setDropdownWorksiteId: (id) => dispatch(setDropdownWorksiteId(id)),
  logout: () => dispatch(logout()),
  getUserTypeList: () => dispatch(getUserTypeList()),
  refreshToken: () => dispatch(refreshToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
