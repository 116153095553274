import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/BreadCrumb.component";
import { connect } from "react-redux";
import Snackbar from "../../SnackBar";
import { Table } from "react-bootstrap";
import { resetBillingState, getInvoices } from "../../../../redux/billing/action";
import moment from "moment";
import { BILLING_PLANS } from "../../../../constant/billingPlans";
import { toGetExpiryDate } from "../../../../utils";
import alertConstant from "../../../../constant/messages/toaster.json";
import { Trans, useTranslation } from "react-i18next";
const billingConstant = require("../../../../constant/planConstant.json");

const Manage = ({
  customerSuccess,
  resetBillingState,
  getInvoices,
  customerDetails,
  invoices,
  setShowPlan,
  plans,
  loginDetails,
  basicPlanSuccess,
}) => {
  const { t } = useTranslation();

  const handleCloseSnackBar = () => {
    resetBillingState();
  };

  const handleMessage = (customerSuccess, basicPlanSuccess) => {
    if (customerSuccess) {
      return t(alertConstant.payment_successfull);
    } else if (basicPlanSuccess) {
      return t(alertConstant.plan_selected_success);
    }
  };

  useEffect(() => {
    let id = customerDetails && customerDetails.customerID;
    getInvoices(id);
  }, [customerDetails]);

  let currentPlanId = loginDetails && loginDetails.plan;

  let planID =
    currentPlanId === billingConstant.BASE_PLAN
      ? BILLING_PLANS[0]
      : currentPlanId === billingConstant.ANNUALLY_PLAN
      ? BILLING_PLANS[1].yearly
      : BILLING_PLANS[1].monthly;

  let isTrial = loginDetails?.isTrial;
  // let createdDate = loginDetails && loginDetails.sessionData && loginDetails.sessionData.timestamp;

  return (
    <div>
      {/* <Breadcrumb /> */}
      <div id="tab01" className="tab-contents">
        <h3 className="plan-title">
          <Trans>Plan Settings</Trans>
        </h3>
        <div className="paddingBlock">
          <div className="row equalHWrap eqWrap">
            <div className="col-md-8 equalHW eq">
              <div className="billing-div-sec">
                <div className="left-billing">
                  <div className="left-current">
                    <div className="top-left-biling">
                      <Trans>Current Subscription Plan</Trans>
                    </div>
                    <div className="center-top-biling">
                      <div className="center-left-bill">
                        {isTrial ? (
                          <>
                            <h5>
                              <Trans>Pro-Trial</Trans>
                            </h5>
                            {/* <p>
                              <Trans>Free trial expiring in</Trans> {toGetExpiryDate(createdDate)}{" "}
                              <Trans>days</Trans>
                            </p> */}
                          </>
                        ) : (
                          <>
                            <h5>{planID && planID.title}</h5>
                            <h4>
                              ${planID && planID.amount}
                              <small>/{planID && planID.time}</small>
                            </h4>

                            <p>
                              <Trans>monthly subscription</Trans>
                            </p>
                          </>
                        )}
                      </div>

                      <div className="center-right-bill">
                        <div className="star-round">
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>

                    <div className="change-plan">
                      <a className="change-plan-ach" onClick={() => setShowPlan(false)}>
                        <Trans>UPGRADE</Trans>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="right-billing clipped"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentPlanId !== billingConstant.BASE_PLAN && (
        <>
          <h4 className="plan-title">
            <Trans>Invoices</Trans>
          </h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <Trans>Date</Trans>
                </th>
                <th>
                  <Trans>Name</Trans>
                </th>
                {/*  <th>Invoice Description</th> */}
                <th>
                  <Trans>Action</Trans>
                </th>
              </tr>
            </thead>

            <tbody>
              {invoices &&
                invoices.data &&
                invoices.data.map((invoice, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{moment.unix(new Date(invoice.created)).format("dddd, MMMM Do YYYY, h:mm a")}</td>
                    <td>{invoice.customer_name}</td>
                    <td>
                      <a href={invoice.invoice_pdf} target="_blank">
                        {" "}
                        <i className="far fa-eye"></i>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
      <Snackbar
        isSuccess={customerSuccess || basicPlanSuccess}
        label={handleMessage(customerSuccess, basicPlanSuccess)}
        handleClose={handleCloseSnackBar}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerSuccess: state.billing.CustomerSuccess,
  customerDetails: state.billing.CustomerDetails,
  invoices: state.billing.Invoices,
  plans: state.billing.Plans,
  loginDetails: state.user.loginDetails,
  basicPlanSuccess: state.billing.BasicPlanSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  resetBillingState: () => dispatch(resetBillingState()),
  getInvoices: (id) => dispatch(getInvoices(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
