import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";

import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  imageContainer: {
    position: "relative",
    height: 95,
    width: 95,
    borderRadius: 75,
    "&:hover": {
      "& $overlayImage": {
        height: "45%",
      },
    },
  },
  image: {
    display: "block",
    height: 95,
    objectFit: "contain",
    width: 95,
    borderRadius: "100%",
  },
  overlayImage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.6)",
    overflow: "hidden",
    width: "100%",
    height: 0,
    transition: "0s ease",
    borderBottomLeftRadius: "105px",
    borderBottomRightRadius: "105px",
    cursor: "pointer",
  },
  imageText: {
    color: "#fff",
    fontSize: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    "-ms-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    cursor: "pointer",
  },
  message: {
    color: "rgba(0,0,0,0.5)",
  },
}));

const PreviewImageComp = React.memo((props) => {
  const { imageLoaded, setImageLoaded, defaultSrc, PreviewImage } = props;
  const classes = useStyles();

  const Image = React.memo(function Image({ className, src }) {
    return <Avatar src={src} className={className} />;
  });

  return (
    <>
      {PreviewImage ? (
        <span>
          <img
            alt="img"
            className={imageLoaded && classes.image}
            style={imageLoaded ? {} : { display: "none" }}
            src={PreviewImage}
            key={PreviewImage}
            onLoad={() => setImageLoaded(true)}
          />
          {!imageLoaded && <div className="spinner-border" style={{ marginTop: "30%" }} role="status"></div>}
        </span>
      ) : (
        <Image className={classes.image} src={defaultSrc} />
      )}
    </>
  );
});

const ImageUpload = React.memo(({ id, src, updateImage, imageLoading, defaultSrc }) => {
  const classes = useStyles();
  const [PreviewImage, setPreviewImage] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  const handleImage = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    updateImage(e.target.files[0], id);
  };

  useEffect(() => {
    console.debug("src", src);
    setPreviewImage(src);
    setImageLoaded(false);
  }, [src]);

  return (
    <>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={handleImage}
      />

      <div className={classes.imageContainer}>
        <PreviewImageComp
          imageLoaded={imageLoaded}
          setImageLoaded={setImageLoaded}
          defaultSrc={defaultSrc}
          PreviewImage={PreviewImage}
        />
        <div className={classes.overlayImage}>
          <label htmlFor="contained-button-file">
            <Typography className={classes.imageText}>{t("Change Photo")}</Typography>
          </label>
        </div>
        {imageLoading && (
          <CircularProgress
            style={{
              color: "#fff",
              position: "absolute",
              top: 45,
              left: 42,
            }}
            size={30}
          />
        )}
      </div>
    </>
  );
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  success: state.asset.Success,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
