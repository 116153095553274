import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import AccountList from "./AccountList.component";
import Account from "./Account.component";
import { connect } from "react-redux";
import { setEditAlertModal } from "../../../redux/modals/action";
import AddIcon from "@material-ui/icons/Add";
import { Trans, useTranslation } from "react-i18next";
import AccountActionBar from "./AccountsActionBar.component";
import SearchBox from "../../../components/SearchBox/Searchbox.component";

const AccountManagement = ({ setEditAlertModal, editForm, editFormSecond }) => {
  const [SearchText, setSearchText] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {/* <Row className="m-0 p-0">
        <div className="mr-2 pl-0">
          <Button
            disabled={true}
            className="buttonAdd ripple"
            // onClick={() => setModalWorksiteShow(true)}
            variant="outline-primary"
          >
            <AddIcon className="buttonIcon" /><Trans>ADD ACCOUNT</Trans>
          </Button>
        </div>
      </Row> */}
      <div>
        <AccountActionBar />
      </div>

      <Row style={{ paddingTop: "10px" }} className="AccountPageDes">
        <Col lg={3}>
          <SearchBox
            searchText={SearchText}
            setSearchText={setSearchText}
            placeholder={t("Search an account")}
            label={t("Your Accounts")}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <AccountList
            setImageLoaded={() => setImageLoaded(false)}
            searchText={SearchText}
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
          />
        </Col>
        <Col lg={9}>
          <Account imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  assetTypes: state.assets.AssetTypes,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement);
