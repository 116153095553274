import styled from "styled-components";

export const PassFail = styled.div`
  .PassBorderDiv {
    border: 1px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  }
  .FailBorderDiv {
    border: 1px solid red !important;
  }
  .TimedOutBorderDiv {
    border: 1px solid black !important;
  }

  #button-container {
    position: relative;
    width: 100px;
    height: 24px;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
  }

  #nameUserStyle {
    position: absolute;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    left: -100%;
    transition: 0.5s;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #statusStyle {
    position: absolute;
    left: 0;
    transition: 0.5s;
    cursor: pointer;
    display: inline-block;
    width: 100%;
  }

  #button-container:hover #nameUserStyle {
    left: 0;
  }

  #button-container:hover #statusStyle {
    left: 100%;
  }
`;

export const StyledSpan = styled.span`
  text-align: center;
  clear: both;
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
