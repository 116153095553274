import React from "react";
import ForkLiftTopView from "../../../../../assets/CameraSettings/Forklift_Position.png";
import "../CameraSetting.css";
import {
  ForkLiftWrapper,
  CamPositionWrapper,
  StyledFormCheckBox,
  ForkliftImage,
  CheckboxesContainer,
  doesCamsNeedSetup,
} from "./CameraLocation.styles";
import { unselectedCams } from "../constants";

function VehicleWithCamMapping(props) {
  const { assignedPosition, cameraPositionArray, doesCamsNeedSetup } = props;
  const camPositionArr = doesCamsNeedSetup ? unselectedCams : cameraPositionArray;

  return (
    <ForkLiftWrapper>
      <CamPositionWrapper>
        <CheckboxesContainer>
          {camPositionArr.map((data, i) => (
            <StyledFormCheckBox
              name="1"
              className={`checkbox-${data?.position?.toLowerCase()} checkbox-position ${
                data.isDisabled ? "hide-checkbox" : ""
              }`}
              size="lg"
              type="checkbox"
              value={data.position}
              checked={assignedPosition.join().toLocaleLowerCase().includes(data.position)}
              id={`default-${i}`}
              disabled={data.isDisabled}
              top={data.top}
              left={data.left}
              onChange={() => {}}
            />
          ))}
        </CheckboxesContainer>
      </CamPositionWrapper>
      <ForkliftImage src={ForkLiftTopView} alt="Fork Lift" />
    </ForkLiftWrapper>
  );
}

export default VehicleWithCamMapping;
