import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Input from "../../../../components/Input";
import InputField from "../../../../components/InputFieldComponent/InputFieldWrapper.component";

const Phone = (props) => {
  const { formData, setFormData, selectedUserType, phoneErrorMessage } = props;
  const { t } = useTranslation();
  const customField = selectedUserType?.customFields.find((field) => field.type === "PHONE");

  return (
    <>
      <Col className="asterisk_form">
        <InputField
          label={t("Phone")}
          type="phone"
          inputType="number"
          id="phone"
          variant="standard"
          name="phone"
          value={formData?.phone}
          required
          onChange={(e) => {
            setFormData({
              ...formData,
              phone: e.target.value,
            });
          }}
          errorMessage={phoneErrorMessage}
          isError={phoneErrorMessage}
        />
        {/* {customField?.isRequired && <p className="asterisk_user">*</p>} */}
      </Col>
      {/* <Col>
        <p className="mt-2 text-danger" style={{ fontSize: "12px!important" }}>
          {phoneErrorMessage}
        </p>
      </Col> */}
    </>
  );
};

export default Phone;
