// Libraries
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDropdownAssetTypeList } from "../../../../../redux/dropdownFilters/action";
import { getAssetTypes } from "../../../../../redux/assets/action";

//Custom Components and utilities
import SnackBar from "../../../SnackBar";
import alertConstant from "../../../../../constant/messages/toaster.json";

// Custom Styles
import Styles from "./AddAssetTypeModalStyles.module.css";

// Multiple language support
import { Trans, useTranslation } from "react-i18next";

// Button Component
import Button from "../../../../../components/Button/Button.component";

import RadioButton from "../../../../../components/RadioButton/Radio.component";
import {
  AssetTypeDetailsWrapper,
  DeleteIconStyled,
  HeadingContainer,
  RadioButtonContainer,
  ErrorMessage,
} from "./AddAssetTypeDetails.component.styles";

import Table from "../../../../../components/DataTableComponent/DataTable.component"; // Assuming this is the path to your Table component
import InputField from "../../../../../components/InputFieldComponent/InputField.component";

const baseUrl = require("../../../../../config").baseUrl; //Base Url

const AddAssetTypeDetails = ({
  addAssetTypeModalData,
  setAddAssetTypeModalData,
  activeStep,
  setActiveStep,
  dropdownAccountId,
  loginDetails,
  getDropdownAssetTypeList,
  isSuperAdmin,
  handleCloseModal,
  setIsError,
  setIsSuccess,
  getAssetTypes,
}) => {
  const { t } = useTranslation();

  const [isValidationError, setIsValidationError] = useState(false);
  const [isValidationErrorVisible, setIsValidationErrorVisible] = useState(false);
  const isValidationSet = new Set();
  const [isLoading, setIsLoading] = useState(false);
  // const [IsSuccess, setIsSuccess] = useState(false)
  // const [IsError, setIsError] = useState(false)
  const fieldContainerDiv = useRef(null);

  const removeSchemaAttribute = (e) => {
    isValidationSet.delete(e.currentTarget.id);
    addAssetTypeModalData.AssetTypeAttributes.map((data, i) => {
      if (data.id == e.currentTarget.id) {
        addAssetTypeModalData.AssetTypeAttributes.splice(i, 1);
      }
    });
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes],
    });
    // setSchemaList([...schemaList]);
  };

  const handleAddCustomField = () => {
    const newEntry = {
      id: Date.now(),
      AssetAttributeName: "",
      AssetAttributeRequired: false,
      AssetAttributeDescription: "",
      disabled: false,
      removeDelete: false,
    };
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes, newEntry],
    });

    fieldContainerDiv.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
    fieldContainerDiv.current.scrollTop = 0;
  };

  const handleBack = () => {
    // Handle Validation for step 2 then set active step accordingly

    setActiveStep(0);
  };

  const checkForValidation = () => {
    // console.log(isValidationSet)
    if (isValidationError) {
      setIsValidationErrorVisible(true);
      return false;
    } else {
      return true;
    }
  };

  const submitAssetType = async () => {
    setIsLoading(true);
    const headers = {
      "auth-token": loginDetails.token,
    };
    let accountId = loginDetails.accountId;
    if (isSuperAdmin) {
      accountId = dropdownAccountId;
    }
    let data = addAssetTypeModalData;

    const responseUser = await axios.post(`${baseUrl}/assettype/create/${accountId}`, data, { headers });

    if (responseUser.data.success === true) {
      setIsLoading(false);
      setIsSuccess(alertConstant.asset_type_added_success);
      getDropdownAssetTypeList();
      getAssetTypes({ pageNo: 1, pageSize: 15 }, true);
      handleCloseModal();
    } else {
      setIsError(alertConstant.asset_type_name_duplicate);
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (checkForValidation()) {
      // alert("Submitted")
      submitAssetType();
    } else {
      console.log("ERROR");
    }
  };

  const handleRequired = (value, id) => {
    // console.log(id)
    if (value === "YES") {
      const newSchemaList = [...addAssetTypeModalData.AssetTypeAttributes];
      newSchemaList.forEach((data) => {
        if (data.id === id) {
          data.AssetAttributeRequired = true;
        }
      });
      // setSchemaList(newSchemaList)
      setAddAssetTypeModalData({ ...addAssetTypeModalData, AssetTypeAttributes: newSchemaList });
    } else {
      const newSchemaList = [...addAssetTypeModalData.AssetTypeAttributes];
      newSchemaList.forEach((data) => {
        if (data.id === id) {
          data.AssetAttributeRequired = false;
        }
      });
      setAddAssetTypeModalData({ ...addAssetTypeModalData, AssetTypeAttributes: newSchemaList });
    }
  };

  const handleFieldNameChange = (id, value) => {
    addAssetTypeModalData.AssetTypeAttributes.map((data, i) => {
      if (data.id == id) {
        data.AssetAttributeName = value;
      }
    });
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes],
    });
  };

  const handleDescription = (id, value) => {
    addAssetTypeModalData.AssetTypeAttributes.map((data, i) => {
      if (data.id == id) {
        data.AssetAttributeDescription = value;
      }
    });
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes],
    });
    // setSchemaList([...schemaList]);
  };

  useEffect(() => {
    addAssetTypeModalData.AssetTypeAttributes.forEach((currentAttribute) => {
      if (!currentAttribute?.removeDelete) {
        if (currentAttribute?.AssetAttributeName && currentAttribute?.AssetAttributeName.trim()) {
          // console.log("DELETING SET : ", currentAttribute.id)
          isValidationSet.delete(currentAttribute.id);
          // console.log(isValidationSet)
        } else {
          isValidationSet.add(currentAttribute.id);
        }
      }
    });
    if (isValidationSet?.size == 0) {
      setIsValidationError(false);
    } else {
      setIsValidationError(true);
    }
    setIsValidationErrorVisible(false);
  }, [addAssetTypeModalData]);

  const handleCloseSnackBar = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  const handleNext = () => {
    if (checkForValidation()) {
      // alert("Submitted")
      setActiveStep(activeStep + 1);
    } else {
      console.log("ERROR");
    }
  };

  return (
    <AssetTypeDetailsWrapper>
      {/* Add Asset Type Modal Heading */}
      <HeadingContainer>
        <Trans>Asset Type Details</Trans>
      </HeadingContainer>

      {/* Add Asset Type Modal Body */}
      <Table
        data={addAssetTypeModalData.AssetTypeAttributes.map((data, i) => ({
          id: data.id,
          AttributeName: (
            <span title="Enter Field Title" className="enterTitle">
              {i === 0 && !data.removeDelete && <p>*</p>}
              <InputField
                minLength={1}
                maxLength={64}
                disabled={data.removeDelete}
                placeholder={t("Enter Field Title")}
                value={data.AssetAttributeName}
                onChange={(e) => handleFieldNameChange(data.id, e.target.value)}
              />
            </span>
          ),
          Required: (
            <RadioButtonContainer>
              <RadioButton
                onClick={() => handleRequired("YES", data.id)}
                checked={data.AssetAttributeRequired}
                label="Yes"
                size="small"
                id={"yes_" + i}
              />
              <RadioButton
                onClick={() => handleRequired("OPTIONAL", data.id)}
                checked={!data.AssetAttributeRequired}
                label="Optional"
                size="small"
                id={"optional_" + i}
              />
            </RadioButtonContainer>
          ),
          Description: (
            <span title="Enter Field Description">
              <InputField
                id={data.id}
                disabled={data.removeDelete}
                minLength={1}
                maxLength={128}
                placeholder={t("Enter Field Description")}
                value={data.AssetAttributeDescription}
                onChange={(e) => handleDescription(data.id, e.target.value)}
              />
            </span>
          ),
          Action: !data.removeDelete && (
            <DeleteIconStyled
              title="Delete"
              className="icon icon-delete"
              id={data.id}
              onClick={removeSchemaAttribute}
            />
          ),
        }))}
        columns={[
          { name: "Field Name", selector: (row) => row.AttributeName },
          { name: "Required", selector: (row) => row.Required, width: "160" },
          { name: "Description", selector: (row) => row.Description },
          { name: "Action", selector: (row) => row.Action, width: "100", centered: true },
        ]}
        rowHeight={518}
        pagination={false}
        accordion={false}
        scrollable={true}
        noDataMessage="No custom fields added"
        showLoadingIndicator={false}
        autoScrollBottom={true}
      />

      {isValidationErrorVisible ? (
        <ErrorMessage>
          *<Trans>All the field name are required</Trans>
        </ErrorMessage>
      ) : (
        ""
      )}

      {/* Add Asset Type Modal Actions */}
      <div className={`${Styles.footerActions}`}>
        <div className={`${Styles.leftSideActions}`}>
          <Button
            iconClass="add"
            label={t("Add Custom Field")}
            onClick={handleAddCustomField}
            showIcon
            buttonType="primary"
          />
        </div>
        <div className={`${Styles.rightSideActions}`}>
          <Button label={t("Back")} onClick={handleBack} showIcon iconClass="arrow-left" buttonType="ghost" />

          <Button
            label={t("Next")}
            onClick={() => handleNext()}
            iconClass="arrow-right"
            showIcon
            iconPosition="after"
            buttonType="primary"
          />
        </div>
      </div>
    </AssetTypeDetailsWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  accountList: state.accounts.Accounts,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  addAssetModalRedirect: state.modals.addAssetModalRedirect,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  getAssetTypes: (paginationData, isResetState) => dispatch(getAssetTypes(paginationData, isResetState)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAssetTypeDetails));
