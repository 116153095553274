import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { getAllChecklist } from "../../../redux/checklists/action";
import Input from "../../../components/Input";
import { setEditAlertModal } from "../../../redux/modals/action";
import { setEditForm } from "../../../redux/forms/action";
import S3ConfirmationModal from "./Modal/S3ConfirmationModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const labelStyle = {
  position: "relative",
  height: "0px%",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
    boxShadow: "unset",
    borderBottom: "1px solid #ccc",
    fontSize: "30px",
  },
  labelWrapped: {
    fontSize: "0.875rem",
  },
}));

function AssetOverviewTab({
  editForm,
  assetOverview,
  worksiteList,
  setShowEditButton,
  statusButton,
  editData,
  setEditData,
  setEditForm,
  validated,
  dropdownWorksiteList,
  dropdownFilterProductList,
  AssetLoading,
  setEditAlertModal,
  getAllChecklist,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const { t } = useTranslation();

  const changeFeildValue = (e, i) => {
    let newData = [];
    editData.assetAttributes.map((data, index) => {
      if (i == index) {
        newData.push({
          ...data,
          AssetAttributeValue: e.target.value,
        });
      } else {
        newData.push({
          ...data,
        });
      }
    });
    setEditData({ ...assetOverview, assetAttributes: [...newData] });
  };

  useEffect(() => {
    //Load the checklist
    getAllChecklist({ pageNo: 0, pageSize: 0 }, true);
  }, []);

  useEffect(() => {
    if (editForm) {
      if (assetOverview?.assetAttributes) {
        setEditData({ ...assetOverview, assetAttributes: [...assetOverview?.assetAttributes] });
      }
    }
  }, [editForm]);

  useEffect(() => {
    setValue(0);
    if (assetOverview?.assetAttributes) {
      setEditData({ ...assetOverview, assetAttributes: [...assetOverview?.assetAttributes] });
    }
  }, [assetOverview]);

  const setModalShowClose = () => {
    setModalShow(false);
    setEditData({ ...editData, s3enabled: !isCheckboxChecked });
  };

  return (
    <div className={classes.root}>
      <div classes="tabPanels">
        {/* <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="ant example"
            className="tabpanelSize"
          >
            <Tab className="buttontabpanels buttonTabSize" label={t("Asset Overview")} {...a11yProps(0)} />
            <Tab className="buttontabpanels buttonTabSize" label={t("Asset Checklists")} {...a11yProps(1)} />
            <Tab className="buttontabpanels buttonTabSize" label={t("Asset Media")} {...a11yProps(2)} />
            <Tab className="buttontabpanels buttonTabSize" label={t("Inspection Reports")} {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={3}>
          <AssetOverviewInspectionReports assetId={assetOverview ? assetOverview?._id : null}/>
        </TabPanel>
*/}
        <TabPanel value={value} index={0} className="worksite-overview-height">
          {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
          {!AssetLoading && assetOverview ? (
            <Form noValidate validated={validated} id="assetOverviewEdit">
              <Row>
                {/* <Col md={4} className="work-label assetOverS3">
                  <label class="switch mr-2 switchTabs3" >
                    <input
                      disabled={editForm}
                      type="checkbox"
                      checked={editData && editData.s3enabled}
                      onChange={changeStatus}
                    />
                    <span class="slider" style={{ cursor: (editData ? "default" : "pointer") }}></span>
                  </label>
                  <div className="mt-2 text-open-sans ">
                    <Trans>Asset S3 Enabled</Trans>?
                    <span style={{ cursor: "pointer" }} className="pl-2 text-style" >

                      <OverlayTrigger placement="right" className="tooltipS3over"

                        overlay={
                          <div placement="top" id="tooltip-top" ClassName="tooltipS3P"

                            style={{
                              backgroundColor: '#F2F2F2',
                              padding: '5px 10px',
                              color: '#323232',
                              borderRadius: 3,
                              marginTop: "10px",
                              marginLeft: "5px",
                              boxShadow: " -3px 5px 5px -5px rgba(0,0,0,0.75)"
                            }}
                          >
                            <Trans>Select this option if S3 is available for this asset</Trans>
                          </div>
                        }
                      >
                        <img style={{ marginTop: "-3px" }} height="17px" src={Information} />
                      </OverlayTrigger></span>
                  </div>
                </Col> */}
              </Row>
              <div className="assetTableszOver">
                <Row>
                  <Col md={4}>
                    <Input
                      disabled={editForm}
                      showLabel={true}
                      label={t("SIERA.AI Product")}
                      type="select"
                      options={dropdownFilterProductList?.map((option) => ({
                        _id: option._id,
                        label: option.name,
                      }))}
                      as="select"
                      required={true}
                      // isValidationError={isValidationError}
                      value={editData?.productType || "NA"}
                      onChange={(value) => {
                        setEditData({ ...editData, productType: value });
                      }}
                      feedback={t("Please select a Product")}
                    />
                    {/* <p className="asterisk_user">*</p> */}
                  </Col>
                  <Col md={4}>
                    {/*   <Form.Group className="selectBoxDiv editForm hide-detail">  
                      <Form.Label>
                        <Trans>Worksite Name</Trans>
                      </Form.Label>
                      <Form.Control
                        disabled={editForm}
                        value={editData && editData.siteId && editData.siteId._id}
                        style={editForm ? { pointerEvents: "none" } : null}
                        label={t("Worksite Name")}
                        onChange={handleChangeWorksiteName}
                        as="select"
                        className="selectBox"
                      >
                        {dropdownWorksiteList && dropdownWorksiteList[0] && dropdownWorksiteList.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>*/}

                    <Input
                      disabled={editForm}
                      showLabel={true}
                      label={t("Worksite Name")}
                      type="select"
                      options={
                        dropdownWorksiteList &&
                        dropdownWorksiteList[0] &&
                        dropdownWorksiteList.map((option) => ({
                          _id: option._id,
                          label: option.name,
                        }))
                      }
                      as="select"
                      // required={true}
                      // isValidationError={isValidationError}
                      value={editData && editData.siteId && editData.siteId._id}
                      onChange={(value) => {
                        if (value) {
                          setEditData({
                            ...editData,
                            siteId: {
                              _id: value,
                            },
                          });
                        }
                      }}
                      //feedback={t("Please select a Product")}
                    />
                  </Col>

                  {editData &&
                    editData.assetAttributes &&
                    editData.assetAttributes[0] &&
                    editData.assetAttributes.map((data, i) => (
                      <Col md={4}>
                        <Form.Group className="text-open-sans mb-0">
                          <Form.Label style={labelStyle}>
                            <Trans>{data.AssetAttributeName}</Trans>
                          </Form.Label>
                          <Form.Control
                            required={data.AssetAttributeRequired}
                            disabled={editForm}
                            //  className="inputBox"
                            variant="standard"
                            fullWidth
                            type="text"
                            minLength={"1"}
                            maxLength={"64"}
                            value={data.AssetAttributeValue ? data.AssetAttributeValue : ""}
                            InputProps={{ disableUnderline: true }}
                            label={data.AssetAttributeName}
                            onChange={(e) => changeFeildValue(e, i)}
                          />
                          <Form.Control.Feedback type="invalid">
                            <Trans>Field is required</Trans>.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    ))}
                </Row>
              </div>
            </Form>
          ) : (
            <></>
          )}
        </TabPanel>
      </div>
      <S3ConfirmationModal
        setModalShow={setModalShow}
        setModalShowClose={setModalShowClose}
        modalShow={modalShow}
        setEditData={setEditData}
        editData={editData}
        isCheckboxChecked={isCheckboxChecked}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetOverview: state.assets.AssetOverview,
  worksiteList: state.worksites.Worksites,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownFilterProductList: state.dropdownFilters.productList,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  // setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetOverviewTab);
