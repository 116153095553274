import React, { useState, useEffect, Component, useContext } from "react";
import Select, { components } from "react-select";
import { Trans, useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { ThemeContext } from "styled-components";
import { isIterable } from "../utils";

const { Option } = components;
const customOption = (props) => {
  return (
    <Tooltip title={props.label}>
      <span>
        <Option {...props} />
      </span>
    </Tooltip>
  );
};

function DropdownFilter({
  filter,
  userFilter,
  handleFilter,
  useAsDropdown,
  disabled,
  selectFirstAsDefault,
  value,
  inputRef,
  isBox,
  isLoading,
  sortData,
}) {
  const themeContext = useContext(ThemeContext);

  let { data } = filter;

  const [dropdownOption, setDropdownOption] = useState([]);

  useEffect(() => {
    console.debug("data", data);
    sortData &&
      data &&
      data.length > 0 &&
      data.sort((a, b) => {
        let fa = a?.[filter.key || "name"]?.toLowerCase(),
          fb = b?.[filter.key || "name"]?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    setDropdownOption(data);
  }, [sortData, data]);

  const labelStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
    textAlign: "left",
    marginBottom: "0px",
    fontSize: "12px",
    color: themeContext?.colors?.grayL4 || "#9A9A9A",
  };

  const { t, i18n } = useTranslation();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      //borderBottom: '0px dotted green',
      color: state.isSelected
        ? themeContext?.colors?.grayL1 || "#323232"
        : themeContext?.colors?.grayL1 || "#323232",
      background: state.isSelected
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      textAlign: "left",
      backgroundColor: state.isSelected
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      fontFamily: "Open Sans, sans-serif",
      whiteSpace: "nowrap",
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover": {
        background: themeContext?.colors?.grayL6 || "#F2F2F2",
      },
    }),
    menuList: (provided, state) => ({
      ...provided,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "28px",
      textAlign: "left",
      backgroundColor: state.disabled
        ? themeContext?.colors?.grayL7 || "#FFFFFF"
        : themeContext?.colors?.grayL7 || "#FFFFFF",
      border: state.isFocused
        ? `1px solid ${themeContext?.colors?.themePrimary || "#0d5fbe"}`
        : `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
      boxShadow: state.isFocused
        ? themeContext?.colors?.themePrimary || "#0d5fbe"
        : themeContext?.colors?.themePrimary || "#0d5fbe",
      "&:focus": {
        borderColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      },
      "&:hover": {
        borderColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL1 || "#323232",
      textAlign: "left",
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "max-content",
      fontFamily: "Open Sans, sans-serif",
      //  position:'relative',
      top: "auto",
      transform: "translateY(0%)",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: "0px",
      paddingRight: "2px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      textAlign: "left",
      color: state.isDisabled
        ? themeContext?.colors?.grayL5 || "#CCCCCC"
        : themeContext?.colors?.grayL1 || "#323232",
      fontFamily: "Open Sans, sans-serif",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      display: "grid",
    }),
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <label style={labelStyle} className="text-open-sans">
        <Trans>{filter.label || filter.type}</Trans>
      </label>
      {value ? (
        <Select
          components={{
            Option: customOption,
          }}
          placeholder={filter?.placeholder || filter.type}
          styles={customStyles}
          className="text-open-sans input-select-control"
          classNamePrefix={filter.type}
          value={
            useAsDropdown
              ? selectFirstAsDefault
                ? [dropdownOption?.[0]]
                : dropdownOption &&
                  isIterable(dropdownOption) &&
                  dropdownOption.map((option) => {
                    if (option._id == value) {
                      return option;
                    }
                  })
              : value && value !== "all"
              ? dropdownOption &&
                isIterable(dropdownOption) &&
                dropdownOption.map((option) => {
                  if (option._id == value) {
                    return option;
                  }
                })
              : [{ [filter.key]: t("All"), _id: "all" }]
          }
          isDisabled={disabled}
          isLoading={isLoading}
          isClearable={false}
          isSearchable={true}
          name="filter"
          selected={
            useAsDropdown ? (selectFirstAsDefault ? [dropdownOption && dropdownOption?.[0]] : "") : "all"
          }
          getOptionLabel={(option) => option[filter.key]}
          getOptionValue={(option) => (option ? option["_id"] : "")}
          options={
            useAsDropdown
              ? dropdownOption
              : dropdownOption &&
                isIterable(dropdownOption) && [{ [filter.key]: t("All"), _id: "all" }, ...dropdownOption]
          }
          onChange={(value) => handleFilter(value ? value._id : value)}
          //  menuPortalTarget={document.body}
          //   menuPosition={'fixed'}
        />
      ) : (
        <Select
          components={{
            Option: customOption,
          }}
          placeholder={filter?.placeholder || filter.type}
          styles={customStyles}
          className="text-open-sans input-select-control"
          classNamePrefix={filter.type}
          defaultValue={
            useAsDropdown
              ? selectFirstAsDefault
                ? [dropdownOption && dropdownOption?.[0]]
                : dropdownOption &&
                  isIterable(dropdownOption) &&
                  dropdownOption.map((option) => {
                    if (option._id == value) {
                      return option;
                    }
                  })
              : value && value !== "all"
              ? filter &&
                dropdownOption &&
                isIterable(dropdownOption) &&
                dropdownOption.map((option) => {
                  if (option._id == value) {
                    return option;
                  }
                })
              : [{ [filter.key]: t("All"), _id: "all" }]
          }
          isDisabled={disabled}
          isLoading={false}
          isClearable={false}
          isSearchable={true}
          name="filter"
          selected={
            useAsDropdown ? (selectFirstAsDefault ? [dropdownOption && dropdownOption?.[0]] : "") : "all"
          }
          getOptionLabel={(option) => option[filter.key]}
          getOptionValue={(option) => (option ? option["_id"] : "")}
          options={
            useAsDropdown
              ? dropdownOption
              : dropdownOption &&
                isIterable(dropdownOption) && [{ [filter.key]: t("All"), _id: "all" }, ...dropdownOption]
          }
          onChange={(value) => handleFilter(value ? value._id : value)}
          // menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      )}
    </div>
  );
}

DropdownFilter.defaultProps = {
  sortData: true,
};

export default DropdownFilter;
