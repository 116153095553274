import React, { useState } from "react";
import axios from "axios";
import { makeStyles, Typography, CircularProgress, FormHelperText } from "@material-ui/core";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Button from "../../../components/Button/Button.component";
import { passwordFieldValidation } from "../../../utils";
import SnackBar from "../../Dashboard/SnackBar/SnackBar";
import validationConstant from "../../../constant/messages/validation.json";
import { PasswordRulesWrapper, RegistrationFormWrapper, FieldLabel } from "./ResetPasswordForm.styles";
import PasswordRecoveryBackground from "../../../assets/profile.jpg";
const baseUrl = require("../../../config").baseUrl; //Base Url

const { password_not_match } = validationConstant;

// Use Styles Material UI Hook
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  mainWrapper: {
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 1100px)": {
      margin: "auto",
      width: "95%",
    },
  },
  registerInputText: {
    width: "45ch",
    backgroundColor: "white",
    paddingBottom: "20px",
    fontFamily: "Open Sans",
  },
  submitButton: {
    width: "13ch",
    height: "4ch",
    marginTop: "20px",
    marginBottom: "20px",
    background: "#11A70B",
    fontFamily: "Kanit",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#11A70B",
    },
  },
  subTitle: {
    fontSize: 16,
    color: "#323232",
    fontFamily: "Open Sans",
    textAlign: "center",
    width: "60%",
    margin: "0 auto",
  },
  form: {
    marginTop: 30,
  },
  textIcon: {
    marginRight: 10,
    "& svg": {
      color: "#919191",
      cursor: "pointer",
    },
  },
  rootText: {
    fontSize: "12px !important",
  },
  title: {
    fontSize: "40px !important",
    textAlign: "center",
    color: "#323232",
    marginTop: 20,
    fontFamily: "kanit",
  },
  ImageStyle: {
    height: "86vh",
    "@media (max-width: 1100px)": {
      height: "100%",
      width: "100%",
    },
  },
}));

// Forgot Password Form Component
const ResetPasswordForm = ({ history, match }) => {
  // Assigning use styles hook to classes
  const classes = useStyles();

  // Setting Forgot State
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState(null);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [ShowNewPassword, setShowNewPassword] = useState(false);
  const [ShowConfirmPassoword, setShowConfirmPassoword] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsFailed, setIsFailed] = useState(false);

  const [IsValidated, setIsValidated] = useState(false);

  const handleShowPassword = () => {
    setShowNewPassword(!ShowNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassoword(!ShowConfirmPassoword);
  };

  // Submitting Form data to Forgot Password API
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!!NewPassword || !!ConfirmPassword) {
      setIsSubmitted(true);
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setIsValidated(true);
      return;
    }

    if (passwordFieldValidation(NewPassword) && NewPassword === ConfirmPassword) {
      setIsLoading(true);
      let token = match.params.token;
      let data = {
        password: NewPassword,
        passwordVerification: ConfirmPassword,
      };
      let headers = {
        "Content-Type": "application/json",
        "auth-token": token,
      };

      axios
        .put(`${baseUrl}/user/update/forgotPassword`, data, { headers: headers })
        .then((response) => {
          if (response.data.success) {
            setIsLoading(false);
            // Load the back to login page from here...
            history.push("/backtologin");
          } else {
            setIsLoading(false);
            setIsFailed(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setIsFailed(true);
        });
    }
  };

  const handleCloseSnackBar = () => {
    setIsFailed(false);
  };
  const { t } = useTranslation();
  const handleMessage = (IsFailed) => {
    if (IsFailed) {
      return t("Password Reset Link is Expired. Please Try Again");
    }
  };

  const passwordPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-~()]).{8,64}$";

  return (
    <RegistrationFormWrapper>
      {/*Password Recovery Title*/}
      <div className={classes.mainWrapper}>
        <Row>
          <Col md={8}>
            <Typography className={classes.title}>Password Recovery</Typography>

            <Row>
              <Col md={3}> </Col>
              <Col md={6}>
                <PasswordRulesWrapper
                  isError={
                    IsSubmitted && (NewPassword || ConfirmPassword) && !passwordFieldValidation(NewPassword)
                  }
                >
                  <Trans>Password Rules</Trans>
                </PasswordRulesWrapper>
              </Col>
              <Col md={3}> </Col>
            </Row>

            <Form onSubmit={handleSubmit} className={classes.form} noValidate validated={IsValidated}>
              <Row>
                <Col md={3}> </Col>
                <Col md={6}>
                  {/*Email Text Field*/}
                  <Form.Group controlId="formBasicEmail" className="label">
                    <FieldLabel show={!!NewPassword}>
                      <Form.Label>{t("New Password")}</Form.Label>
                    </FieldLabel>
                    <InputGroup>
                      <Form.Control
                        type={ShowNewPassword ? "text" : "password"}
                        className="registerInputText"
                        placeholder={t("New Password")}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required={true}
                        value={NewPassword}
                        pattern={passwordPattern}
                      />

                      <InputGroup.Append>
                        <InputGroup.Text id="inputGroupPrepend" onClick={handleShowPassword}>
                          {ShowNewPassword ? (
                            <i className="far fa-eye"></i>
                          ) : (
                            <i className="far fa-eye-slash"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {/* Password length must be between 8 to 20 characters*/}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}> </Col>
                <Col md={3}> </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicEmail" className="label">
                    <FieldLabel show={!!ConfirmPassword}>
                      <Form.Label>{t("Confirm Password")}</Form.Label>
                    </FieldLabel>
                    <InputGroup>
                      <Form.Control
                        type={ShowConfirmPassoword ? "text" : "password"}
                        className="registerInputText"
                        placeholder={t("Confirm Password")}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required={true}
                        value={ConfirmPassword}
                        pattern={passwordPattern}
                      />

                      <InputGroup.Append>
                        <InputGroup.Text id="inputGroupPrepend" onClick={handleShowConfirmPassword}>
                          {ShowConfirmPassoword ? (
                            <i className="far fa-eye"></i>
                          ) : (
                            <i className="far fa-eye-slash"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className="text-muted">{/*   Both the passwords must match" */}</Form.Text>
                  </Form.Group>
                  {IsSubmitted && ConfirmPassword && NewPassword !== ConfirmPassword ? (
                    <FormHelperText
                      error={IsSubmitted && NewPassword !== ConfirmPassword}
                      style={{ textAlign: "center" }}
                    >
                      {t(password_not_match)}
                    </FormHelperText>
                  ) : null}
                </Col>
                <Col md={3}> </Col>
              </Row>

              <Col md={12}>
                {/*Submit Button*/}

                <Button
                  label={IsLoading ? <CircularProgress size={25} style={{ color: "#fff" }} /> : t("Submit")}
                  buttonType="primary"
                  size="large"
                  showIcon
                  iconClass="save"
                />
              </Col>

              <Col md={12} style={{ textAlign: "-webkit-center" }}>
                {/*Cancel Link Redirecting to Enterprise Home Page*/}

                <Button
                  onClick={() => history.push("/")}
                  label={t("CANCEL")}
                  buttonType="ghost"
                  size="large"
                  showIcon={false}
                />
              </Col>
            </Form>
            <SnackBar isFailed={IsFailed} label={handleMessage(IsFailed)} handleClose={handleCloseSnackBar} />
          </Col>
          <Col md={4}>
            <img src={PasswordRecoveryBackground} alt="submit" className={classes.ImageStyle} />
          </Col>
        </Row>
      </div>
    </RegistrationFormWrapper>
  );
};

export default withRouter(ResetPasswordForm);
