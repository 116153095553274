import styled from "styled-components";

export const AssetPermissionWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 33% 33% auto;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "14px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  label {
    margin: 0 !important;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const RowsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
  height: calc(100vh - 640px);
  padding-bottom: 25px;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
`;

export const AllowDisallowWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const AllowDisallow = styled.div`
  color: ${({ isDisabled }) => (isDisabled ? "#bcbcbc" : "#1070e0")};
  padding: 0 5px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  user-select: none;
`;
