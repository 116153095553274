import styled from "styled-components";
export const AccounttProfileContainer = styled.div`
  label {
   /* color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;*/
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    border: 2px solid;
  }
  input.form-control[disabled]:hover {
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }

  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
`;

export const InputProfileDiv = styled.div`
  clear: both;
  margin-top: 10px;
  float: left;
  margin-bottom: 15px;
  width: 100%;
`;

export const InputProfileDivCont = styled.div`
  clear: both;
  margin: 5px auto;
  overflow: hidden;
`;

export const MFAWrapper = styled.div`
  align-items: center;
  margin-top: 15px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
`;

export const OptionsWrapper = styled.div`
  display: flex;
`;
