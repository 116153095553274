//TODO Cleanup this file. Remove unused/commented code and optimize code. Make sure to use of proper let and const. Segregate constants, create cleaner sub components etc

import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { updateUser } from "../../../../redux/userManagement/userManagement.action";
import { setEditForm } from "../../../../redux/forms/action";
import Input from "../../../../components/Input";
import validationConstant from "../../../../constant/messages/validation.json";
import { toValidateEmail } from "../../../../utils/validations/checkValidation";
import { apiCall } from "../../../../utils/apiCall";
import UserEditComponent from "./UserEdit.component";

import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import MultiSelector from "../../../../components/multiSelector/MultiSelector.component";

const UserPageWrapper = styled.div`
  height: calc(100vh - 549px);
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

`;

function UserOverviewTab({
  userDetails,
  dropdownWorksiteList,
  setUserDetails,
  userOverviewDetails,
  setEditForm,
  editForm,
  updateUser,
  handleUpdateUser,
  activeTab,
  userTypeDetailList,
}) {
  const { t } = useTranslation();
  const [updateError, setUpdateError] = useState("");

  const labelStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
    textAlign: "left",
    marginBottom: "0px",
    fontSize: "12px",
    color: "#919191",
  };

  const emailValidation = (e) => {
    e.preventDefault();
    const userEmail = e.target.value;
    let isError = toValidateEmail(userEmail);

    if (isError) {
      setUpdateError(isError);
      return;
    }

    apiCall(`/user/emailavailabilty`, { method: "POST", data: { email: userEmail } }).then((response) => {
      if (response.data.success === false) {
        // setUpdateButtonStatus(true);
        setUpdateError(validationConstant.update_email_duplicate_error);
      } else {
        // setUpdateButtonStatus(false);
        setUpdateError("");
      }
    });
  };

  const handleEmailChange = (e) => {
    if (userOverviewDetails?.email?.toLowerCase() !== e.target.value?.toLowerCase()) {
      emailValidation(e);
    }
    setUserDetails({ ...userDetails, email: e.target.value });
  };

  const getUserType = (userTypeId) => {
    const userTypeObj = userTypeDetailList?.find?.((userType) => userType._id === userTypeId);
    return userTypeObj?.accessLevel;
  };

  const handleWorksiteChange = (authorizedSitesArr) => {
    setUserDetails({ ...userDetails, authorizedSites: authorizedSitesArr });
  };

  const [resetState, setResetState] = useState(false);

  useEffect(() => {
    if (editForm) {
      setResetState(true);
    }
  }, [editForm]);

  const [isWorksiteMandatory, setIsWorksiteMandatory] = useState(false);

  useEffect(() => {
    if (
      (getUserType(userOverviewDetails.userTypeId) === "SINGLE_WORKSITE" ||
        getUserType(userOverviewDetails.userTypeId) === "INDIVIDUAL_EMPLOYEE" ||
        getUserType(userOverviewDetails.userTypeId) === "MULTIPLE_WORKSITES") &&
      userDetails?.authorizedSites?.length <= 0
    ) {
      setIsWorksiteMandatory(true);
    } else {
      setIsWorksiteMandatory(false);
    }
  }, [userDetails.authorizedSites]);

  return (
    <>
      <UserPageWrapper>
        <div style={{ display: "flex", flexDirection: "column" }} className="userPageResponsive">
          <Row className="overviewStyle">
            {getUserType(userOverviewDetails.userTypeId) === "MULTIPLE_WORKSITES" ? (
              <Col md={4}>
                <MultiSelector
                  options={dropdownWorksiteList?.map((option) => ({
                    label: option.name,
                    value: option._id,
                  }))}
                  placeholder={"Select worksites"}
                  label={"Worksites"}
                  changeHandler={handleWorksiteChange}
                  returnValueArray={true}
                  selectedOptions={userOverviewDetails?.authorizedSites.map((option) => ({
                    label: option.name,
                    value: option._id,
                  }))}
                  clearAllText={"Clear All"}
                  isDisabled={editForm}
                  resetState={resetState}
                  setResetState={setResetState}
                  maxMenuHeight={182}
                />
              </Col>
            ) : getUserType(userOverviewDetails.userTypeId) === "SINGLE_WORKSITE" ||
              getUserType(userOverviewDetails.userTypeId) === "INDIVIDUAL_EMPLOYEE" ? (
              <Col md={4}>
                <Input
                  disabled={editForm}
                  showLabel={true}
                  label={t("Worksite")}
                  defaultValue={
                    dropdownWorksiteList && dropdownWorksiteList[0] && dropdownWorksiteList[0]._id
                  }
                  required
                  value={
                    Array.isArray(userDetails?.authorizedSites)
                      ? userDetails?.authorizedSites[0]
                      : userDetails?.authorizedSites
                  }
                  type="select"
                  className="selectBox"
                  onChange={(value) => setUserDetails({ ...userDetails, authorizedSites: value })}
                  options={dropdownWorksiteList?.map?.((data) => ({
                    _id: data._id,
                    label: data.name,
                  }))}
                  feedback={t(validationConstant.worksite_required)}
                />
              </Col>
            ) : null}

            {getUserType(userOverviewDetails.userTypeId) !== "INDIVIDUAL_EMPLOYEE" && (
              <Col md={4}>
                <Form.Group className="text-open-sans">
                  <Form.Label style={labelStyle}>
                    <Trans>Email</Trans>
                  </Form.Label>
                  <Form.Control
                    disabled={editForm}
                    type="email"
                    value={userDetails.email}
                    InputProps={{ disableUnderline: true }}
                    label={t("Email")}
                    minLength={1}
                    maxLength={64}
                    onChange={handleEmailChange}
                  ></Form.Control>
                </Form.Group>
                <p className="text-danger">{updateError}</p>
              </Col>
            )}
            <Col md={4}>
              <Input
                disabled={editForm}
                label={t("Phone")}
                type="phone"
                id="outlined-basic"
                variant="standard"
                name="phone"
                value={userDetails.phone ? userDetails.phone : ""}
                onChange={(e) => setUserDetails({ ...userDetails, phone: e.target.value })}
              />
            </Col>
          </Row>
        </div>
      </UserPageWrapper>
      <PermissionCheck section="DASHBOARD" permissionName={"USER_MANAGEMENT"} actionName="edit">
        <UserEditComponent
          handleUpdateUser={handleUpdateUser}
          userDetails={userDetails}
          activeTab={activeTab}
          isWorksiteMandatory={isWorksiteMandatory}
        />
      </PermissionCheck>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userDetails) => dispatch(updateUser(userDetails)),
  setEditForm: (data) => dispatch(setEditForm(data)),
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  UsersLoading: state.users.UsersLoading,
  editForm: state.forms.editForm,
  userTypeDetailList: state.userType.userTypeDetailList,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserOverviewTab));
