import { Trans, useTranslation } from "react-i18next";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ApplyToAllWrapper, StyledInformationIcon } from "./ActionButtons.styles";
import Checkbox from "../../../components/Checkbox/Checkbox.component";
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans>
      Select this option when you want to apply these settings to your entire account. Please note that any
      existing settings will get overriden if you select apply to all.
    </Trans>
  </Tooltip>
);

const ActionButtons = (props) => {
  const {
    handleApplyToAll,
    applyToAll,
    updatableChanges,
    handleUpdate,
    handleCancel,
    isLoading,
    showAppyToAll,
    applyToAllLabel,
    handleReset,
    showResetButton,
  } = props;
  const { t } = useTranslation();
  return (
    <PermissionCheck section="DASHBOARD" permissionName={"SYSTEM_SETTINGS"} actionName="edit">
      <Row className="update-button-s2 mb-2 pr-2" style={{ justifyContent: "flex-end" }}>
        <Col md={4} className="worksite-names" style={{ display: "flex", justifyContent: "flex-start" }}>
          {showResetButton && (
            <Button
              label={t("RESET TO DEFAULT")}
              onClick={handleReset}
              showIcon={false}
              buttonType="secondary"
            />
          )}
        </Col>
        <Col md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
          {showAppyToAll && (
            <ApplyToAllWrapper className="apptoall">
              {/*<StyledLabel>
            <Form.Check className="mt-1" type="checkbox" onClick={handleApplyToAll} checked={applyToAll} />
            <span>
              <Trans>{applyToAllLabel || "Apply to All"}</Trans>
            </span>
          </StyledLabel>*/}
              <Checkbox
                label={applyToAllLabel || "Apply to All"}
                onClick={handleApplyToAll}
                checked={!!applyToAll}
              />
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <span>
                  <StyledInformationIcon />
                </span>
              </OverlayTrigger>
            </ApplyToAllWrapper>
          )}

          <>
            {!isLoading && (
              <Button label={t("Cancel")} onClick={handleCancel} showIcon={false} buttonType="ghost" />
            )}
            {updatableChanges ? (
              <Button
                label={
                  isLoading ? (
                    <div className="spinner-border spinner-border-sm" role="status"></div>
                  ) : (
                    t("Save")
                  )
                }
                onClick={!isLoading && handleUpdate}
                showIcon
                iconClass="save"
                buttonType="save"
              />
            ) : (
              <Button label={t("Update")} disabled showIcon={false} buttonType="primary" />
            )}
          </>
        </Col>
      </Row>
    </PermissionCheck>
  );
};

export default ActionButtons;
