import React, { useEffect } from "react";
import Slider from "../camSettingView/SCZSlider";
import SCZForkLiftTopView from "../../../../../../assets/CameraSettings/Forklift_Topview.svg";
import "../../CameraSetting.css";
import { widthDataConst, widthDataConstFeet } from "../../constants";

const SCZCameraWidthView = (props) => {
  const {
    forkliftClass,
    selectedCamera,
    inputValue,
    setInputValue,
    obstacle,
    value,
    setValue,
    setTabKey,
    unitType,
    isFeet,
    s3SettingsUntouchedObject,
    camIndex,
  } = props;

  let canvas = document.getElementById("sczMyCanvas");

  useEffect(() => {
    if (canvas) {
      return;
    }
    canvas = document.getElementById("sczMyCanvas");
  }, [value]);

  useEffect(() => {
    let tempPositionPedestrian = {};
    let tempPositionObject = {};
    let currentWidthConst = isFeet ? widthDataConstFeet : widthDataConst;
    currentWidthConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["sczPedestrian"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionPedestrian[type] = newValue;
    });
    currentWidthConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["sczObject"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionObject[type] = newValue;
    });
    let newValue = { ...value };
    let pos4Object = tempPositionObject?.pos4;
    let pos4Pedestrian = tempPositionPedestrian?.pos4;
    if (isNaN(pos4Object) || isNaN(pos4Pedestrian)) {
      return;
    }
    newValue["sczPedestrian"] = { ...tempPositionPedestrian };
    newValue["sczObject"] = { ...tempPositionObject };
    setValue({ ...newValue });
  }, [inputValue]);

  let rangeSlider1 = document.getElementById("scz-rs-range-line-1");
  let rangeBullet1 = document.getElementById("scz-rs-bullet-1");
  let rangeInput1 = document.getElementById("scz-rs-input-1");
  let rangeSlider2 = document.getElementById("scz-rs-range-line-2");
  let rangeBullet2 = document.getElementById("scz-rs-bullet-2");
  let rangeInput2 = document.getElementById("scz-rs-input-2");
  let rangeSlider3 = document.getElementById("scz-rs-range-line-3");
  let rangeBullet3 = document.getElementById("scz-rs-bullet-3");
  let rangeInput3 = document.getElementById("scz-rs-input-3");

  const drawingForFeet = () => {
    let { pos1, pos2, pos3 } = value[obstacle];
    pos1 = parseFloat(pos1);
    pos2 = parseFloat(pos2);
    pos3 = parseFloat(pos3);
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, 190);
    let bulletPosition1 = pos1 / rangeSlider1?.max;
    let bulletPosition2 = pos2 / rangeSlider2?.max;
    let bulletPosition3 = pos3 / rangeSlider3?.max;
    if (rangeBullet1) {
      rangeBullet1.style.left = bulletPosition1 * 102 + "px";
      ctx.lineTo(pos2 * 2.1 + 46, 141 - pos2 * 2.08);
      if (pos1 >= pos2) {
        ctx.lineTo(pos1 * 2 + 50, 141 - pos2 * 2.08);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 140 - pos1 * 2);
      }

      if (pos1 >= pos3) {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos3 * 2);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos1 * 2);
      }

      ctx.lineTo(pos3 * 2.04 + 48, 240 + pos3 * 2);

      ctx.lineTo(0, 190);
      ctx.closePath();
      ctx.fillStyle = "rgb(14, 95, 190, 0)";
      ctx.strokeStyle = "rgb(14, 95, 190, 0)";
      ctx.stroke();
      ctx.fill();
    }
    if (rangeBullet2) {
      rangeBullet2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeBullet3) {
      rangeBullet3.style.left = bulletPosition3 * 102 + "px";
    }
    if (rangeInput1) {
      rangeInput1.style.left = bulletPosition1 * 102 + "px";
    }
    if (rangeInput2) {
      rangeInput2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeInput3) {
      rangeInput3.style.left = bulletPosition3 * 102 + "px";
    }
  };

  const drawingForMM = () => {
    let { pos1, pos2, pos3 } = value[obstacle];
    pos1 = parseInt(pos1);
    pos2 = parseInt(pos2);
    pos3 = parseInt(pos3);
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, 190);
    let bulletPosition1 = pos1 / rangeSlider1?.max;
    let bulletPosition2 = pos2 / rangeSlider2?.max;
    let bulletPosition3 = pos3 / rangeSlider3?.max;
    if (rangeBullet1) {
      rangeBullet1.style.left = bulletPosition1 * 102 + "px";
      ctx.lineTo(pos2 * 2.1 + 46, 141 - pos2 * 2.08);
      if (pos1 >= pos2) {
        ctx.lineTo(pos1 * 2 + 50, 141 - pos2 * 2.08);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 140 - pos1 * 2);
      }

      if (pos1 >= pos3) {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos3 * 2);
      } else {
        ctx.lineTo(pos1 * 2 + 50, 240 + pos1 * 2);
      }

      ctx.lineTo(pos3 * 2.04 + 48, 240 + pos3 * 2);

      ctx.lineTo(0, 190);
      ctx.closePath();
      ctx.fillStyle = "rgb(14, 95, 190, 0)";
      ctx.strokeStyle = "rgb(14, 95, 190, 0)";
      ctx.stroke();
      ctx.fill();
    }
    if (rangeBullet2) {
      rangeBullet2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeBullet3) {
      rangeBullet3.style.left = bulletPosition3 * 102 + "px";
    }
    if (rangeInput1) {
      rangeInput1.style.left = bulletPosition1 * 102 + "px";
    }
    if (rangeInput2) {
      rangeInput2.style.left = bulletPosition2 * 102 + "px";
    }
    if (rangeInput3) {
      rangeInput3.style.left = bulletPosition3 * 102 + "px";
    }
  };

  useEffect(() => {
    if (unitType?.toLowerCase() === "metric") {
      drawingForMM();
    } else {
      drawingForFeet();
    }
  }, [value, inputValue, obstacle, selectedCamera]);

  const unTouchedNearMissDetectionObj =
    s3SettingsUntouchedObject?.[camIndex]?.speedControlZones?.[obstacle.replace("scz", "").toLowerCase()];

  return (
    <div style={{ postion: "relative" }} className="camera_width_depth_style speedControl">
      <div className="width-text">
        <p>Move the slider up or down to adjust the values</p>
      </div>
      <canvas id="sczMyCanvas" className="canvas" width="310" height="370"></canvas>
      <img
        src={SCZForkLiftTopView}
        style={{ width: "150px", height: "100px" }}
        className={`forklift-${forkliftClass?.toLowerCase()}`}
        alt="Forklift Top View"
      />
      <div className="square-red" />
      <div className="dot-line-1" />
      <div className="dot-line-2" />
      <div className="dot-line-3" />
      <div className="dot-line-4" />
      <div className="dot-line-5" />
      <div className="dot-line-6" />
      <div className="dot-line-7" />
      <div className="container parent">
        <>
          <Slider
            unitType={unitType}
            setTabKey={setTabKey}
            obstacle={obstacle}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setValue={setValue}
            value={value}
            type="pos2"
            min={isFeet ? 1.97 : 600}
            max={isFeet ? 9.84 : 3000}
            untouchedValueinMM={Math.abs(unTouchedNearMissDetectionObj?.left)}
          />
          <Slider
            unitType={unitType}
            setTabKey={setTabKey}
            obstacle={obstacle}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setValue={setValue}
            value={value}
            type="pos3"
            min={isFeet ? 1.97 : 600}
            max={isFeet ? 9.84 : 3000}
            untouchedValueinMM={Math.abs(unTouchedNearMissDetectionObj?.right)}
          />{" "}
        </>
      </div>
    </div>
  );
};

export default SCZCameraWidthView;
