//TODO- Clean up this file when time permits. Remove unused/commented code, improve/optimize code

import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled, { ThemeContext } from "styled-components";

import AssetManagement from "../../../Dashboard/Asset/AssetManagement.component";
import AddAssetStepper from "../../../Dashboard/Asset/AddAssetStepper.component";
import PlanAlert from "../../Asset/Modal/PlanAlertModal";

import { getAssetTypes, getAllAsset } from "../../../../redux/assets/action";
import { toGenerateCSV } from "../../../../redux/assets/action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { CSVLink } from "react-csv";
import { Trans, useTranslation } from "react-i18next";

import Button from "../../../../components/Button/Button.component";
import { assetTypeList, defaultAssetTypeOverview } from "../../../../redux/assetType/assetType.action";
import { setQrcodeFilterBy, getDropdownAssetTypeList } from "../../../../redux/dropdownFilters/action";
import { setAddAssetModal, setEditAlertModal, setModalWaitingToOpen } from "../../../../redux/modals/action";
import { setShowFilters } from "../../../../redux/mobileView/action";
import planConstant from "../../../../constant/planConstant.json";
import { PLAN_TO_ID } from "../../../../constant/billingPlans";
import AssetActionBar from "../../Asset/AssetActionBar.component";
import AssetsCard from "../../Asset/AssetsCard.component";
import SearchBox from "../../../../components/SearchBox.component";
import AssetFilters from "../../Asset/AssetFilters.component";
import AssetList from "../../Asset/AssetList.component";
import AssetOverview from "../../Asset/AssetOverview.component";
import AssetBulkUpload from "../../Asset/Modal/BulkUploadModal/AssetBulkUpload.component";
import CommonSuccessMessage from "../../common/CommonSuccessMessage.component";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import ModalPopup from "../../../../components/ModalPopupComponent/ModalPopup.component";
import AddAssetStepperComponent from "../../../../components/CustomStepper/CustomStepper.component";
import ManagementPanelStyles from "./ManagementPanel.module.css";
import rightIcon from "../../../../assets/rightIconwhite.svg";

const AssetPanelStyled = styled.div`
  .assetMobileCss {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  }
  .filterButtonCss {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;
const FilterIconStyled = styled.div`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  position: relative;
  top: 3px;
`;

const { inspectionPlan } = planConstant;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
  demo1: {
    paddingTop: "10px",
    backgroundColor: "#ffffff",
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  addAccountText: {
    fontFamily: "Kanit",
    fontSize: "25px",
    fontStyle: "normal",
  },
  bulkIcon: {
    marginBottom: "3px",
    width: 18,
    marginRight: 7,
    // stroke: "#c6c6c6",
    fill: "#c6c6c6",
    strokeWidth: "2px",
  },
}));

function AssetPanel(props) {
  let {
    assetOverview,
    history,
    assetLoading,
    toGenerateCSV,
    generateCSVData,
    generateCSVLoading,
    loginDetails,
    setAssetTypeList,
    defaultAssetTypeOverview,
    inspectionPlanDetail,
    isSuperAdmin,
    setAddAssetModal,
    addAssetModal,
    setQrcodeFilterBy,
    updateActiveNavItem,
    setModalWaitingToOpen,
    setShowFilters,
    showFilters,
    assetName,
    editForm,
    editFormSecond,
    setEditAlertModal,
    getDropdownAssetTypeList,
  } = props;

  const classes = useStyles();
  const [worksiteBulkUploadShow, setWorksiteBulkUploadShow] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setIsError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [filterAccountId, setFilterAccountId] = useState("all");
  const [createdAsset, setCreatedAsset] = useState({});
  const [showExportData, setShowExportData] = useState(false);

  const { t } = useTranslation();

  //TODO-Optimize this code
  const isPlanValidToExport = () => {
    let noInspecDone = inspectionPlanDetail && inspectionPlanDetail.count;
    let noOfLimit =
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan] &&
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan].maxAllowed;
    if (!noInspecDone) {
      noInspecDone = 0;
    }
    if (isSuperAdmin) {
      return true;
    }
    if (!noOfLimit) {
      return false;
    } else if (noOfLimit > noInspecDone) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getDropdownAssetTypeList();
    if (isPlanValidToExport) {
      toGenerateCSV();
    }
    updateActiveNavItem("assets");
  }, []);

  useEffect(() => {
    if (generateCSVData?.length > 0) {
      setShowExportData(true);
    } else {
      setShowExportData(false);
    }
    // updateActiveNavItem("users");
  }, [generateCSVData]);
  const headersData = [
    { label: t("Asset Name"), key: "assetName" },
    { label: t("Asset Type"), key: "assetType" },
    { label: t("Worksite"), key: "worksite" },
    { label: t("Worksite Manager"), key: "manager" },
    { label: t("Last Meter Reading"), key: "meterReading" },
    // { label: t("Last Inspection Result"), key: "status" },
    // { label: t("Last Inspected By"), key: "inspector" },
    // { label: t("Last Inspected On"), key: "date" },
    // { label: t("Last Impact On"), key: "lastImpactDate" },
    // { label: t("Last Impact Severity"), key: "lastimpactSeverity" },
    // { label: t("Last Impact By"), key: "lastImpactBy" },
    { label: t("Last Passed Inspection On"), key: "lastPassedInspectionAt" },
    { label: t("Last Failed Inspection On"), key: "lastFailedInspectionAt" },
    { label: t("Last Timedout Inspection On"), key: "lastTimedoutInspectionAt" },
    { label: t("Last Low Impact On"), key: "lastLowImpactAt" },
    { label: t("Last Medium Impact On"), key: "lastMediumImpactAt" },
    { label: t("Last High Impact On"), key: "lastHighImpactAt" },
  ];
  const handleDownloadCSV = (e) => {
    if (generateCSVData?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  let basicPlan = loginDetails?.plan === PLAN_TO_ID.Basic;

  return (
    <AssetPanelStyled>
      {/* {assetLoading || generateCSVLoading ? <Loader /> : null} */}
      <div className={`${ManagementPanelStyles.desktopManagementPanel}`}>
        <Row className="m-0 p-0">
          <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_TYPE_MANAGEMENT"} actionName={"view"}>
            <div className="mr-2 pl-0">
              <Button
                iconClass="manage-asset-types"
                label={t("Manage asset types")}
                onClick={() =>
                  editForm && editFormSecond
                    ? history.push("/app/management/assettype")
                    : setEditAlertModal(true)
                }
                showIcon
                buttonType="secondary"
              />
            </div>
          </PermissionCheck>
          <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"add"}>
            <div className="mr-2 pl-0">
              <Button
                iconClass="add"
                label={t("Add Asset")}
                onClick={() =>
                  editForm && editFormSecond ? setAddAssetModal(true) : setEditAlertModal(true)
                }
                showIcon
                buttonType="secondary"
              />
            </div>

            <div className="mr-2 pl-0">
              <Button
                iconClass="bulk-upload"
                label={t("Bulk Upload")}
                onClick={() =>
                  editForm && editFormSecond ? setWorksiteBulkUploadShow(true) : setEditAlertModal(true)
                }
                showIcon
                buttonType="secondary"
              />
            </div>
          </PermissionCheck>

          <div className="mr-2 pl-0">
            <>
              {isPlanValidToExport() ? (
                <CSVLink
                  data={generateCSVData ? generateCSVData : []}
                  headers={headersData}
                  filename={"Asset-Ledger.csv"}
                >
                  <Button
                    disabled={!showExportData}
                    iconClass="download"
                    label={t("Export Data")}
                    showIcon
                    buttonType="secondary"
                    onClick={editForm && editFormSecond ? handleDownloadCSV : setEditAlertModal}
                  />
                </CSVLink>
              ) : (
                <Button
                  iconClass="download"
                  label={t("Export Data")}
                  onClick={() => (editForm && editFormSecond ? setModalShow(true) : setEditAlertModal(true))}
                  showIcon
                  buttonType="secondary"
                />
              )}
            </>
          </div>
          {/* <Col  md={{ offset: 5 }}><SearchAWorksite /></Col> */}
          <div className="mr-2 pl-0">
            <Button
              iconClass="print"
              label={t("Print Qr Codes")}
              onClick={() => {
                if (editForm) {
                  setQrcodeFilterBy("assets");
                  history.push("/app/management/qrcode");
                } else {
                  setEditAlertModal(true);
                }
              }}
              showIcon
              buttonType="secondary"
            />
          </div>
        </Row>
        <AssetManagement />
      </div>

      <div className={`${ManagementPanelStyles.mobileManagementPanel}`}>
        <AssetActionBar
          setAddAssetModal={setAddAssetModal}
          setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
          isPlanValidToExport={isPlanValidToExport}
          generateCSVData={generateCSVData}
          basicPlan={basicPlan}
          history={history}
          headersData={headersData}
          handleDownloadCSV={handleDownloadCSV}
          setModalShow={setModalShow}
        />
        {!showAssetDetail ? (
          <>
            <AssetsCard />
            <div className={`${ManagementPanelStyles.searchBoxFilter}`}>
              <div style={{ width: "88%" }}>
                <div className={`${ManagementPanelStyles.searchMobileView}`}>
                  <SearchBox
                    searchText={SearchText}
                    setSearchText={setSearchText}
                    placeholder={t("Search an asset")}
                  />
                </div>
              </div>
              <div style={{ width: "12%" }} className={`${ManagementPanelStyles.filterButtonStyle}`}>
                <p onClick={() => setShowFilters(!showFilters)}>
                  {showFilters ? (
                    <button className={`${ManagementPanelStyles.filterButtonStyleHide} filterButtonCss`}>
                      <FilterIconStyled className="icon icon-filter"></FilterIconStyled>
                    </button>
                  ) : (
                    <button className={`${ManagementPanelStyles.filterButtonStyleShow} filterButtonCss`}>
                      <FilterIconStyled className="icon icon-filter"></FilterIconStyled>
                    </button>
                  )}
                </p>
              </div>
            </div>
            <div className={`${ManagementPanelStyles.filterSelectStyle}`}>
              {showFilters ? <AssetFilters /> : ""}
            </div>
            <div className={`${ManagementPanelStyles.mobileListingSection}`}>
              <AssetList
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                searchText={SearchText}
                filterAccountId={filterAccountId}
                setShowAssetDetail={() => setShowAssetDetail(true)}
              />
            </div>
          </>
        ) : (
          <>
            <p className={`${ManagementPanelStyles.assetNameMobile} assetMobileCss`}>
              <span onClick={() => setShowAssetDetail(false)}>Your Asset</span>{" "}
              <img height="15" src={rightIcon} /> {` ${assetName || ""}`}
            </p>
            <AssetOverview />
          </>
        )}
      </div>

      <AssetCentralModal
        show={addAssetModal}
        onHide={() => {
          setAddAssetModal(false);
          setModalWaitingToOpen(false);
        }}
        toogleModalClose={() => {
          setAddAssetModal(false);
          setModalWaitingToOpen(false);
        }}
        setIsSuccess={setIsSuccess}
        setIsError={setIsError}
        setCreatedAsset={setCreatedAsset}
      />
      {/* <WoksiteBulkUploadModal
        show={worksiteBulkUploadShow}
        onHide={() => setWorksiteBulkUploadShow(false)}
        toogleModalClose={() => setWorksiteBulkUploadShow(false)}
      /> */}
      {worksiteBulkUploadShow && (
        <AssetBulkUpload
          show={worksiteBulkUploadShow}
          onHide={() => setWorksiteBulkUploadShow(false)}
          toogleModalClose={() => setWorksiteBulkUploadShow(false)}
        />
      )}
      <PlanAlert history={history} modalShow={modalShow} setModalShow={setModalShow} />

      <CommonSuccessMessage />
    </AssetPanelStyled>
  );
}

function WoksiteBulkUploadModal(props) {
  return (
    <Modal
      backdrop="static"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ padding: "200px", height: "500px" }}
    >
      <Modal.Body>
        <Row className="pl-4 pr-4 justify-content-center">
          <h4 className="text-style">
            <Trans>This feature will be here soon, kindly contact</Trans>{" "}
            <a href="mailto:support@siera.ai">support@siera.ai</a> <Trans>for more information</Trans>.
          </h4>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

// export function AssetCentralModal(props) {
//   const classes = useStyles();
//   const { t } = useTranslation();
//   const themeContext = useContext(ThemeContext);
//   const styles = {
//     modalBody: {
//       backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
//       "& .MuiPaper-root": {
//         backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
//       },
//     },
//     colorStyle: {
//       color: themeContext?.colors?.grayL1 || "#323232",
//     },
//     stepperStyle: {
//       backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
//     },
//     modalHeader: {
//       backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
//       borderColor: themeContext?.colors?.grayL5 || "#CCCCCC",
//     },
//     modalTittle: {
//       color: themeContext?.colors?.grayL1 || "#323232",
//     },
//   };
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       //  style={{ paddingTop: "60px" }}
//       backdrop="static"
//       className={`${ManagementPanelStyles.popupAssetModal}`}
//     >
//       <Modal.Header style={styles.modalHeader}>
//         <Modal.Title
//           style={styles.modalTittle}
//           className={classes.addAccountText}
//           id="contained-modal-title-vcenter"
//         >
//           <Trans>Add Asset</Trans>
//         </Modal.Title>

//         <Button label={t("Cancel")} onClick={props.toogleModalClose} showIcon={false} buttonType="ghost" />

//         <button onClick={props.toogleModalClose} className={`${ManagementPanelStyles.ButtonMobileCancel}`}>
//           <Trans>X</Trans>
//         </button>
//       </Modal.Header>
//       <Modal.Body style={styles.modalBody} className={`${ManagementPanelStyles.AssetModalBody}`}>
//         <AddAssetStepper
//           {...props}
//           toogleModalClose={props.toogleModalClose}
//           setIsSuccess={props.setIsSuccess}
//           setIsError={props.setIsError}
//         />
//       </Modal.Body>
//     </Modal>
//   );
// }

export function AssetCentralModal(props) {
  const { t } = useTranslation();
  return (
    <ModalPopup
      {...props}
      size="lg"
      centered
      backdrop="static"
      title={t("Add Asset")}
      handleClose={props.toogleModalClose}
    >
      <AddAssetStepper
        {...props}
        toogleModalClose={props.toogleModalClose}
        setIsSuccess={props.setIsSuccess}
        setIsError={props.setIsError}
      />
    </ModalPopup>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  accountList: state.account.accountList,
  addassetSuccessFlag: state.assets.addassetSuccessFlag,
  menuList: state.sideMenu.menuState,
  assetLoading: state.assets.AssetLoading,
  generateCSVData: state.assets.GeneratingCSVData,
  generateCSVLoading: state.assets.GenerateCSVLoading,
  inspectionPlanDetail: state.config.inspectionPlan,
  isSuperAdmin: state.user.isSuperAdmin,
  addAssetModal: state.modals.addAssetModal,
  showFilters: state.mobileView.showFilters,
  assetName: state?.assets?.AssetOverview?.assetName,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  setAssetTypeList: (assetTypeLists) => dispatch(assetTypeList(assetTypeLists)),
  defaultAssetTypeOverview: () => dispatch(defaultAssetTypeOverview()),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  getAllAsset: () => dispatch(getAllAsset()),
  getAssetTypes: () => dispatch(getAssetTypes()),
  toGenerateCSV: () => dispatch(toGenerateCSV()),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setQrcodeFilterBy: (data) => dispatch(setQrcodeFilterBy(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setShowFilters: (data) => dispatch(setShowFilters(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetPanel));
