import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Table,
  Accordion,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import AccountSvg from "../../../../assets/AddIcon/accounts.svg";
import DropdownFilter from "../../../../components/DropdownFilter.component";
import { ReactComponent as ExpandMoreIcon } from "../../../../assets/drawer_assets/ExpandMoreIcon.svg";
import { ReactComponent as ExpandLessIcon } from "../../../../assets/drawer_assets/ExpandLessIcon.svg";
import { getBillingInfo } from "../../../../redux/billing/action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
const CarouselWrapperDiv = styled.div`
  .borderCar {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .count-card {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    span {
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    }
  }
  .borderLeftStyle {
    border-left: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;

const billingConstant = require("../../../../constant/planConstant.json");

const BillingAdmin = ({ getBillingInfo, billingInfo, updateActiveNavItem }) => {
  const { t } = useTranslation();

  const styles = {
    vertical: {
      borderLeft: "1px solid #C6C6C6",
      height: "72px",
    },
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [SearchText, setSearchText] = useState("");
  const [filterPlanType, setFilterPlanType] = useState("all");
  const [currentActiveKey, setCurrentActiveKey] = useState("");

  let scoutBasicCount = billingInfo?.filter(
    (billingInfo) => billingInfo && billingInfo.billingPlan === billingConstant.BASE_PLAN
  ).length;

  let scoutProCount = billingInfo?.filter(
    (billingInfo) =>
      (billingInfo && billingInfo.billingPlan === billingConstant.MONTHLY_PLAN) ||
      (billingInfo && billingInfo.billingPlan === billingConstant.ANNUALLY_PLAN)
  ).length;

  const filterByPlanType =
    billingInfo &&
    billingInfo?.filter((billingInfo) => {
      if (filterPlanType === "SCOUT_PRO") {
        return (
          (billingInfo && billingInfo.billingPlan === billingConstant.MONTHLY_PLAN) ||
          (billingInfo && billingInfo.billingPlan === billingConstant.ANNUALLY_PLAN)
        );
      } else if (filterPlanType === "SCOUT_BASIC") {
        return billingInfo && billingInfo.billingPlan === billingConstant.BASE_PLAN;
      } else {
        return true;
      }
    });

  let filteredUserByPlanType = filterPlanType === "all" ? billingInfo : filterByPlanType;

  let searchedUsers =
    filteredUserByPlanType &&
    filteredUserByPlanType.filter((userBillingInfo) => {
      if (userBillingInfo.accountName) {
        return (
          userBillingInfo.accountName &&
          userBillingInfo.accountName.toLowerCase().indexOf(SearchText.toLowerCase()) !== -1
        );
      }
    });

  let tableHeader = [
    { name: "Account Name", space: 2, feildName: "accountName" },
    { name: "Billing Plan", space: 1, feildName: "billingCycle" },
    { name: "Valid Untill", space: 2, feildName: "validupto" },
    { name: "Assets", space: 1, feildName: "assetsCount" },
    { name: "Worksites", space: 1, feildName: "worksiteCount" },
    { name: "Total Inspections", space: 2, feildName: "inspectionCount" },
    { name: "Inspections This Month", space: 2, feildName: "inspectionCountMonthly" },
  ];

  let tableRowData = [
    { name: "Valid Untill", space: 2, feildName: "validupto" },
    { name: "Assets", space: 1, feildName: "assetsCount" },
    { name: "Worksites", space: 1, feildName: "worksiteCount" },
    { name: "Total Inspections", space: 2, feildName: "inspectionCount" },
    { name: "Inspections This Month", space: 2, feildName: "inspectionCountMonthly" },
  ];

  let billingDetails = [
    // { name: "Billing Plan", feildName: "billingPlan", isDate: false },
    { name: "Billing Cycle", feildName: "billingCycle", isDate: false },
    { name: "Effective Since", feildName: "effectiveSince", isDate: true },
    { name: "Valid Untill", feildName: "validupto", isDate: true },
    { name: "Cost/Asset", feildName: "costPerasset", isDate: false },
    { name: "Voucher Applied", feildName: "Null", isDate: false },
    { name: "Assets", feildName: "assetsCount", isDate: false },
    { name: "Max Assets", feildName: "Null", isDate: false },
    { name: "Worksites", feildName: "worksiteCount", isDate: false },
    { name: "Users", feildName: "userCount", isDate: false },
    { name: "Operators", feildName: "operatorCount", isDate: false },
    { name: "Expiration Reminder", feildName: "Null", isDate: false },
    { name: "Total Inspections", feildName: "inspectionCount", isDate: false },
  ];

  let accountsTypeByPlan = [
    { name: "All Accounts", feildName: billingInfo?.length },
    { name: "Scout Basic", feildName: scoutBasicCount },
    { name: "Scout Pro", feildName: scoutProCount },
  ];

  const toogleDropdown = (eventKey) => {
    if (eventKey != currentActiveKey) {
      setCurrentActiveKey(eventKey);
    } else {
      setCurrentActiveKey("");
    }
  };

  useEffect(() => {
    getBillingInfo();
    updateActiveNavItem("billingLink");
  }, []);

  return (
    <div>
      <BreadCrumb />
      <br />
      <Row className="biiling_row">
        <Col className="billing_col">
          <div className="searchBar mt-3">
            <input
              id="searchQueryInput"
              maxLength={64}
              minLength={1}
              type="text"
              name="searchQueryInput"
              placeholder={t("Search a account")}
              onChange={(e) => setSearchText(e.target.value)}
              value={SearchText}
            />
            <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
              <svg style={{ width: "24px", height: "24px", transform: "rotate(84deg)" }} viewBox="0 0 24 24">
                <path
                  fill="#666666"
                  d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                />
              </svg>
            </button>
          </div>
        </Col>
        <Col>
          <DropdownFilter
            filter={{
              type: "Subscription Plan",
              name: "subscriptionPlan",
              key: "name",
              data: [
                { name: "Scout Basic", _id: "SCOUT_BASIC" },
                { name: "Scout Pro", _id: "SCOUT_PRO" },
              ],
            }}
            label={t("filter")}
            handleFilter={(value) => setFilterPlanType(value)}
          />
        </Col>
        <Col lg={5}>
          <CarouselWrapperDiv>
            <Carousel responsive={responsive} className="shadow-sm borderCar Account_Carousel">
              {accountsTypeByPlan.map((accountType) => (
                <div className="fexDiv fexDiv_A">
                  <Col className="count-card">
                    <Trans>{accountType.name}</Trans>
                    <br />
                    <img alt="Account" src={AccountSvg} style={{ height: "20px", marginRight: "10px" }} />
                    <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                      {accountType.feildName}
                    </span>
                  </Col>
                  <div style={styles.vertical} className="borderLeftStyle"></div>
                </div>
              ))}
            </Carousel>
          </CarouselWrapperDiv>
        </Col>
      </Row>
      <br />
      <Table borderless className="text-kanit">
        <thead
          style={{
            backgroundColor: "#0D5FBE",
            fontSize: "28px",
            border: "0px solid",
          }}
        >
          <tr>
            <th className="biling_head">
              <Row className="ml-2 mr-2 text-white">
                {tableHeader.map((data) => (
                  <Col lg={data.space} md={2} className="text-left m-0 p-0">
                    <Trans>{data.name}</Trans>
                  </Col>
                ))}
              </Row>
            </th>
          </tr>
        </thead>
        <div className="billing-height">
          <Accordion activeKey={currentActiveKey}>
            {searchedUsers && searchedUsers[0] ? (
              searchedUsers.map((data, i) => (
                <Card>
                  <Accordion.Toggle
                    className="border-0"
                    as={Card}
                    eventKey={i + 1}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => toogleDropdown(i + 1)}
                  >
                    <Row className="ml-2 mr-2">
                      <Col lg={2} md={2} className="text-left m-0 p-2 text_size_bill">
                        <p className="bill_respo">
                          <Trans>Account Name</Trans>
                        </p>
                        <h7 className="text-primary font-weight-bold">{data.accountName}</h7>
                        <div className="text-muted" style={{ fontSize: "8px !important" }}>
                          {data.acountOwner}
                        </div>
                      </Col>
                      <Col lg={1} md={1} className="text-left m-0 p-2 text_size_bill">
                        <p className="bill_respo">
                          <Trans>Billing Plan</Trans>
                        </p>
                        <h7 className="text-primary font-weight-bold">{billingConstant[data.billingPlan]}</h7>
                        <div className="text-muted" style={{ fontSize: "8px !important" }}>
                          {data.billingCycle}
                        </div>
                      </Col>
                      {tableRowData.map((tableData) => (
                        <Col lg={tableData.space} md={2} className="text-left m-0 p-2 hidden_call">
                          {tableData.feildName === "validupto"
                            ? new Date(data[tableData.feildName]).toDateString()
                            : data[tableData.feildName]}
                        </Col>
                      ))}
                      <Col lg={1} md={1} className="m-0 p-2">
                        {i + 1 == currentActiveKey ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </Col>
                    </Row>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey={i + 1}>
                    <Card.Body style={{ backgroundColor: "#F6F6F6" }} className="border-0">
                      <Row>
                        <Col lg={2} sm={1}>
                          <div className="mb-0" style={{ textAlign: "left" }}>
                            <Trans>Billing Plan</Trans>
                          </div>
                          <div
                            style={{ backgroundColor: "#FFFFFF", borderRadius: "5px", textAlign: "left" }}
                            className="p-2 mb-3"
                          >
                            {billingConstant[data.billingPlan]}
                          </div>
                        </Col>
                        {billingDetails.map((billingDetail) => (
                          <Col lg={2} sm={1}>
                            <div className="mb-0" style={{ textAlign: "left" }}>
                              <Trans>{billingDetail.name}</Trans>
                            </div>
                            <div
                              style={{ backgroundColor: "#FFFFFF", borderRadius: "5px", textAlign: "left" }}
                              className="p-2 mb-3"
                            >
                              {billingDetail.isDate
                                ? new Date(data[billingDetail.feildName]).toDateString()
                                : data[billingDetail.feildName]
                                ? data[billingDetail.feildName]
                                : "NA"}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))
            ) : (
              <div className="p-4">
                <Trans>No Billing Detail</Trans>
              </div>
            )}
          </Accordion>
        </div>
        <hr className="m-0 p-0" />
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  billingInfo: state.billing.BillingInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getBillingInfo: () => dispatch(getBillingInfo()),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingAdmin);
