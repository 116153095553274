/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import SettingsSection from "../../../../components/SettingsSection";
import {
  getWorksiteSummaryAlertSettings,
  updateWorksiteSummaryAlertSettings,
  resetWorksiteSummaryAlertSettings,
} from "../../../../redux/notification/action";
import { isEqual } from "../../../../utils/validations/utilities";
import { SettingWrapper } from "./NotificationSetting.component.styles";

import ActionsBar from "./ActionsBar";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, SettingsContainer, SettingsFooter } from "../Settings.Styles";
import {
  bulkSettingsUpdate,
  resetNotificationSettingsFlags,
  bulkUpdateWorksiteSummaryAlertSettings,
} from "../../../../redux/config/action";
import SnackBar from "../../SnackBar";
import { NoAssetWrapper } from "./../common/NoAssetFound.styles";
import Recipients from "./emailerRecipients/Recipients";
import { handleRecipientsChangeUtil } from "./common";
import validationConstant from "../../../../constant/messages/validation.json";

const WorksiteSummarySettings = ({
  loginDetails,
  notificationSetting,
  isSiteAdmin,
  setDropdownAccountId,
  dropdownAccountId,
  getWorksiteSummaryAlertSettings,
  updateWorksiteSummaryAlertSettings,
  resetWorksiteSummaryAlertSettings,
  dropdownWorksiteId,
  isLoading,
  bulkSettingsUpdate,
  bulkUpdateWorksiteSummaryAlertSettings,
  loading,
  notificationUpdateLoading,
  notificationBulkUpdateSuccess,
  isSuperAdmin,
}) => {
  const [workSiteSummaryAlertSettings, setWorkSiteSummaryAlertSettings] = useState(null);
  //const [isLoading, setIsLoading] = useState(false);
  const [worksiteId, setWorksiteId] = useState(null);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  const settingsOptions = [
    {
      label: "All Worksites",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "worksites",
    },
    {
      label: "Selected Worksites",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "name",
      radioGroup: "worksites",
      dropdown: {
        placeholder: "Select a Worksite",
      },
    },
  ];

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getWorksiteSummaryAlertSettings(dropdownWorksiteId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  useEffect(() => {
    setCurrentAccountId(isSuperAdmin ? dropdownAccountId : loginDetails && loginDetails.accountId);
  }, [dropdownAccountId, loginDetails, isSuperAdmin]);

  const { t } = useTranslation();

  const setDefaultNotificationSettings = () => {
    setClearSelection(true);
    setWorkSiteSummaryAlertSettings(notificationSetting?.workSiteSummaryAlertSettings);
  };

  const hideButtonsForWorksiteSettings = isEqual(
    notificationSetting?.workSiteSummaryAlertSettings,
    workSiteSummaryAlertSettings
  );

  useEffect(() => {
    if (notificationSetting) {
      setDefaultNotificationSettings();
    }
  }, [notificationSetting?.workSiteSummaryAlertSettings]);

  const handleWorkSiteSummaryAlertSettingsChange = (field, value) => {
    setWorkSiteSummaryAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const onSubmitWorksiteSettings = () => {
    //setIsLoading(true);
    let selectedWorksites = [];
    const worksiteId = isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownWorksiteId;

    if (applyToAllObj.worksites?.all) {
      selectedWorksites = ["all"];
    } else if (applyToAllObj.worksites?.selected) {
      applyToAllObj?.worksites?.selectedItems?.forEach((site) => selectedWorksites.push(site.value));

      if (!selectedWorksites.includes(worksiteId)) {
        selectedWorksites = [...selectedWorksites, worksiteId];
      }
    } else {
      selectedWorksites = [worksiteId];
    }

    if (Object.keys(applyToAllObj).length !== 0) {
      const siteIds = applyToAllObj?.worksites?.all
        ? "all"
        : applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);

      const updatableSettings = {
        sites: [...siteIds, dropdownWorksiteId],
        settings: { workSiteSummaryAlertSettings },
      };
      bulkUpdateWorksiteSummaryAlertSettings(updatableSettings, setIsUpdateLoading);
      // bulkSettingsUpdate(updatableSettings, setIsUpdateLoading);
    } else {
      updateWorksiteSummaryAlertSettings(
        dropdownWorksiteId,
        { workSiteSummaryAlertSettings },
        setIsUpdateLoading
      );
    }
  };

  const displaySettings = {
    workSiteSummaryAlertSettings: {
      title: "worksiteSummaryAlert",
      description: "worksiteSummaryAlertDescription",
      tableHeader: ["Duration", "Alert"],
      onChange: handleWorkSiteSummaryAlertSettingsChange,
      tableData: [
        {
          label: "Weekly",
          value: !!workSiteSummaryAlertSettings?.weekly,
          field: "weekly",
        },
        {
          label: "Monthly",
          value: !!workSiteSummaryAlertSettings?.monthly,
          field: "monthly",
        },
      ],
    },
  };

  const handleAssetIdFilter = (assetId) => {
    setSelectedAssetId(assetId);
  };

  useEffect(() => {
    if (dropdownWorksiteId) {
      getWorksiteSummaryAlertSettings(dropdownWorksiteId);
    }
  }, [dropdownWorksiteId]);

  const onReset = () => {
    resetWorksiteSummaryAlertSettings(dropdownWorksiteId, setIsUpdateLoading);
  };

  const handleCloseSnackBar = () => {
    resetNotificationSettingsFlags();
    setIsUpdateLoading(false);
  };

  const handleRecipientsChange = (userType, isChecked) => {
    handleRecipientsChangeUtil(
      userType,
      isChecked,
      workSiteSummaryAlertSettings,
      setWorkSiteSummaryAlertSettings
    );
  };

  const handleCustomEmailsChange = (email) => {
    const temp = {
      ...workSiteSummaryAlertSettings,
      customEmails: [...email],
    };
    setWorkSiteSummaryAlertSettings(temp);
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("A summary of each worksite will be sent based on the time span selected below.")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
        showWorksiteFilter={true}
        showAssetTypeFilter={false}
        showAssetFilter={false}
        show
      />
      {currentAccountId === "all" || !currentAccountId ? (
        <NoAssetWrapper>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      ) : (
        <>
          <SettingsContainer>
            <SettingWrapper>
              <SettingsSection
                {...displaySettings.workSiteSummaryAlertSettings}
                isLoading={notificationUpdateLoading || loading}
              />
              <Recipients
                customEmails={workSiteSummaryAlertSettings?.customEmails}
                handleCustomEmailsChange={handleCustomEmailsChange}
                recipients={workSiteSummaryAlertSettings?.userTypes}
                handleRecipientsChange={handleRecipientsChange}
              />
            </SettingWrapper>
          </SettingsContainer>
          <SettingsFooter>
            <ActionsBar
              setWorksiteId={setWorksiteId}
              showAppyToAll={!isSiteAdmin}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleCancel={setDefaultNotificationSettings}
              isLoading={isLoading}
              worksiteId={worksiteId}
              showWorksiteSelection={false}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={checkApplyToAll}
              setCheckApplyToAll={setCheckApplyToAll}
              handleUpdate={onSubmitWorksiteSettings}
              handleReset={onReset}
              settingsOptions={settingsOptions}
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </SettingsFooter>
          <SnackBar
            isSuccess={notificationBulkUpdateSuccess || isUpdateLoading}
            label={t(validationConstant.notification_settings_updated)}
            handleClose={handleCloseSnackBar}
          />
        </>
      )}
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    isSuperAdmin: state.user.isSuperAdmin,
    loginDetails: state.user.loginDetails,
    isSiteAdmin: state.user.isSiteAdmin,
    notificationSetting: state.notificationSetting,
    dropdownAccountId: state.dropdownFilters?.settings?.accountId,
    dropdownWorksiteId: state.dropdownFilters?.settings?.worksiteId,
    isLoading: state.dropdownFilters.loading,
    loading: state.notificationSetting.loading,

    notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
    notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWorksiteSummaryAlertSettings: (siteId) => dispatch(getWorksiteSummaryAlertSettings(siteId)),
  updateWorksiteSummaryAlertSettings: (siteId, data, setIsUpdateLoading) =>
    dispatch(updateWorksiteSummaryAlertSettings(siteId, data, setIsUpdateLoading)),
  resetWorksiteSummaryAlertSettings: (siteId, setUpdateLoading) =>
    dispatch(resetWorksiteSummaryAlertSettings(siteId, setUpdateLoading)),
  bulkSettingsUpdate: (data, setUpdateLoading) => dispatch(bulkSettingsUpdate(data, setUpdateLoading)),
  bulkUpdateWorksiteSummaryAlertSettings: (data, setUpdateLoading) =>
    dispatch(bulkUpdateWorksiteSummaryAlertSettings(data, setUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorksiteSummarySettings);
