//TODO - When time permits, review and optmize this file. Remove unused/commented code. Create cleaner sub components

import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";

import QrCode from "../../../components/QrCodeAndDownload.component";
import UserProfile from "../../../assets/Profile_Photo.svg";
import UserOverviewTab from "./Tabs/UserOverviewTab.component";
import OperatorCertificatesTab from "./Tabs/operatorCertificate/OperatorCertificatesTab.component";

import UserInspectionReportTab from "./Tabs/UserInspectionReportTab.component";
import UserMultiCard from "./UserMultiCard.component";
import PasswordModal from "./modals/ChangePassword.component";
import ImageUpload from "../../../components/ImageRenderUpload.component";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import { setUpdateUserModal } from "../../../redux/modals/action";
import SnackBar from "../SnackBar";
import {
  userStatusUpdate,
  resetUsersState,
  updateUserDetail,
  getUserDetailById,
  getUserExportData,
  updateUserSuccess,
  getAllWorksiteUsersByAccountId,
} from "../../../redux/users/action";
import {
  updateUser,
  updateLoginUserDetail,
  updateAccountOwner,
  updateAccountOwnerResetFlags,
} from "../../../redux/user/user.action";
import { setEditForm } from "../../../redux/forms/action";
import { setEditAlertModal } from "../../../redux/modals/action";

import alertConstant from "../../../constant/messages/toaster.json";
import Input from "../../../components/Input";
import {
  toEditUserName,
  resetUserNameState,
  setSelectedAdminUser,
} from "../../../redux/userManagement/userManagement.action";
import UserTabBar from "./Tabs/UserTabBar.component";

import { apiCall } from "../../../utils/apiCall";
import AssetPermissionTab from "./Tabs/assetPermission/AssetPermissionTab.component";
import { getAllassetTypesWithCountByAccountId } from "../../../redux/assetType/assetType.action";
import { ManagementPanelWrapper, Error, ParagraphDiv } from "./UserOverview.component.styles";
import { CacheRefs } from "../../../utils/cache/CacheRefs";
import { toValidateUserName } from "../../../utils/validations/checkValidation";
import { getUserTypeDetailList, getUserTypeList } from "../../../redux/userType/action";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import ModalPopup from "../../../components/ModalPopupComponent/ModalPopup.component";
import AdminList from "./AdminList.component";

const s3BucketProfileImg = require("../../../config").s3BucketProfileImg; //User profile image s3 path
const permissionToLabel = require("../../../constant/permissionToLabel");
const qrType = require("../../../constant/qrType");

const styles = {
  titleName: {
    fontFamily: "Kanit",
    fontSize: "24px",
    color: "black",
    paddingTop: "2px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  accessLevelTitle: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    color: "#323232",
    paddingTop: "2px",
  },
  date: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#919191",
    //paddingTop: "8px",
  },
  safetyEnabled: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    color: "#0D5FBE",
    fontWeight: "bold",
    paddingTop: "7px",
  },
  message: {
    color: "rgba(0,0,0,0.5)",
  },
};

const UserOverview = ({
  userOverviewDetail,
  loginDetails,
  updateUserDetail,
  setUpdateUserModal,
  success,
  resetUserNameState,
  userStatusUpdate,
  statusSuccess,
  resetUsersState,
  inspectionCount,
  imageLoaded,
  setImageLoaded,
  UsersLoading,
  isSuperAdmin,
  UserDetailLoading,
  updateUser,
  getUserDetailById,
  updateUserSuccess,
  setEditForm,
  editForm,
  setEditAlertModal,
  addUserModal,
  updateLoginUserDetail,
  getAllassetTypesWithCountByAccountId,
  accountListById,
  getUserExportData,
  generateCSVData,
  generateCSVLoading,
  updateUserSuccessAction,
  getUserTypeDetailList,
  getUserTypeList,
  userTypeDetailList,
  userTypeList,
  StatusData,
  statusFailed,
  getAllWorksiteUsersByAccountId,
  allWorksitesUsersByAccountId,
  updateAccountOwner,
  updateAccountOwnerSuccess,
  updateAccountOwnerLoading,
  updateAccountOwnerResetFlags,
  updateAccountOwnerError,
  setSelectedAdminUser,
  newAdminUserData,
}) => {
  const {
    fullName,
    _id,
    status,
    email,
    phone,
    permissions,
    authorizedSites,
    unauthorizedAssetTypes,
    certificates,
  } = userOverviewDetail
    ? userOverviewDetail
    : {
        fullName: "",
        createdDate: "",
        accountId: "",
        _id: "",
        status: "",
        permissions: "",
        authorizedSites: "",
        unauthorizedAssetTypes: [],
        certificates: [],
      };

  const [statusButton, setStatusButton] = useState(status);
  const [image, setImage] = useState({});
  const [userRole, setUserRole] = useState("");
  const [isPin, setIsPin] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [UserName, setUserName] = useState("");
  const [IsEdit, setIsEdit] = useState(false);
  const [isLoadingPasswordChange, setLoadingPasswordChange] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [isUserDeactivated, setIsUserDeactivated] = useState(false);
  const [IsPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsError, setIsError] = useState(false);
  const [updatingImageLoading, setUpdatingImageLoading] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    phone: "",
    _id: "",
    fullName: "",
    permissions: "",
    authorizedSites: "",
    unauthorizedAssetTypes: [],
    certificates: [],
  });
  const [isPasswordError, setIsPasswordError] = useState(false);

  const [activeTab, setActiveTab] = useState("userOverview");
  //const [disableSwitch, setDisableSwitch] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getUserTypeDetailList();
    getUserTypeList(false, userOverviewDetail?.accountId);
  }, []);

  useEffect(() => {
    getUserTypeList(false, userOverviewDetail?.accountId);
  }, [userOverviewDetail?.accountId]);

  const getUsertypeObj = (id) => {
    const userType = userTypeDetailList?.find?.((userType) => id === userType._id);
    return userType;
  };

  const userType = getUsertypeObj(userOverviewDetail?.userTypeId);

  useEffect(() => {
    if (userType?.accessLevel === "INDIVIDUAL_EMPLOYEE") {
      getAllassetTypesWithCountByAccountId(userOverviewDetail?.accountId);
    }
  }, [userOverviewDetail?.permissions, userOverviewDetail?._id]);

  const getAuthorizedSitesArray = (authorizedSites) => {
    return authorizedSites?.map?.((site) => site._id);
  };

  useEffect(() => {
    if (userOverviewDetail) {
      let {
        fullName,
        createdDate,
        accountId,
        s3Enable,
        _id,
        status,
        email,
        phone,
        permissions,
        authorizedSites,
        unauthorizedAssetTypes,
        certificates,
      } = userOverviewDetail;

      setUserDetails({
        fullName,
        createdDate,
        accountId,
        s3Enable,
        _id,
        status,
        email,
        phone,
        permissions,
        unauthorizedAssetTypes,
        authorizedSites: getAuthorizedSitesArray(authorizedSites),
        certificates,
      });

      setStatusButton(status);
    }
  }, [userOverviewDetail]);

  const handleCancelButton = () => {
    setUserDetails({
      email,
      phone,
      _id,
      fullName,
      permissions,
      authorizedSites: getAuthorizedSitesArray(authorizedSites),
      unauthorizedAssetTypes,
      certificates,
    });
  };

  useEffect(() => {
    handleCancelButton();
  }, [editForm]);

  useEffect(() => {
    if (success) {
      setIsEdit(false);
    }
  }, [success]);

  useEffect(() => {
    if (statusSuccess) {
      setStatusButton(!statusButton);
    }
  }, [statusSuccess]);

  useEffect(() => {
    if (updateUserSuccess) {
      setEditForm(!editForm);
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    setUserRole(loginDetails ? loginDetails?.permission?.permissionName : "");
  }, [loginDetails]);

  useEffect(() => {
    if (userOverviewDetail) {
      image.imgData = userOverviewDetail.imgData ? s3BucketProfileImg + userOverviewDetail.imgData : "";
      setImage({ ...image });
    }
    setUserName(userOverviewDetail && userOverviewDetail.fullName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOverviewDetail]);

  useEffect(() => {
    setActiveTab("userOverview");
    //reset on unload of the page
    return () => {
      handleCloseSnackBar();
    };
  }, [UserDetailLoading]);

  const handleUpdatePhoto = (imgDataUpload, id) => {
    setUpdatingImageLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("userData", JSON.stringify({ email: userOverviewDetail.email }));
    bodyFormData.append("profileImg", imgDataUpload);

    apiCall(`/user/${id}`, { method: "PUT", data: bodyFormData })
      .then((response) => {
        setUpdatingImageLoading(false);
        setEditForm(true);
        if (response.data.success === true) {
          let imgData = response.data.data.imgData;
          updateUserDetail({ imgData, _id });
          if (userOverviewDetail.isOwner) {
            updateLoginUserDetail({ imgData });
          }
          setImageUploadSuccess(alertConstant.user_updated_successful);
        } else {
          //setUpdateError(response.data.message)
        }
      })
      .catch((error) => {
        //setUpdateError('Internal Error')
        console.log(error);
      });
  };

  const passwordChange = (e, passwordData) => {
    e.preventDefault();
    setLoadingPasswordChange(true);
    let url = "";
    if (!passwordData.pin) {
      url = `/user/password/change/${passwordData.userId}`;
    } else {
      url = `/user/update/pin`;
    }
    apiCall(url, { method: "PUT", data: passwordData })
      .then((response) => {
        setEditForm(true);
        if (response.data.success === true) {
          if (passwordData.pin) {
            updateUserDetail({ pin: passwordData.pin, _id: passwordData.userId });
            setIsPasswordChangeSuccess(alertConstant.pin_update);
          } else {
            setIsPasswordChangeSuccess(alertConstant.password_update);
          }

          setPasswordModal(false);
        } else {
          if (passwordData.pin) {
            setIsPasswordError(t("This PIN exists for another user. Please choose a different PIN."));
          } else {
            setIsPasswordError(t(response?.data?.message));
          }
          setLoadingPasswordChange(false);
        }
      })
      .catch((error) => {
        setIsPasswordError(t("Error"));
        setLoadingPasswordChange(false);
        console.log(error);
      });
  };

  const handleCloseSnackBar = () => {
    //setDisableSwitch(false);
    resetUserNameState();
    setIsPasswordChangeSuccess(false);
    setIsSuccess(false);
    setIsError(false);
    setImageUploadSuccess(false);
    setIsPasswordError(false);
    resetUsersState();
    updateAccountOwnerResetFlags();
    setIsUserDeactivated(false);
  };

  useEffect(() => {
    if (statusSuccess) {
      if (statusButton) {
        setIsUserDeactivated(true);
      }
    }
  }, [statusSuccess]);

  const handleMessage = (IsSuccess, IsError, IsPasswordChangeSuccess, statusSuccess, imageUploadSuccess) => {
    if (IsSuccess) {
      return t("User updated successfully");
    } else if (IsPasswordChangeSuccess) {
      return t(IsPasswordChangeSuccess) + ".";
    } else if (IsError) {
      return t("User Details Update Failed. Please try again");
    } else if (statusSuccess) {
      if (statusButton) {
        return t("The user is now active");
      } else if (!statusButton) {
        return t("The user has been deactivated");
      }
    } else if (imageUploadSuccess) {
      return t(imageUploadSuccess);
    } else if (isPasswordError) {
      return isPasswordError;
    }
  };
  const validationFailingForUserUpdate = () => {
    return toValidateUserName(userDetails?.fullName);
  };

  const handleUpdateUser = () => {
    if (validationFailingForUserUpdate()) {
      return;
    }
    setIsLoading(true);

    let bodyFormData = new FormData();
    bodyFormData.append("userData", JSON.stringify(userDetails));
    apiCall(`/user/${userDetails?._id}`, { method: "PUT", data: bodyFormData })
      .then((response) => {
        let { email } = userDetails;
        if (response?.data?.success) {
          getUserDetailById(response?.data?.data?._id, false);
          if (loginDetails?.sessionData?.userId === userDetails?._id) {
            updateUser({ email });
          }
          userDetails.userId = userDetails?._id;
          updateUserSuccessAction(userDetails);
          setEditForm(true);
          setIsSuccess(true);
          setIsLoading(false);

          //below is to refresh export data
          CacheRefs.removeCacheForUsers();
          getUserExportData();
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  };

  let currentUserID = loginDetails && loginDetails.userData && loginDetails.userData._id;
  let selectedUserID = userOverviewDetail && userOverviewDetail._id;

  let isUserSame = currentUserID === selectedUserID;

  const getUserTypeName = (id) => {
    const userType = userTypeDetailList?.find?.((userType) => id === userType._id);
    return userType?.name;
  };

  const getCommaseparatedWorksites = (sitesArr) =>
    Array.isArray(sitesArr) ? sitesArr?.map?.((site) => site.name).join(", ") : sitesArr;

  const handleAlertModalClose = () => {
    setShowAlert(false);
  };

  const handleAlertModalContinue = () => {
    setShowAlert(false);
    setShowAdminListModal(true);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showAdminListModal, setShowAdminListModal] = useState(false);

  const handleSelectAccountId = (newAdminId, newAdminName) => {
    setSelectedAdminUser({ adminId: newAdminId, adminName: newAdminName });
    setShowAdminListModal(false);
    setShowConfirmAlert(true);
  };

  const changeStatus = (e) => {
    if (editForm) {
      const id = e.target.id;
      const status = e.target.checked;

      if (!status && userOverviewDetail.isOwner) {
        setShowAlert(true);
        getAllWorksiteUsersByAccountId(userOverviewDetail.accountId);
      } else {
        userStatusUpdate(id, status);
      }
    } else {
      setEditAlertModal(true);
    }
  };

  useEffect(() => {
    return () => {
      setIsUserDeactivated(false);
    };
  }, []);

  const handleAdminListModalClose = () => {
    setShowAdminListModal(false);
  };

  //TODO
  const handleModalConfirm = () => {
    updateAccountOwner(userOverviewDetail._id, newAdminUserData?.adminId);
  };

  const handleModalConfirmClose = () => {
    setShowConfirmAlert(false);
    setShowAdminListModal(true);
  };

  const handleModalSuccessClose = () => {
    updateAccountOwnerResetFlags();
    getUserDetailById(userOverviewDetail._id, false);
  };

  useEffect(() => {
    if (updateAccountOwnerSuccess) {
      setShowAlert(false);
      setShowAdminListModal(false);
      setShowConfirmAlert(false);
    }
  }, [updateAccountOwnerSuccess]);

  return (
    <>
      <ManagementPanelWrapper>
        <Card className="worksite-height" style={{ minHeight: "calc(100vh - 322px)", height: "auto" }}>
          {(UsersLoading || UserDetailLoading) && (
            <Card.Body>
              <Row>
                <Col lg={2}>
                  <Skeleton circle={true} height={100} width={100} />
                </Col>
                <Col lg={3}>
                  <Skeleton height={40} />
                  <Skeleton height={10} count={3} />
                </Col>
                <Col lg={2}>
                  <Skeleton height={100} width={70} />
                </Col>
                <Col lg={3}>
                  <Skeleton height={70} width={130} />
                </Col>
                <Col lg={2}>
                  <Skeleton height={60} width={60} />
                </Col>
              </Row>
            </Card.Body>
          )}
          <Card.Body>
            {userOverviewDetail && !UsersLoading && !UserDetailLoading && (
              <Row>
                <Col lg={2} style={{}}>
                  <ImageUpload
                    id={userOverviewDetail._id}
                    updateImage={handleUpdatePhoto}
                    imageLoading={updatingImageLoading}
                    src={image.imgData}
                    defaultSrc={UserProfile}
                    key={image.imgData}
                  />
                </Col>

                <Col lg={3} className="text-left pl-0 pr-0">
                  {!editForm ? (
                    <div style={styles.date} className="user_card_details">
                      <Trans>First and Last Name</Trans>
                      <Form.Group className="mb-3" controlId="firstnlastname">
                        <Form.Control
                          value={userDetails?.fullName}
                          minLength={1}
                          maxLength={64}
                          onChange={(e) => {
                            // if(!toValidateUserName(e?.target?.value)){
                            setUserDetails({
                              ...userDetails,
                              fullName: e.target.value,
                            });
                            // }
                          }}
                          error={(IsSubmitted && !UserName) || toValidateUserName(userDetails?.fullName)}
                          fullWidth
                          helperText={IsSubmitted && !UserName && <Trans>Name is required</Trans>}
                          isInvalid={toValidateUserName(userDetails?.fullName)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {toValidateUserName(userDetails?.fullName)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  ) : (
                    <div style={styles.date} className="user_card_details">
                      {/*  <label style={labelStyle}><Trans>First and Last Name</Trans></label> */}
                      <span className="tooltipMUI" title={fullName}>
                        <h2 style={styles.titleName} className="elip_hover">
                          {fullName}
                        </h2>
                      </span>
                    </div>
                  )}

                  {!editForm && !userOverviewDetail.isOwner ? (
                    <Input
                      showLabel={true}
                      label="User Type"
                      type="select"
                      options={userTypeList.map((type) => ({
                        _id: type._id,
                        label: type.name,
                      }))}
                      value={userOverviewDetail.userTypeId}
                      onChange={(userTypeId) => {
                        if (userOverviewDetail.userTypeId !== userTypeId) {
                          setUpdateUserModal({
                            ...userOverviewDetail,
                            ...userDetails,
                            oldUserTypeId: userOverviewDetail.userTypeId,
                            newUserTypeId: userTypeId,
                            authorizedSites: userDetails?.authorizedSites,
                            password: undefined,
                          });
                        }
                      }}
                    />
                  ) : (
                    <div style={styles.date} className="asset-type-size text_Repo_elips">
                      <Trans>User Type</Trans>:&nbsp;
                      <span
                        title={
                          (userOverviewDetail.isOwner && "Account Owner") ||
                          permissionToLabel[userOverviewDetail ? userOverviewDetail.permissions : ""]
                        }
                      >
                        <Trans>
                          {getUserTypeName(userOverviewDetail.userTypeId) ||
                            (userOverviewDetail.isOwner && "Account Owner")}{" "}
                        </Trans>
                      </span>
                    </div>
                  )}

                  <div style={styles.date} className="asset-type-size text_Repo_elips">
                    <Trans>Account No</Trans>:
                    <span> {userOverviewDetail && userOverviewDetail.accountNo}</span>
                  </div>

                  {isSuperAdmin && userOverviewDetail?.accountId && (
                    <div style={styles.date} className="asset-type-size text_Repo_elips">
                      <Trans>AccountLabel</Trans>
                      <span title={accountListById?.[userOverviewDetail?.accountId]?.companyName}>
                        {" "}
                        {accountListById?.[userOverviewDetail?.accountId]?.companyName}
                      </span>
                    </div>
                  )}
                  <div style={styles.date} className="asset-type-size text_Repo_elips">
                    <Trans>PIN</Trans>:
                    <span title={userOverviewDetail && userOverviewDetail.pin}>
                      {" "}
                      {userOverviewDetail && userOverviewDetail.pin}
                    </span>
                  </div>
                  {(userType?.accessLevel === "INDIVIDUAL_EMPLOYEE" ||
                    userType?.accessLevel === "SINGLE_WORKSITE" ||
                    userType?.accessLevel === "MULTIPLE_WORKSITES") && (
                    <div style={styles.date} className="asset-type-size text-open-sans text_Repo_elips">
                      <Trans>Worksite(s)</Trans>:&nbsp;
                      <span title={getCommaseparatedWorksites(userOverviewDetail?.authorizedSites)}>
                        {getCommaseparatedWorksites(userOverviewDetail?.authorizedSites)}
                      </span>
                    </div>
                  )}
                </Col>

                <Col lg={2} className="text-center">
                  <QrCode qrFor={qrType.USERS} qrDetail={userOverviewDetail} />
                </Col>
                <Col lg={3}>
                  <UserMultiCard data={inspectionCount} />
                </Col>

                <Col lg={2}>
                  <PermissionCheck
                    section="DASHBOARD"
                    permissionName={"USER_MANAGEMENT"}
                    actionName="deactivate"
                  >
                    {!isUserSame && (
                      <>
                        <p className="m-0 statusStyle">
                          <Trans>Status</Trans>
                        </p>

                        <SwitchComponent
                          id={userOverviewDetail?._id}
                          type="checkbox"
                          checked={statusButton}
                          onChange={changeStatus}
                        />
                      </>
                    )}
                  </PermissionCheck>

                  {statusButton && (
                    <div className="div_user_pin" style={{ textAlign: "-webkit-center" }}>
                      <PermissionCheck
                        section="DASHBOARD"
                        permissionName={"USER_MANAGEMENT"}
                        actionName="edit"
                      >
                        {userType?.accessLevel !== "INDIVIDUAL_EMPLOYEE" && (
                          <div
                            onClick={() => {
                              setIsPin(false);
                              setPasswordModal(true);
                            }}
                            className="btn btn-sm btn-success m-0 change_pin_style"
                          >
                            <Trans>Change Password</Trans>
                          </div>
                        )}

                        <div
                          onClick={() => {
                            setIsPin(true);
                            setPasswordModal(true);
                          }}
                          className="btn btn-sm btn-success m-0 changePinB mt-1"
                        >
                          <Trans>Change PIN</Trans>
                        </div>
                      </PermissionCheck>
                    </div>
                  )}
                </Col>
              </Row>
            )}

            {userOverviewDetail && !UsersLoading && !UserDetailLoading && (
              <>
                <UserTabBar
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  isOperator={userType?.accessLevel === "INDIVIDUAL_EMPLOYEE"}
                />

                {activeTab === "userOverview" && (
                  <UserOverviewTab
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    userOverviewDetails={userOverviewDetail}
                    handleUpdateUser={handleUpdateUser}
                    activeTab={activeTab}
                  />
                )}

                {activeTab === "operatorCertificates" && (
                  <OperatorCertificatesTab
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    handleUpdateUser={handleUpdateUser}
                    activeTab={activeTab}
                  />
                )}

                {activeTab === "inspectionReports" && <UserInspectionReportTab activeTab={activeTab} />}
                {activeTab === "assetPermissions" && (
                  <AssetPermissionTab
                    editForm={editForm}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    userOverviewDetails={userOverviewDetail}
                    handleUpdateUser={handleUpdateUser}
                    activeTab={activeTab}
                  />
                )}
              </>
            )}
            <PasswordModal
              id={userOverviewDetail ? userOverviewDetail._id : ""}
              isPin={isPin}
              changePassword={passwordChange}
              showModal={passwordModal}
              setModal={() => setPasswordModal()}
              isLoadingPasswordChange={isLoadingPasswordChange}
              setLoadingPasswordChange={setLoadingPasswordChange}
            />
          </Card.Body>
          {!userOverviewDetail && !UsersLoading && !UserDetailLoading && (
            <>
              <div className="noSection">
                <Typography align="center" style={styles.message}>
                  <Trans>There is no user in your worksite right now</Trans>.
                </Typography>
                <Typography align="center" style={styles.message}>
                  <Trans>Click '+ ADD USER' to add a User</Trans>
                </Typography>
              </div>
            </>
          )}
        </Card>
      </ManagementPanelWrapper>
      <SnackBar
        isSuccess={
          (IsSuccess || IsPasswordChangeSuccess || statusSuccess || imageUploadSuccess) && !isUserDeactivated
        }
        isWarning={IsError || isPasswordError}
        isFailed={IsSuccess || isUserDeactivated}
        label={handleMessage(
          IsSuccess,
          IsError,
          IsPasswordChangeSuccess,
          statusSuccess,
          imageUploadSuccess,
          isPasswordError,
          isUserDeactivated
        )}
        handleClose={handleCloseSnackBar}
      />

      <ModalComponent
        show={showAlert}
        variant="AlertModal"
        iconClass="alertsettings"
        paragraphWidth="80%"
        message="DEACTIVATING ACCOUNT OWNER"
        paragraph={
          <ParagraphDiv>
            Caution! You are deactivating <b>{fullName}</b> as the account owner of{" "}
            <b>{accountListById?.[userOverviewDetail?.accountId]?.companyName}</b>. If you wish to continue
            with this action then you must replace another user as the Account Owner for this account. Are you
            sure you wish to continue?
          </ParagraphDiv>
        }
        button1Text="Cancel"
        onClickA={handleAlertModalClose}
        typeA="primary"
        button2Text="Continue"
        typeB="secondary"
        onClickB={handleAlertModalContinue}
      />

      <ModalPopup
        show={showAdminListModal}
        size="lg"
        centered
        backdrop="static"
        title="New Account Owner"
        handleClose={handleAdminListModalClose}
      >
        <AdminList
          companyName={accountListById?.[userOverviewDetail?.accountId]?.companyName}
          adminList={allWorksitesUsersByAccountId}
          handleSelectAccountId={handleSelectAccountId}
        />
      </ModalPopup>

      <ModalComponent
        show={showConfirmAlert}
        variant="AlertModal"
        paragraphWidth="80%"
        iconClass="alertsettings"
        message="Confirm New Account Owner"
        paragraph={
          <ParagraphDiv>
            You have selected <b>{newAdminUserData?.adminName}</b> as the new Account Owner of{" "}
            <b>{accountListById?.[userOverviewDetail?.accountId]?.companyName}</b>. Do you confirm?
            {updateAccountOwnerError && <Error>Error Occurred</Error>}
          </ParagraphDiv>
        }
        button1Text="Confirm"
        onClickA={handleModalConfirm}
        typeA="primary"
        button2Text="Cancel"
        typeB="secondary"
        onClickB={handleModalConfirmClose}
      />

      <ModalComponent
        show={updateAccountOwnerSuccess}
        variant="AlertModal"
        paragraphWidth="80%"
        iconClass="certificate-ok"
        message="New Account Owner Confirmed"
        paragraph={
          <ParagraphDiv>
            Congratulations! <b>{newAdminUserData?.adminName}</b> is now the new account owner of{" "}
            <b>{accountListById?.[userOverviewDetail?.accountId]?.companyName}</b>. An Acknowledgement email
            has also been sent to <b>{newAdminUserData?.adminName}</b>'s email ID.
          </ParagraphDiv>
        }
        button1Text="Continue"
        typeA="primary"
        onClickA={handleModalSuccessClose}
      />
      <SnackBar
        isFailed={updateAccountOwnerError}
        label={"Error Occurred while updating Account Owner. Please try again."}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userOverviewDetail: state.users.UserOverview,
  loginDetails: state.user.loginDetails,
  loading: state.userManagement.Loading,
  success: state.userManagement.Success,
  statusSuccess: state.users.StatusSuccess,
  statusMessage: state.users.StatusMessage,
  inspectionCount: state.counts.inspectionCount,
  UsersLoading: state.users.UsersLoading,
  isSuperAdmin: state.user.isSuperAdmin,
  UserDetailLoading: state.users.userDetailLoading,
  updateUserSuccess: state.users.updateUserSuccess,
  editForm: state.forms.editForm,
  addUserModal: state.modals.addUserModal,
  accountListById: state.dropdownFilters.accountListById,
  generateCSVData: state.users.GeneratingCSVData,
  generateCSVLoading: state.users.GenerateCSVLoading,
  userTypeDetailList: state.userType.userTypeDetailList,
  userTypeList: state.userType.UserTypeList,
  StatusData: state.users.StatusData,
  statusFailed: state.users.StatusFailed,
  allWorksitesUsersByAccountId: state.users.allWorksitesUsersByAccountId,
  updateAccountOwnerSuccess: state.user.updateAccountOwnerSuccess,
  updateAccountOwnerError: state.user.updateAccountOwnerError,
  updateAccountOwnerLoading: state.user.updateAccountOwnerLoading,
  newAdminUserData: state.userManagement.newAdminUserData,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserDetail: (userDetails) => dispatch(updateUserDetail(userDetails)),
  updateLoginUserDetail: (details) => dispatch(updateLoginUserDetail(details)),
  updateUser: (email) => dispatch(updateUser(email)),
  toEditUserName: (value, id) => dispatch(toEditUserName(value, id)),
  resetUserNameState: () => dispatch(resetUserNameState()),
  userStatusUpdate: (id, status) => dispatch(userStatusUpdate(id, status)),
  resetUsersState: () => dispatch(resetUsersState()),
  setUpdateUserModal: (details) => dispatch(setUpdateUserModal(details)),
  getUserDetailById: (id, bool) => dispatch(getUserDetailById(id, bool)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  getAllassetTypesWithCountByAccountId: (accountId) =>
    dispatch(getAllassetTypesWithCountByAccountId(accountId)),
  getUserExportData: (siteId) => dispatch(getUserExportData(siteId)),
  updateUserSuccessAction: (userDetails) => dispatch(updateUserSuccess(userDetails)),
  getUserTypeDetailList: () => dispatch(getUserTypeDetailList()),
  getUserTypeList: (hideLoading, accId) => dispatch(getUserTypeList(hideLoading, accId)),
  getAllWorksiteUsersByAccountId: (accountId) => dispatch(getAllWorksiteUsersByAccountId(accountId)),
  updateAccountOwner: (currentAccountOwner, newAccountOwner) =>
    dispatch(updateAccountOwner(currentAccountOwner, newAccountOwner)),
  updateAccountOwnerResetFlags: () => dispatch(updateAccountOwnerResetFlags()),
  setSelectedAdminUser: (newAdminUserData) => dispatch(setSelectedAdminUser(newAdminUserData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOverview);
