import React, { useState } from "react";
import DataTableUnAuthorizedReports from "../../../../components/DataTableComponent/DataTable.component";

import { tableHeader, rowPerPageDropdown } from "./constants";

import { toDownloadFiles } from "../../../../redux/config/action";
import awsConstant from "../../../../constant/awsFolder.json";
import PopoverCustom from "../../../../components/PopoverCustom.component";
import ReportDownload from "../ReportShare/ReportShareModal.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Checkbox from "../../../../components/Checkbox/Checkbox.component";
import moment from "moment-timezone";
import { getTimeWithTimeZone } from "../../../../utils/timezoneConvert";

import { StyledSpan } from "./TableWrapper.component.styles";

const UnAuthorizedReportsDataTable = ({
  data,
  loginDetails,
  isSuperAdmin,
  isSiteAdmin,
  toDownloadFiles,
  setIsSuccess,
  setIsError,
  fileDownloadLoading,
  handleCheckboxClick,
  handleSelectAll,
  downloadReportSet,
  isAllSelected,
  selectedAccount,
  handlePageLength,
  totalUnauthorizedReportCount,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  rowHeight,
}) => {
  const [loadingReportId, setLoadingReportId] = useState("");

  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.unauthorizedReport, `${id}.pdf`);
  };

  const viewReportClickHandler = (e, row) => {
    localStorage.setItem("unitType", loginDetails?.unitType);
    localStorage.setItem("unauthorizedReportObject", JSON.stringify(row));
    window.open("/#/app/unauthorized-access-report", "_blank");
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i title="View" onClick={(e) => viewReportClickHandler(e, row)} className="icon icon-view"></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
            ></i>
            {/* <i className="icon icon-share"></i> */}
            <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 3,
                        reportId: row?._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>
          </div>
        )}
      </>
    );
  };

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          value={row._id}
          checked={downloadReportSet.has(row._id)}
        />
      ),
    },
  ];

  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name == "Account") {
        return {
          name: data.name,
          selector: (row) => row?.accountName || "NA",
          sortable: true,
        };
      }
      if (data.name == "Worksite") {
        return {
          name: data.name,
          selector: (row) => row?.siteName || "NA",
          sortable: true,
        };
      }

      if (data.name == "Asset Type") {
        return {
          name: data.name,
          selector: (row) => row?.assetType || "NA",
          sortable: true,
        };
      }

      if (data.name == "Asset Name") {
        return {
          name: data.name,
          selector: (row) => row?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Actions") {
        return {
          name: data.name,
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      if (data.name === "Date, Time") {
        return {
          name: data.name,
          selector: (row) => {
            const isStatus = row.hasOwnProperty("status");
            const isDate = row.hasOwnProperty("impactRecordedAt") && typeof row.impactRecordedAt === "string";
            const feildName = "impactRecordedAt";

            if (!isStatus && !isDate) {
              return row[feildName] || row.email || "NA";
            } else if (isDate) {
              return `${moment(row[feildName])
                .utcOffset(row?.timeZone?.split?.(" ")[0])
                .format("MMM-DD-YYYY, hh:mm A")} ${getTimeWithTimeZone(row?.timeZone)}`;
            } else {
              return "NA";
            }
          },
          sortable: true,
        };
      }

      return {
        name: data.name,
        selector: () => data.feildName,
        sortable: true,
      };
    });

  columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Account", className: "accountStyle" },
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Asset Type", className: "assetTyleStyle" },
    { columnName: "Asset Name", className: "assetNameStyle" },
    { columnName: "Incident By", className: "inspectedByStyle" },
    { columnName: "Report Status", className: "reportStatusStyle" },
  ];

  const mobileCheckboxAccordian = [
    "Account",
    "Worksite",
    "Asset Type",
    "Asset Name",
    "Incident By",
    "Actions",
  ];

  if (!Array.isArray(data)) {
    return <StyledSpan height={rowHeight}>No Unauthorized reports available</StyledSpan>;
  }

  return (
    <>
      {!selectedAccount && isSuperAdmin ? (
        <StyledSpan height={rowHeight}>Please select an account to view the reports</StyledSpan>
      ) : (
        <DataTableUnAuthorizedReports
          title=""
          columns={columns}
          data={data}
          pagination
          responsive
          dense
          rowsPerPageOptions={[10, 25, 50, 100]}
          loading
          rowHeight={"370"}
          scrollable={true}
          fixedHeader
          mobileCheckboxOnly
          mobileDateTimeOnly
          mobileActionItemOnly
          mobileCheckboxDateTime={mobileCheckboxDateTime}
          mobileCheckboxAccordion={mobileCheckboxAccordian}
          noDataMessage="No Unauthorized reports available"
          pageSize={pageSize}
          pageNo={pageNo}
          totalPageReport={totalUnauthorizedReportCount}
          handlePageLength={handlePageLength}
          paginationOptions={rowPerPageDropdown}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  unauthorizedReportList: state.reports.unauthorizedReportList,
  getUnauthorizedReportLoading: state.reports.getUnauthorizedReportLoading,
  totalUnauthorizedReportCount: state.reports.totalUnauthorizedReportCount,
  getZipFileImpactReportLoading: state.reports.getZipFileImpactReportLoading,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  unauthorizedAccessFilter: state.filters.unauthorizedAccessFilter,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnAuthorizedReportsDataTable));
