import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
// import { ReactComponent as RightArrow } from "../../../../../assets/AddIcon/RightArrow.svg";
// import { ReactComponent as AssetTypeOverviewIcon } from "../../../../../assets/AssetTypeOverview.svg";
// import { ReactComponent as AssetTypeDetailsIcon } from "../../../../../assets/AssetTypeDetails.svg";
// import { ReactComponent as AssetTypeChecklistIcon } from "../../../../../assets/AssetTypeChecklistsIcon.svg";
//import CustomStepper from "../../../../../components/CustomStepper/CustomStepper.component";
import CustomStepper from "../../../../../components/StepperComponent/Stepper.Component";
// import Button from "../../../../../components/Button/Button.component";
import AddAssetTypeDetails from "./AddAssetTypeDetails.component";
import AddAssetTypeOverview from "./AddAssetTypeOverview.component";
import AddAssetTypeChecklist from "./AddAssetTypeChecklist.component";
import ModalComponent from "../../../../../components/ModalPopupComponent/ModalPopup.component"; // Importing the custom modal component
import styled from "styled-components";
const AssetTypeOverviewIcon = styled.div`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;
const AssetTypeDetailsIcon = styled.div`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;
const AssetTypeChecklistIcon = styled.div`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

function AddAssetTypeModal(props) {
  const { t } = useTranslation();
  const [dropdownAccountId, setAccountId] = useState("");
  const [validated, setValidated] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [addAssetTypeModalData, setAddAssetTypeModalData] = useState({
    assetTypeName: "",
    AssetTypeDescription: "",
    AssetTypeAttributes: [
      {
        id: 1234,
        AssetAttributeName: "Make",
        AssetAttributeRequired: false,
        AssetAttributeDescription: "Manufacturer of the asset",
        disabled: false,
        removeDelete: true,
      },
      {
        id: 2345,
        AssetAttributeName: "Model",
        AssetAttributeRequired: false,
        AssetAttributeDescription: "Model Type",
        disabled: false,
        removeDelete: true,
      },
      {
        id: 3456,
        AssetAttributeName: "Serial Number",
        AssetAttributeRequired: false,
        AssetAttributeDescription: "Manufacturer’s serial number",
        disabled: false,
        removeDelete: true,
      },
    ],
  });

  // const allSteps = [
  //   {
  //     icon: <AssetTypeOverviewIcon className="icon icon-assettypeoverview" />,
  //     heading: t("Asset Type Overview"),
  //   },
  //   {
  //     icon: <AssetTypeDetailsIcon className="icon icon-assettypedetails" />,
  //     heading: t("Asset Type Detail"),
  //   },
  //   {
  //     icon: <AssetTypeChecklistIcon className="icon icon-assettypechecklistsicon" />,
  //     heading: t("Asset Type Checklists"),
  //   },
  // ];
  const steps = [
    { iconClass: "assettypeoverview", heading: t("Asset Type Overview") },
    { iconClass: "assettypedetails", heading: t("Asset Type Detail") },
    { iconClass: "assettypechecklistsicon", heading: t("Asset Type Checklists") },
  ];

  const getAddAssetTypeModalStep = (step, setActiveStep, handleCloseModal) => {
    switch (step) {
      case 0:
        return (
          <AddAssetTypeOverview
            addAssetTypeModalData={addAssetTypeModalData}
            setAddAssetTypeModalData={setAddAssetTypeModalData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setAccountId={setAccountId}
            dropdownAccountId={dropdownAccountId}
            validated={validated}
            setValidated={setValidated}
          />
        );
      case 1:
        return (
          <AddAssetTypeDetails
            addAssetTypeModalData={addAssetTypeModalData}
            setAddAssetTypeModalData={setAddAssetTypeModalData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            dropdownAccountId={dropdownAccountId}
            handleCloseModal={handleCloseModal}
            setIsError={props.setIsError}
            setIsSuccess={props.setIsSuccess}
            validated={validated}
            setValidated={setValidated}
          />
        );
      case 2:
        return (
          <AddAssetTypeChecklist
            dropdownAccountId={dropdownAccountId}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            addAssetTypeModalData={addAssetTypeModalData}
            setAddAssetTypeModalData={setAddAssetTypeModalData}
            setIsError={props.setIsError}
            setIsSuccess={props.setIsSuccess}
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        return <AddAssetTypeOverview />;
    }
  };

  useEffect(() => {
    console.log(addAssetTypeModalData);
  }, [addAssetTypeModalData]);

  useEffect(() => {
    resetModalData();
  }, []);

  const resetModalData = () => {
    setActiveStep(0);
    setAccountId("");
    setAddAssetTypeModalData({
      assetTypeName: "",
      assetTypeDescription: "",
      AssetTypeAttributes: [
        {
          id: 1234,
          AssetAttributeName: "Make",
          AssetAttributeRequired: false,
          AssetAttributeDescription: "Manufacturer of the asset",
          disabled: false,
          removeDelete: true,
        },
        {
          id: 2345,
          AssetAttributeName: "Model",
          AssetAttributeRequired: false,
          AssetAttributeDescription: "Model Type",
          disabled: false,
          removeDelete: true,
        },
        {
          id: 3456,
          AssetAttributeName: "Serial Number",
          AssetAttributeRequired: false,
          AssetAttributeDescription: "Manufacturer’s serial number",
          disabled: false,
          removeDelete: true,
        },
      ],
    });
  };

  const handleModalCloseCustom = () => {
    resetModalData();
    setValidated(false);
    props.toogleModalClose();
  };

  return (
    <ModalComponent
      show={props.show}
      title={<Trans>Add Asset Type</Trans>}
      handleClose={handleModalCloseCustom}
      withHeader
    >
      <CustomStepper className="mt-2 mb-4" allSteps={steps} activeStep={activeStep} />
      {getAddAssetTypeModalStep(activeStep, setActiveStep, handleModalCloseCustom)}
    </ModalComponent>
  );
}

AddAssetTypeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toogleModalClose: PropTypes.func.isRequired,
  setIsError: PropTypes.func.isRequired,
  setIsSuccess: PropTypes.func.isRequired,
};

export default AddAssetTypeModal;
