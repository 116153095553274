import styled from "styled-components";

export const StyledSpan = styled.span`
  text-align: center;
  clear: both;
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const StatusDiv = styled.div`
  .status-pending {
    border-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .status-in-progress {
    border-color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
    color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  }
  .status-failed {
    border-color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  }
  .status-completed {
    border-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  }
`;

export const StatusSpan = styled.div`
  border: 1px solid;
  border-radius: 4px;
  width: 130px;
  float: left;
  text-align: center;
  line-height: 25px;
  position: relative;
`;
