import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Row, Button, Col, Table, Accordion, Card, Form } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { ReactComponent as Download } from "../../../../../assets/Download.svg";
import Information from "../../../../../assets/Information_Icon.svg";
import Dropdown from "../../../../../components/DropdownFilter.component";

import { Trans, useTranslation } from "react-i18next";
import { getAssetBulkUploadTemplates } from "../../../../../redux/bulkUpload/action";
import {
  DownloadStyled,
  AssetBulkUploadTableView,
  RowDiv,
  Column,
  RowsContainer,
  DownloadAnchor,
  NoAssetDivView,
} from "./AssetBulkUploadDownloadTemplate.component.styles";

function AssetBulkUploadDownloadTemplate(props) {
  let {
    assetTypeTemplateList,
    getAssetBulkUploadTemplates,
    assetBulkUploadLoading,
    loginDetails,
    isSuperAdmin,
    dropdownAccountList,
  } = props;

  const [isAccordianVisible, setisAccordianVisible] = useState(false);
  const [templateList, setTemplateList] = useState(assetTypeTemplateList);
  const [dropdownAccountId, setDropdownAccountId] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (!isSuperAdmin) {
      getAssetBulkUploadTemplates(loginDetails?.accountId);
    }
  }, []);

  useEffect(() => {
    setTemplateList(assetTypeTemplateList);
  }, [assetTypeTemplateList]);

  useEffect(() => {
    if (isSuperAdmin && !dropdownAccountId) {
      setDropdownAccountId(dropdownAccountList?.[0] && dropdownAccountList?.[0]?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAccountList]);

  useEffect(() => {
    if (dropdownAccountId) getAssetBulkUploadTemplates(dropdownAccountId);
  }, [dropdownAccountId]);

  return (
    <>
      <Row>
        {isSuperAdmin && (
          <Col lg={4}>
            <Dropdown
              filter={{
                type: "Account",
                name: "companyName",
                key: "companyName",
                data: dropdownAccountList,
              }}
              value={dropdownAccountId}
              label={t("filter")}
              useAsDropdown={true}
              handleFilter={(value) => {
                // setDropdownAccountId(value);
                // setAccountId(value)
                setDropdownAccountId(value);
              }}
            />
          </Col>
        )}
      </Row>

      <AssetBulkUploadTableView>
        <RowDiv isHeader={true}>
          <Column isHeader={true} align={"left"}>
            <Trans>File Name</Trans>
          </Column>
          <Column isHeader={true} align={"left"}>
            <Trans>Description</Trans>
          </Column>

          <Column isHeader={true} align={"center"}>
            <Trans>Action</Trans>
          </Column>
        </RowDiv>
        {assetBulkUploadLoading ? (
          <DownloadAnchor>
            <p style={{ textAlign: "center", width: "100%" }}>
              <CircularProgress size={25} style={{ color: "#000", margin: "auto" }} />
            </p>
          </DownloadAnchor>
        ) : (
          <RowsContainer>
            {templateList?.map((templateData) => (
              <>
                <RowDiv>
                  <Column
                    align={"left"}
                    textleft={"left"}
                    widthResp={"75%"}
                    floatResp={"left"}
                    RmarginResp={"5%"}
                    title={templateData?.assetType || "NA"}
                  >
                    {templateData?.assetType || "NA"}
                  </Column>

                  <Column
                    align={"left"}
                    textleft={"left"}
                    widthResp={"20%"}
                    floatResp={"none"}
                    title={templateData?.AssetTypeDescription || "NA"}
                  >
                    {templateData?.AssetTypeDescription || "NA"}
                  </Column>
                  <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                    <a
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      href={`${templateData?.templateUrl || "#"}`}
                      download="inspection-report"
                    >
                      <DownloadStyled className="icon icon-download" />
                    </a>
                  </Column>
                </RowDiv>
              </>
            ))}
          </RowsContainer>
        )}
        {templateList?.length == 0 && !assetBulkUploadLoading && (
          <NoAssetDivView>
            <p style={{ textAlign: "center", width: "100%" }}>No asset type</p>
          </NoAssetDivView>
        )}
      </AssetBulkUploadTableView>

      {/* <Row className="bottom_bulk_row" style={{ width: "100%", margin: "0px" }}>
                <Col className="instruction-bottom" style={{ padding: "0px" }}>
                    <Accordion className="text-open-sans accordion_bulk">
                        <Card style={{ border: "none", padding: "0px" }} >
                            <Card.Header className={isAccordianVisible ? "bulk_information_header" : "bulk_information_header_white"}>
                                <Accordion.Toggle onClick={() => setisAccordianVisible(!isAccordianVisible)} as={Button} variant="button" tabIndex="0" eventKey="0" className="info_button">
                                    <img src={Information} /><Trans>IMPORTANT INSTRUCTIONS</Trans>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="bulk_information"><div className="line_vertical"></div><Trans>Please select the correct worksite,asset type and the SIERA.AI Product before submitting the file to avoid errors in the asset's information</Trans>.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    </Accordion>
                </Col>
                <Col className="download-top" style={{ padding: "0px" }}>
                    <button className="buttonSubmit bulk_submit_button">
                        <>
                            <Download className="download_svg_bulk" />
                            <Trans>DOWNLOAD ALL</Trans>
                        </>
                    </button>

                </Col>
            </Row> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  assetTypeTemplateList: state.bulkUpload.assetTypeTemplateList,
  assetBulkUploadLoading: state.bulkUpload.assetBulkUploadLoading,
  dropdownAccountList: state.dropdownFilters.accountList,
});

const mapDispatchToProps = (dispatch) => ({
  getAssetBulkUploadTemplates: (accountId) => dispatch(getAssetBulkUploadTemplates(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBulkUploadDownloadTemplate));
